// AuthContext.tsx (TypeScript) or AuthContext.js (JavaScript)
import React, { createContext, useContext, ReactNode, useRef } from 'react';
import useAuthentication from 'src/hooks/useAuthentication';

interface AuthContextProps {
  // If you want to expose any specific data or functions
  // from the useAuthentication hook, you can define them here
  renders: number;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

/**
 * Wraps your useAuthentication() hook in a Context Provider.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // Call your custom hook to initialize authentication
  const renderRef = useRef<number>(1);
  useAuthentication();

  return (
    <AuthContext.Provider
      value={{
        renders: renderRef.current,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Consumer hook for using authentication context
 */
export const useAuth = () => {
  return useContext(AuthContext);
};
