import React, { useRef } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

let submitTimer;

export const TextAnswer = ({ placeholder, answer, onChange, tabIndex }) => {
  const { t } = useTranslation();
  // use reference instead of state in order for setTimeout scope to have the most recent value
  // (otherwise, it gets locked to when the timeout started)
  const answerText = useRef(answer.answer || '');

  const handleTextChange = (event, data) => {
    answerText.current = data.value;
    submit();
    if (submitTimer) {
      clearTimeout(submitTimer);
      submitTimer = null;
    }
    submitTimer = setTimeout(function () {
      submit();
      submitTimer = null; // remove timer
    }, 500); // submit after 0.5 sec of inactivity
    // })
  };

  const handleKeyDown = event => {
    var TABKEY = 9;
    if (event.key === 'Enter' || event.which === TABKEY) {
      // send change event
      event.preventDefault();
      submit();
    }
  };

  const submit = () => {
    // update if the answer has changed
    if (answerText.current !== answer.answer) {
      onChange(answerText.current);
    }
  };

  return (
    <Form.TextArea
      tabIndex={tabIndex}
      className="answer"
      rows={2}
      placeholder={placeholder || t('Answer')}
      value={answerText.current}
      onChange={handleTextChange}
      onBlur={submit}
      onKeyDown={handleKeyDown}
    />
  );
};

export default TextAnswer;
