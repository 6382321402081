/* eslint-disable */
module.exports = {
  en: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Please note that when messaging a reader your username and not your pen name will be visible',
      Library: 'Library',
      Account: 'Account',
      EmptyLibrary: 'Your library is empty.',
      EmptyLibraryAction:
        "Were you expecting to find something here? Contact the author, or send us an email on {{_email}}, and we'll see what we can do.",
      ByAuthor: 'by {{_authorName}}',
      ExceedChapterLenghtWarning: 'Chapter is too long to import',
      WordCount: '{{_wordCount}} words',
      WordCountString: '{{count}} word',
      WordCountString_plural: '{{count}} words',
      CharCountString: '{{count}} character',
      CharCountString_plural: '{{count}} characters',
      SurveyCount: '{{count}} survey',
      SurveyCount_plural: '{{count}} surveys',
      ChapterCount: '{{count}} chapter',
      ChapterCount_plural: '{{count}} chapters',
      QuestionCountString: '{{count}} question',
      QuestionCountString_plural: '{{count}} questions',
      LoginToChangeEmail: 'Please login again to change your e-mail',
      SendVerification: 'Send verification link',
      Name: 'Name',
      Email: 'Email',
      EnterYourName: 'Please enter your name',
      ReceiveCompanyUpdates: 'Receive emails and news from BetaReader',
      ReceiveBookEmails: 'Recieve emails about new books',
      ReceiveBookActivityUpdates: 'Receive emails about activity on my books',
      ReceiveMessageUpdates: 'Receive emails when I get new messages',
      IncludeProfileInReaderList: 'Include my profile in the reader database',
      PrivacyPolicy: 'Privacy policy',
      TermsOfService: 'Terms of Service',
      ReadOurBlog: 'Read our blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Next',
      Back: 'Back',
      CreateAccount: 'Create account',
      CreateAccountPrompt:
        '<0>Do you not have an account? <1><0>Create one here!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Forgot your password? <1><0>Reset it here!</0></1></0>',
      SignIn: 'Sign in',
      SignOut: 'Sign out',
      SendResetLink: 'Send reset link',
      ResetPassword: 'Reset password',
      Password: 'Password',
      OrUse: 'or use',
      NoConnection: 'Offline...',
      AcceptTermsMessage: 'Accept the terms of service',
      AcceptToSToCreateAccount:
        'You have to accept our terms of service to create an account.',
      Answer: 'Answer',
      FailedToLoadBook: "Couldn't load the book.",
      PullDownToRefresh: 'Pull down to refresh.',
      ContactSupport: 'Contact support',
      Support: 'Support',
      LastRead: 'Last read',
      XInvitations: '{{count}} invitation',
      XInvitations_plural: '{{count}} invitations',
      EnterComment: 'Comment...',
      EnterReply: 'Reply...',
      Reply: 'Reply',
      Reply_plural: 'Replies',
      Reply_verb: 'Reply',
      show: 'show',
      hide: 'hide',
      Comments: 'Comments',
      InlineComment: 'Inline comment',
      Reject: 'Reject',
      Accept: 'Accept',
      Error: 'Error',
      failed: 'failed',
      SelectedText: 'Selected text',
      FailedToFetchUserProfile: 'Failed to fetch user profile.',
      ProblemPersistContactSupport:
        'If this problem persists, contact support at:',
      clickToAddComment: 'Click here to add comment',
      ColorMode: 'Color mode',
      FontSize: 'Font size',
      Discover: 'Discover',
      MyAccount: 'My Account',
      MyManuscripts: 'My manuscripts',
      Sweden: 'Sweden',
      USA: 'USA',
      UK: 'UK',
      Germany: 'Germany',
      France: 'France',
      Spain: 'Spain',
      Country: 'Country',
      Gender: 'Gender',
      YoB: 'Year of birth',
      PreferredGenres: 'Preferred genres',
      PreferredLanguages: 'Preferred languages',
      EmailPreferences: 'Email preferences',
      chapters: 'chapters',
      words: 'words',
      YouShouldBetaRead: 'You should read',
      onBetaReader: 'on BetaReader.io',
      Share: 'Share',
      NoGuidelines: 'The author has not provided any feedback guidelines.',
      Read: 'Read',
      on: 'on',
      Author: 'Author',
      JoinBeta: 'I want to read this',
      joinConsentString:
        'I understand that by requesting to join this beta, I will share my email address with the author.',
      Submit: 'Submit',
      AnswerSurvey: 'Answer!',
      SendJoinRequest: 'Send',
      IJustWantToRead: 'I just want to read',
      CopyrightInfo: 'Copyright info',
      RequestToRead: 'Send read request',
      Feedback: 'Feedback',
      NoComment: 'No one has commented.',
      OnceUponATime: 'Once upon a time ...',
      Review: 'Review',
      Overall: 'Overall',
      Plot: 'Plot',
      Grammar: 'Grammar',
      Title: 'Title',
      AddReview: 'Add a review',
      EnterReviewText: 'Enter a short review',
      OverallRating: 'Overall',
      OverallRatingRequired: 'Add overall rating',
      PlotRating: 'Plot',
      GrammarRating: 'Grammar',
      SubmitReview: 'Submit review',
      LeaveReview: 'Leave review',
      checkOutAuthorApp:
        '<0>Are you a writer?</0><1></1><2>Check out our <1>app for authors</1>!</2>',
      MyLibrary: 'My Library',
      ReadingPreferences: 'Reading preferences',
      'E.g.': 'E.g.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'I want to receive emails from BetaReader.io when the author makes changes or updates to',
      'I want to receive emails about BetaReader news and updates.':
        'I want to receive emails about BetaReader news and updates.',
      'I want to receive emails about new books that match my reading preferences.':
        'I want to receive emails about new books that match my reading preferences.',
      'this book': 'this book',
      'Email us on': 'Email us on',
      "and we'll see what we can do :)": "and we'll see what we can do :)",
      'Want an author account?': 'Want an author account?',
      Preferences: 'Preferences',
      Language: 'Language',
      Browse: 'Browse',
      'to beginning': 'to beginning',
      'to chapter...': 'to chapter...',
      Location: 'Location',
      Previous: 'Previous',
      Finish: 'Finish',
      Description: 'Description',
      FeedbackGuidelines: 'Feedback guidelines',
      'Start reading': 'Start reading',
      StartReading: 'Start reading',
      LeaveComment: 'Comment',
      replies: 'replies',
      Show: 'Show',
      Hide: 'Hide',
      'Add Reply': 'Add Reply',
      PendingInvitation: 'Pending invitation',
      Decline: 'Decline',
      DeclinedInvitationMessage:
        'You have declined this invitation to read, but you can still accept it if you have changed your mind.',
      Welcome: 'Welcome',
      by: 'by',
      to: 'to',
      AcceptInvitation: 'Accept Invitation',
      InvitationAccepted: 'Invitation Accepted',
      WaitForBook: 'Please wait while we prepare your book',
      PasswordLength: 'Your password needs to be at least 6 characters long',
      AgreeToPolicyAndTerms:
        "You must agree to BetaReader's Privacy Policy & Terms of Service in order to sign up.",
      'Sending...': 'Sending...',
      VerificationSent: 'Verification email sent.',
      VerificationEmailFailed: 'Failed to send verification email.',
      IAcceptToC:
        "<0>I accept BetaReader.io's <1>Privacy Policy</1> & <3>Terms of Service</3></0>",
      AccountSettings: 'Account Settings',
      ContactSettings: 'Contact Settings',
      Chapters: 'Chapters',
      Options: 'Options',
      Option: 'Option',
      BetaLanguageMessage:
        "<0>We are working on improved language support, and are super grateful for any help we can get. If you find any errors on the site, please don't hesitate to contact us on: <1>hello@betareader.io</1></0>",
      Genres: 'Genres',
      PressEnterToSave: 'Press Enter to save',
      female: 'female',
      male: 'male',
      other: 'non-binary',
      DontWantToShare: 'private',
      SignInWithPassword: 'Sign in with password',
      GoToBook: 'Go to book',
      Requested: 'Your request is pending',
      AnsweredOrRequestedNotice:
        'You have requested to join this beta. The author will get back to you as soon as they can.',
      RequestedWithDifferentEmail:
        'You have requested to join this beta with another email than you where invited with. The author will get back to you as soon as they can.',
      LoadingLibrary: 'Looking for books...',
      PreparingBook: 'Preparing book',
      InLineCommentPlaceholder: 'Leave your comment and press enter to save',
      ExitSurvey: 'Exit survey',
      WhyExitQuestion: 'Why do you want to leave this beta?',
      LostInterest: 'I lost interest',
      NotEnoughTime: "I don't have enough time",
      Other: 'Other reason',
      reason_lost_interest: 'I lost interest',
      reason_no_time: "I don't have enough time",
      reason_other: 'Other reason',
      reason_: 'Not specified',
      ExitFeedbackQuestion: 'Do you want to leave some feedback to the author?',
      ExitFeedbackPlaceholder: 'All feedback is useful',
      ThankYouForYourFeebdack: 'Thank you for your feedback!',
      AppVersion: 'Version',
      AppBuildNumber: '#',
      Like: 'Like',
      Love: 'Love',
      Laugh: 'Haha',
      Surprise: 'Surprise',
      Sad: 'Sad',
      Angry: 'Angry',
      NoBooksInList: 'No books here.',
      Update: 'Update',
      Overview: 'Overview',
      Content: 'Content',
      Readers: 'Readers',
      NotificationSettings: 'Notification settings',
      SendEmailOnComments: 'Send email when readers leave comments',
      SendEmailOnSurveyComplete: 'Send email when readers complete a survey',
      CoverUploadMsg:
        'Click to upload your cover image (png or jpg, 980x1568px), or drop it in this area.',
      DefaultNamePlaceholder: 'Default: {{displayName}}',
      SelectUpTo5Genres: 'Select up to 5 genres',
      AdvancedSettings: 'Advanced settings',
      EnableComments: 'Enable comments',
      EnableBookReviews: 'Enable book reviews',
      DeleteBook: 'Delete book',
      DeleteCover: 'Delete cover',
      DeleteCoverConfirmation:
        'Are you sure you want to delete the book cover?',
      YesBinIt: 'Yes, bin it!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Are you sure you want to delete <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} has been deleted.',
      No: 'No',
      Yes: 'Yes',
      UserCommentedOn: 'commented on',
      ShowXReplies: 'Show {{count}} reply',
      ShowXReplies_plural: 'Show {{count}} replies',
      HideXReplies: 'Hide {{count}} reply',
      HideXReplies_plural: 'Hide {{count}} replies',
      NoInLineComments: 'No inline comments.',
      Chapter: 'Chapter',
      NoCommentsToShow: 'No comments to show.',
      Save: 'Save',
      TooLongTitle: 'Your title is too long (max 60 characters)',
      ClickToUploadDoc: 'Click to add or drop your docx file to import it',
      LongChapterWarning: 'Long chapter, is this correct?',
      Upload: 'Upload',
      UploadChapters: 'Upload chapters',
      ImportBooksHelpMsg:
        'No one will be able to see your manuscript without your approval. Read about <1>importing books</1>.',
      Add: 'Add',
      NewChapter: 'New chapter',
      NewSurvey: 'New survey',
      SelectedPart: 'selected part',
      SelectPart: 'Select part',
      NotifyMe: 'Notify me',
      WhenReadersStart: 'on start',
      WhenReadersComplete: 'on end',
      ManageChapter: 'Manage chapter',
      ManageSurvey: 'Manage survey',
      ManageReaders: 'Manage readers',
      MoveUp: 'Move up',
      MoveDown: 'Move down',
      SaveSurvey: 'Save survey',
      Saved: 'Saved',
      EnterSurveyName: 'Give your survey a name',
      EnterSurveyDescription: 'Describe your survey',
      SelectAtLeast: 'Select at least',
      SelectAtMost: 'Select at most',
      LabelOptional: 'Label (optional)',
      MyBooks: 'My books',
      ChangeRoleToWriterToCreateBooks:
        'Please change role to writer to create books.',
      UsingXOutOfYBooks: 'You are using {{x}} out of {{y}} books.',
      UsingXOutOfYReaders: 'You are using {{x}} out of {{y}} readers.',
      NothingHere: 'Nothing here at the moment.',
      OpenReaderWeb: 'Open the reader',
      PrivacyLevel: 'Sharing status',
      PublishingStatus: 'Sharing status',
      PrivacyLevel_infotext_unpublished:
        '<0>Your book is offline. Move it to <1>private</1>, <3>closed</3> or <5>listed</5> for readers to be able to find it.</0>',
      PrivacyLevel_infotext_private:
        '<0>Your book is not listed anywhere. Only you can invite readers.</0>',
      PrivacyLevel_infotext_closed:
        "<0>Your book's presentation can be seen by anyone with <1>the link</1>. Readers can request an invitation, but you need to approve each request.</0>",
      PrivacyLevel_infotext_listed:
        '<0>Your book is listed <1>here</1>. Readers can request an invitation, but you need to approve each request.</0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Private',
      PrivacyLevel_state_closed: 'Closed',
      PrivacyLevel_state_listed: 'Listed',
      SendReminder: 'Send reminder',
      YouCanSendAReminderEvery3Days: 'You can send a reminder every third day',
      LastSeenAt: 'Last seen at',
      ReminderSentAt: 'Reminder sent',
      XReadersInactiveForYDays:
        '{{count}} reader has not finished the book and has not been active over the past {{days}} days.',
      XReadersInactiveForYDays_plural:
        '{{count}} readers have not finished the book and have not been active over the past {{days}} days.',
      InactiveReaders: 'Inactive readers',
      XReadersNotStartedForYDays:
        '{{count}} reader has not accepted your more than {{days}} days old invitation',
      XReadersNotStartedForYDays_plural:
        '{{count}} readers have not accepted your more than {{days}} days old invitation',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Readers who have not accepted the invitation',
      YouHaveXJoinRequests: 'You have {{count}} request to read your book',
      YouHaveXJoinRequests_plural:
        'You have {{count}} requests to read your book',
      JoinRequests: 'Requests to read',
      Approve: 'Approve',
      RequestedAt: 'Requested',
      NoAnswer: 'No answer',
      SendSurveyReminder: 'Send survey-reminder',
      Remove: 'Remove',
      Created: 'Created',
      RequestedToRead: 'Requested to read',
      InvitationSent: 'Invitation sent',
      Reminded: 'Reminded',
      Question: 'Question',
      YouCanAskUpToXQuestions:
        'You can add up to {{maxQuestions}} questions that the readers are required to answer when signing up.',
      NewQuestion: 'New question',
      ReaderSignUpForm: 'Sign-up form',
      NoReadersFound: 'No readers found',
      ReachedPartTitle: 'Reached {{partTitle}}',
      ReasonToQuit: 'Reason',
      YourBookDoesNotHaveAnyChaptersYet:
        'Your book does not have any chapters yet',
      Invited: 'Invited',
      Started: 'Started',
      Finished: 'Finished',
      Abandoned: 'Abandoned',
      AvgOverallRating: 'Overall',
      AvgPlotRating: 'Plot',
      AvgGrammarRating: 'Grammar',
      YouDoNotHaveReviewsYet: "You don't have any reviews yet.",
      PersonalLink: 'Personal link',
      HelpUsReachMoreWriters:
        'Do you enjoy using BetaReader.io? Use your personal link to help us help more authors!',
      Reports: 'Reports',
      WeNeedYourEmail: 'Email needed',
      MissingEmailMsg:
        'We need to be able to contact you regarding your account. We will not share it with anyone, and only contact you if strictly required.',
      EmailNotVerifiedPrompt:
        "Email not verified. <1>Click here to resend</1> the verification email. (check your spam folder if you don't receive it)",
      Skip: 'Skip',
      RoleWriter: 'Writer',
      RoleReader: 'Reader',
      RoleBoth: 'Both',
      AddReader: 'Add new reader',
      DownloadReaderList: 'Download reader list',
      'Show only': 'Show only',
      StatusNEW: 'New',
      StatusTODO: 'To do',
      StatusDOING: 'Doing',
      StatusDONE: 'Done',
      StatusIGNORE: 'Ignore',
      RemoveFilters: 'Remove filters',
      ReadingDataReport: 'Reading data',
      SurveyAnswersReport: 'Survey answers',
      Reviews: 'Reviews',
      AbandonedReadersReport: 'Lost readers',
      SignUpMsg:
        '<0>Thanks for signing up!</0><1>A verification email has been sent to <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Copied {{x}} to the clipboard.',
      DoYouWantToBeMyBetaReader: 'Do you want to be my beta reader?',
      LookingForBetaReadersForBookTitle:
        'I am looking for beta readers for {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'I am looking for beta readers for {{bookTitle}}. Follow this link to read more and sign up: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Social sharing is not possible for private books.',
      ShareTheLinkToYourBook: 'Share the link to your book!',
      Messages: 'Messages',
      FailedToLoadMessage: 'We failed to load this message.',
      FailedToSendMessage: 'We failed to send your message.',
      FailedToStartConversation: 'We failed to start the conversation.',
      NewMessage: 'New message',
      MessageSubject: 'Subject',
      MessageBody: 'Write your message...',
      MessageFrom: 'From',
      MessageTo: 'To',
      SendMessage: 'Send',
      Message: 'Message',
      ReaderMarketplaceBetaInfoText:
        'We are experimenting with letting our authors pay ordinary readers and bookworms a symbolic sum for the service of reading a manuscript. We offer control mechanisms to validate the feedback and control that the readers have read the full manuscript before anyone gets paid. If you want to try this feature, please indicate how many readers you need.',
      ReaderMarketplaceBetaFullText:
        'This program is currently full. Would you like us to notify you when we get an opening?',
      Sum: 'Sum',
      XUSDPerReader: '${{x}} / reader',
      AmountOfReaders: 'Amount of readers',
      PayForTestReaders: 'Pay for test readers',
      GoToCheckout: 'Go to checkout',
      YesPlease: 'Yes, please!',
      BETA: 'BETA',
      NoMessages: 'You have no messages',
      OnBoarding_import_book:
        '<0>Welcome to BetaReader.io!</0>In order to share your book, you first need to import it, either by <2> uploading it</2>, or by creating it directly on the platform. Go ahead and try it out! Everything on BetaReader.io is private by default, so no one will see it unless you invite them.',
      Onboarding_book_overview:
        'The book overview is where you configure details about your book. Title, genres, and feedback guidelines go here, but also whether or not you want readers to be able to comment and leave reviews.',
      Onboarding_book_content:
        'The Content tab is where you control the order of things. Add your chapters and surveys here.',
      Onboarding_book_readers:
        'The Readers tab is where you control who gets to read your book. Send private invitations, share your sign-up link via Twitter, or add your book to our Discovery list in order to reach more readers.',
      Onboarding_book_feedback:
        'The Feedback tab gives you an overview of all the feedback that readers have left. Reply to comments and filter away unimportant stuff.',
      Onboarding_book_reports:
        'The Report tab gives you an overview of how your book is doing. How many readers have been invited, how many have started reading, left rave reviews, and so on.',
      Close: 'Close',
      Last: 'Last',
      DuplicatedBook: '<0><0></0>Copied book {{bookTitle}}</0>',
      PenName: 'Pen name',
      MissingNameMsg:
        'You need to enter a name to be able to communicate with your readers.',
      NameConversation: 'Name this conversation',
      With: 'with...',
      SelectPeople: 'Select people',
      UploadAsNewVersion: '...as new version',
      UploadAsExtraChapters: '...to this version',
      Cancel: 'Cancel',
      Unnamed: 'unnamed',
      NewVersion: 'New version',
      NoContacts: 'No contacts',
      GetContacts:
        'Authors of the books you read, and readers of the books you write, become your contacts.',
      NoConversations: 'No conversations...',
      AllVersionsNeedContent:
        'Your current version needs content before you can create a new one.',
      InviteToVersion: 'Invite to version',
      latestVersion: 'latest',
      VersionName: 'Version {{x}}',
      Reader: 'Reader',
      StartedReading: 'Started reading',
      CurrentPosition: 'Current Position',
      PickVersion: 'Pick version',
      Filters: 'Filters',
      Versions: 'versions',
      ChapterInitial: 'ch',
      AvgTimeToComplete: 'Average time to read chapter',
      ReaderProgress: 'Positions',
      SelectFilter: 'Select a filter',
      CreatePost: 'Share an update',
      NewBookPostPlaceholder: 'What do you want to say?',
      BookUpdateForX: 'Update for book {{x}}',
      OnSaveChapterMessage: 'Let your readers know what you changed',
      SampleChapterUpdatePost: 'I changed this...',
      SaveWithoutPosting: 'Save without posting...',
      SaveAndPost: 'Save and post...',
      DiscoverSearch: 'Search for a title or genre...',
      LoadMore: 'Load more...',
      PendingReadInvitations: 'Pending invitations',
      PendingReadRequests: 'Pending requests',
      UserReactedOn: 'reacted on',
      ChapterComment: 'Chapter comment',
      InlineCommentNotFound:
        'This comment was not found in the text. Has the commented text been removed?',
      Or: 'Or',
      Version: 'Version',
      Updates: 'Updates',
      CurrentBetaIsFullMessage:
        'This beta is currently full, please contact the author to open up more slots.',
      Edit: 'Edit',
      Delete: 'Delete',
      DropFilesHere: 'Drop your files here',
      ShowOnly: 'Show only',
      FeedbackSearch: 'Text search',
      'BuySubscriptionToS&PPMessage':
        '<0>By buying a subscription from BetaReader.io you indicate that you have read and accepted our<1> terms of service</1> & <5>privacy policy</5></0>',
      Inbox: 'Inbox',
      CofirmMovePart:
        'Do you really want to move this part? It will affect the following readers:',
      'AreYouSure?': 'Are you sure?',
      LetYourReadersKnowWhatYouChanged:
        'Let your readers know what you changed',
      ThisChangeAffectsTheFollowingReaders:
        'This change affects the following readers:',
      MovedXFromYtoZ: 'Moved {{x}} from {{y}} to {{z}}',
      Subscription: 'Subscription',
      Subscriptions: 'Subscriptions',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Basic plan, forever free.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Good for hobby perfectionists',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Great for professional indie writers',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Perfect for publishers',
      CancelSubscription: 'Cancel subscription',
      YourSubscriptionHasBeenCancelled: 'Your subscription has been cancelled',
      CancelSubscriptionConfirmation:
        'Are you sure that you want to cancel your subscription?',
      ValidUntil: 'Valid until',
      UpgradeSubscriptionTitle: 'Upgrade',
      UpgradeSubscriptionPromoText: 'Upgrade your account',
      Abort: 'Abort',
      BuyNow: 'Buy now',
      Current: 'Current plan',
      Monthly: 'Monthly',
      Yearly: 'Yearly',
      monthly: 'monthly',
      yearly: 'year',
      month: 'month',
      XCheaper: '{{x}} cheaper!',
      Downgrade: 'Downgrade',
      Forever: 'forever',
      ChangePlan: 'Change plan',
      TrackReaderProgress: 'Track your readers in real-time',
      InlineComments: 'In-line comments and reactions',
      ReaderSurveys: 'Reader surveys',
      NativeApps: 'Android and iOS app',
      MultipleVersions: 'Manuscript versioning',
      '1ActiveManuscript': '1 manuscript',
      '5ActiveManuscripts': 'Up to 5 manuscripts',
      '20ActiveManuscripts': 'Up to 20 manuscripts',
      UnlimitedActiveManuscripts: 'Unlimited manuscripts',
      '3ActiveReadersPerManuscript': 'Up to 3 readers',
      '20ActiveReadersPerManuscript': 'Up to 20 readers',
      '50ActiveReadersPerManuscript': 'Up to 50 readers',
      UnlimitedActiveReaders: 'Unlimited number of readers',
      Zero: 'Zero',
      NoCreditCardNeeded: 'No credit card needed',
      Recommended: 'Recommended',
      SomethingWentWrong: 'Something went wrong',
      SubscriptionChangeError: 'Failed to change subscription',
      SubscriptionChangeSuccess: 'We updated your subscription',
      ActionCancelled: 'The action was cancelled',
      Cancelled: 'Cancelled',
      NextPayment: 'Next payment',
      PaymentPastDueMessage:
        'Your payment is past due. Next attempt: {{nextAttempt}}',
      ChangePaymentInformation: 'Change billing details',
      DashboardWelcomeMessage:
        "<0>Welcome to BetaReader.io!</0><1>Start by <2>uploading</2> or <6>adding</6> your manuscript</1><2>Discovery</2><3>Don't forget to list your manuscript in our <2>discovery</2> section, to access more beta readers.</3><4><0>Here</0> is a quick walkthrough of how to do that.</4><5>Support</5><6>Please check out our <2>help</2> portal or ping us directly by pressing the (?) at the top right of the page.</6><7>You can always send us a friendly mail at <2>hello@betareader.io</2></7>",
      Manuscripts: 'Manuscripts',
      CurrentlyReading: 'Currently reading',
      WeeklyReaders: 'Weekly readers',
      TotalReaders: 'Total readers',
      FailedToDownloadReport: 'Failed to download report',
      WaitingForConfirmation: 'Waiting for confirmation',
      ChangePublishingStatusToAddReaders:
        'Change publishing status to add readers',
      SuggestASwap: 'Suggest a swap',
      WithdrawRequest: 'Withdraw request',
      Confirm: 'Confirm',
      ThisIsASwapRequestForX:
        'This is a swap request for <2>{{x}}</2>. If you approve this request, you will be added as a reader for {{x}}.',
      ApprovedSwapRequestForX: 'This is a swap request for <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'This is a swap request. If you approve this request, you will be added as a reader for:',
      SwapRequest: 'Swap request',
      ApproveAndJoin: 'Approve and join',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} has offered to read {{y}} in exchange for you reading this book.',
      GoToSwapRequest: 'Go to swap request',
      SwitchVersion: 'Switch version',
      SendMagicLinkToX: 'Send a magic link to {{x}}',
      notification_verb_comment: 'commented on',
      notification_verb_react: 'reacted on',
      notification_verb_reply: 'replied to a comment on',
      notification_verb_abandon: 'abandoned your book ',
      notification_verb_request_to_join: 'requested to read {{y}}',
      notification_verb_message: 'sent you a message',
      notification_verb_sent_join_approval: 'has approved your request to read',
      notification_verb_invitation_to_join: 'invited you to read {{y}}',
      notification_verb_manuscript_submitted:
        'has submitted a manuscript to {{y}}',
      'SaveAnd...': 'Save and...',
      Send: 'Send',
      OnlySave: 'Only save',
      YourNotifications: 'Your notifications',
      Notifications: 'Notifications',
      Images: 'Images',
      '365DaysReadingData': '365 days reader history',
      '90DaysReadingData': '90 days reader history',
      '30DaysReadingData': '30 days reader history',
      alerts_PaymentPastDue:
        'Your payment is past due. Update your billing details',
      alerts_MissingName:
        "We're missing your name (or pen name). Update it here:",
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} left to claim your lifetime discount. Enter LAUNCH in the coupon field when you upgrade:',
      RequestedToSwapForX: 'You have requested to swap this one for {{x}}',
      PaymentSuccessMessage:
        '<0><0>Thanks for your purchase!</0><1><0></0></1></0>',
      Dashboard: 'Dashboard',
      year: 'year',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'How likely are you to recommend BetaReader.io to a friend?',
      NotAtAllLikely: 'Not likely',
      ExtremelyLikely: 'Extremely likely',
      ThanksDoYouWantToTellUsWhy: 'Thanks! Do you want to tell us why?',
      Create: 'Create',
      Books: 'Books',
      Feed: 'Feed',
      ANewStory: 'a new story',
      FailedToGetInvitations: 'Failed to get invitations',
      unauthorizedPrivateBook:
        'This book is private and not available to this account.',
      invalidToken: 'Invitation is not valid for this account or has expired.',
      RaiseYourReaderLimit: 'Raise your reader limit (currently {{x}})',
      InviteYourFriends: 'Invite your friends',
      referralText:
        '<0>Get up to 10 extra reader slots by inviting your friends to BetaReader.io!</0><1>For every friend who creates a validated author account and lists a manuscript thanks to you, we will give you and your friend 1 reader slot each, up to a maximum of 10 slots.</1><2>You can also raise your reader limit by reading and reviewing 100k words via our <1><0>reader app</0></1>, or by <3><0>upgrading</0></3> your account!</2>',
      INVITED: 'Invited',
      CREATED_ACCOUNT: 'Created account',
      CONFIRMED: 'Confirmed',
      RegistrationDate: 'Registration date',
      Profile: 'Profile',
      EditProfile: 'Edit profile',
      GetMoreReaders: 'Get more readers',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>You have reached your reader limit. <1>Upgrade</1> to send more invitations in our reader index or approve read requests. In our free plan you can suggest to <3>swap</3> with other writers or invite as many readers you want directly through their email address.</0>',
      SwapsDontAffectReaderLimit:
        'Manuscript swapping does not count towards your reader limit.',
      maxBooksLimitMessage:
        '<0>You have reached your manuscript limit. <1>Raise the limit</1> to be able to test several manuscripts in parallel.</0>',
      Type: 'Type',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0>Unlock your signup bonus!</0><1>It looks like you were referred by a friend - follow the below steps to unlock your mutual sign-up bonus!</1><2><0><0></0><1><0>Validate account</0><1>You need a valid name and email address</1></1></0><1><0></0><1><0>List manuscript</0><1>List the manuscript you want to test</1></1></1><2><0></0><1><0>Wait</0><1>It takes around 3 days for us to validate</1></1></2></2>',
      titleDiscoveryRecommended: 'Recommended for you',
      subheaderDiscoveryRecommended:
        'Something new based on your selected genres',
      titleDiscoveryRecentlyAdded: 'Recently added',
      subheaderDiscoveryRecentlyAdded: 'Recently added titles',
      discoveryRecentlyAddedTitlesInGenre:
        'Recently added titles in genre {{x}}',
      titleDiscoveryPremium: 'Featured titles',
      subheaderDiscoveryPremium: 'Titles by premium authors',
      referralMessage:
        'I invite you to BetaReader.io! If you register using this link, we both get a bonus!',
      referralBody: 'Sign up here: {{x}}',
      ThisCannotBeUndone: 'This cannot be undone',
      DeletedVersion: 'The version was deleted',
      InvalidEmail: 'Invalid email',
      EmailNotVerified: 'Not verified, please check your email',
      SelectFavoriteGenres: 'Select your favorite genres',
      Loading: 'Loading',
      ChapterIdentification: 'Chapter identification',
      ChapterAnalysis: 'Chapter analysis',
      CompleteImport: 'Complete the import',
      Import: 'Import',
      Processing: 'Processing file...',
      ThisMightTakeAWhile: 'This might take a while',
      LikeThisCover: 'Like this book cover?',
      DownloadFailedMsg:
        'The download failed. Was it blocked by a pop-up blocker?',
      ShareAuthorAppEmailSuccessMessage:
        'Invitation sent. Check the status <1>here</1>.',
      failedToUpdateReader: 'Failed to update reader',
      activeReaders: 'Active readers',
      ReadingList: 'Reading list',
      Users: 'Users',
      InviteUser: 'Invite user',
      Role: 'Role',
      member_role_admin: 'Administrator',
      member_role_member: 'Member',
      couponsOrDiscountsWillNotBeKept:
        '<0>Existing discounts will not follow to the new plan.</0>',
      FailedToFetchX: 'Failed to fetch {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        "You have been invited to join {{x}}'s BetaReader.io account.",
      AcceptAccountInvitationMsg:
        'If you accept the invitation, you will be able to see and edit all manuscripts on this account.',
      InvitedMembersMsg:
        'Account members will be able to see and edit all manuscripts on your account, but they will not see any billing details. See betareader.io/pricing for information about the cost per account member.',
      InviteCollaborator: 'Invite collaborator',
      InviteCollaboratorMsg:
        'Collaborators can view the manuscript and reader details and respond to feedback.',
      YouHaveBeenInvitedToCollaborateOnX:
        'You have been invited to collaborate on {{book}} by {{author}}',
      AcceptCollaborationInvitationMsg:
        'If you accept the invitation, you will be able to view the manuscript and reader details, and respond to feedback.',
      Collaborators: 'Collaborators',
      ThisIsABetaFeature:
        'This is a beta feature. Let us know if if you experience any issues with it, or if you have any feedback to share.',
      UserNotFoundMsg:
        "It looks like you don't have an account. Create one <1>here</1>!",
      AuthorName: 'Author name',
      Details: 'Details',
      Settings: 'Settings',
      ReviewAtLeastXwords: 'Review at least {{x}} words',
      CheckBonus: 'Check your bonus',
      FailedToAddCommentOrReaction: 'Failed to add comment or reaction',
      ThisIsAWIP: 'This is a WIP',
      WIPExplanation:
        "Flag your story as WIP (Work In Progress) when it is not finished, e.g. when you haven't added all the chapters.",
      MatureContent: 'Mature content',
      PublicationDate: 'Publication date',
      TimePlan: 'Time plan',
      NotSpecified: 'Not specified',
      WhatIsThisStoryAbout: 'What is this story about?',
      WhatDoYouNeedHelpWith: 'What do you need help with?',
      StartWriting: 'Start writing',
      CreateManuscript: 'Create manuscript',
      ImportManuscript: 'Import manuscript',
      ImportManuscriptStepDescription: 'Upload or create your manuscript',
      InviteReaders: 'Invite your readers',
      InviteReadersStepDescription: 'You decide who can read',
      CollectFeedback: 'Collect feedback',
      CollectFeedbackStepDescription: 'Analyze comments & reading patterns',
      YouDontHaveAnyManuscripts: "You don't yet have any manuscripts",
      AuthorsAndPublishers: 'Authors and publishers',
      FindOutWhatYourReadersThink:
        'Find out what your readers really think of your book',
      ReadAndInfluenceTheNextBestseller:
        'Read and influence coming best-sellers',
      PrivateSharing: 'Private sharing',
      PrivateSharingDescription: 'Share your manuscript privately with readers',
      CollectUnbiasedFeedback: 'Collect unbiased feedback',
      CollectUnbiasedFeedbackDescription:
        "Let your readers comment without seeing each others' feedback",
      AnalyzeReadingPatterns: 'Analyze reading patterns',
      AnalyzeReadingPatternsDescription:
        'Find out where your readers lose interest or get hooked by the story',
      ReadItFirst: 'Read it first',
      ReadItFirstDescription: 'Read coming novels while they are still in beta',
      InfluenceTheStory: 'Influence the story',
      InfluenceTheStoryDescription:
        'Your feedback will influence the final result',
      ConnectWithAuthors: 'Connect with the authors',
      ConnectWithAuthorsDescription:
        'Help your favorite authors by becoming part of their beta team',
      YourBooks: 'Your books',
      New: 'New',
      Members: 'Members',
      Contacts: 'Contacts',
      AddMembersFromContacts: 'Add members from your contact list',
      ConversationSettings: 'Conversation settings',
      PremiumAuthorInfo:
        'This is a manuscript by a premium author. This typically means that the author is more invested in the project and that you can expect them to do what it takes to get this book published.',
      StillWorkingOnThis:
        "We are still building out BetaReader.io. Let us know if there's anything specific you want to see here.",
      Engagement: 'Engagement',
      AssemblingLetters: 'Assembling letters...',
      ConstructingAlphabet: 'Constructing alphabet...',
      BuildingSentences: 'Building sentences...',
      BecomingSentient: 'Becoming sentient...',
      BooksRead: 'Books read: {{x}}',
      WordsReviewed: 'Words reviewed: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Average turnaround time per 1000 words: {{count}} day',
      AvgTurnAroundTimePer1kWords_plural:
        'Average turnaround time per 1000 words: {{count}} days',
      ViewAsReader: 'View as reader',
      EditMode: 'Edit mode',
      BookCover: 'Book cover',
      WelcomeToBRReadathon: 'Welcome to the BetaReader.io Readathon!',
      ReadathonHasEnded: 'The Readathon has now ended.',
      ReadathonInfo:
        'The BetaReader.io Readathon lasts between September 26th and October 31st, 2019. Anyone who reads and reviews at least one book via BetaReader.io during this time participates, and you may increase your chance of winning by reading and reviewing more books. The winner will be drawn on November 1st, and the more words you have read and reviewed by then, the higher your chances are of winning. For example, someone who has read and reviewed 50,000 words will have 5x has high chance of winning as someone who has read and reviewed 10,000 words.',
      ReadathonPrize1: '1st prize: $50 Amazon Gift Card.',
      ReadathonPrize23: '2nd - 3rd prize: $20 Amazon Gift Card.',
      SignInToJoin: 'Sign in to join',
      WordsReviewedByYou: 'Words reviewed by you',
      WordsReviewedByEveryone: 'Words reviewed by everyone',
      ChanceOfWinning: 'Current chance of winning',
      Completed: 'Completed',
      CompletedAt: 'Completed at',
      Pending: 'Pending',
      Position: 'Position',
      FindSomethingToRead: 'Find something to read',
      DontKnow: "Don't know",
      IsThisReadyToPublish:
        'Do you think that this book is ready to be published?',
      UpdateProfileHeader: 'We need some more information for your profile',
      WhatsYourName: 'What is your name or pseudonym?',
      WhereAreYouFrom: 'Where are you from?',
      WhenWereYouBorn: 'Which year you born?',
      WhichGenresDoYouPrefer: 'Which genres do you prefer?',
      WhichLanguagesDoYouRead: 'Which languages do you read?',
      BioPlaceholder:
        'Tell us who you are, and if you have any websites to refer to. A stronger bio will help you get approved for more manuscripts.',
      Thanks: 'Thanks!',
      WhenDoYouWantToHearFromUs: 'When do you want to hear from us?',
      ReaderListConsentHeader:
        'Do you want to be included in our reader database?',
      ReaderListConsentInfo:
        "Authors with works matching your preferences will be able to send you the link to their books, but you are not obliged to read if you don't want to or don't have the time.",
      RoleHeader: 'Are you a writer or a reader?',
      RoleInfo: 'We use this info to optimize your experience.',
      OnYourProfile: 'This will be displayed on your user profile.',
      ProfileBuilderThankYouMessage:
        '<0>Thank you!</0><1>You can always update your profile <1>here</1>.</1>',
      DoThisLater: 'Do this later',
      '2MonthsFree': '2 months free',
      Joined: 'Joined',
      UserInfo: 'User info',
      Age: 'Age',
      Bio: 'Bio',
      ReaderList: 'Find readers',
      Popular: 'Popular',
      BookHas0Words: '0 words',
      BookHas0WordsDescription:
        '<0>This book has at least one version with 0 words. You can add or upload chapters </0><1>here</1>.',
      LeaveBeta: 'Leave beta',
      DiscoverNewBooks: 'Discover new books',
      FailedToFindReader: 'Failed to find reader',
      ApprovedReadRequest: 'Read request is approved',
      Approved: 'Approved',
      UserAlreadyInvited: '{{user}} is already invited',
      SelectBook: 'Select book',
      InvitationMessagePlaceholder:
        'Include a personal message in your invitation to increase your chances of getting accepted.',
      YouHaveAPendingInvitation: 'You have a pending invitation',
      ReaderIndexInfo:
        'The reader database contains BetaReader.io users who have indicated that they are open to be approached by authors. Send them an invitation with your best pitch, but keep in mind that they are free to decline your invitation for any reason.',
      ReaderLimitReached: 'You have reached your reader limit',
      Invite: 'Invite',
      Languages: 'Languages',
      LinkWasSentToX: 'We have sent a sign-in link to {{x}}',
      Verifying: 'Verifying...',
      FoundNoAvailableManuscripts: 'We found no available manuscripts',
      FindASpecificQuestion: 'Filter questions',
      ReadAndReviewBonusInfo:
        'Your READ & REVIEW bonus updates after you finish and review a book.',
      ManuscriptStatus: 'Manuscript status',
      MSStatusDescription_alpha:
        'This is an early stage or partial manuscript.',
      MSStatusDescription_beta:
        'This is a late stage manuscript of a full book.',
      SelectStatus: 'Select status',
      MaxAllowedGenres: 'You can only select up to 5 genres',
      TooLongDescription: 'Your description is too long (max 1000 characters)',
      Duplicate: 'Duplicate',
      Download: 'Download',
      DuplicateVersionInfo:
        'When you duplicate a version, all chapters and surveys will be copied to the new version, but your readers will remain on the old version.',
      NoBookMsg:
        '<0>There is nothing here!</0><1><0>Do you want to </0><1>add your own manuscript?</1></1>',
      CommentsCopiedFromOriginal: 'Comments were copied from original document',
      CopyComments: 'Copy all comments',
      MigrateReaders: 'Migrate readers',
      Posts: 'Posts',
      Sent: 'Sent',
      Username: 'Username',
      UsernameAlreadyTaken: 'This username is taken',
      Followers: 'Followers',
      ReadersAndFollowers: 'Readers and followers',
      WhoCanSeeThis: 'Who can see this?',
      FollowCount: '{{count}} follower',
      FollowCount_plural: '{{count}} followers',
      Follow: 'Follow',
      Following: 'Following',
      MarkAllAsRead: 'Mark all as read',
      Working: 'Working...',
      Audience: 'Audience',
      ShowAllComments: 'Show all comments...',
      Publish: 'Publish',
      InviteByEmail: 'Invite by email',
      InviteByLink: 'Invite by link',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        'Hi {{x}}! Welcome to Betareader support 👋,  we try to answer as soon as we can. What can we help you with?',
      TalkToSupport: 'Talk to our support team',
      StartSupportChat: 'Start support chat',
      StartSupportChatPMessage:
        '<0>You can always reach us through our chat and we will answer as soon as we can.</0>',
      FindHelpArticlesMessage:
        '<0>Need help? check out our<1> help </1>section, full of help articles to guide you.</0>',
      HelpArticles: 'Help articles',
      CloseConversationAlertHeader: 'Close conversation',
      CloseConversationAlertMessage: 'Do you want to close this conversation?',
      EmailSupportMessage:
        '<0>Send an email to <1>{{mail}}</1>, and we will answer as soon as we can.</0>',
      Includes: 'Includes',
      DoesNotInclude: 'Does not include',
      IncludesAll: 'Includes all',
      RemoveFilter: 'Remove filter',
      AddFilter: 'Add filter',
      SelectGenres: 'Select genres',
      And: 'And',
      ContinueWithGoogle: 'Continue with Google',
      ContinueWithFacebook: 'Continue with Facebook',
      ContinueWithApple: 'Continue with Apple',
      NewOnBetareader: 'New to BetarReader?',
      AlreadyHaveAccount: 'Already have an account?',
      SignUpWithFacebook: 'Sign up with Facebook',
      SignUpWithGoogle: 'Sign up with Google',
      SignUpWithApple: 'Sign up with Apple',
      OpportunityWithoutDescriptionMessage:
        'The author has not provided a description.',
      SignInToViewContent: 'Sign in to view this content',
      ContentBelongsToAnotherAccount:
        'This content belongs to a different account than your active one.',
      SwitchAccount: 'Switch account',
      OwnBooks: 'Own books',
      Collaborations: 'Collaborations',
      UploadImage: 'Upload image',
      SplitChapter: 'Split chapter',
      UpgradeToUnlock: 'Upgrade to unlock',
      '3ReaderSlots': '3 reader slots',
      UnlimitedReaderSlots: 'Unlimited reader slots',
      ImportWarning: 'Import warning',
      ImportExeedLenghMessage:
        '<0><0>One or more of your chapters appear to be too long to import, make sure you have have followed our<1> import guidelines. </1></0><1>Tip: you can hover over the chapter text and use the sizzor tool to split larger chapters</1></0>',
      OneTeamMember: '1 team member',
      AdditionalTeamMembers: 'Add team members',
      PerMonth: 'per month',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Submit your manuscript',
      SubmitTo: 'Submit to',
      YourSubmittedManuscripts: 'Your submitted manuscripts',
      SelectManuscriptToSubmit: 'Select manuscript to submit',
      AvailableToRead: 'Available to read',
      ManuscriptSubmissionSuccess: 'Manuscript submitted',
      ManuscriptSubmittedTo:
        'Your manuscript, {{_manuscriptName}}, has been sent to {{_inboxName}}',
      SubmittedBy: 'Submitted by {{_userName}}',
      ReceivedManuscripts: 'Received manuscripts',
      InviteToRead: 'Invite to read',
      YourReview: 'Your review',
      UserReviewPlaceholder: 'User reviews are visible to anyone.',
      LeaveReviewError:
        'You can only review users who have finished reading one of your manuscripts.',
      NotEnoughData: 'Not enough data',
      EditSignUpSurveyQuestions: 'Edit sign up survey questions',
      AddSignUpSurveyQuestions: 'Add sign up survey questions',
      To: 'To',
      FinishedReadingAt: 'Finished reading at {{x}}',
      FailedToSendInvitation:
        'Failed to send invitation, please verify the email adress',
      EditInvitation: 'Edit invitation',
      ChapterSeparator: 'Chapter separator',
      CancelReason: 'Before you continue, would you mind telling us why?',
      cancel_reason_technical_issues: 'Technical issues',
      cancel_reason_too_expensive: 'The product is too expensive',
      cancel_reason_finished_my_beta: 'I finished my project',
      cancel_reason_switching_to_another_product:
        "I'm switching to another product",
      cancel_reason_missing_features: "I'm missing important features",
      cancel_reason_did_not_meet_expectations:
        'The platform did not meet my expectations',
      cancel_reason_other: 'Other reasons',
      TellUsMore: 'Tell us more',
      DeleteAccount: 'Delete account',
      YourAccountHasBeenDeleted: 'Your account has been deleted',
      DeleteAccountConfirmation:
        'Are you sure that you want to delete your account and all its data? This action cannot be undone.',
      GoodbyeMessage:
        "We're sorry to see you leave us, and hope that you'll return in the future.",
      EditContent: 'Edit content',
      AffectedReadersMessage:
        '{{count}} reader has already read this chapter. Click here to post an update about your changes.\n',
      AffectedReadersMessage_plural:
        '{{count}} readers have already read this chapter. Click here to post an update about your changes.\n',
      ImportingChapterXofY: 'Importing chapter {{_x}} / {{_y}}',
      ImportStarted: 'Import started',
      XVersions: '{{count}} version',
      XVersions_plural: '{{count}} versions',
      UnsubscribeSuccessful:
        'You have been unsubscribed. You can always update your preferences via your profile.',
      UnsubscribeUnsuccessful:
        'Failed to unsubscribe. Send a message to support@betareader.io if the issue persists.',
      GoodbyeHeader: 'Good bye!',
      BulkAddReaders: 'Bulk add readers',
      Home: 'Home',
      Actions: 'Actions',
      Invitations: 'Invitations',
      SelectCSVWithReaders: 'Select CSV with readers',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'Let our AI, BetaBot, generate a synopsis based on your text. It will not be visible to your readers.',
      LastUpdated: 'Last updated',
      CreateSynopsis: 'Create synopsis',
      RefreshSynopsis: 'Refresh synopsis',
      ElevatorPitch: 'Elevator pitch',
      ThisIsAPremiumFeature: 'Premium feature',
      GoBack: 'Go back',
      Retry: 'Retry'
    }
  },
  es: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Tenga en cuenta que al enviar un lector de un nombre de usuario y no su seudónimo será visible',
      Library: 'Biblioteca',
      Account: 'Cuenta',
      EmptyLibrary: 'Su biblioteca está vacía.',
      EmptyLibraryAction:
        '¿Se esperaba encontrar algo aquí? Contacta con el autor, o enviar un correo electrónico en {{_email}}, y veremos lo que podemos hacer.',
      ByAuthor: 'por {{_authorName}}',
      ExceedChapterLenghtWarning:
        'El capítulo es demasiado largo para importarlo.',
      WordCount: '{{_wordCount}} palabras',
      WordCountString: '{{count}} palabra',
      WordCountString_plural: '{{count}} palabras',
      CharCountString: '{{count}} caracteres',
      CharCountString_plural: '{{count}} caracteres',
      SurveyCount: '{{count}} encuesta',
      SurveyCount_plural: '{{count}} encuestas',
      ChapterCount: '{{count}} capítulo',
      ChapterCount_plural: '{{count}} capítulos',
      QuestionCountString: '{{count}} pregunta',
      QuestionCountString_plural: '{{count}} preguntas',
      LoginToChangeEmail:
        'Entra en la cuenta de nuevo para cambiar su dirección de e-mail',
      SendVerification: 'Enviar enlace de verificación',
      Name: 'Nombre',
      Email: 'Correo electrónico',
      EnterYourName: 'por favor, escriba su nombre',
      ReceiveCompanyUpdates:
        'Recibir mensajes de correo electrónico y noticias de beta reader',
      ReceiveBookEmails: 'Recibir correos electrónicos sobre nuevos libros',
      ReceiveBookActivityUpdates:
        'Recibir correos electrónicos sobre la actividad en mis libros',
      ReceiveMessageUpdates:
        'Recibir correos electrónicos cuando llegue nuevos mensajes',
      IncludeProfileInReaderList:
        'Incluir mi perfil en la base de datos de lectores',
      PrivacyPolicy: 'Política de privacidad',
      TermsOfService: 'Términos de servicio',
      ReadOurBlog: 'Lea nuestro blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Próximo',
      Back: 'atrás',
      CreateAccount: 'Crear una cuenta',
      CreateAccountPrompt:
        '<0>¿No tiene una cuenta?<1> <0>Cree uno aquí!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Ha olvidado su contraseña?<1> <0>Restablecer aquí!</0></1></0>',
      SignIn: 'Registrarse',
      SignOut: 'Desconectar',
      SendResetLink: 'Enviar enlace de restablecimiento',
      ResetPassword: 'Restablecer la contraseña',
      Password: 'Contraseña',
      OrUse: 'o usa',
      NoConnection: 'Desconectado...',
      AcceptTermsMessage: 'Aceptar los términos del servicio',
      AcceptToSToCreateAccount:
        'Tienes que aceptar nuestras condiciones de servicio para crear una cuenta.',
      Answer: 'Respuesta',
      FailedToLoadBook: 'No se pudo cargar el libro.',
      PullDownToRefresh: 'Tire hacia abajo para refrescar.',
      ContactSupport: 'Contacta supporto',
      Support: 'Apoyo',
      LastRead: 'Última lectura',
      XInvitations: '{{count}} invitación',
      XInvitations_plural: '{{count}} invitaciones',
      EnterComment: 'Comenta...',
      EnterReply: 'Respuesta...',
      Reply: 'Responder',
      Reply_plural: 'Respuestas',
      Reply_verb: 'Responder',
      show: 'mostrar',
      hide: 'ocultar',
      Comments: 'Comentarios',
      InlineComment: 'Comentario en línea',
      Reject: 'Rechazar',
      Accept: 'Aceptar',
      Error: 'Error',
      failed: 'ha fallado',
      SelectedText: 'El texto seleccionado',
      FailedToFetchUserProfile: 'No se pudo obtener el perfil de usuario.',
      ProblemPersistContactSupport:
        'Si el problema persiste, póngase en contacto en:',
      clickToAddComment: 'Haga clic aquí para añadir comentarios',
      ColorMode: 'Modo de color',
      FontSize: 'Tamaño de fuente',
      Discover: 'Descubrir',
      MyAccount: 'Mi cuenta',
      MyManuscripts: 'Mis manuscritos',
      Sweden: 'Suecia',
      USA: 'EE.UU',
      UK: 'Reino Unido',
      Germany: 'Alemania',
      France: 'Francia',
      Spain: 'España',
      Country: 'País',
      Gender: 'Género',
      YoB: 'año de nacimiento',
      PreferredGenres: 'Géneros preferidos',
      PreferredLanguages: 'Idiomas preferidos',
      EmailPreferences: 'Preferencias de correo electrónico',
      chapters: 'capítulos',
      words: 'palabras',
      YouShouldBetaRead: 'deberías leer',
      onBetaReader: 'en BetaReader.io',
      Share: 'Compartir',
      NoGuidelines: 'El autor no ha proporcionado ninguna guía de comentarios.',
      Read: 'Leer',
      on: 'en',
      Author: 'Autor',
      JoinBeta: 'quiero leer esto',
      joinConsentString:
        'Entiendo que al solicitar unirme a esta versión beta, compartiré mi dirección de correo electrónico con el autor.',
      Submit: 'Entregar',
      AnswerSurvey: '¡Respuesta!',
      SendJoinRequest: 'Enviar',
      IJustWantToRead: 'Solo quiero leer',
      CopyrightInfo: 'Información de derechos de autor',
      RequestToRead: 'Enviar solicitud de lectura',
      Feedback: 'Comentario',
      NoComment: 'Nadie ha comentado.',
      OnceUponATime: 'Érase una vez ...',
      Review: 'Revisión',
      Overall: 'En general',
      Plot: 'Intriga',
      Grammar: 'Gramática',
      Title: 'Título',
      AddReview: 'Añadir una reseña',
      EnterReviewText: 'Introduzca una breve reseña',
      OverallRating: 'En general',
      OverallRatingRequired: 'Agregar calificación general',
      PlotRating: 'Intriga',
      GrammarRating: 'Gramática',
      SubmitReview: 'Enviar reseña',
      LeaveReview: 'dejar reseña',
      checkOutAuthorApp:
        '<0>Es usted un escritor?</0><1></1><2>Consulte nuestra <1>aplicación para autores</1></2>',
      MyLibrary: 'Mi biblioteca',
      ReadingPreferences: 'Preferencias de lectura',
      'E.g.': 'P.ej.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Recibir correos electrónicos de BetaReader.io cuando el autor hace cambios o actualizaciones',
      'I want to receive emails about BetaReader news and updates.':
        'Recibir correos electrónicos sobre noticias y actualizaciones beta reader.',
      'I want to receive emails about new books that match my reading preferences.':
        'Recibir correos electrónicos sobre nuevos libros que responden a mis preferencias de lectura.',
      'this book': 'este libro',
      'Email us on': 'Envíenos un correo electrónico a',
      "and we'll see what we can do :)": 'y veremos qué podemos hacer :)',
      'Want an author account?': '¿Quieres una cuenta de autor?',
      Preferences: 'Preferencias',
      Language: 'Idioma',
      Browse: 'Navegar',
      'to beginning': 'al comienzo',
      'to chapter...': 'al capítulo...',
      Location: 'Ubicación',
      Previous: 'Anterior',
      Finish: 'Finalizar',
      Description: 'Descripción',
      FeedbackGuidelines: 'Directrices de retroalimentación',
      'Start reading': 'Empezar a leer',
      StartReading: 'Empezar a leer',
      LeaveComment: 'Comentario',
      replies: 'respuestas',
      Show: 'Espectáculo',
      Hide: 'Esconder',
      'Add Reply': 'Agregar respuesta',
      PendingInvitation: 'Invitación pendiente',
      Decline: 'Rechaza',
      DeclinedInvitationMessage:
        'Has rechazado esta invitación para leer, pero aún puedes aceptarla si has cambiado de opinión.',
      Welcome: 'Bienvenido',
      by: 'de',
      to: 'a',
      AcceptInvitation: 'Aceptar invitación',
      InvitationAccepted: 'Invitación aceptada',
      WaitForBook: 'Por favor espera mientras preparamos tu libro.',
      PasswordLength: 'Su contraseña debe tener al menos 6 caracteres',
      AgreeToPolicyAndTerms:
        'Debe aceptar la Política de privacidad y los Términos de servicio de BetaReader para poder registrarse.',
      'Sending...': 'Envío...',
      VerificationSent: 'Correo electrónico de verificación enviado.',
      VerificationEmailFailed:
        'No se pudo enviar el correo electrónico de verificación.',
      IAcceptToC:
        '<0>Acepto de BetaReader.io <1>Política de privacidad</1> y <3>Condiciones del servicio</3></ 0>',
      AccountSettings: 'Configuraciones de la cuenta',
      ContactSettings: 'Configuraciones de contact',
      Chapters: 'Capítulos',
      Options: 'Opciones',
      Option: 'Opción',
      BetaLanguageMessage:
        '<0>Estamos trabajando en la compatibilidad de idiomas, y estamos superagradecido por cualquier ayuda que podamos conseguir. Si encuentra algún error en el sitio, por favor no dude en ponerse en contacto con nosotros: <1>hello@betareader.io</1></0>',
      Genres: 'Géneros',
      PressEnterToSave: 'Presione Entrar para guardar',
      female: 'femenino',
      male: 'masculino',
      other: 'no binario',
      DontWantToShare: 'privado',
      SignInWithPassword: 'Iniciar sesión con contraseña',
      GoToBook: 'Ir a reservar',
      Requested: 'Tu solicitud está pendiente',
      AnsweredOrRequestedNotice:
        'Has solicitado unirte a esta versión beta. El autor se comunicará con usted tan pronto como pueda.',
      RequestedWithDifferentEmail:
        'Has solicitado unirte a esta versión beta con un correo electrónico diferente al que te invitaron. El autor se comunicará con usted tan pronto como pueda.',
      LoadingLibrary: 'Buscando libros...',
      PreparingBook: 'Libro de preparación',
      InLineCommentPlaceholder:
        'Deja tu comentario y presiona enter para guardar',
      ExitSurvey: 'Encuesta de salida',
      WhyExitQuestion: '¿Por qué quieres abandonar esta beta?',
      LostInterest: 'perdí el interés',
      NotEnoughTime: 'no tengo suficiente tiempo',
      Other: 'Otra razón',
      reason_lost_interest: 'perdí el interés',
      reason_no_time: 'no tengo suficiente tiempo',
      reason_other: 'Otra razón',
      reason_: 'No especificado',
      ExitFeedbackQuestion: '¿Quieres dejar algún comentario al autor?',
      ExitFeedbackPlaceholder: 'Todos los comentarios son útiles.',
      ThankYouForYourFeebdack: '¡Gracias por tus comentarios!',
      AppVersion: 'Versión',
      AppBuildNumber: '#',
      Like: 'Como',
      Love: 'Amar',
      Laugh: 'Ja ja',
      Surprise: 'Sorpresa',
      Sad: 'Triste',
      Angry: 'Enojado',
      NoBooksInList: 'No hay libros aquí.',
      Update: 'Actualizar',
      Overview: 'Descripción general',
      Content: 'Contenido',
      Readers: 'Lectores',
      NotificationSettings: 'Configuración de notificaciones',
      SendEmailOnComments:
        'Enviar correo electrónico cuando los lectores dejen comentarios',
      SendEmailOnSurveyComplete:
        'Enviar correo electrónico cuando los lectores completen una encuesta',
      CoverUploadMsg:
        'Haga clic para cargar su imagen de portada (png o jpg, 980x1568px), o suéltela en esta área.',
      DefaultNamePlaceholder: 'Por defecto: {{displayName}}§',
      SelectUpTo5Genres: 'Selecciona hasta 5 géneros',
      AdvancedSettings: 'Configuraciones avanzadas',
      EnableComments: 'Habilitar comentarios',
      EnableBookReviews: 'Habilitar reseñas de libros',
      DeleteBook: 'Eliminar libro',
      DeleteCover: 'Eliminar cubierta',
      DeleteCoverConfirmation:
        '¿Estás seguro de que deseas eliminar la portada del libro?',
      YesBinIt: 'Sí, estoy seguro!',
      AreYouSureYouWantToDeleteBookName:
        '<0>¿Está seguro que desea borrar <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} ha sido borrado.',
      No: 'No',
      Yes: 'Sí',
      UserCommentedOn: 'comentado en',
      ShowXReplies: 'Mostrar {{count}} respuesta',
      ShowXReplies_plural: 'Mostrar {{count}} respuestas',
      HideXReplies: 'Esconder {{count}} respuesta',
      HideXReplies_plural: 'Esconder {{count}} respuestas',
      NoInLineComments: 'Sin comentarios en línea.',
      Chapter: 'Capítulo',
      NoCommentsToShow: 'No hay comentarios para mostrar.',
      Save: 'Ahorrar',
      TooLongTitle: 'Tu título es demasiado largo (máximo 60 caracteres)',
      ClickToUploadDoc:
        'Haga clic para agregar o soltar su archivo docx para importarlo',
      LongChapterWarning: 'Largo capítulo, es esto correcto?',
      Upload: 'Subir',
      UploadChapters: 'Subir capítulos',
      ImportBooksHelpMsg:
        'Nadie podrá ver su manuscrito sin su aprobación. Lea acerca de <1> importar libros </1>.',
      Add: 'Agregar',
      NewChapter: 'Nuevo capitulo',
      NewSurvey: 'Nueva encuesta',
      SelectedPart: 'parte seleccionada',
      SelectPart: 'Seleccionar parte',
      NotifyMe: 'Notificarme',
      WhenReadersStart: 'al empezar',
      WhenReadersComplete: 'de punta',
      ManageChapter: 'Administrar capítulo',
      ManageSurvey: 'Administrar encuesta',
      ManageReaders: 'Administrar lectores',
      MoveUp: 'Subir',
      MoveDown: 'Bajar',
      SaveSurvey: 'Guardar encuesta',
      Saved: 'Guardado',
      EnterSurveyName: 'Dale un nombre a tu encuesta',
      EnterSurveyDescription: 'Describe tu encuesta',
      SelectAtLeast: 'Seleccione al menos',
      SelectAtMost: 'Seleccionar como máximo',
      LabelOptional: 'Etiqueta (opcional)',
      MyBooks: 'mis libros',
      ChangeRoleToWriterToCreateBooks:
        'Cambie el rol a escritor para crear libros.',
      UsingXOutOfYBooks: 'Está utilizando {{x}} de {{y}} libros.',
      UsingXOutOfYReaders: 'Está utilizando {{x}} de {{y}} lectores.',
      NothingHere: 'Nada aquí por el momento.',
      OpenReaderWeb: 'abrir el lector',
      PrivacyLevel: 'Estado de uso compartido',
      PublishingStatus: 'Estado de uso compartido',
      PrivacyLevel_infotext_unpublished:
        '<0> Tu libro está fuera de línea. Muévalo a <1> privado </1>, <3> cerrado </3> o <5> listado </5> para que los lectores puedan encontrarlo. </0>',
      PrivacyLevel_infotext_private:
        '<0> Su libro no aparece en cualquier lugar. Sólo se puede invitar a los lectores. </0>',
      PrivacyLevel_infotext_closed:
        '<0> presentación de su libro puede ser visto por cualquier persona con <1> el enlace </1>. Los lectores pueden solicitar una invitación, pero tiene que aprobar cada solicitud. </0>',
      PrivacyLevel_infotext_listed:
        '<0> Su libro aparece <1>aquí</1>. Los lectores pueden solicitar una invitación, pero tiene que aprobar cada solicitud. </0>',
      PrivacyLevel_state_unpublished: 'Desconectado',
      PrivacyLevel_state_private: 'Privado',
      PrivacyLevel_state_closed: 'Cerrado',
      PrivacyLevel_state_listed: 'Listado',
      SendReminder: 'Enviar recordatorio',
      YouCanSendAReminderEvery3Days:
        'Puedes enviar un recordatorio cada tres días.',
      LastSeenAt: 'Última vez a las',
      ReminderSentAt: 'Recordatorio enviado',
      XReadersInactiveForYDays:
        '{{count}} lector no ha terminado el libro y no ha sido activos en los últimos {{days}} días.',
      XReadersInactiveForYDays_plural:
        '{{count}} lectores no han terminado el libro y no han sido activos en los últimos {{days}} días.',
      InactiveReaders: 'lectores inactivos',
      XReadersNotStartedForYDays:
        '{{count}} lector no ha aceptado su más que {{days}} días de edad invitación',
      XReadersNotStartedForYDays_plural:
        '{{count}} lectores no han aceptado su más que {{days}} días de edad invitación',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Lectores que no han aceptado la invitación',
      YouHaveXJoinRequests: 'Tienes {{count}} petición de lectura de su libro',
      YouHaveXJoinRequests_plural:
        'Tiene {{count}} solicitudes hechas para leer su libro',
      JoinRequests: 'Solicitudes de lectura',
      Approve: 'Aprobar',
      RequestedAt: 'Solicitado',
      NoAnswer: 'Sin respuesta',
      SendSurveyReminder: 'Enviar recordatorio de encuesta',
      Remove: 'Retirar',
      Created: 'Creado',
      RequestedToRead: 'Se solicitó a leer',
      InvitationSent: 'Invitación enviada',
      Reminded: 'Recordado',
      Question: 'Pregunta',
      YouCanAskUpToXQuestions:
        'Puede añadir hasta {3} {} preguntas que se requieren a los lectores a responder al registrarse.',
      NewQuestion: 'Nueva pregunta',
      ReaderSignUpForm: 'Formulario de registro',
      NoReadersFound: 'No se encontraron lectores',
      ReachedPartTitle: 'Alcanzado {{partTitle}}',
      ReasonToQuit: 'Razón',
      YourBookDoesNotHaveAnyChaptersYet: 'Tu libro aún no tiene capítulos.',
      Invited: 'Invitado',
      Started: 'Comenzó',
      Finished: 'Finalizado',
      Abandoned: 'Abandonado',
      AvgOverallRating: 'En general',
      AvgPlotRating: 'Intriga',
      AvgGrammarRating: 'Gramática',
      YouDoNotHaveReviewsYet: 'Aún no tienes ninguna reseña.',
      PersonalLink: 'Enlace personal',
      HelpUsReachMoreWriters:
        '¿Te gusta betareader.io? ¡Utiliza tu enlace personal para ayudarnos a ayudar a más autores!',
      Reports: 'Informes',
      WeNeedYourEmail: 'Correo electrónico sea necesario',
      MissingEmailMsg:
        'Necesitamos poder comunicarnos con usted con respecto a su cuenta. No lo compartiremos con nadie y solo nos comunicaremos con usted si es estrictamente necesario.',
      EmailNotVerifiedPrompt:
        'El correo electrónico no verificada. <1> Haga clic aquí para volver a enviar </1> el mensaje de verificación. (Revise su carpeta de correo no deseado si no lo recibe)',
      Skip: 'Saltar',
      RoleWriter: 'Escritor',
      RoleReader: 'Lector',
      RoleBoth: 'Ambos',
      AddReader: 'Agregar nuevo lector',
      DownloadReaderList: 'Descargar lista de lectores',
      'Show only': 'Mostrar solo',
      StatusNEW: 'Nuevo',
      StatusTODO: 'Hacer',
      StatusDOING: 'Haciendo',
      StatusDONE: 'Hecho',
      StatusIGNORE: 'Ignorar',
      RemoveFilters: 'Quitar filtros',
      ReadingDataReport: 'Leyendo datos',
      SurveyAnswersReport: 'Respuestas de la encuesta',
      Reviews: 'Reseñas',
      AbandonedReadersReport: 'Lectores perdidos',
      SignUpMsg:
        '<0>¡Gracias por registrarse!</0> <1>Se ha enviado un correo electrónico de verificación a <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Copiado {{x}} al portapapeles.',
      DoYouWantToBeMyBetaReader: '¿Quieres ser mi lector beta?',
      LookingForBetaReadersForBookTitle:
        'Busco lectores beta de {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Busco lectores beta de {{bookTitle}}. Siga este enlace para leer más e inscribirse: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'El intercambio social no es posible para libros privados.',
      ShareTheLinkToYourBook: '¡Comparte el enlace a tu libro!',
      Messages: 'Mensajes',
      FailedToLoadMessage: 'No pudimos cargar este mensaje.',
      FailedToSendMessage: 'No pudimos enviar su mensaje.',
      FailedToStartConversation: 'No pudimos iniciar la conversación.',
      NewMessage: 'Nuevo mensaje',
      MessageSubject: 'Sujeto',
      MessageBody: 'Escribe tu mensaje...',
      MessageFrom: 'De',
      MessageTo: 'A',
      SendMessage: 'Enviar',
      Message: 'Mensaje',
      ReaderMarketplaceBetaInfoText:
        'Estamos experimentando con permitir que nuestros autores paguen a los lectores comunes y a los ratones de biblioteca una suma simbólica por el servicio de leer un manuscrito. Ofrecemos mecanismos de control para validar los comentarios y controlar que los lectores hayan leído el manuscrito completo antes de que alguien cobre. Si desea probar esta función, indique cuántos lectores necesita.',
      ReaderMarketplaceBetaFullText:
        'Este programa está actualmente completo. ¿Quieres que te avisemos cuando tengamos una vacante?',
      Sum: 'Suma',
      XUSDPerReader: '${{x}} / lector',
      AmountOfReaders: 'cantidad de lectores',
      PayForTestReaders: 'Pagar por lectores de prueba',
      GoToCheckout: 'Ir a pagar',
      YesPlease: '¡Sí, por favor!',
      BETA: 'BETA',
      NoMessages: 'no tienes mensajes',
      OnBoarding_import_book:
        '<0> Bienvenido a BetaReader.io! </0> Con el fin de compartir su libro, primero tiene que importarlo, ya sea por <2> subirlo </2>, o creando directamente en la plataforma. Seguir adelante y probarlo! Todo en BetaReader.io es privado de forma predeterminada, por lo que nadie verá a menos que los invita.',
      Onboarding_book_overview:
        'La descripción general del libro es donde configura los detalles sobre su libro. El título, los géneros y las pautas de comentarios van aquí, pero también si desea o no que los lectores puedan comentar y dejar reseñas.',
      Onboarding_book_content:
        'La pestaña Contenido es donde controlas el orden de las cosas. Agregue sus capítulos y encuestas aquí.',
      Onboarding_book_readers:
        'La pestaña Lectores es donde controlas quién puede leer tu libro. Envíe invitaciones privadas, comparta su enlace de registro a través de Twitter o agregue su libro a nuestra lista Discovery para llegar a más lectores.',
      Onboarding_book_feedback:
        'La pestaña Comentarios le brinda una descripción general de todos los comentarios que dejaron los lectores. Responde a los comentarios y filtra las cosas sin importancia.',
      Onboarding_book_reports:
        'La pestaña Informe le ofrece una descripción general del rendimiento de su libro. Cuántos lectores han sido invitados, cuántos han comenzado a leer, cuántos han dejado críticas favorables, etc.',
      Close: 'Cerca',
      Last: 'Último',
      DuplicatedBook: '<0> <0> </0> libro copiado {{bookTitle}} </0>',
      PenName: 'seudónimo',
      MissingNameMsg:
        'Debe ingresar un nombre para poder comunicarse con sus lectores.',
      NameConversation: 'Nombra esta conversación',
      With: 'con...',
      SelectPeople: 'Seleccionar personas',
      UploadAsNewVersion: '...como nueva versión',
      UploadAsExtraChapters: '...a esta versión',
      Cancel: 'Cancelar',
      Unnamed: 'sin nombre',
      NewVersion: 'Nueva versión',
      NoContacts: 'Sin contactos',
      GetContacts:
        'Los autores de los libros que lees y los lectores de los libros que escribes se convierten en tus contactos.',
      NoConversations: 'Sin conversaciones...',
      AllVersionsNeedContent:
        'Su versión actual necesita contenido antes de que pueda crear una nueva.',
      InviteToVersion: 'Invitar a la versión',
      latestVersion: 'último',
      VersionName: 'Versión {{x}}',
      Reader: 'Lector',
      StartedReading: 'Lectura comenzado',
      CurrentPosition: 'Posición actual',
      PickVersion: 'Elegir versión',
      Filters: 'Filtros',
      Versions: 'versiones',
      ChapterInitial: 'c',
      AvgTimeToComplete: 'Tiempo promedio para leer el capítulo.',
      ReaderProgress: 'Posiciones',
      SelectFilter: 'Seleccione un filtro',
      CreatePost: 'Compartir una actualización',
      NewBookPostPlaceholder: '¿Qué quieres decir?',
      BookUpdateForX: 'Actualización para el libro {{x}}',
      OnSaveChapterMessage: 'Deja que tus lectores sepan lo que cambiaste',
      SampleChapterUpdatePost: 'Cambié esto...',
      SaveWithoutPosting: 'Guardar sin publicar...',
      SaveAndPost: 'Guardar y publicar...',
      DiscoverSearch: 'Buscar un título o género...',
      LoadMore: 'Cargar más...',
      PendingReadInvitations: 'Invitaciones pendientes',
      PendingReadRequests: 'Solicitudes pendientes',
      UserReactedOn: 'reaccionó',
      ChapterComment: 'Capítulo comentario',
      InlineCommentNotFound:
        'Este comentario no se encontró en el texto. ¿Se ha eliminado el texto comentado?',
      Or: 'O',
      Version: 'Versión',
      Updates: 'Actualizaciones',
      CurrentBetaIsFullMessage:
        'Esta versión beta está actualmente llena, comuníquese con el autor para abrir más espacios.',
      Edit: 'Editar',
      Delete: 'Borrar',
      DropFilesHere: 'Deja tus archivos aquí',
      ShowOnly: 'Mostrar solo',
      FeedbackSearch: 'Búsqueda de texto',
      'BuySubscriptionToS&PPMessage':
        '<0> Al comprar una suscripción de BetaReader.io, usted indica que ha leído y aceptado nuestros <1> términos de servicio </1> y <5> política de privacidad </5> </0>',
      Inbox: 'Bandeja de entrada',
      CofirmMovePart:
        '¿Realmente quieres mover esta parte? Afectará a los siguientes lectores:',
      'AreYouSure?': '¿Está seguro?',
      LetYourReadersKnowWhatYouChanged:
        'Deja que tus lectores sepan lo que cambiaste',
      ThisChangeAffectsTheFollowingReaders:
        'Este cambio afecta a los siguientes lectores:',
      MovedXFromYtoZ: 'Se movió {{x}} de {{y}} a {{z}}',
      Subscription: 'Suscripción',
      Subscriptions: 'Suscripciones',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Plan básico, para siempre gratis.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription:
        'Bueno para los perfeccionistas aficionados',
      IndieSubscriptionTitle: 'v',
      IndieSubscriptionDescription:
        'Ideal para escritores independientes profesionales.',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Perfecto para editores',
      CancelSubscription: 'Cancelar suscripción',
      YourSubscriptionHasBeenCancelled: 'Su suscripción ha sido cancelada.',
      CancelSubscriptionConfirmation:
        '¿Estás seguro de que quieres cancelar tu suscripción?',
      ValidUntil: 'Válido hasta el',
      UpgradeSubscriptionTitle: 'Ascender',
      UpgradeSubscriptionPromoText: 'Actualiza tu cuenta',
      Abort: 'Abortar',
      BuyNow: 'Comprar',
      Current: 'Plan actual',
      Monthly: 'Mensual',
      Yearly: 'Anual',
      monthly: 'mensual',
      yearly: 'año',
      month: 'mes',
      XCheaper: '{{x}} más barato!',
      Downgrade: 'Degradar',
      Forever: 'para siempre',
      ChangePlan: 'Cambio de plan',
      TrackReaderProgress: 'Rastrea a tus lectores en tiempo real',
      InlineComments: 'Comentarios y reacciones en línea',
      ReaderSurveys: 'Encuestas de lectores',
      NativeApps: 'Aplicación para Android y iOS',
      MultipleVersions: 'Versionamiento de manuscritos',
      '1ActiveManuscript': '1 manuscrito',
      '5ActiveManuscripts': 'Hasta 5 manuscritos',
      '20ActiveManuscripts': 'Hasta 20 manuscritos',
      UnlimitedActiveManuscripts: 'Manuscritos ilimitadas',
      '3ActiveReadersPerManuscript': 'Hasta 3 lectores',
      '20ActiveReadersPerManuscript': 'Hasta 20 lectores',
      '50ActiveReadersPerManuscript': 'Hasta 50 lectores',
      UnlimitedActiveReaders: 'Número ilimitado de lectores',
      Zero: 'Nada',
      NoCreditCardNeeded: 'No se necesita tarjeta de crédito',
      Recommended: 'Recomendado',
      SomethingWentWrong: 'Algo salió mal',
      SubscriptionChangeError: 'Error al cambiar la suscripción',
      SubscriptionChangeSuccess: 'Hemos actualizado tu suscripción',
      ActionCancelled: 'La acción fue cancelada',
      Cancelled: 'Cancelado',
      NextPayment: 'Próximo pago',
      PaymentPastDueMessage:
        'Su pago se ha vencido. Tentativa siguiente: {{nextAttempt}}',
      ChangePaymentInformation: 'Cambiar detalles de facturación',
      DashboardWelcomeMessage:
        '<0> Bienvenido a BetaReader.io! </ 0> <1> Para empezar, <2> subir </ 2> o <6> añadir </ 6> su manuscrito </ 1> <2> Descubrimiento </ 2> < 3> No se olvide de la lista de su manuscrito en nuestro <2> descubrimiento </ 2> sección, para acceder a los lectores más beta. </ 3> <4> <0> Aquí </ 0> es un tutorial rápido de cómo hacer eso. </ 4> <5> Soporte </ 5> <6> Por favor, echa un vistazo a nuestro <2> ayuda </ 2> portal o nos bala directamente pulsando el (?) en la parte superior derecha de la página. < / 6> <7> siempre puede enviar un correo electrónico amistoso en <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Manuscritos',
      CurrentlyReading: 'Actualmente leyendo',
      WeeklyReaders: 'Lectores semanales',
      TotalReaders: 'Lectores totales',
      FailedToDownloadReport: 'No se pudo descargar el informe',
      WaitingForConfirmation: 'Esperando confirmación',
      ChangePublishingStatusToAddReaders:
        'Cambiar el estado de publicación para agregar lectores',
      SuggestASwap: 'Sugerir un intercambio',
      WithdrawRequest: 'Retirar solicitud',
      Confirm: 'Confirmar',
      ThisIsASwapRequestForX:
        'Esta es una solicitud de intercambio para <2>{{x}}</2>. Si aprueba la solicitud de intercambio, se agregará como lector en {{x}}.',
      ApprovedSwapRequestForX:
        'Esta es una solicitud de intercambio para <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Esta es una solicitud de intercambio. Si aprueba esta solicitud, se le agregará como lector de:',
      SwapRequest: 'Solicitud de intercambio',
      ApproveAndJoin: 'Aprobar y unirse',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} se ha ofrecido a leer {{y}} a cambio de que leas este libro.',
      GoToSwapRequest: 'Ir a solicitud',
      SwitchVersion: 'Cambiar la versión',
      SendMagicLinkToX: 'Enviar un enlace mágico a {{x}}',
      notification_verb_comment: 'ha comentado en',
      notification_verb_react: 'reaccionó a',
      notification_verb_reply: 'respondió a un comentario en',
      notification_verb_abandon: 'abandonó tu libro',
      notification_verb_request_to_join: 'ha solicitado leer',
      notification_verb_message: 'te envié un mensaje',
      notification_verb_sent_join_approval:
        'ha aprobado su solicitud para leer',
      notification_verb_invitation_to_join: 'te invitó a leer {{y}}',
      notification_verb_manuscript_submitted:
        'ha enviado un manuscrito a {{y}}',
      'SaveAnd...': 'Guarda y...',
      Send: 'Enviar',
      OnlySave: 'Solo guardar',
      YourNotifications: 'Tus notificaciones',
      Notifications: 'Notificaciones',
      Images: 'Imágenes',
      '365DaysReadingData': '365 días de historia del lector',
      '90DaysReadingData': '90 días de historia del lector',
      '30DaysReadingData': '30 días de historia del lector',
      alerts_PaymentPastDue:
        'Su pago está vencido. Actualiza tus datos de facturación',
      alerts_MissingName:
        'Nos falta tu nombre (o seudónimo). Actualízalo aquí:',
      alerts_TimeLeftOnLaunchOffer:
        'Quedan {{x}} para reclamar su descuento de por vida. Ingrese LAUNCH en el campo del cupón cuando actualice:',
      RequestedToSwapForX: 'Ha solicitado cambiar éste por {{x}}',
      PaymentSuccessMessage:
        '<0><0>¡Gracias por tu compra!</0><1><0></0></1></0>',
      Dashboard: 'Inicio',
      year: 'año',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        '¿Qué probabilidades hay de que recomiendes BetaReader.io a un amigo?',
      NotAtAllLikely: 'No es probable',
      ExtremelyLikely: 'Extremadamente probable',
      ThanksDoYouWantToTellUsWhy: '¡Gracias! ¿Quieres decirnos por qué?',
      Create: 'Crear',
      Books: 'Libros',
      Feed: 'Fluir',
      ANewStory: 'una nueva historia',
      FailedToGetInvitations: 'No se pudo descargar invitaciones',
      unauthorizedPrivateBook:
        'Este libro es privado y no está disponible para esta cuenta.',
      invalidToken:
        'La invitación no es válida para esta cuenta o ha caducado.',
      RaiseYourReaderLimit: 'Aumenta tu límite de lectores (actualmente {{x}})',
      InviteYourFriends: 'invita a tus amigos',
      referralText:
        '<0> ¡Obtenga hasta 10 espacios de lectura adicionales invitando a sus amigos a BetaReader.io! </0> <1> Por cada amigo que cree una cuenta de autor validada y enumere un manuscrito gracias a usted, le daremos a usted y a su amigo 1 ranura de lectura cada una, hasta un máximo de 10 ranuras. </1> <2> También puede aumentar su límite de lectura leyendo y revisando 100.000 palabras a través de nuestra <1> <0> aplicación de lectura </0> </1>, o <3><0> actualizando</0> </3> su cuenta </2>',
      INVITED: 'Invitado',
      CREATED_ACCOUNT: 'Cuenta creada',
      CONFIRMED: 'Confirmado',
      RegistrationDate: 'Fecha de registro',
      Profile: 'Perfil',
      EditProfile: 'Editar perfil',
      GetMoreReaders: 'Consigue más lectores',
      Status: 'Estado',
      maxReadersLimitMessage:
        '<0>Has llegado a tu límite de lector.<1>Actualización</1> para enviar más invitaciones en nuestro índice de lectores o aprobar solicitudes de lectura. En nuestro plan gratuito, puede sugerirle a <3>swap</3> con otros escritores o invitar a tantos lectores que desee directamente a través de su dirección de correo electrónico.</0>',
      SwapsDontAffectReaderLimit:
        'El intercambio de manuscritos no cuenta para el límite de lectores.',
      maxBooksLimitMessage:
        '<0> Has alcanzado tu límite de manuscrito. <1> Eleve el límite </1> para poder probar varios manuscritos en paralelo. </0>',
      Type: 'Tipo',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> ¡Desbloquea tu bono de registro! </0> <1> Parece que un amigo te recomendó. ¡Sigue los pasos a continuación para desbloquear tu bono de registro mutuo! </1> <2> <0> <0 > </0> <1> <0> Validar cuenta </0> <1> Necesita un nombre y una dirección de correo electrónico válidos </1> </1> </0> <1> <0> </0> <1> <0> Listar manuscrito </0> <1> Listar el manuscrito que desea probar </1> </1> </1> <2> <0> </0> <1> <0> Espere </0> <1> Nos toma alrededor de 3 días validar </1> </1> </2> </2>',
      titleDiscoveryRecommended: 'Recomendado para ti',
      subheaderDiscoveryRecommended:
        'Algo nuevo basado en tus géneros seleccionados.',
      titleDiscoveryRecentlyAdded: 'Agregado recientemente',
      subheaderDiscoveryRecentlyAdded: 'Títulos añadidos recientemente',
      discoveryRecentlyAddedTitlesInGenre:
        'Títulos agregados recientemente en el género {{x}}',
      titleDiscoveryPremium: 'Títulos destacados',
      subheaderDiscoveryPremium: 'Títulos de autores premium',
      referralMessage:
        'Te invito a BetaReader.io! Si te registras usando este enlace, ambos obtendremos un bono!',
      referralBody: 'Regístrese aquí: {{x}}',
      ThisCannotBeUndone: 'Esto no se puede deshacer',
      DeletedVersion: 'La versión fue eliminada.',
      InvalidEmail: 'Correo electrónico no válido',
      EmailNotVerified: 'No verificado, por favor revisa tu correo electrónico',
      SelectFavoriteGenres: 'Selecciona tus géneros favoritos',
      Loading: 'Cargando',
      ChapterIdentification: 'Identificación de capítulos',
      ChapterAnalysis: 'Análisis de capítulos',
      CompleteImport: 'Completar la importación',
      Import: 'Importar',
      Processing: 'Procesando archivo ...',
      ThisMightTakeAWhile: 'Esto podría tomar un momento',
      LikeThisCover: '¿Te gusta la portada de este libro?',
      DownloadFailedMsg:
        'La descarga falló. ¿Fue bloqueado por un bloqueador de ventanas emergentes?',
      ShareAuthorAppEmailSuccessMessage:
        'Invitación enviada. Compruebe el estado <1> aquí </1>.',
      failedToUpdateReader: 'No se pudo actualizar el lector',
      activeReaders: 'Lectores activos',
      ReadingList: 'lista de lectura',
      Users: 'Usuarios',
      InviteUser: 'Invitar un usuario',
      Role: 'Role',
      member_role_admin: 'Administrador',
      member_role_member: 'Miembro',
      couponsOrDiscountsWillNotBeKept:
        '<0> Descuentos existentes no siga al nuevo plan. </ 0>',
      FailedToFetchX: 'Error al obtener {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Te han invitado a unirte a la cuenta BetaReader.io de {{x}}.',
      AcceptAccountInvitationMsg:
        'Si acepta la invitación, podrá ver y editar todos los manuscritos en esta cuenta.',
      InvitedMembersMsg:
        'Los miembros de la cuenta podrán ver y editar todos los manuscritos en su cuenta, pero no verán ningún detalle de facturación. Consulte betareader.io/pricing para obtener información sobre el costo por miembro de la cuenta.',
      InviteCollaborator: 'Invitar a un colaborador',
      InviteCollaboratorMsg:
        'Los colaboradores pueden ver los detalles del manuscrito y el lector y responder a los comentarios.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Has sido invitado a colaborar en {{book}} por {{author}}',
      AcceptCollaborationInvitationMsg:
        'Si acepta la invitación, podrá ver los detalles del manuscrito y el lector, y responder a los comentarios.',
      Collaborators: 'Colaboradores',
      ThisIsABetaFeature:
        'Esta es una característica beta. Háganos saber si tiene algún problema con él o si tiene algún comentario para compartir',
      UserNotFoundMsg:
        'Parece que no tienes una cuenta. Crea uno <1> aquí </1>!',
      AuthorName: 'Nombre del autor',
      Details: 'Detalles',
      Settings: 'Ajustes',
      ReviewAtLeastXwords: '{{x}} palabras revisadas',
      CheckBonus: 'Consulta tu bono',
      FailedToAddCommentOrReaction: 'No se pudo agregar comentario o reacción',
      ThisIsAWIP: 'Este es un WIP',
      WIPExplanation:
        'Marca tu historia como WIP (Trabajo en progreso) cuando no esté terminada, p. cuando no hayas agregado todos los capítulos.',
      MatureContent: 'Contenido adulto',
      PublicationDate: 'Publication',
      TimePlan: 'plan de tiempo',
      NotSpecified: 'No especificado',
      WhatIsThisStoryAbout: '¿De que se trata esta historia?',
      WhatDoYouNeedHelpWith: '¿Con qué necesitas ayuda?',
      StartWriting: 'empezar a escribir',
      CreateManuscript: 'Crear manuscrito',
      ImportManuscript: 'Importar manuscrito',
      ImportManuscriptStepDescription: 'Sube o crea tu manuscrito',
      InviteReaders: 'Invita a tus lectores',
      InviteReadersStepDescription: 'Tú decides quién puede leer',
      CollectFeedback: 'Recoger información',
      CollectFeedbackStepDescription:
        'Analizar comentarios y patrones de lectura',
      YouDontHaveAnyManuscripts: 'Aún no tienes manuscritos',
      AuthorsAndPublishers: 'Autores y editores',
      FindOutWhatYourReadersThink:
        'Descubre lo que tus lectores realmente piensan de tu libro',
      ReadAndInfluenceTheNextBestseller:
        'Leer e influir en los próximos best-sellers',
      PrivateSharing: 'Compartir en privado',
      PrivateSharingDescription:
        'Comparta su manuscrito de forma privada con los lectores.',
      CollectUnbiasedFeedback: 'Recopile comentarios imparciales',
      CollectUnbiasedFeedbackDescription:
        'Deje que sus lectores comenten sin ver los comentarios de los demás.',
      AnalyzeReadingPatterns: 'Analizar patrones de lectura',
      AnalyzeReadingPatternsDescription:
        'Descubra dónde pierden interés sus lectores o se enganchan con la historia',
      ReadItFirst: 'Léelo primero',
      ReadItFirstDescription:
        'Lee las próximas novelas mientras aún están en beta',
      InfluenceTheStory: 'Influir en la historia',
      InfluenceTheStoryDescription:
        'Su regeneración influirá en el resultado final',
      ConnectWithAuthors: 'Conecta con los autores',
      ConnectWithAuthorsDescription:
        'Ayuda a tus autores favoritos formando parte de su equipo beta',
      YourBooks: 'Tus libros',
      New: 'Nuevo',
      Members: 'Miembros',
      Contacts: 'Contactos',
      AddMembersFromContacts: 'Añadir los miembros de su lista de contactos',
      ConversationSettings: 'Configuraciones de conversación',
      PremiumAuthorInfo:
        'Este es un manuscrito de un autor premium. Esto generalmente significa que el autor está más involucrado en el proyecto y que puede esperar que hagan lo que sea necesario para publicar este libro.',
      StillWorkingOnThis:
        'Todavía estamos construyendo a cabo BetaReader.io. Háganos saber si hay algo específico que desea ver aquí.',
      Engagement: 'Compromiso',
      AssemblingLetters: 'Montaje de las letras ...',
      ConstructingAlphabet: 'La construcción de alfabeto ...',
      BuildingSentences: 'frases de construcción ...',
      BecomingSentient: 'Convertirse sensible ...',
      BooksRead: 'Libros leídos: {{x}}',
      WordsReviewed: 'Palabras revisados: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'el tiempo de respuesta promedio por cada 1000 palabras: {{count}} días',
      AvgTurnAroundTimePer1kWords_plural:
        'el tiempo de respuesta promedio por 1000 palabras: {{count}} días',
      ViewAsReader: 'Ver como lector',
      EditMode: 'Modo de edición',
      BookCover: 'Tapa del libro',
      WelcomeToBRReadathon: 'Bienvenido al BetaReader.io Readathon!',
      ReadathonHasEnded: 'El Readathon ya ha terminado.',
      ReadathonInfo:
        'El BetaReader.io Readathon dura entre 26 de septiembre y 31 de octubre de 2019. Cualquiera que lea revisa al menos un libro a través de BetaReader.io durante este tiempo y participa, y usted puede aumentar sus posibilidades de ganar mediante la lectura y la revisión de más libros. El ganador será elegido el 1 de noviembre, y las más palabras que ha leído y revisado por entonces, los mayores son sus posibilidades de ganar. Por ejemplo, alguien que ha leído y revisado 50.000 palabras tendrán 5x tiene una alta probabilidad de ganar como alguien que ha leído y revisado 10.000 palabras.',
      ReadathonPrize1: '1er premio: $ 50 Tarjeta de regalo de Amazon.',
      ReadathonPrize23: '2a-3a premio: $ 20 Tarjeta de regalo de Amazon.',
      SignInToJoin: 'Iniciar sesión en unirse',
      WordsReviewedByYou: 'Palabras revisados ​​por usted',
      WordsReviewedByEveryone: 'Palabras revisado por todo el mundo',
      ChanceOfWinning: 'Oportunidad de ganar actual',
      Completed: 'Terminado',
      CompletedAt: 'completado por lo',
      Pending: 'Pendiente',
      Position: 'Posición',
      FindSomethingToRead: 'Encuentra algo para leer',
      DontKnow: 'No se',
      IsThisReadyToPublish:
        '¿Crees que este libro está listo para ser publicado?',
      UpdateProfileHeader:
        'Necesitamos un poco más de información de su perfil',
      WhatsYourName: '¿Cuál es su nombre o seudónimo?',
      WhereAreYouFrom: '¿De donde eres?',
      WhenWereYouBorn: '¿En qué año nació?',
      WhichGenresDoYouPrefer: '¿Qué géneros prefieres?',
      WhichLanguagesDoYouRead: '¿Qué idiomas lees?',
      BioPlaceholder:
        'Díganos quién es y si tiene algún sitio web al que consultar. Una biografía más sólida lo ayudará a obtener la aprobación para más manuscritos.',
      Thanks: '¡Gracias!',
      WhenDoYouWantToHearFromUs: '¿Cuándo quieres saber de nosotros?',
      ReaderListConsentHeader:
        '¿Quieres ser incluido en nuestra base de datos de lectores?',
      ReaderListConsentInfo:
        'Los autores con obras que coincidan con tus preferencias podrán enviarte el enlace a sus libros, pero no estás obligado a leer si no quieres o no tienes tiempo.',
      RoleHeader: '¿Eres escritor o lector?',
      RoleInfo: 'Usamos esta información para optimizar su experiencia.',
      OnYourProfile: 'Esto se mostrará en su perfil de usuario.',
      ProfileBuilderThankYouMessage:
        '<0>Gracias!</0><1>Siempre se puede actualizar su perfil <1>aquí</1>.</1>',
      DoThisLater: 'Haz esto más tarde',
      '2MonthsFree': '2 meses gratis',
      Joined: 'Unido',
      UserInfo: 'información de usuario',
      Age: 'Edad',
      Bio: 'Biografía',
      ReaderList: 'encontrar lectores',
      Popular: 'Popular',
      BookHas0Words: '0 palabras',
      BookHas0WordsDescription:
        '<0> Este libro tiene por lo menos una versión con 0 palabras. Puede agregar o cargar capítulos </0> <1> aquí </1>.',
      LeaveBeta: 'Dejar beta',
      DiscoverNewBooks: 'Descubre nuevos libros',
      FailedToFindReader: 'No se pudo encontrar el lector',
      ApprovedReadRequest: 'Se aprueba la solicitud de lectura',
      Approved: 'Aprobado',
      UserAlreadyInvited: '{{user}} ya está invitado',
      SelectBook: 'Seleccionar libro',
      InvitationMessagePlaceholder:
        'Incluya un mensaje personal en su invitación para aumentar sus posibilidades de ser aceptado.',
      YouHaveAPendingInvitation: 'Tienes una invitación pendiente',
      ReaderIndexInfo:
        'La base de datos del lector contiene usuarios BetaReader.io que han indicado que están abiertos a ser abordados por los autores. Envíeles una invitación con su mejor tono, pero tenga en cuenta que pueden rechazar su invitación por cualquier motivo.',
      ReaderLimitReached: 'Has alcanzado tu límite de lectores.',
      Invite: 'Invitar',
      Languages: 'Idiomas',
      LinkWasSentToX: 'Hemos enviado un enlace de inicio de sesión a {{x}}',
      Verifying: 'Verificación ...',
      FoundNoAvailableManuscripts: 'No encontramos manuscritos disponibles.',
      FindASpecificQuestion: 'Filtrar preguntas',
      ReadAndReviewBonusInfo:
        'Sus actualizaciones de bonificación READ & REVIEW después de que termine y revise un libro.',
      ManuscriptStatus: 'Estado de manuscrito',
      MSStatusDescription_alpha:
        'Esta es una etapa temprana o manuscrito parcial.',
      MSStatusDescription_beta:
        'Este es un manuscrito en etapa avanzada de un libro completo',
      SelectStatus: 'Seleccionar estado',
      MaxAllowedGenres: 'Sólo puedes seleccionar hasta 5 géneros.',
      TooLongDescription:
        'Tu descripción es demasiado larga (máximo 1000 caracteres)',
      Duplicate: 'Duplicado',
      Download: 'Descargar',
      DuplicateVersionInfo:
        'Cuando duplica una versión, todos los capítulos y encuestas se copiarán a la nueva versión, pero sus lectores permanecerán en la versión anterior.',
      NoBookMsg:
        '<0> ¡Aquí no hay nada! </0> <1> <0> ¿Desea </0> <1> agregar su propio manuscrito? </1> </1>',
      CommentsCopiedFromOriginal:
        'Dos comentarios fueron copiados del documento original',
      CopyComments: 'Copiar todos los comentarios',
      MigrateReaders: 'Migrar lectores',
      Posts: 'Publicaciones',
      Sent: 'Enviado',
      Username: 'Nombre de usuario',
      UsernameAlreadyTaken: 'Este nombre de usuario está tomado',
      Followers: 'Seguidores',
      ReadersAndFollowers: 'Lectores y seguidores',
      WhoCanSeeThis: '¿Quién puede ver esto?',
      FollowCount: '{{count}} seguidor',
      FollowCount_plural: '{{count}} seguidores',
      Follow: 'Seguir',
      Following: 'Siguiente',
      MarkAllAsRead: 'Marcar todo como leido',
      Working: 'Laboral...',
      Audience: 'Audiencia',
      ShowAllComments: 'Mostrar todos los comentarios...',
      Publish: 'Publicar',
      InviteByEmail: 'Invitar por correo electrónico',
      InviteByLink: 'Invitar por enlace',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        '¡Hola {{x}}! Bienvenido al soporte de Betareader 👋, intentamos responder lo antes posible. ¿En qué podemos ayudarte?',
      TalkToSupport: 'Habla con nuestro equipo de soporte',
      StartSupportChat: 'Iniciar chat de soporte',
      StartSupportChatPMessage:
        '<0>Siempre puedes comunicarte con nosotros a través de nuestro chat y te responderemos tan pronto como podamos.</0>',
      FindHelpArticlesMessage:
        '<0>¿Necesitas ayuda? Consulte nuestra sección <1> ayuda </1>, repleta de artículos de ayuda que le guiarán.</0>',
      HelpArticles: 'Artículos de ayuda',
      CloseConversationAlertHeader: 'Cerrar conversación',
      CloseConversationAlertMessage: '¿Quieres cerrar esta conversación?',
      EmailSupportMessage:
        '<0> Envíe un correo electrónico a <1>{{mail}}</1> y le responderemos lo antes posible. </0>',
      Includes: 'Incluye',
      DoesNotInclude: 'No incluye',
      IncludesAll: 'Incluye todo',
      RemoveFilter: 'Quitar filtro',
      AddFilter: 'Agregar filtro',
      SelectGenres: 'Seleccionar géneros',
      And: 'Y',
      ContinueWithGoogle: 'Continuar con Google',
      ContinueWithFacebook: 'Continuar con Facebook',
      ContinueWithApple: 'Continuar con Apple',
      NewOnBetareader: '¿Nuevo en BetarReader?',
      AlreadyHaveAccount: '¿Ya tienes una cuenta?',
      SignUpWithFacebook: 'Regístrate con Facebook',
      SignUpWithGoogle: 'Regístrate con Google',
      SignUpWithApple: 'Regístrate con Apple',
      OpportunityWithoutDescriptionMessage:
        'El autor no ha proporcionado una descripción.',
      SignInToViewContent: 'Inicia sesión para ver este contenido',
      ContentBelongsToAnotherAccount:
        'Este contenido pertenece a una cuenta diferente a la suya activa.',
      SwitchAccount: 'Cambiar de cuenta',
      OwnBooks: 'Libros propios',
      Collaborations: 'Colaboraciones',
      UploadImage: 'Subir imagen',
      SplitChapter: 'Dividir el capítulo',
      UpgradeToUnlock: 'Actualiza para desbloquear',
      '3ReaderSlots': '3 ranuras para lectores',
      UnlimitedReaderSlots: 'Ranuras del lector ilimitadas',
      ImportWarning: 'Advertencia de importación',
      ImportExeedLenghMessage:
        '<0><0>Uno o más de sus capítulos parecen ser demasiado largos para importarlos; asegúrese de haber seguido nuestras<1> pautas de importación. </1></0><1>Consejo: puedes pasar el cursor sobre el texto del capítulo y utilizar la herramienta Sizzor para dividir capítulos más grandes</1></0>',
      OneTeamMember: '1 miembro del equipo',
      AdditionalTeamMembers: 'Agregar miembros del equipo',
      PerMonth: 'por mes',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Envíe su manuscrito',
      SubmitTo: 'Enviar a',
      YourSubmittedManuscripts: 'Sus manuscritos enviados',
      SelectManuscriptToSubmit: 'Seleccionar manuscrito para enviar',
      AvailableToRead: 'Disponible para leer',
      ManuscriptSubmissionSuccess: 'Manuscrito presentado',
      ManuscriptSubmittedTo:
        'Su manuscrito, {{_manuscriptName}}, se ha enviado a {{_inboxName}}',
      SubmittedBy: 'Presentado por {{_userName}}',
      ReceivedManuscripts: 'Manuscritos recieved',
      InviteToRead: 'invitar a leer',
      YourReview: 'Su reseña',
      UserReviewPlaceholder:
        'Las reseñas de los usuarios son visibles para todos.',
      LeaveReviewError:
        'Sólo puede revisar a los usuarios que hayan terminado de leer uno de sus manuscritos.',
      NotEnoughData: 'No hay suficientes datos',
      EditSignUpSurveyQuestions: 'Editar preguntas de la encuesta de registro',
      AddSignUpSurveyQuestions: 'Agregar preguntas de la encuesta de registro',
      To: 'A',
      FinishedReadingAt: 'Lectura terminada en {{x}}',
      FailedToSendInvitation:
        'No se pudo enviar la invitación, verifique la dirección de correo electrónico',
      EditInvitation: 'Editar invitación',
      ChapterSeparator: 'Separador de capítulos',
      CancelReason: 'Antes de continuar, ¿te importaría decirnos por qué?',
      cancel_reason_technical_issues: 'Problemas técnicos',
      cancel_reason_too_expensive: 'El producto es demasiado caro.',
      cancel_reason_finished_my_beta: 'He terminado mi proyecto',
      cancel_reason_switching_to_another_product:
        'Estoy cambiando a otro producto',
      cancel_reason_missing_features: 'Me falta características importantes',
      cancel_reason_did_not_meet_expectations:
        'La plataforma no cumplió con mis expectativas',
      cancel_reason_other: 'Otras razones',
      TellUsMore: 'Cuéntanos más',
      DeleteAccount: 'Eliminar cuenta',
      YourAccountHasBeenDeleted: 'Tu cuenta ha sido eliminada',
      DeleteAccountConfirmation:
        '¿Estás seguro de que quieres eliminar tu cuenta y todos sus datos? Esta acción no se puede deshacer.',
      GoodbyeMessage:
        'Lamentamos que nos dejes y esperamos que regreses en el futuro.',
      EditContent: 'Contenido editado',
      AffectedReadersMessage:
        '{{count}} lector ya ha leído este capítulo. Haga clic aquí para publicar una actualización sobre sus cambios.',
      AffectedReadersMessage_plural:
        '{{count}} leitores já leram este capítulo. Clique aqui para postar uma atualização sobre suas alterações.\n',
      ImportingChapterXofY: 'Importando capítulo {{_x}} / {{_y}}',
      ImportStarted: 'Importación iniciada',
      XVersions: '{{count}} versión',
      XVersions_plural: '{{count}} versiones',
      UnsubscribeSuccessful:
        'Se te ha dado de baja. Siempre puedes actualizar tus preferencias a través de tu perfil.',
      UnsubscribeUnsuccessful:
        'No se pudo cancelar la suscripción. Envíe un mensaje a support@betareader.io si el problema persiste.',
      GoodbyeHeader: '¡Adiós!',
      BulkAddReaders: 'Añadir lectores desde CSV',
      Home: 'Hogar',
      Actions: 'Comportamiento',
      Invitations: 'Invitaciones',
      SelectCSVWithReaders: 'Seleccionar CSV con lectores',
      Synopsis: 'Sinopsis',
      SynopsisDescription:
        'Deje que nuestra IA, BetaBot, genere una sinopsis basada en su texto. No será visible para sus lectores.',
      LastUpdated: 'Última actualización',
      CreateSynopsis: 'Crear sinopsis',
      RefreshSynopsis: 'Actualizar sinopsis',
      ElevatorPitch: 'Parcela de ascensor',
      ThisIsAPremiumFeature: 'Característica premium',
      GoBack: 'Volver',
      Retry: 'Rever'
    }
  },
  fr: {
    translations: {
      NoteThatChatWillUseYourUserName:
        "Veuillez noter que lors de la messagerie un lecteur votre nom d'utilisateur et que votre pseudonyme sera visible",
      Library: 'Bibliothèque',
      Account: 'Compte',
      EmptyLibrary: 'Votre bibliothèque est vide.',
      EmptyLibraryAction:
        "Vous attendiez-vous à trouver quelque chose ici? Contactez l'auteur, ou envoyez-nous un email sur {{_email}}, et nous verrons ce que nous pouvons faire.",
      ByAuthor: 'par {{_authorName}}',
      ExceedChapterLenghtWarning: 'Le chapitre est trop long à importer',
      WordCount: '{{_wordCount}} mots',
      WordCountString: '{{count}} mot',
      WordCountString_plural: '{{count}} mots',
      CharCountString: '{{count}} caractères',
      CharCountString_plural: '{{count}} caractères',
      SurveyCount: '{{count}} enquête',
      SurveyCount_plural: '{{count}} enquêtes',
      ChapterCount: '{{count}} chapitre',
      ChapterCount_plural: '{{count}} chapitres',
      QuestionCountString: '{{count}} question',
      QuestionCountString_plural: '{{count}} des questions',
      LoginToChangeEmail:
        "S'il vous plaît se connecter à nouveau pour changer votre e-mail",
      SendVerification: 'Envoyer le lien de vérification',
      Name: 'Prénom',
      Email: 'E-mail',
      EnterYourName: "S'il vous plaît entrez votre nom",
      ReceiveCompanyUpdates:
        'Recevoir des courriels et des nouvelles de bêta-lecteur',
      ReceiveBookEmails: 'Recevoir des courriels sur de nouveaux livres',
      ReceiveBookActivityUpdates:
        "Recevoir des courriels sur l'activité sur mes livres",
      ReceiveMessageUpdates:
        'Recevoir des e-mails lorsque je reçois de nouveaux messages',
      IncludeProfileInReaderList:
        'Inclure mon profil dans la base de données des lecteurs',
      PrivacyPolicy: 'Politique de confidentialité',
      TermsOfService: "Conditions d'utilisation",
      ReadOurBlog: 'Lire notre blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Suivant',
      Back: 'Retour',
      CreateAccount: 'Créer un compte',
      CreateAccountPrompt:
        '<0>Avez-vous pas un compte?<1> <0>Créer un ici!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Mot de passe oublié?<1> <0>Réinitialiser ici!</0></1></0>',
      SignIn: 'Se connecter',
      SignOut: 'Déconnexion',
      SendResetLink: 'Envoyer le lien reset',
      ResetPassword: 'Réinitialiser le mot de passe',
      Password: 'Mot de passe',
      OrUse: 'Ou utiliser',
      NoConnection: 'Hors ligne...',
      AcceptTermsMessage: 'Acceptez les conditions de service',
      AcceptToSToCreateAccount:
        'Vous devez accepter nos conditions de service pour créer un compte.',
      Answer: 'Répondre',
      FailedToLoadBook: 'Impossible de charger le livre.',
      PullDownToRefresh: 'Tirer vers le bas pour se rafraîchir.',
      ContactSupport: 'Contactez le support',
      Support: 'Soutien',
      LastRead: 'Dernière lecture',
      XInvitations: 'Vous avez {{count}} invitation',
      XInvitations_plural: '{{count}} invitations',
      EnterComment: 'Commentez...',
      EnterReply: 'Répondre...',
      Reply: 'Répondre',
      Reply_plural: 'Réponses',
      Reply_verb: 'Répondre',
      show: 'afficher',
      hide: 'masquer',
      Comments: 'Commentaires',
      InlineComment: 'Commentaire en ligne',
      Reject: 'Rejeter',
      Accept: 'Accepter',
      Error: 'Erreur',
      failed: 'échoué',
      SelectedText: 'Texte sélectionné',
      FailedToFetchUserProfile:
        "Impossible de récupérer le profil de l'utilisateur.",
      ProblemPersistContactSupport:
        "Si cela persiste problème, contactez l'assistance:",
      clickToAddComment: 'Cliquez ici pour ajouter un commentaire',
      ColorMode: 'Mode de couleur',
      FontSize: 'Taille de police',
      Discover: 'Découvrir',
      MyAccount: 'Mon compte',
      MyManuscripts: 'Mes manuscrits',
      Sweden: 'Suède',
      USA: 'USA',
      UK: 'ROYAUME-UNI',
      Germany: 'Allemagne',
      France: 'France',
      Spain: 'Espagne',
      Country: 'Pays',
      Gender: 'Genre',
      YoB: 'Année de naissance',
      PreferredGenres: 'Genres préférés',
      PreferredLanguages: 'Langues préférées',
      EmailPreferences: 'Préférences de courrier électronique',
      chapters: 'chapitres',
      words: 'mots',
      YouShouldBetaRead: 'Tu devrais lire',
      onBetaReader: 'sur BetaReader.io',
      Share: 'Partager',
      NoGuidelines:
        "L'auteur n'a fourni aucune directive en matière de commentaires.",
      Read: 'Lire',
      on: 'sur',
      Author: 'Auteur',
      JoinBeta: 'je veux lire ça',
      joinConsentString:
        "Je comprends qu'en demandant à rejoindre cette version bêta, je partagerai mon adresse e-mail avec l'auteur.",
      Submit: 'Soumettre',
      AnswerSurvey: 'Répondre!',
      SendJoinRequest: 'Envoyer',
      IJustWantToRead: 'Je veux juste lire',
      CopyrightInfo: "Informations sur les droits d'auteur",
      RequestToRead: 'Envoyer une demande de lecture',
      Feedback: 'Retour',
      NoComment: "Personne n'a commenté.",
      OnceUponATime: 'Il était une fois ...',
      Review: 'Revoir',
      Overall: "Dans l'ensemble",
      Plot: 'Intrigue',
      Grammar: 'Grammaire',
      Title: 'Titre',
      AddReview: 'Ajouter un avis',
      EnterReviewText: 'Entrez un bref avis',
      OverallRating: "Dans l'ensemble",
      OverallRatingRequired: 'Ajouter une note globale',
      PlotRating: 'Intrigue',
      GrammarRating: 'Grammaire',
      SubmitReview: "Envoyer l'avis",
      LeaveReview: 'Laisser un avis',
      checkOutAuthorApp:
        '<0>Êtes-vous un écrivain?</0><1></1><2>Consultez notre <1>app pour les auteurs</1></2>',
      MyLibrary: 'Ma bibliothèque',
      ReadingPreferences: 'Préférences de lecture',
      'E.g.': 'Par ex.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        "Je souhaite recevoir des e-mails de BetaReader.io lorsque l'auteur apporte des modifications ou des mises à jour à",
      'I want to receive emails about BetaReader news and updates.':
        'Je souhaite recevoir des e-mails sur les actualités et mises à jour de BetaReader.',
      'I want to receive emails about new books that match my reading preferences.':
        'Je souhaite recevoir des e-mails concernant les nouveaux livres qui correspondent à mes préférences de lecture.',
      'this book': 'ce livre',
      'Email us on': 'Envoyez-nous un email au',
      "and we'll see what we can do :)":
        'et nous verrons ce que nous pouvons faire :)',
      'Want an author account?': 'Vous voulez un compte auteur ?',
      Preferences: 'Préférences',
      Language: 'Langue',
      Browse: 'Parcourir',
      'to beginning': 'au début',
      'to chapter...': 'au chapitre...',
      Location: 'Emplacement',
      Previous: 'Précédent',
      Finish: 'Finition',
      Description: 'Description',
      FeedbackGuidelines: 'Directives relatives aux commentaires',
      'Start reading': 'Commencez à lire',
      StartReading: 'Commencez à lire',
      LeaveComment: 'Commenter',
      replies: 'réponses',
      Show: 'Montrer',
      Hide: 'Cacher',
      'Add Reply': 'Ajouter une réponse',
      PendingInvitation: 'Invitation en attente',
      Decline: 'Refuser',
      DeclinedInvitationMessage:
        "Vous avez décliné cette invitation à lire, mais vous pouvez toujours l'accepter si vous changez d'avis.",
      Welcome: 'Accueillir',
      by: 'de',
      to: 'à',
      AcceptInvitation: "Accepter l'invitation",
      InvitationAccepted: 'Invitation acceptée',
      WaitForBook: 'Veuillez patienter pendant que nous préparons votre livre',
      PasswordLength: 'Votre mot de passe doit comporter au moins 6 caractères',
      AgreeToPolicyAndTerms:
        "Vous devez accepter la politique de confidentialité et les conditions d'utilisation de BetaReader pour vous inscrire.",
      'Sending...': 'Envoi...',
      VerificationSent: 'E-mail de vérification envoyé.',
      VerificationEmailFailed: "Échec de l'envoi de l'e-mail de vérification.",
      IAcceptToC:
        "<0>J'accepte de BetaReader.io <1>Politique de confidentialité</1> & <3>Conditions d'utilisation</3></0>",
      AccountSettings: 'Paramètres du compte',
      ContactSettings: 'Paramètres du contact',
      Chapters: 'Chapitres',
      Options: 'Options',
      Option: 'Option',
      BetaLanguageMessage:
        "<0>Nous travaillons sur le soutien linguistique amélioré, et nous sommes super reconnaissants pour toute aide que nous pouvons obtenir. Si vous trouvez des erreurs sur le site, s'il vous plaît ne hésitez pas à nous contacter sur: <1>hello@betareader.io</1></0>",
      Genres: 'Genre',
      PressEnterToSave: 'Appuyez sur Entrée pour enregistrer',
      female: 'femelle',
      male: 'mâle',
      other: 'non binaire',
      DontWantToShare: 'privé',
      SignInWithPassword: 'Connectez-vous avec un mot de passe',
      GoToBook: 'Aller réserver',
      Requested: 'Votre demande est en attente',
      AnsweredOrRequestedNotice:
        "Vous avez demandé à rejoindre cette version bêta. L'auteur vous répondra dans les plus brefs délais.",
      RequestedWithDifferentEmail:
        "Vous avez demandé à rejoindre cette version bêta avec une autre adresse e-mail que celle avec laquelle vous avez été invité. L'auteur vous répondra dans les plus brefs délais.",
      LoadingLibrary: 'À la recherche de livres...',
      PreparingBook: 'Préparation livre',
      InLineCommentPlaceholder:
        'Laissez votre commentaire et appuyez sur Entrée pour enregistrer',
      ExitSurvey: 'Enquête de sortie',
      WhyExitQuestion: 'Pourquoi veux-tu quitter cette version bêta ?',
      LostInterest: "j'ai perdu tout intérêt",
      NotEnoughTime: "je n'ai pas assez de temps",
      Other: 'Autre raison',
      reason_lost_interest: "j'ai perdu tout intérêt",
      reason_no_time: "je n'ai pas assez de temps",
      reason_other: 'Autre raison',
      reason_: 'Non spécifié',
      ExitFeedbackQuestion:
        'Souhaitez-vous laisser quelques commentaires à l’auteur ?',
      ExitFeedbackPlaceholder: 'Tous les retours sont utiles',
      ThankYouForYourFeebdack: 'Merci pour votre retour !',
      AppVersion: 'Version',
      AppBuildNumber: '#',
      Like: 'Comme',
      Love: 'Amour',
      Laugh: 'Haha',
      Surprise: 'Surprendre',
      Sad: 'Triste',
      Angry: 'En colère',
      NoBooksInList: 'Pas de livres ici.',
      Update: 'Mettre à jour',
      Overview: 'Aperçu',
      Content: 'Contenu',
      Readers: 'Lecteurs',
      NotificationSettings: 'Paramètres de notification',
      SendEmailOnComments:
        'Envoyer un e-mail lorsque les lecteurs laissent des commentaires',
      SendEmailOnSurveyComplete:
        'Envoyer un e-mail lorsque les lecteurs répondent à une enquête',
      CoverUploadMsg:
        'Cliquez pour télécharger votre image de couverture (png ou jpg, 980x1568px), ou déposez-la dans cette zone.',
      DefaultNamePlaceholder: 'Par défaut: {{displayName}}',
      SelectUpTo5Genres: "Sélectionnez jusqu'à 5 genres",
      AdvancedSettings: 'Paramètres avancés',
      EnableComments: 'Activer les commentaires',
      EnableBookReviews: 'Activer les critiques de livres',
      DeleteBook: 'Supprimer livre',
      DeleteCover: 'Supprimer couverture',
      DeleteCoverConfirmation:
        'Êtes-vous sûr de vouloir supprimer la couverture du livre ?',
      YesBinIt: 'Oui, supprimez-le!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Êtes-vous sûr de vouloir supprimer <1>{{bookTitle}}</1>?</ 0>',
      DeletedBookX: '{{x}} a été supprimé.',
      No: 'Non',
      Yes: 'Oui',
      UserCommentedOn: 'commenté',
      ShowXReplies: 'Afficher {{count}} réponse',
      ShowXReplies_plural: 'Voir {{count}} réponses',
      HideXReplies: 'Cacher {{count}} réponse',
      HideXReplies_plural: 'Cacher {{count}} réponses',
      NoInLineComments: 'Aucun commentaire en ligne.',
      Chapter: 'Chapitre',
      NoCommentsToShow: 'Aucun commentaire à afficher.',
      Save: 'Sauvegarder',
      TooLongTitle: 'Votre titre est trop long (max 60 caractères)',
      ClickToUploadDoc:
        "Cliquez pour ajouter ou déposer votre fichier docx pour l'importer",
      LongChapterWarning: 'Long chapitre, cela est exact?',
      Upload: 'Télécharger',
      UploadChapters: 'Télécharger des chapitres',
      ImportBooksHelpMsg:
        "Personne ne pourra voir votre manuscrit sans votre approbation. En savoir plus sur <1> l'importation de livres </1>.",
      Add: 'Ajouter',
      NewChapter: 'Nouveau chapitre',
      NewSurvey: 'Nouvelle enquête',
      SelectedPart: 'partie sélectionnée',
      SelectPart: 'Sélectionner une pièce',
      NotifyMe: 'Prévenez-moi',
      WhenReadersStart: 'au démarrage',
      WhenReadersComplete: 'à la fin',
      ManageChapter: 'Gérer le chapitre',
      ManageSurvey: "Gérer l'enquête",
      ManageReaders: 'Gérer les lecteurs',
      MoveUp: 'Monter',
      MoveDown: 'Descendre',
      SaveSurvey: "Enregistrer l'enquête",
      Saved: 'Enregistré',
      EnterSurveyName: 'Donnez un nom à votre enquête',
      EnterSurveyDescription: 'Décrivez votre enquête',
      SelectAtLeast: 'Sélectionnez au moins',
      SelectAtMost: 'Sélectionnez au maximum',
      LabelOptional: 'Étiquette (facultatif)',
      MyBooks: 'Mes livres',
      ChangeRoleToWriterToCreateBooks:
        'Veuillez changer le rôle en écrivain pour créer des livres.',
      UsingXOutOfYBooks: 'Vous utilisez {{x}} de {{y}} livres.',
      UsingXOutOfYReaders: 'Vous utilisez {{x}} de {{y}} lecteurs.',
      NothingHere: 'Rien ici pour le moment.',
      OpenReaderWeb: 'Ouvrez le lecteur',
      PrivacyLevel: 'État de partage',
      PublishingStatus: 'État de partage',
      PrivacyLevel_infotext_unpublished:
        '<0> Votre livre est hors ligne. Déplacez-le dans <1> privé </1>, <3> fermé </3> ou <5> répertorié </5> pour que les lecteurs puissent le trouver. </0>',
      PrivacyLevel_infotext_private:
        '<0> Votre livre ne figure pas partout. vous pouvez seulement inviter les lecteurs. </0>',
      PrivacyLevel_infotext_closed:
        '<0> Présentation de votre livre peut être vu par tout le monde avec <1> le lien </1>. Les lecteurs peuvent demander une invitation, mais vous devez approuver chaque demande. </0>',
      PrivacyLevel_infotext_listed:
        '<0> est affichée sur votre livre <1>ici</1>. Les lecteurs peuvent demander une invitation, mais vous devez approuver chaque demande. </0>',
      PrivacyLevel_state_unpublished: 'Hors ligne',
      PrivacyLevel_state_private: 'Privé',
      PrivacyLevel_state_closed: 'Fermé',
      PrivacyLevel_state_listed: 'Listé',
      SendReminder: 'Envoyer un rappel',
      YouCanSendAReminderEvery3Days:
        'Vous pouvez envoyer un rappel tous les trois jours',
      LastSeenAt: 'Vu la dernière fois à',
      ReminderSentAt: 'Rappel envoyé',
      XReadersInactiveForYDays:
        "{{count}} lecteur n'a pas fini le livre et n'a pas été actif au cours des dernières {{days}} jours.",
      XReadersInactiveForYDays_plural:
        "{{count}} lecteurs ont pas fini le livre et n'ont pas été actifs au cours des dernières {{days}} jours.",
      InactiveReaders: 'Lecteurs inactifs',
      XReadersNotStartedForYDays:
        "{{count}} lecteur n'a pas accepté votre plus que {{days}} jours invitation",
      XReadersNotStartedForYDays_plural:
        "{{count}} lecteurs n'ont pas accepté votre plus que {{days}} jours invitation",
      ReadersWhoHaveNotAcceptedTheInvitation:
        "Lecteurs qui n'ont pas accepté l'invitation",
      YouHaveXJoinRequests:
        'Vous avez la demande {{count}} pour lire votre livre',
      YouHaveXJoinRequests_plural:
        'Vous avez {{count}} requêtes de lecture de votre livre',
      JoinRequests: 'Demandes de lecture',
      Approve: 'Approuver',
      RequestedAt: 'Demandé',
      NoAnswer: 'Pas de réponse',
      SendSurveyReminder: "Envoyer un rappel d'enquête",
      Remove: 'Retirer',
      Created: 'Créé',
      RequestedToRead: 'Demandé à lire',
      InvitationSent: 'Invitation envoyée',
      Reminded: 'Rappelé',
      Question: 'Question',
      YouCanAskUpToXQuestions:
        "Vous pouvez ajouter jusqu'à {{maxQuestions}} des questions que les lecteurs sont tenus de répondre lors de la signature.",
      NewQuestion: 'Nouvelle question',
      ReaderSignUpForm: "Formulaire d'inscription",
      NoReadersFound: 'Aucun lecteur trouvé',
      ReachedPartTitle: '{{X atteint}}',
      ReasonToQuit: 'Raison',
      YourBookDoesNotHaveAnyChaptersYet:
        "Votre livre n'a pas encore de chapitres",
      Invited: 'Invité',
      Started: 'Commencé',
      Finished: 'Fini',
      Abandoned: 'Abandonné',
      AvgOverallRating: "Dans l'ensemble",
      AvgPlotRating: 'Intrigue',
      AvgGrammarRating: 'Grammaire',
      YouDoNotHaveReviewsYet: "Vous n'avez pas encore d'avis.",
      PersonalLink: 'Lien personnel',
      HelpUsReachMoreWriters:
        "Aimez-vous utiliser BetaReader.io ? Utilisez votre lien personnel pour nous aider à aider plus d'auteurs !",
      Reports: 'Rapports',
      WeNeedYourEmail: 'Courriel nécessaire',
      MissingEmailMsg:
        'Nous devons pouvoir vous contacter concernant votre compte. Nous ne le partagerons avec personne et ne vous contacterons que si cela est strictement nécessaire.',
      EmailNotVerifiedPrompt:
        "E-mail non vérifié. <1> Cliquez ici pour renverrons </1> l'e-mail de vérification. (Vérifier si vous ne recevez pas votre dossier spam)",
      Skip: 'Passer',
      RoleWriter: 'Écrivain',
      RoleReader: 'Lecteur',
      RoleBoth: 'Les deux',
      AddReader: 'Ajouter un nouveau lecteur',
      DownloadReaderList: 'Télécharger la liste des lecteurs',
      'Show only': 'Afficher uniquement',
      StatusNEW: 'Nouveau',
      StatusTODO: 'Faire',
      StatusDOING: 'Faire',
      StatusDONE: 'Fait',
      StatusIGNORE: 'Ignorer',
      RemoveFilters: 'Supprimer les filtres',
      ReadingDataReport: 'Lecture de données',
      SurveyAnswersReport: "Réponses à l'enquête",
      Reviews: 'Avis',
      AbandonedReadersReport: 'Lecteurs perdus',
      SignUpMsg:
        '<0>Merci de votre inscription! </ 0> <1>Un e-mail de vérification a été envoyé à <1>{{email}} </ 1> </ 1>.',
      CopiedXToClipboard: 'Copié {{x}} dans le presse-papiers.',
      DoYouWantToBeMyBetaReader: 'Voulez-vous être mon lecteur bêta ?',
      LookingForBetaReadersForBookTitle:
        'Je suis à la recherche pour les lecteurs bêta pour {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Je suis à la recherche pour les lecteurs bêta pour {{bookTitle}}. Suivez ce lien pour en savoir plus et vous inscrire: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        "Le partage social n'est pas possible pour les livres privés.",
      ShareTheLinkToYourBook: 'Partagez le lien vers votre livre !',
      Messages: 'Messages',
      FailedToLoadMessage: "Nous n'avons pas réussi à charger ce message.",
      FailedToSendMessage: "Nous n'avons pas réussi à envoyer votre message.",
      FailedToStartConversation:
        "Nous n'avons pas réussi à entamer la conversation.",
      NewMessage: 'Nouveau message',
      MessageSubject: 'Sujet',
      MessageBody: 'Écrivez votre message...',
      MessageFrom: 'Depuis',
      MessageTo: 'À',
      SendMessage: 'Envoyer',
      Message: 'Message',
      ReaderMarketplaceBetaInfoText:
        "Nous expérimentons en permettant à nos auteurs de payer aux lecteurs ordinaires et aux rats de bibliothèque une somme symbolique pour le service de lecture d'un manuscrit. Nous proposons des mécanismes de contrôle pour valider les commentaires et contrôler que les lecteurs ont lu le manuscrit complet avant que quiconque ne soit payé. Si vous souhaitez essayer cette fonctionnalité, veuillez indiquer le nombre de lecteurs dont vous avez besoin.",
      ReaderMarketplaceBetaFullText:
        'Ce programme est actuellement complet. Souhaitez-vous que nous vous informions lorsque nous obtenons une ouverture ?',
      Sum: 'Somme',
      XUSDPerReader: '${{x}} / lecteur',
      AmountOfReaders: 'Nombre de lecteurs',
      PayForTestReaders: 'Payer pour les lecteurs de test',
      GoToCheckout: 'Passer à la caisse',
      YesPlease: "Oui s'il vous plait!",
      BETA: 'BÊTA',
      NoMessages: "Vous n'avez aucun message",
      OnBoarding_import_book:
        "<0> Bienvenue à BetaReader.io! </0> Afin de partager votre livre, vous devez d'abord importer, soit par <2> le télécharger </2>, ou en créant directement sur la plate-forme. Allez-y et essayez le! Tout sur BetaReader.io est privé par défaut, donc personne ne le verra à moins que vous les invitez.",
      Onboarding_book_overview:
        "L'aperçu du livre est l'endroit où vous configurez les détails de votre livre. Les directives concernant le titre, les genres et les commentaires sont ici, mais aussi si vous souhaitez ou non que les lecteurs puissent commenter et laisser des avis.",
      Onboarding_book_content:
        "L'onglet Contenu est l'endroit où vous contrôlez l'ordre des choses. Ajoutez vos chapitres et enquêtes ici.",
      Onboarding_book_readers:
        "L'onglet Lecteurs vous permet de contrôler qui peut lire votre livre. Envoyez des invitations privées, partagez votre lien d'inscription via Twitter ou ajoutez votre livre à notre liste Découverte afin d'atteindre plus de lecteurs.",
      Onboarding_book_feedback:
        "L'onglet Commentaires vous donne un aperçu de tous les commentaires laissés par les lecteurs. Répondez aux commentaires et filtrez les éléments sans importance.",
      Onboarding_book_reports:
        "L'onglet Rapport vous donne un aperçu de l'évolution de votre livre. Combien de lecteurs ont été invités, combien ont commencé à lire, ont laissé des critiques élogieuses, etc.",
      Close: 'Fermer',
      Last: 'Dernier',
      DuplicatedBook: '<0> <0> </0>livre copié {{x}} </0>',
      PenName: 'Pseudonyme',
      MissingNameMsg:
        'Vous devez saisir un nom pour pouvoir communiquer avec vos lecteurs.',
      NameConversation: 'Nommez cette conversation',
      With: 'avec...',
      SelectPeople: 'Sélectionnez des personnes',
      UploadAsNewVersion: '...comme nouvelle version',
      UploadAsExtraChapters: '...vers cette version',
      Cancel: 'Annuler',
      Unnamed: 'anonyme',
      NewVersion: 'Nouvelle version',
      NoContacts: 'Aucun contact',
      GetContacts:
        'Les auteurs des livres que vous lisez et les lecteurs des livres que vous écrivez deviennent vos contacts.',
      NoConversations: 'Aucune conversation...',
      AllVersionsNeedContent:
        'Votre version actuelle a besoin de contenu avant de pouvoir en créer une nouvelle.',
      InviteToVersion: 'Inviter à la version',
      latestVersion: 'dernier',
      VersionName: 'Version {{x}}',
      Reader: 'Lecteur',
      StartedReading: 'Lecture commencé',
      CurrentPosition: 'Poste actuel',
      PickVersion: 'Choisir la version',
      Filters: 'Filtres',
      Versions: 'variantes',
      ChapterInitial: 'ch',
      AvgTimeToComplete: 'Temps moyen pour lire le chapitre',
      ReaderProgress: 'Positions',
      SelectFilter: 'Sélectionnez un filtre',
      CreatePost: 'Partager une mise à jour',
      NewBookPostPlaceholder: 'Que veux-tu dire ?',
      BookUpdateForX: 'Mise à jour pour le livre {{x}}',
      OnSaveChapterMessage:
        'Faites savoir à vos lecteurs ce que vous avez changé',
      SampleChapterUpdatePost: "J'ai changé ça...",
      SaveWithoutPosting: 'Enregistrer sans partager ...',
      SaveAndPost: 'Enregistrer et publiér ...',
      DiscoverSearch: 'Rechercher un titre ou un genre...',
      LoadMore: 'Charger plus...',
      PendingReadInvitations: 'Invitations en attente',
      PendingReadRequests: 'Demandes en attente',
      UserReactedOn: 'a réagi sur',
      ChapterComment: 'Chapitre commentaire',
      InlineCommentNotFound:
        "Ce commentaire n'a pas été trouvé dans le texte. Le texte commenté a-t-il été supprimé ?",
      Or: 'Ou',
      Version: 'Version',
      Updates: 'Mises à jour',
      CurrentBetaIsFullMessage:
        "Cette version bêta est actuellement complète, veuillez contacter l'auteur pour ouvrir plus de créneaux.",
      Edit: 'Modifier',
      Delete: 'Supprimer',
      DropFilesHere: 'Déposez vos fichiers ici',
      ShowOnly: 'Afficher uniquement',
      FeedbackSearch: 'Recherche de texte',
      'BuySubscriptionToS&PPMessage':
        "<0> L’achat d’un abonnement auprès de BetaReader.io indique que vous avez lu et accepté nos <1> conditions d'utilisation </ 1> et <5> notre politique de confidentialité </ 5> </ 0>",
      Inbox: 'Boîte de réception',
      CofirmMovePart:
        'Voulez-vous vraiment déplacer cette partie ? Cela affectera les lecteurs suivants :',
      'AreYouSure?': 'Es-tu sûr?',
      LetYourReadersKnowWhatYouChanged:
        'Faites savoir à vos lecteurs ce que vous avez changé',
      ThisChangeAffectsTheFollowingReaders:
        'Ce changement affecte les lecteurs suivants :',
      MovedXFromYtoZ: 'Déplacé {{x}} de {{y}} vers {{z}}',
      Subscription: 'Abonnement',
      Subscriptions: 'Abonnements',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Plan de base, gratuit pour toujours.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription:
        'Bon pour les passe-temps perfectionnistes',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Idéal pour les écrivains indépendants',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Idéal pour les éditeurs',
      CancelSubscription: "Annuler l'abonnement",
      YourSubscriptionHasBeenCancelled: 'Votre abonnement a été annulé',
      CancelSubscriptionConfirmation:
        'Êtes-vous sûr de vouloir annuler votre abonnement?',
      ValidUntil: "Valable jusqu'au",
      UpgradeSubscriptionTitle: 'Mettre à niveau',
      UpgradeSubscriptionPromoText: 'Mettez à niveau votre compte',
      Abort: 'Avorter',
      BuyNow: 'Acheter',
      Current: 'Plan actuel',
      Monthly: 'Mensuel',
      Yearly: 'Annuel',
      monthly: 'mensuel',
      yearly: 'année',
      month: 'mois',
      XCheaper: '{{x}} moins cher!',
      Downgrade: 'Déclasser',
      Forever: 'pour toujours',
      ChangePlan: 'Changer de plan',
      TrackReaderProgress: 'Suivez vos lecteurs en temps réel',
      InlineComments: 'Commentaires et réactions en ligne',
      ReaderSurveys: 'Sondages auprès des lecteurs',
      NativeApps: 'Application Android et iOS',
      MultipleVersions: 'Versioning de manuscrit',
      '1ActiveManuscript': '1 manuscrit',
      '5ActiveManuscripts': "Jusqu'à 5 manuscrits",
      '20ActiveManuscripts': "Jusqu'à 20 manuscrits",
      UnlimitedActiveManuscripts: 'Manuscrits illimités',
      '3ActiveReadersPerManuscript': "Jusqu'à 3 lecteurs",
      '20ActiveReadersPerManuscript': "Jusqu'à 20 lecteurs",
      '50ActiveReadersPerManuscript': "Jusqu'à 50 lecteurs",
      UnlimitedActiveReaders: 'Nombre illimité de lecteurs',
      Zero: 'Rien',
      NoCreditCardNeeded: 'Aucune carte de crédit nécessaire',
      Recommended: 'Conseillé',
      SomethingWentWrong: 'Quelque chose a mal tourné',
      SubscriptionChangeError: "Échec de la modification de l'abonnement",
      SubscriptionChangeSuccess: 'Nous avons mis à jour votre abonnement',
      ActionCancelled: "L'action a été annulée",
      Cancelled: 'Annulé',
      NextPayment: 'Prochain paiement',
      PaymentPastDueMessage:
        'Votre paiement est en retard. Tentative suivante: {{nextAttempt}}',
      ChangePaymentInformation: 'Modifier les détails de facturation',
      DashboardWelcomeMessage:
        "<0> Bienvenue à BetaReader.io! </ 0> <1> Commencez par <2> importer </ 2> ou <6> ajouter </ 6> votre manuscrit </ 1> <2> Découverte </ 2> < 3> N’oubliez pas d'inscrire votre manuscrit dans notre section <2> découverte </ 2> , pour accéder à plus de lecteurs bêta. </ 3> <4> <0> Voici </ 0> les instructions étape en étape pour vous aider. </ 4> <5> Support </ 5> <6> Consultez notre <2> support </ 2> ou contactez-nous directement par appuyer (?) en haut à droite de la page. < / 6> <7> Vous pouvez toujours nous envoyer un mail amical <2> hello@betareader.io </ 2> </ 7>",
      Manuscripts: 'Manuscrits',
      CurrentlyReading: 'En train de lire',
      WeeklyReaders: 'Lecteurs hebdomadaires',
      TotalReaders: 'Total des lecteurs',
      FailedToDownloadReport: 'Échec du téléchargement du rapport',
      WaitingForConfirmation: 'En attente de confirmation',
      ChangePublishingStatusToAddReaders:
        'Changer le statut de publication pour ajouter des lecteurs',
      SuggestASwap: 'Proposer un échange',
      WithdrawRequest: 'Retirer demande',
      Confirm: 'Confirmer',
      ThisIsASwapRequestForX:
        "Ceci est une demande d'échange pour <2>{{x}}</2>. Si vous approuvez la demande d'échange, vous serez ajouté en tant que lecteur le {{x}}.",
      ApprovedSwapRequestForX:
        "Ceci est une demande d'échange pour <2>{{x}}</2>.",
      ThisIsASwapRequestFor:
        "Il s'agit d'une demande d'échange. Si vous approuvez cette demande, vous serez ajouté en tant que lecteur pour :",
      SwapRequest: "Demande d'échange",
      ApproveAndJoin: 'Approuver et rejoindre',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} hat angeboten, {{y}} zu lesen, wenn Sie dieses Buch lesen.',
      GoToSwapRequest: 'Aller à la demande',
      SwitchVersion: 'Changer de version',
      SendMagicLinkToX: 'Envoyer un lien magique à {{x}}',
      notification_verb_comment: 'a commenté sur',
      notification_verb_react: 'a réagi à',
      notification_verb_reply: 'a répondu à un commentaire sur',
      notification_verb_abandon: 'a abandonné votre livre',
      notification_verb_request_to_join: 'a demandé à lire',
      notification_verb_message: 'vous a envoyé un message',
      notification_verb_sent_join_approval:
        'a approuvé votre demande de lecture',
      notification_verb_invitation_to_join: 'vous a invité à lire {{y}}',
      notification_verb_manuscript_submitted: 'a soumis un manuscrit à {{y}}',
      'SaveAnd...': 'Enregistrez et...',
      Send: 'Envoyer',
      OnlySave: 'Seulement sauver',
      YourNotifications: 'Vos notifications',
      Notifications: 'Notifications',
      Images: 'Images',
      '365DaysReadingData': 'Historique du lecteur 365 jours',
      '90DaysReadingData': 'Historique du lecteur 90 jours',
      '30DaysReadingData': 'Historique du lecteur 30 jours',
      alerts_PaymentPastDue:
        'Votre paiement est en souffrance. Mettez à jour vos informations de facturation',
      alerts_MissingName:
        'Il nous manque votre nom (ou votre nom de plume). Mettez-le à jour ici :',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} restants pour réclamer votre réduction à vie. Entrez LAUNCH dans le champ du coupon lorsque vous effectuez une mise à niveau:',
      RequestedToSwapForX: "Vous avez demandé d'échanger celui-ci pour {{x}}",
      PaymentSuccessMessage:
        '<0><0>Merci pour votre achat !</0><1><0></0></1></0>',
      Dashboard: 'Accueil',
      year: 'année',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Quelle est la probabilité que vous recommandiez BetaReader.io à un ami ?',
      NotAtAllLikely: 'Peu probable',
      ExtremelyLikely: 'Extrêmement probable',
      ThanksDoYouWantToTellUsWhy: 'Merci! Voulez-vous nous dire pourquoi?',
      Create: 'Créer',
      Books: 'Livres',
      Feed: 'Flux',
      ANewStory: 'une nouvelle histoire',
      FailedToGetInvitations: 'Impossible de télécharger des invitations',
      unauthorizedPrivateBook:
        "Ce livre est privé et n'est pas disponible pour ce compte.",
      invalidToken: "L'invitation n'est pas valide pour ce compte ou a expiré.",
      RaiseYourReaderLimit:
        'Augmentez votre limite de lecteurs (actuellement {{x}})',
      InviteYourFriends: 'Invitez vos amis',
      referralText:
        "<0> Obtenez jusqu'à 10 emplacements de lecture supplémentaires en invitant vos amis sur BetaReader.io! </0> <1> Pour chaque ami qui crée un compte d'auteur validé et répertorie un manuscrit grâce à vous, nous vous donnerons, vous et votre ami 1 emplacement de lecteur chacun, jusqu'à un maximum de 10 emplacements. </1> <2> Vous pouvez également augmenter votre limite de lecture en lisant et en révisant 100 000 mots via notre <1> <0> application de lecture </0> </1>, ou en <3><0>mettre à jour</0> </3> votre compte! </2>",
      INVITED: 'Invité',
      CREATED_ACCOUNT: 'Compte créé',
      CONFIRMED: 'Confirmé',
      RegistrationDate: "Date d'inscription",
      Profile: 'Profil',
      EditProfile: 'Modifier le profil',
      GetMoreReaders: 'Obtenez plus de lecteurs',
      Status: 'Statut',
      maxReadersLimitMessage:
        "<0>Vous avez atteint votre limite de lecture. <1>Mettez à niveau</1> Pour envoyer plus d'invitations dans notre indice de lecteur ou approuver les demandes de lecture. Dans notre plan gratuit, vous pouvez suggérer à <3>Swap</3> avec d'autres écrivains ou invitez autant de lecteurs que vous souhaitez directement via leur adresse e-mail.</0>",
      SwapsDontAffectReaderLimit:
        "L'échange de manuscrit ne compte pas dans votre limite de lecture.",
      maxBooksLimitMessage:
        '<0> Vous avez atteint votre limite de manuscrit. <1> Augmentez la limite </1> pour pouvoir tester plusieurs manuscrits en parallèle. </0>',
      Type: 'Taper',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        "<0> Débloquez votre bonus d'inscription! </ 0> <1> Il semble que vous ayez été référé par un ami. Suivez les étapes ci-dessous pour débloquer votre bonus d'inscription mutuel! </ 1> <2> <0> <0 > </ 0> <1> <0> Valider le compte </ 0> <1> Vous avez besoin d'un nom et d'une adresse e-mail valides </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Liste du manuscrit </ 0> <1> Indiquez le manuscrit que vous souhaitez tester </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> Attendez </ 0> <1> Il nous faut environ 3 jours pour valider </ 1> </ 1> </ 2> </ 2>",
      titleDiscoveryRecommended: 'Recommandé pour vous',
      subheaderDiscoveryRecommended:
        'Quelque chose de nouveau en fonction de vos genres sélectionnés',
      titleDiscoveryRecentlyAdded: 'Récemment ajouté',
      subheaderDiscoveryRecentlyAdded: 'Titres récemment ajoutés',
      discoveryRecentlyAddedTitlesInGenre:
        'Titres récemment ajoutés dans le genre {{x}}',
      titleDiscoveryPremium: 'Titres en vedette',
      subheaderDiscoveryPremium: "Titres d'auteurs premium",
      referralMessage:
        'Je vous invite à BetaReader.io! Si vous vous inscrivez en utilisant ce lien, nous recevons tous les deux un bonus!',
      referralBody: 'Inscrivez-vous ici: {{x}}',
      ThisCannotBeUndone: 'Cela ne peut pas être annulé',
      DeletedVersion: 'La version a été supprimée',
      InvalidEmail: 'E-mail invalide',
      EmailNotVerified: 'Non vérifié, veuillez vérifier votre email',
      SelectFavoriteGenres: 'Sélectionnez vos genres préférés',
      Loading: 'Chargement',
      ChapterIdentification: 'Identification de chapitres',
      ChapterAnalysis: 'Analyse de chapitres',
      CompleteImport: "Terminez l'importation",
      Import: 'Importer',
      Processing: 'Traitement du fichier ...',
      ThisMightTakeAWhile: 'Cela pourrait prendre un moment',
      LikeThisCover: 'Vous aimez cette couverture de livre ?',
      DownloadFailedMsg:
        'Le téléchargement a échoué. A-t-il été bloqué par un bloqueur de pop-up ?',
      ShareAuthorAppEmailSuccessMessage:
        'Invitation envoyée. Vérifiez l’état <1> ici </1>.',
      failedToUpdateReader: 'Échec de la mise à jour du lecteur',
      activeReaders: 'Lecteurs actifs',
      ReadingList: 'Liste de lecture',
      Users: 'Utilisateurs',
      InviteUser: 'Inviter un utilisateur',
      Role: 'Rôle',
      member_role_admin: 'Administrateur',
      member_role_member: 'Membre',
      couponsOrDiscountsWillNotBeKept:
        '<0> Réductions existantes ne suivent pas le nouveau plan. </ 0>',
      FailedToFetchX: "Impossible d'obtenir {{x}}",
      YouHaveBeenInvitedToJoinAccountX:
        'Vous avez été invité à vous connecter au compte BetaReader.io de {{x}}.',
      AcceptAccountInvitationMsg:
        "Si vous acceptez l'invitation, vous pourrez voir et éditer tous les manuscrits de ce compte.",
      InvitedMembersMsg:
        "Les membres du compte pourront voir et modifier tous les manuscrits de votre compte, mais ils ne verront aucun détail de facturation. Voir betareader.io/pricing pour plus d'informations sur le coût par membre du compte.",
      InviteCollaborator: 'Inviter un collaborateur',
      InviteCollaboratorMsg:
        'Les collaborateurs peuvent visualiser les détails du manuscrit et du lecteur et répondre aux commentaires.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Vous avez été invité à collaborer sur {{book}} par {{author}}',
      AcceptCollaborationInvitationMsg:
        "Si vous acceptez l'invitation, vous pourrez afficher les détails du manuscrit et du lecteur, et répondre aux commentaires.",
      Collaborators: 'Collaborateurs',
      ThisIsABetaFeature:
        'Ceci est une fonctionnalité bêta. Faites-nous savoir si vous rencontrez des problèmes, ou si vous avez des commentaires à partager.',
      UserNotFoundMsg:
        "On dirait que vous n'avez pas de compte. Créez-en un <1> ici </ 1>!",
      AuthorName: "Nom de l'auteur",
      Details: 'Détails',
      Settings: 'Paramètres',
      ReviewAtLeastXwords: '{{x}} mots revue',
      CheckBonus: 'Vérifiez votre bonus',
      FailedToAddCommentOrReaction:
        "Échec de l'ajout d'un commentaire ou d'une réaction",
      ThisIsAWIP: 'Ceci est un en-cours',
      WIPExplanation:
        "Marquez votre histoire comme WIP (Work In Progress) lorsqu'elle n'est pas terminée, par ex. quand vous n'avez pas ajouté tous les chapitres.",
      MatureContent: 'Adulte',
      PublicationDate: 'Publication',
      TimePlan: 'Calendrier',
      NotSpecified: 'Non spécifié',
      WhatIsThisStoryAbout: 'De quoi parle cette histoire?',
      WhatDoYouNeedHelpWith: 'Pour quoi avez-vous besoin d’aide ?',
      StartWriting: 'Commencez à écrire',
      CreateManuscript: 'Créer un manuscrit',
      ImportManuscript: 'Importer le manuscrit',
      ImportManuscriptStepDescription: 'Téléchargez ou créez votre manuscrit',
      InviteReaders: 'Invitez vos lecteurs',
      InviteReadersStepDescription: 'Vous décidez qui peut lire',
      CollectFeedback: 'Recueillir des commentaires',
      CollectFeedbackStepDescription:
        'Analyser les commentaires et les habitudes de lecture',
      YouDontHaveAnyManuscripts: "Vous n'avez pas encore de manuscrit",
      AuthorsAndPublishers: 'Auteurs et éditeurs',
      FindOutWhatYourReadersThink:
        'Découvrez ce que vos lecteurs pensent vraiment de votre livre',
      ReadAndInfluenceTheNextBestseller:
        'Lisez et influencez les prochains best-sellers',
      PrivateSharing: 'Partage privé',
      PrivateSharingDescription:
        'Partagez votre manuscrit en privé avec les lecteurs',
      CollectUnbiasedFeedback: 'Recueillir des commentaires impartiaux',
      CollectUnbiasedFeedbackDescription:
        'Laissez vos lecteurs commenter sans voir les commentaires des autres',
      AnalyzeReadingPatterns: 'Analyser les habitudes de lecture',
      AnalyzeReadingPatternsDescription:
        'Découvrez où vos lecteurs perdent tout intérêt ou deviennent accrochés à l’histoire',
      ReadItFirst: "Lisez-le d'abord",
      ReadItFirstDescription:
        "Lire les romans à venir alors qu'ils sont encore en version bêta",
      InfluenceTheStory: "Influencer l'histoire",
      InfluenceTheStoryDescription:
        'Vos commentaires influencera le résultat final',
      ConnectWithAuthors: 'Connectez-vous avec les auteurs',
      ConnectWithAuthorsDescription:
        'Aidez tes auteurs préférés en faisant partie de leur équipe bêta',
      YourBooks: 'Tes livres',
      New: 'Nouveau',
      Members: 'Membres',
      Contacts: 'Contacts',
      AddMembersFromContacts: 'Ajouter des membres de votre liste de contacts',
      ConversationSettings: 'Paramètres de conversation',
      PremiumAuthorInfo:
        "Ceci est un manuscrit d'un auteur premium. Cela signifie généralement que l'auteur investit davantage dans le projet et que vous pouvez vous attendre à ce qu'il fasse le nécessaire pour que ce livre soit publié.",
      StillWorkingOnThis:
        "Nous sommes encore en train de construire des BetaReader.io. Faites-nous savoir s'il y a quelque chose que vous voulez voir spécifique ici.",
      Engagement: 'Fiançailles',
      AssemblingLetters: 'Assemblage des lettres ...',
      ConstructingAlphabet: "La construction de l'alphabet ...",
      BuildingSentences: 'peines de construction ...',
      BecomingSentient: 'Devenir ... sensitif',
      BooksRead: 'Livres lus: {{x}}',
      WordsReviewed: 'Mots examinés: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Délai moyen pour 1000 mots: {{count}} jours',
      AvgTurnAroundTimePer1kWords_plural:
        'Délai moyen pour 1000 mots: {{count}} jours',
      ViewAsReader: 'Afficher en lecteur',
      EditMode: 'Mode édition',
      BookCover: 'couverture du livre',
      WelcomeToBRReadathon: 'Bienvenue sur le BetaReader.io Readathon!',
      ReadathonHasEnded: 'Le Readathon est maintenant terminé.',
      ReadathonInfo:
        "Le BetaReader.io Readathon dure entre Septembre 26 et 31 Octobre, 2019. Toute personne qui lit et examine au moins un livre par BetaReader.io pendant ce temps participe, et vous pouvez augmenter vos chances de gagner en lisant et en examinant d'autres livres. Le gagnant sera tiré au sort le 1er Novembre, et plus les mots que vous avez lu et examiné alors, les plus vos chances de gagner. Par exemple, quelqu'un qui a lu et examiné 50.000 mots auront 5x a une grande chance de gagner comme quelqu'un qui a lu et examiné 10.000 mots.",
      ReadathonPrize1: '1er prix: 50 $ Carte-cadeau Amazon.',
      ReadathonPrize23: '2e-3e prix: 20 $ Amazon Gift Card.',
      SignInToJoin: 'Connectez-vous pour joindre',
      WordsReviewedByYou: 'Les mots examinés par vous',
      WordsReviewedByEveryone: 'Mots examinés par tout le monde',
      ChanceOfWinning: 'La chance actuelle de gagner',
      Completed: 'Terminé',
      CompletedAt: 'terminé à',
      Pending: 'En attente',
      Position: 'Position',
      FindSomethingToRead: 'Trouver quelque chose à lire',
      DontKnow: 'Ne sais pas',
      IsThisReadyToPublish: 'Pensez-vous que ce livre est prêt à être publié?',
      UpdateProfileHeader:
        "Nous avons besoin de plus d'informations pour votre profil",
      WhatsYourName: 'Quel est votre nom ou pseudonyme?',
      WhereAreYouFrom: "D'où êtes-vous?",
      WhenWereYouBorn: 'Quelle année êtes-vous né?',
      WhichGenresDoYouPrefer: 'Quels sont les genres préférez-vous?',
      WhichLanguagesDoYouRead: 'Quelles langues lisez-vous?',
      BioPlaceholder:
        'Dites-nous qui vous êtes et si vous avez des sites Web auxquels vous référer. Une bio plus forte vous aidera à faire approuver davantage de manuscrits.',
      Thanks: 'Merci!',
      WhenDoYouWantToHearFromUs:
        'Quand souhaitez-vous avoir de nos nouvelles ?',
      ReaderListConsentHeader:
        'Voulez-vous être inclus dans notre base de données de lecteurs?',
      ReaderListConsentInfo:
        "Les auteurs dont les œuvres correspondent à vos préférences pourront vous envoyer le lien vers leurs livres, mais vous n'êtes pas obligé de lire si vous ne voulez pas ou n'avez pas le temps.",
      RoleHeader: 'Êtes-vous écrivain ou lecteur?',
      RoleInfo:
        'Nous utilisons ces informations pour optimiser votre expérience.',
      OnYourProfile: 'Ceci sera affiché sur votre profil utilisateur.',
      ProfileBuilderThankYouMessage:
        '<0>Merci!</0><1>Vous pouvez toujours mettre à jour votre profil <1>ici</1>.</1>',
      DoThisLater: 'Fais ça plus tard',
      '2MonthsFree': '2 mois gratuits',
      Joined: 'Rejoint',
      UserInfo: 'Informations utilisateur',
      Age: 'Âge',
      Bio: 'Biographie',
      ReaderList: 'Trouver des lecteurs',
      Popular: 'Populaire',
      BookHas0Words: '0 mots',
      BookHas0WordsDescription:
        '<0> Ce livre a au moins une version avec 0 mots. Vous pouvez ajouter ou télécharger des chapitres </0> <1> ici </1>.',
      LeaveBeta: 'Quitter la version bêta',
      DiscoverNewBooks: 'Découvrez de nouveaux livres',
      FailedToFindReader: 'Impossible de trouver le lecteur',
      ApprovedReadRequest: 'La demande de lecture est approuvée',
      Approved: 'Approuvé',
      UserAlreadyInvited: '{{user}} est déjà invité',
      SelectBook: 'Sélectionner un livre',
      InvitationMessagePlaceholder:
        "Incluez un message personnel dans votre invitation pour augmenter vos chances d'être accepté.",
      YouHaveAPendingInvitation: 'Vous avez une invitation en attente',
      ReaderIndexInfo:
        "La base de données des lecteurs contient les utilisateurs de BetaReader.io qui ont indiqué qu’ils sont prêts à être approchés par les auteurs. Envoyez-leur une invitation avec votre meilleur argumentaire, mais gardez à l'esprit qu'ils sont libres de refuser votre invitation pour quelque raison que ce soit.",
      ReaderLimitReached: 'Vous avez atteint votre limite de lecteurs',
      Invite: 'Inviter',
      Languages: 'Langues',
      LinkWasSentToX: 'Nous avons envoyé un lien de connexion à {{x}}.',
      Verifying: 'Vérification...',
      FoundNoAvailableManuscripts:
        "Nous n'avons trouvé aucun manuscrit disponible",
      FindASpecificQuestion: 'Filtrer les questions',
      ReadAndReviewBonusInfo:
        'Votre bonus de READ & REVIEW est mis à jour après avoir terminé et révisé un livre.',
      ManuscriptStatus: 'État manuscrit',
      MSStatusDescription_alpha:
        'Ceci est un manuscrit à un stade précoce ou partiel.',
      MSStatusDescription_beta:
        "Ceci est un manuscrit tardif d'un livre complet",
      SelectStatus: 'Sélectionnez le statut',
      MaxAllowedGenres: "Vous ne pouvez sélectionner que jusqu'à 5 genres",
      TooLongDescription:
        'Votre description est trop longue (max 1000 caractères)',
      Duplicate: 'Double',
      Download: 'Télécharger',
      DuplicateVersionInfo:
        "Lorsque vous dupliquez une version, tous les chapitres et sondages seront copiés dans la nouvelle version, mais vos lecteurs resteront sur l'ancienne version.",
      NoBookMsg:
        "<0> Il n'y a rien ici! </0> <1> <0> Voulez-vous </0> <1> ajouter votre propre manuscrit? </1> </1>",
      CommentsCopiedFromOriginal:
        "Des commentaires ont été copiés du document d'origine",
      CopyComments: 'Copiez tous les commentaires',
      MigrateReaders: 'Migrer les lecteurs',
      Posts: 'Messages',
      Sent: 'Envoyé',
      Username: "Nom d'utilisateur",
      UsernameAlreadyTaken: "Ce nom d'utilisateur est pris",
      Followers: 'Abonnés',
      ReadersAndFollowers: 'Les lecteurs et les suiveurs',
      WhoCanSeeThis: 'Qui peut voir ça ?',
      FollowCount: '{{count}} suiveur',
      FollowCount_plural: '{{count}} disciples',
      Follow: 'Suivre',
      Following: 'Suivant',
      MarkAllAsRead: 'Tout marquer comme lu',
      Working: 'Fonctionnement...',
      Audience: 'Public',
      ShowAllComments: 'Afficher tous les commentaires...',
      Publish: 'Publier',
      InviteByEmail: 'Inviter par email',
      InviteByLink: 'Inviter par lien',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        'Salut {{x}} ! Bienvenue sur le support Betareader 👋, nous essayons de répondre dans les plus brefs délais. En quoi pouvons-nous vous aider ?',
      TalkToSupport: "Parlez à notre équipe d'assistance",
      StartSupportChat: "Démarrer le chat d'assistance",
      StartSupportChatPMessage:
        '<0>Vous pouvez toujours nous joindre via notre chat et nous vous répondrons dans les plus brefs délais.</0>',
      FindHelpArticlesMessage:
        "<0>Besoin d'aide ? consultez notre <1> section d'aide </1>, qui regorge d'articles d'aide pour vous guider.</0>",
      HelpArticles: "Articles d'aide",
      CloseConversationAlertHeader: 'Fermer la conversation',
      CloseConversationAlertMessage:
        'Voulez-vous mettre fin à cette conversation ?',
      EmailSupportMessage:
        '<0> Envoyez un e-mail à <1> support@betareader.io </1>, et nous vous répondrons dès que possible. </0>',
      Includes: 'Comprend',
      DoesNotInclude: "N'inclut pas",
      IncludesAll: 'Comprend tout',
      RemoveFilter: 'Supprimer le filtre',
      AddFilter: 'Ajouter un filtre',
      SelectGenres: 'Sélectionnez les genres',
      And: 'Et',
      ContinueWithGoogle: 'Continuer avec Google',
      ContinueWithFacebook: 'Continuer avec Facebook',
      ContinueWithApple: 'Continuer avec Apple',
      NewOnBetareader: 'Nouveau sur BetarReader ?',
      AlreadyHaveAccount: 'Vous avez déjà un compte ?',
      SignUpWithFacebook: 'Inscrivez-vous avec Facebook',
      SignUpWithGoogle: 'Inscrivez-vous avec Google',
      SignUpWithApple: 'Inscrivez-vous avec Apple',
      OpportunityWithoutDescriptionMessage:
        "L'auteur n'a pas fourni de description.",
      SignInToViewContent: 'Connectez-vous pour voir ce contenu',
      ContentBelongsToAnotherAccount:
        'Ce contenu appartient à un compte différent de votre compte actif.',
      SwitchAccount: 'Changer de compte',
      OwnBooks: 'Posséder des livres',
      Collaborations: 'Collaborations',
      UploadImage: 'Télécharger une image',
      SplitChapter: 'Divisez le chapitre',
      UpgradeToUnlock: 'Mettre à niveau pour déverrouiller',
      '3ReaderSlots': '3 emplacements pour lecteurs',
      UnlimitedReaderSlots: 'Fentes de lecture illimités',
      ImportWarning: "Avertissement d'importation",
      ImportExeedLenghMessage:
        "<0><0>Un ou plusieurs de vos chapitres semblent trop longs à importer, assurez-vous d'avoir suivi nos<1> directives d'importation. </1></0><1>Conseil : vous pouvez survoler le texte du chapitre et utiliser l'outil Sizzor pour diviser des chapitres plus grands</1></0>",
      OneTeamMember: "1 membre de l'équipe",
      AdditionalTeamMembers: "Ajouter des membres de l'équipe",
      PerMonth: 'par mois',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Soumettez votre manuscrit',
      SubmitTo: 'Soumettre à',
      YourSubmittedManuscripts: 'Vos manuscrits soumis',
      SelectManuscriptToSubmit: 'Sélectionnez le manuscrit à soumettre',
      AvailableToRead: 'Disponible pour lire',
      ManuscriptSubmissionSuccess: 'Manuscrit soumis',
      ManuscriptSubmittedTo:
        'Votre manuscrit, {{_manuscriptName}}, a été envoyé à {{_inboxName}}',
      SubmittedBy: 'Soumis par {{_userName}}',
      ReceivedManuscripts: 'Manuscrits reçu',
      InviteToRead: 'Inviter à lire',
      YourReview: 'Votre avis',
      UserReviewPlaceholder:
        'Les avis des utilisateurs sont visibles par tous.',
      LeaveReviewError:
        "Vous ne pouvez évaluer que les utilisateurs qui ont fini de lire l'un de vos manuscrits.",
      NotEnoughData: 'Pas assez de données',
      EditSignUpSurveyQuestions:
        "Modifier les questions de l'enquête d'inscription",
      AddSignUpSurveyQuestions: "Ajouter des questions d'enquête d'inscription",
      To: 'À',
      FinishedReadingAt: 'Lecture terminée à {{x}}',
      FailedToSendInvitation:
        "Échec de l'envoi de l'invitation, veuillez vérifier l'adresse e-mail",
      EditInvitation: "Modifier l'invitation",
      ChapterSeparator: 'Séparateur de chapitre',
      CancelReason:
        'Avant de continuer, pourriez-vous nous expliquer pourquoi ?',
      cancel_reason_technical_issues: 'Problèmes techniques',
      cancel_reason_too_expensive: 'Le produit est trop cher',
      cancel_reason_finished_my_beta: 'Je finis mon projet',
      cancel_reason_switching_to_another_product:
        'Je suis passer à un autre produit',
      cancel_reason_missing_features:
        'Il me manque des caractéristiques importantes',
      cancel_reason_did_not_meet_expectations:
        "La plate-forme n'a pas répondu à mes attentes",
      cancel_reason_other: 'Autres raisons',
      TellUsMore: 'dis nous en plus',
      DeleteAccount: 'Supprimer le compte',
      YourAccountHasBeenDeleted: 'Votre compte a été supprimé',
      DeleteAccountConfirmation:
        'Êtes-vous sûr de vouloir supprimer votre compte et toutes ses données? Cette action ne peut pas être annulée.',
      GoodbyeMessage:
        'Nous sommes désolés de vous voir quitter et espérons que vous reviendrez dans le futur.',
      EditContent: 'Modifier le contenu',
      AffectedReadersMessage:
        '{{count}} lecteur a déjà lu ce chapitre. Cliquez ici pour publier une mise à jour sur vos modifications.',
      AffectedReadersMessage_plural:
        '{{count}} lecteurs ont déjà lu ce chapitre. Cliquez ici pour publier une mise à jour sur vos modifications.',
      ImportingChapterXofY: "Chapitre en cours d'importation: {{_x}} / {{_y}}",
      ImportStarted: 'Importation commencée',
      XVersions: '{{count}} version',
      XVersions_plural: '{{count}} versions',
      UnsubscribeSuccessful:
        'Vous avez été désabonné. Vous pouvez toujours mettre à jour vos préférences via votre profil.',
      UnsubscribeUnsuccessful:
        'Échec de la désinscription. Envoyez un message à support@betareader.io si le problème persiste.',
      GoodbyeHeader: 'Au revoir!',
      BulkAddReaders: 'Ajouter des lecteurs à partir de CSV',
      Home: 'Maison',
      Actions: 'Actions',
      Invitations: 'Invitations',
      SelectCSVWithReaders: 'Sélectionnez CSV avec des lecteurs',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'Laissez notre IA, BetaBot, générer un synopsis basé sur votre texte. Il ne sera pas visible par vos lecteurs.',
      LastUpdated: 'Dernière mise à jour',
      CreateSynopsis: 'Créer un résumé',
      RefreshSynopsis: 'Actualiser le résumé',
      ElevatorPitch: 'Emplacement avec ascenseur',
      ThisIsAPremiumFeature: 'Fonctionnalité premium',
      GoBack: 'Retourner',
      Retry: 'Réessayer'
    }
  },
  de: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Bitte beachten Sie, dass beim Messen eines Lesers Ihr Benutzername und nicht Ihr Pseudonym sichtbar ist',
      Library: 'Bibliothek',
      Account: 'Konto',
      EmptyLibrary: 'Ihre Bibliothek ist leer.',
      EmptyLibraryAction:
        'Haben Sie mit etwas hier zu finden? Kontaktieren Sie den Autor, oder senden Sie uns eine E-Mail an {{_Bestellinformationen}}, und wir werden sehen, was wir tun können.',
      ByAuthor: 'von {{_authorName}}',
      ExceedChapterLenghtWarning: 'Kapitel ist zu lang, um Import',
      WordCount: '{{_wordCount}} Worte',
      WordCountString: '{{count}} Wort',
      WordCountString_plural: '{{count}} Wörter',
      CharCountString: '{{count}} Zeichen',
      CharCountString_plural: '{{count}} Zeichen',
      SurveyCount: '{{count}} Umfrage',
      SurveyCount_plural: '{{count}} Umfragen',
      ChapterCount: '{{count}} Kapitel',
      ChapterCount_plural: '{{count}} Kapitel',
      QuestionCountString: '{{count}} Frage',
      QuestionCountString_plural: '{{count}} Fragen',
      LoginToChangeEmail: 'Bitte melden Sie sich erneut Ihre E-Mail ändern',
      SendVerification: 'Senden Bestätigungslink',
      Name: 'Name',
      Email: 'Email',
      EnterYourName: 'Bitte geben Sie Ihren Namen ein',
      ReceiveCompanyUpdates:
        'Erhalten Sie E-Mails und Nachrichten aus BetaReader',
      ReceiveBookEmails: 'Recieve E-Mails über neue Bücher',
      ReceiveBookActivityUpdates:
        'Erhalten Sie E-Mails über die Tätigkeit auf meine Bücher',
      ReceiveMessageUpdates:
        'Erhalten Sie E-Mails, wenn ich neue Nachrichten erhalten',
      IncludeProfileInReaderList:
        'Fügen Sie mein Profil in der Leser-Datenbank',
      PrivacyPolicy: 'Datenschutz-Bestimmungen',
      TermsOfService: 'Nutzungsbedingungen',
      ReadOurBlog: 'Lesen Sie unseren Blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Nächster',
      Back: 'Zurück',
      CreateAccount: 'Konto erstellen',
      CreateAccountPrompt:
        '<0> Haben Sie kein Konto? <1> <0> Hier erstellen! </ 0> </ 1> </ 0>',
      ForgotPasswordPrompt:
        '<0> Passwort vergessen? <1> <0>, um es neu hier! </ 0> </ 1> </ 0>',
      SignIn: 'Einloggen',
      SignOut: 'Ausloggen',
      SendResetLink: 'Senden Link zum Zurücksetzen',
      ResetPassword: 'Passwort zurücksetzen',
      Password: 'Passwort',
      OrUse: 'oder Verwendung',
      NoConnection: 'Offline...',
      AcceptTermsMessage: 'Akzeptieren Sie die Nutzungsbedingungen',
      AcceptToSToCreateAccount:
        'Sie müssen unsere Nutzungsbedingungen akzeptieren, um ein Konto zu erstellen.',
      Answer: 'Antworten',
      FailedToLoadBook: 'Kann das Buch nicht laden.',
      PullDownToRefresh: 'Nach unten ziehen zu aktualisieren.',
      ContactSupport: 'Kontaktieren Sie Support',
      Support: 'Unterstützung',
      LastRead: 'Letzte Lese',
      XInvitations: '{{Count}} Einladung',
      XInvitations_plural: '{{Count}} Einladungen',
      EnterComment: 'Kommentar...',
      EnterReply: 'Antworten...',
      Reply: 'Antworten',
      Reply_plural: 'Antworten',
      Reply_verb: 'Antworten',
      show: 'Show',
      hide: 'ausblenden',
      Comments: 'Bemerkungen',
      InlineComment: 'Inline-Kommentar',
      Reject: 'Ablehnen',
      Accept: 'Akzeptieren',
      Error: 'Error',
      failed: 'gescheitert',
      SelectedText: 'Der ausgewählte Text',
      FailedToFetchUserProfile: 'Fehlgeschlagen Benutzerprofil zu holen.',
      ProblemPersistContactSupport:
        'Wenn dieses Problem weiterhin besteht, wenden Sie Unterstützung bei:',
      clickToAddComment: 'Hier klicken um Kommentar',
      ColorMode: 'Farbmodus',
      FontSize: 'Schriftgröße',
      Discover: 'Entdecken',
      MyAccount: 'Mein Konto',
      MyManuscripts: 'Meine Manuskripte',
      Sweden: 'Schweden',
      USA: 'USA',
      UK: 'Vereinigtes Königreich',
      Germany: 'Deutschland',
      France: 'Frankreich',
      Spain: 'Spanien',
      Country: 'Land',
      Gender: 'Geschlecht',
      YoB: 'Geburtsjahr',
      PreferredGenres: 'bevorzugte Genres',
      PreferredLanguages: 'bevorzugte Sprachen',
      EmailPreferences: 'E-Mail Einstellungen',
      chapters: 'Kapitel',
      words: 'Wörter',
      YouShouldBetaRead: 'Du solltest lesen',
      onBetaReader: 'auf BetaReader.io',
      Share: 'Aktie',
      NoGuidelines:
        'Der Autor hat keine Feedback-Richtlinien zur Verfügung gestellt.',
      Read: 'Lesen',
      on: 'auf',
      Author: 'Autor',
      JoinBeta: 'Ich möchte, dies lesen',
      joinConsentString:
        'Ich verstehe, dass durch Anforderung dieser Beta beitreten, werde ich meine E-Mail-Adresse mit dem Autor teilen.',
      Submit: 'einreichen',
      AnswerSurvey: 'Antworten!',
      SendJoinRequest: 'Senden',
      IJustWantToRead: 'Ich möchte nur lesen',
      CopyrightInfo: 'Copyright-Informationen',
      RequestToRead: 'Senden Leseanfrage',
      Feedback: 'Feedback',
      NoComment: 'Niemand hat kommentiert.',
      OnceUponATime: 'Es war einmal ...',
      Review: 'Rezension',
      Overall: 'Insgesamt',
      Plot: 'Handlung',
      Grammar: 'Grammatik',
      Title: 'Titel',
      AddReview: 'Bewertung hinzufügen',
      EnterReviewText: 'Geben Sie einen kurzen Überblick',
      OverallRating: 'Insgesamt',
      OverallRatingRequired: 'In Gesamtbewertung',
      PlotRating: 'Handlung',
      GrammarRating: 'Grammatik',
      SubmitReview: 'Bewertung abschicken',
      LeaveReview: 'Eine Rezension hinterlassen',
      checkOutAuthorApp:
        '<0> Sind Sie ein Schriftsteller? </ 0> <1> </ 1> <2> Sehen Sie sich unsere <1> App für Autoren </ 1> </ 2>',
      MyLibrary: 'Meine Bibliothek',
      ReadingPreferences: 'Lesepräferenzen',
      'E.g.': 'Z.B.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Ich möchte E-Mails von BetaReader.io erhalten, wenn der Autor Änderungen oder Aktualisierungen macht',
      'I want to receive emails about BetaReader news and updates.':
        'Ich möchte E-Mails über BetaReader News und Updates erhalten.',
      'I want to receive emails about new books that match my reading preferences.':
        'Ich möchte E-Mails über neue Bücher erhalten, die meine Lese Präferenzen entsprechen.',
      'this book': 'dieses Buch',
      'Email us on': 'Mailen Sie uns an',
      "and we'll see what we can do :)":
        'und wir werden sehen, was wir tun können :)',
      'Want an author account?': 'Möchten Sie ein Autor Konto?',
      Preferences: 'Voreinstellungen',
      Language: 'Sprache',
      Browse: 'Durchsuche',
      'to beginning': 'zum Anfang',
      'to chapter...': 'Kapitel ...',
      Location: 'Ort',
      Previous: 'Bisherige',
      Finish: 'Fertig',
      Description: 'Beschreibung',
      FeedbackGuidelines: 'Feedback-Richtlinien',
      'Start reading': 'Anfangen zu lesen',
      StartReading: 'Anfangen zu lesen',
      LeaveComment: 'Kommentar',
      replies: 'Antworten',
      Show: 'Show',
      Hide: 'Ausblenden',
      'Add Reply': 'In Antwort',
      PendingInvitation: 'Ausstehende Einladung',
      Decline: 'Ablehnen',
      DeclinedInvitationMessage:
        'Sie haben diese Einladung abgelehnt zu lesen, aber man kann es immer noch annehmen, wenn Sie Ihre Meinung geändert haben.',
      Welcome: 'Herzlich willkommen',
      by: 'durch',
      to: 'zu',
      AcceptInvitation: 'Die Einladung annehmen',
      InvitationAccepted: 'Einladung angenommen',
      WaitForBook: 'Bitte warten Sie, während wir Ihr Buch vorbereiten',
      PasswordLength: 'Ihr Passwort muss mindestens 6 Zeichen lang sein',
      AgreeToPolicyAndTerms:
        'Sie müssen BetaReader die Datenschutzrichtlinie und Nutzungsbedingungen zustimmen, um sich anzumelden.',
      'Sending...': 'Senden ...',
      VerificationSent: 'Überprüfung E-Mail gesendet.',
      VerificationEmailFailed: 'Fehlgeschlagen Überprüfung E-Mail zu senden.',
      IAcceptToC:
        '<0> Ich akzeptiere BetaReader.io der <1> Datenschutz </ 1> & <3> Nutzungsbedingungen </ 3> </ 0>',
      AccountSettings: 'Kontoeinstellungen',
      ContactSettings: 'Kontaktdaten',
      Chapters: 'Chapters',
      Options: 'Optionen',
      Option: 'Möglichkeit',
      BetaLanguageMessage:
        '<0> Wir arbeiten an verbesserter Sprachunterstützung und sind super dankbar für jede Hilfe, die wir bekommen können. Wenn Sie Fehler auf der Website finden, zögern Sie bitte nicht uns zu kontaktieren: <1> hello@betareader.io </ 1> </ 0>',
      Genres: 'Genre',
      PressEnterToSave: 'Drücken Sie die Eingabetaste speichern',
      female: 'weiblich',
      male: 'männlich',
      other: 'nichtbinäres',
      DontWantToShare: 'Privat',
      SignInWithPassword: 'Anmeldung mit Passwort',
      GoToBook: 'Zum buchen',
      Requested: 'Ihre Anfrage aussteht',
      AnsweredOrRequestedNotice:
        'Sie haben angefordert, diese Beta beizutreten. Der Autor wird so schnell wie möglich auf Sie zurückkommen.',
      RequestedWithDifferentEmail:
        'Sie haben angefordert, als Sie diese Beta mit einer anderen E-Mail zu verbinden, in dem mit eingeladen. Der Autor wird so schnell wie möglich auf Sie zurückkommen.',
      LoadingLibrary: 'Bücher finden ...',
      PreparingBook: 'Vorbereiten Buch',
      InLineCommentPlaceholder:
        'Hinterlassen Sie Ihre Kommentare und drücken Sie die Eingabetaste speichern',
      ExitSurvey: 'Exit-Umfrage',
      WhyExitQuestion: 'Warum wollen Sie diese Beta verlassen?',
      LostInterest: 'Ich verlor das Interesse',
      NotEnoughTime: 'Ich habe nicht genug Zeit,',
      Other: 'Anderer Grund',
      reason_lost_interest: 'Ich verlor das Interesse',
      reason_no_time: 'Ich habe nicht genug Zeit,',
      reason_other: 'Anderer Grund',
      reason_: 'Unbestimmt',
      ExitFeedbackQuestion: 'Wollen Sie ein Feedback an den Autor verlassen?',
      ExitFeedbackPlaceholder: 'Alle erhaltenen Bewertungen sind nützlich',
      ThankYouForYourFeebdack: 'Danke für deine Rückmeldung!',
      AppVersion: 'Ausführung',
      AppBuildNumber: '#',
      Like: 'Mögen',
      Love: 'Liebe',
      Laugh: 'Haha',
      Surprise: 'Überraschung',
      Sad: 'Traurig',
      Angry: 'Wütend',
      NoBooksInList: 'Keine Bücher hier.',
      Update: 'Aktualisieren',
      Overview: 'Überblick',
      Content: 'Inhalt',
      Readers: 'Leser',
      NotificationSettings: 'Benachrichtigungseinstellungen',
      SendEmailOnComments:
        'Senden Sie E-Mail, wenn Leser Kommentare hinterlassen',
      SendEmailOnSurveyComplete:
        'Senden Sie E-Mail, wenn Leser eine Umfrage teilnehmen',
      CoverUploadMsg:
        'Klicken Sie auf Ihr Cover-Bild (png oder jpg, 980x1568px) zu laden, oder legen Sie es in diesem Bereich.',
      DefaultNamePlaceholder: 'Standard: {{display}}',
      SelectUpTo5Genres: 'Wählen Sie bis zu 5 Genres',
      AdvancedSettings: 'Erweiterte Einstellungen',
      EnableComments: 'Aktivieren Kommentare',
      EnableBookReviews: 'Aktivieren Buchbesprechungen',
      DeleteBook: 'Löschen Buch',
      DeleteCover: 'Löschen Cover',
      DeleteCoverConfirmation:
        'Sind Sie sicher, dass Sie das Buch-Cover löschen?',
      YesBinIt: 'Ja, bin es!',
      AreYouSureYouWantToDeleteBookName:
        '<0> Sind Sie sicher, dass Sie löschen möchten <1> {{Buchtitel}} </ 1>? </ 0>',
      DeletedBookX: '{{X}} wurde gelöscht.',
      No: 'Nein',
      Yes: 'Ja',
      UserCommentedOn: 'kommentiert',
      ShowXReplies: 'Show {{count}} Antwort',
      ShowXReplies_plural: 'Zeigen Sie {{count}} Antworten',
      HideXReplies: 'Verbergen {{count}} Antwort',
      HideXReplies_plural: 'Verbergen {{count}} Antworten',
      NoInLineComments: 'Keine Inline-Kommentare.',
      Chapter: 'Kapitel',
      NoCommentsToShow: 'Noch keine Kommentare zu zeigen.',
      Save: 'Speichern',
      TooLongTitle: 'Ihr Titel ist zu lang (max 60 Zeichen)',
      ClickToUploadDoc:
        'Klicken Sie, um hinzuzufügen oder zu löschen Ihre docx-Datei zu importieren',
      LongChapterWarning: 'Lange Kapitel, ist das richtig?',
      Upload: 'Hochladen',
      UploadChapters: 'hochladen von Kapiteln',
      ImportBooksHelpMsg:
        'Nein wird man in der Lage sein, Ihr Manuskript ohne Ihre Zustimmung zu sehen. Lesen Sie <1> Importieren Bücher </ 1>.',
      Add: 'Hinzufügen',
      NewChapter: 'Neues Kapitel',
      NewSurvey: 'neue Umfrage',
      SelectedPart: 'ausgewählter Teil',
      SelectPart: 'Wählen Teil',
      NotifyMe: 'Benachrichtige mich',
      WhenReadersStart: 'am Start',
      WhenReadersComplete: 'am Ende',
      ManageChapter: 'verwalten Kapitel',
      ManageSurvey: 'verwalten Umfrage',
      ManageReaders: 'verwalten Leser',
      MoveUp: 'aufrücken',
      MoveDown: 'Sich abwärts bewegen',
      SaveSurvey: 'Speichern Umfrage',
      Saved: 'Gerettet',
      EnterSurveyName: 'Geben Sie Ihre Umfrage einen Namen',
      EnterSurveyDescription: 'Beschreiben Sie Ihre Umfrage',
      SelectAtLeast: 'Wählen Sie mindestens',
      SelectAtMost: 'Wählen Sie bei den meisten',
      LabelOptional: 'Label (optional)',
      MyBooks: 'Meine Bücher',
      ChangeRoleToWriterToCreateBooks:
        'Bitte ändern Rolle Schriftsteller Bücher zu erstellen.',
      UsingXOutOfYBooks: 'Sie sind mit {{x}} von {{y}} Bücher.',
      UsingXOutOfYReaders: 'Sie sind mit {{x}} von {{y}} Leser.',
      NothingHere: 'Nichts ist hier im Moment.',
      OpenReaderWeb: 'Öffnen Sie den Leser',
      PrivacyLevel: 'Freigabestatus',
      PublishingStatus: 'Freigabestatus',
      PrivacyLevel_infotext_unpublished:
        '<0> Ihr Buch ist offline. Bewegen Sie es auf <1> privat </ 1>, <3> geschlossen </ 3> oder <5> aufgelistet </ 5> für Leser in der Lage sein, es zu finden. </ 0>',
      PrivacyLevel_infotext_private:
        '<0> Ihr Buch ist nicht überall aufgeführt. Nur Sie können die Leser einladen. </ 0>',
      PrivacyLevel_infotext_closed:
        '<0> Präsentation Ihres Buches kann mit <1> von niemandem gesehen werden, der Link </ 1>. Die Leser können eine Einladung anfordern, aber Sie müssen jede Anfrage genehmigen. </ 0>',
      PrivacyLevel_infotext_listed:
        '<0> Ihr Buch ist <1> hier </ 1>. Die Leser können eine Einladung anfordern, aber Sie müssen jede Anfrage genehmigen. </ 0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Privat',
      PrivacyLevel_state_closed: 'Geschlossen',
      PrivacyLevel_state_listed: 'Eingestellt',
      SendReminder: 'Eine Erinnerung senden',
      YouCanSendAReminderEvery3Days:
        'Sie können eine Erinnerung an jedem dritten Tag senden',
      LastSeenAt: 'Zuletzt gesehen bei',
      ReminderSentAt: 'Erinnerung gesendet',
      XReadersInactiveForYDays:
        '{{Count}} Leser hat das Buch nicht fertig gestellt und war nicht in den letzten {{days}} Tage.',
      XReadersInactiveForYDays_plural:
        '{{Count}} Leser haben das Buch nicht fertig und nicht aktiv in den letzten {{days}} Tage.',
      InactiveReaders: 'Inaktive Leser',
      XReadersNotStartedForYDays:
        '{{Count}} Leser hat Ihr mehr nicht akzeptiert als {{days}} Tage alt Einladung',
      XReadersNotStartedForYDays_plural:
        '{{Count}} Leser haben Ihre mehr als {{days}} Tage alt Einladung nicht angenommen',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Leser, die die Einladung nicht angenommen haben,',
      YouHaveXJoinRequests: 'Sie haben {{count}} Anfrage Ihr Buch zu lesen',
      YouHaveXJoinRequests_plural:
        'Sie haben {{count}} Anfragen Ihr Buch lesen',
      JoinRequests: 'Anfragen zu lesen',
      Approve: 'Genehmigen',
      RequestedAt: 'Angeforderte',
      NoAnswer: 'Keine Antwort',
      SendSurveyReminder: 'Senden Umfrage-Erinnerung',
      Remove: 'Entfernen',
      Created: 'Erstellt',
      RequestedToRead: 'Angeforderte zu lesen',
      InvitationSent: 'Einladung versendet',
      Reminded: 'erinnerte',
      Question: 'Frage',
      YouCanAskUpToXQuestions:
        'Sie können bis zu {{}} maxQuestions Fragen hinzufügen, dass die Leser Antwort erforderlich sind, bei der Anmeldung.',
      NewQuestion: 'neue Frage',
      ReaderSignUpForm: 'Anmeldeformular',
      NoReadersFound: 'Keine Leser gefunden',
      ReachedPartTitle: 'Erreicht {{partTitle}}',
      ReasonToQuit: 'Grund',
      YourBookDoesNotHaveAnyChaptersYet: 'Ihr Buch hat keine Kapitel noch',
      Invited: 'Eingeladen',
      Started: 'Gestartet',
      Finished: 'Fertig',
      Abandoned: 'Verlassen',
      AvgOverallRating: 'Insgesamt',
      AvgPlotRating: 'Handlung',
      AvgGrammarRating: 'Grammatik',
      YouDoNotHaveReviewsYet: 'Sie haben noch keine Rezensionen.',
      PersonalLink: 'persönlicher Link',
      HelpUsReachMoreWriters:
        'Mögen Sie BetaReader.io mit? Verwenden Sie Ihre persönliche Verbindung, die uns helfen mehr Autoren helfen!',
      Reports: 'Berichte',
      WeNeedYourEmail: 'E-Mail erforderlich',
      MissingEmailMsg:
        'Wir müssen in der Lage, Sie zu Ihrem Konto zu kontaktieren. Wir werden es mit niemandem teilen, und Sie nur kontaktieren, wenn unbedingt erforderlich.',
      EmailNotVerifiedPrompt:
        'Eine E-Mail nicht überprüft. <1> Klicken Sie hier noch einmal zu senden </ 1> die Überprüfung E-Mail. (Überprüfen Sie Ihren Spam-Ordner, wenn Sie es nicht erhalten)',
      Skip: 'Überspringen',
      RoleWriter: 'Schriftsteller',
      RoleReader: 'Leser',
      RoleBoth: 'Beide',
      AddReader: 'Hinzufügen neuer Leser',
      DownloadReaderList: 'Download Leser Liste',
      'Show only': 'Zeige nur',
      StatusNEW: 'Neu',
      StatusTODO: 'Machen',
      StatusDOING: 'Tun',
      StatusDONE: 'Getan',
      StatusIGNORE: 'Ignorieren',
      RemoveFilters: 'Filter entfernen',
      ReadingDataReport: 'Das lesen von Daten',
      SurveyAnswersReport: 'Umfrage Antworten',
      Reviews: 'Bewertungen',
      AbandonedReadersReport: 'Verlorene Leser',
      SignUpMsg:
        '<0> Vielen Dank für Ihre Anmeldung! </ 0> <1> A Überprüfung E-Mail an <1> wurde gesendet {{email}} </ 1> </ 1>',
      CopiedXToClipboard: 'Kopierte {{x}} in die Zwischenablage.',
      DoYouWantToBeMyBetaReader: 'Wollen Sie meinen Beta-Leser sein?',
      LookingForBetaReadersForBookTitle:
        'Ich suche Beta Leser für {{Buchtitel}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Ich suche Beta Leser für {{Buchtitel}}. Folgen Sie diesem Link um mehr zu lesen und anmelden: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Social Sharing ist für private Bücher nicht möglich.',
      ShareTheLinkToYourBook: 'Teilen Sie den Link zu Ihrem Buch!',
      Messages: 'Mitteilungen',
      FailedToLoadMessage: 'Wir versäumen, diese Nachricht zu laden.',
      FailedToSendMessage: 'Es gelang uns nicht Ihre Nachricht zu senden.',
      FailedToStartConversation:
        'Es gelang uns nicht, das Gespräch zu beginnen.',
      NewMessage: 'Neue Nachricht',
      MessageSubject: 'Gegenstand',
      MessageBody: 'Schreibe deine Nachricht...',
      MessageFrom: 'Von',
      MessageTo: 'Zu',
      SendMessage: 'Senden',
      Message: 'Botschaft',
      ReaderMarketplaceBetaInfoText:
        'Wir experimentieren unsere Autoren zahlen gewöhnlichen Leser und Leseratten eine symbolische Summe für den Dienst zum Lesen eines Manuskripts mit im Stich gelassen. Wir bieten Kontrollmechanismen das Feedback und die Kontrolle zu bestätigen, dass die Leser die vollständige Manuskript gelesen haben, bevor jemand bezahlt wird. Wenn Sie diese Funktion ausprobieren möchten, geben Sie bitte an, wie viele Leser die Sie benötigen.',
      ReaderMarketplaceBetaFullText:
        'Dieses Programm ist zur Zeit voll. Möchten Sie, dass Sie sich von uns benachrichtigen, wenn wir eine Öffnung bekommen?',
      Sum: 'Summe',
      XUSDPerReader: '$ {{X}} / Leser',
      AmountOfReaders: 'Anzahl der Leser',
      PayForTestReaders: 'Pay für Test Leser',
      GoToCheckout: 'Zum Checkout gehen',
      YesPlease: 'Ja bitte!',
      BETA: 'BETA',
      NoMessages: 'Du hast keine Nachrichten',
      OnBoarding_import_book:
        '<0> Willkommen bei BetaReader.io! </ 0> Um Ihr Buch zu teilen, müssen Sie zuerst importieren, entweder von <2> uploading it </ 2>, oder indem sie direkt auf der Plattform zu schaffen. Gehen Sie voran und versuchen Sie es aus! Alles auf BetaReader.io ist standardmäßig privat, so dass niemand wird es sehen, wenn Sie sie einladen.',
      Onboarding_book_overview:
        'Das Buch Überblick, wo Sie Informationen über Ihr Buch konfigurieren. Titel, Genre und Feedback-Richtlinien gehen hier, sondern auch, ob Sie wollen Leser Kommentar und Urlaub Bewertungen können.',
      Onboarding_book_content:
        'Die Registerkarte Inhalt ist, wo Sie die Ordnung der Dinge zu steuern. Fügen Sie Ihre Kapitel und Umfragen hier.',
      Onboarding_book_readers:
        'Die Leser Registerkarte können Sie kontrollieren, wer Ihr Buch zu lesen bekommt. Senden private Einladungen, teilen Sie Ihre Anmelde-Link via Twitter, oder fügen Sie Ihr Buch zu unserem Geschäftsbereich Discovery Liste, um mehr Leser zu erreichen.',
      Onboarding_book_feedback:
        'Die Registerkarte Feedback gibt Ihnen einen Überblick über all das Feedback, dass die Leser hinterlassen haben. Antworten auf Kommentare und Filter entfernt unwichtige Sachen.',
      Onboarding_book_reports:
        'Die Registerkarte Bericht gibt Ihnen einen Überblick darüber, wie Ihr Buch tut. Wie viele Leser eingeladen wurde, wie viel Lese hat begonnen, links, begeisterte Kritiken, und so weiter.',
      Close: 'Schließen',
      Last: 'Zuletzt',
      DuplicatedBook: '<0> <0> </ 0> Kopierte Buch {{Buchtitel}} </ 0>',
      PenName: 'Stift name',
      MissingNameMsg:
        'Sie müssen einen Namen eingeben zu können, mit Ihren Lesern kommunizieren.',
      NameConversation: 'Nennen Sie dieses Gespräch',
      With: 'mit...',
      SelectPeople: 'Wählen Menschen',
      UploadAsNewVersion: '... als neue Version',
      UploadAsExtraChapters: '... auf diese Version',
      Cancel: 'Stornieren',
      Unnamed: 'ungenannt',
      NewVersion: 'Neue Version',
      NoContacts: 'keine Kontakte',
      GetContacts:
        'Die Autoren der Bücher, die Sie lesen und Leser der Bücher, die Sie schreiben, werden Ihre Kontakte.',
      NoConversations: 'Keine Gespräche...',
      AllVersionsNeedContent:
        'Ihre aktuelle Version benötigt Inhalt, bevor Sie einen neuen erstellen können.',
      InviteToVersion: 'Einladen Version',
      latestVersion: 'neueste',
      VersionName: 'Version {{x}}',
      Reader: 'Leser',
      StartedReading: 'gestartet Lesung',
      CurrentPosition: 'Aktuelle Position',
      PickVersion: 'Pick-Version',
      Filters: 'Filter',
      Versions: 'Versionen',
      ChapterInitial: 'CH',
      AvgTimeToComplete: 'Durchschnittliche Zeit zum Lesen Kapitel',
      ReaderProgress: 'Positionen',
      SelectFilter: 'Wählen Sie einen Filter',
      CreatePost: 'Ein Update',
      NewBookPostPlaceholder: 'Was willst du sagen?',
      BookUpdateForX: 'Update für Buch {{x}}',
      OnSaveChapterMessage: 'Lassen Sie Ihre Leser wissen, was Sie geändert',
      SampleChapterUpdatePost: 'Ich änderte dies ...',
      SaveWithoutPosting: 'Sparen ohne Eintrag ...',
      SaveAndPost: 'Speichern und senden ...',
      DiscoverSearch: 'Suche nach einem Titel oder Genre ...',
      LoadMore: 'Mehr laden...',
      PendingReadInvitations: 'Ausstehende Einladungen',
      PendingReadRequests: 'Offene Anfragen',
      UserReactedOn: 'reagiert auf',
      ChapterComment: 'Kapitel Kommentar',
      InlineCommentNotFound:
        'Dieser Kommentar wurde im Text nicht gefunden. Hat der kommentierten Text entfernt worden?',
      Or: 'Oder',
      Version: 'Ausführung',
      Updates: 'Aktualisierung',
      CurrentBetaIsFullMessage:
        'Diese Beta ist derzeit voll, wenden Sie sich bitte an den Autor mehr Slots zu öffnen.',
      Edit: 'Bearbeiten',
      Delete: 'Löschen',
      DropFilesHere: 'Lassen Sie Ihre Dateien hier',
      ShowOnly: 'Zeige nur',
      FeedbackSearch: 'Textsuche',
      'BuySubscriptionToS&PPMessage':
        '<0> Durch ein Abonnement von BetaReader.io Kauf zeigen Sie, dass Sie gelesen haben und unsere akzeptiert <1> Nutzungsbedingungen </ 1> & <5> Datenschutzbestimmungen </ 5> </ 0>',
      Inbox: 'Posteingang',
      CofirmMovePart:
        'Wollen Sie wirklich diesen Teil bewegen? Es werden die folgenden Leser beeinflussen:',
      'AreYouSure?': 'Bist du sicher?',
      LetYourReadersKnowWhatYouChanged:
        'Lassen Sie Ihre Leser wissen, was Sie geändert',
      ThisChangeAffectsTheFollowingReaders:
        'Diese Änderung betrifft die folgenden Leser:',
      MovedXFromYtoZ: 'Verschoben {{x}} von {{y}} bis {{z}}',
      Subscription: 'Abonnement',
      Subscriptions: 'Abonnements',
      FreeSubscriptionTitle: 'Frei',
      FreeSubscriptionDescription: 'Grundplan, für immer frei.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Geeignet für Hobby Perfektionisten',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Groß für professionelle Indie Autoren',
      ProSubscriptionTitle: 'Profi',
      ProSubscriptionDescription: 'Perfekt für Verlage',
      CancelSubscription: 'Abonnement kündigen',
      YourSubscriptionHasBeenCancelled: 'Ihr Abonnement wurde storniert',
      CancelSubscriptionConfirmation:
        'Sind Sie sicher, dass Sie Ihr Abonnement kündigen?',
      ValidUntil: 'Gültig bis',
      UpgradeSubscriptionTitle: 'Aktualisierung',
      UpgradeSubscriptionPromoText: 'Aktualisieren Sie Ihr Konto',
      Abort: 'Abbrechen',
      BuyNow: 'Kaufe jetzt',
      Current: 'Derzeitiger Plan',
      Monthly: 'Monatlich',
      Yearly: 'Jährlich',
      monthly: 'monatlich',
      yearly: 'Jahr',
      month: 'Monat',
      XCheaper: '{{X}} billiger!',
      Downgrade: 'degradieren',
      Forever: 'für immer',
      ChangePlan: 'Plan ändern',
      TrackReaderProgress: 'Verfolgen Sie Ihre Leser in Echtzeit',
      InlineComments: 'In-line Kommentare und Reaktionen',
      ReaderSurveys: 'Leserbefragungen',
      NativeApps: 'Android und iOS-App',
      MultipleVersions: 'Manuskript Versionierung',
      '1ActiveManuscript': '1 Manuskript',
      '5ActiveManuscripts': 'Bis zu 5 Handschriften',
      '20ActiveManuscripts': 'Bis zu 20 Handschriften',
      UnlimitedActiveManuscripts: 'Unbegrenzte Manuskripte',
      '3ActiveReadersPerManuscript': 'Bis zu 3 Leser',
      '20ActiveReadersPerManuscript': 'Bis zu 20 Leser',
      '50ActiveReadersPerManuscript': 'Bis zu 50 Leser',
      UnlimitedActiveReaders: 'Unbegrenzte Anzahl von Lesern',
      Zero: 'Null',
      NoCreditCardNeeded: 'Keine Kreditkarte erforderlich',
      Recommended: 'Empfohlen',
      SomethingWentWrong: 'Etwas ist schief gelaufen',
      SubscriptionChangeError: 'Fehler beim Wechsel Abonnement',
      SubscriptionChangeSuccess: 'Wir aktualisieren Ihr Abonnement',
      ActionCancelled: 'Die Aktion wurde abgebrochen',
      Cancelled: 'Abgesagt',
      NextPayment: 'Nächste Zahlung',
      PaymentPastDueMessage:
        'Ihre Zahlung überfällig ist. Nächster Versuch: {{nextAttempt}}',
      ChangePaymentInformation: 'Ändern Rechnungsdetails',
      DashboardWelcomeMessage:
        '<0> Willkommen bei BetaReader.io! </ 0> <1> Start von <2> Hochladen </ 2> oder <6> Hinzufügen </ 6> Ihr Manuskript </ 1> <2> Entdeckung </ 2> < 3> vergessen Sie nicht, Ihr Manuskript zur Liste in unserem <2> Entdeckung </ 2> -Abschnitt, um Zugang zu mehr beta-Lesern. </ 3> <4> <0> hier </ 0> ist ein kurzer Durchmarsch, wie man tun. </ 4> <5> Support </ 5> <6> Bitte beachten Sie auch unsere <2> Hilfe </ 2> Portal oder Ping uns direkt durch Drücken der (?) oben rechts auf der Seite. < / 6> <7> Sie können uns immer eine freundliche Mail an <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Handschriften',
      CurrentlyReading: 'derzeit lesen',
      WeeklyReaders: 'wöchentliche Leser',
      TotalReaders: 'insgesamt Leser',
      FailedToDownloadReport: 'Fehler beim Download Bericht',
      WaitingForConfirmation: 'Auf Bestätigung warten',
      ChangePublishingStatusToAddReaders:
        'Ändern Sie veröffentlichen Status Leser hinzufügen',
      SuggestASwap: 'Vorschlagen Swap',
      WithdrawRequest: 'zurückziehen Anfrage',
      Confirm: 'Bestätigen',
      ThisIsASwapRequestForX:
        'Dies ist eine Swap-Anforderung <2> {{x}} </2>. Wenn Sie den Antrag zustimmen, werden Sie als Leser hinzugefügt für {{x}}.',
      ApprovedSwapRequestForX: 'Dies ist eine Swap-Anforderung <1> {{x}} </1>.',
      ThisIsASwapRequestFor:
        'Dies ist eine Swap-Anfrage. Wenn Sie den Antrag zustimmen, werden Sie als Leser hinzugefügt für:',
      SwapRequest: 'Swap-Anfrage',
      ApproveAndJoin: 'Genehmigen und kommen',
      AuthorXHasProposedToSwapThisForY:
        '{{X}} angeboten hat, lesen {{y}} im Austausch für Sie dieses Buch zu lesen.',
      GoToSwapRequest: 'Zum Swap-Anfrage',
      SwitchVersion: 'Schalterversion',
      SendMagicLinkToX: 'Senden Sie eine magische Verbindung zu {{x}}',
      notification_verb_comment: 'kommentiert',
      notification_verb_react: 'reagiert auf',
      notification_verb_reply: 'auf einen Kommentar antwortet auf',
      notification_verb_abandon: 'verlassen Ihr Buch',
      notification_verb_request_to_join: 'fordert zu lesen {{y}}',
      notification_verb_message: 'Schickte dir eine Nachricht',
      notification_verb_sent_join_approval: 'Ihren Antrag genehmigt zu lesen',
      notification_verb_invitation_to_join: 'Sie eingeladen, lesen {{y}}',
      notification_verb_manuscript_submitted:
        'hat ein Manuskript bei {{y}} eingereicht',
      'SaveAnd...': 'Speichern und...',
      Send: 'Senden',
      OnlySave: 'nur speichern',
      YourNotifications: 'Ihre Benachrichtigungen',
      Notifications: 'Benachrichtigungen',
      Images: 'Bilder',
      '365DaysReadingData': '365 Tage Leser Geschichte',
      '90DaysReadingData': '90 Tage Leser Geschichte',
      '30DaysReadingData': '30 Tage Leser Geschichte',
      alerts_PaymentPastDue:
        'Ihre Zahlung überfällig ist. Aktualisieren Sie Ihre Rechnungsdaten',
      alerts_MissingName:
        'Wir verpassen Sie Ihren Namen (oder Pseudonym). Aktualisieren Sie es hier:',
      alerts_TimeLeftOnLaunchOffer:
        '{{X}} links Ihr Leben Rabattierung beanspruchen. Geben Sie LAUNCH im Gutscheinfeld, wenn Sie ein Upgrade:',
      RequestedToSwapForX: 'Sie haben angefordert, um diese zu tauschen {{x}}',
      PaymentSuccessMessage:
        '<0> <0> Vielen Dank für Ihren Einkauf! </ 0> <1> <0> </ 0> </ 1> </ 0>',
      Dashboard: 'Instrumententafel',
      year: 'Jahr',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Wie wahrscheinlich sind Sie BetaReader.io einem Freund zu empfehlen?',
      NotAtAllLikely: 'Unwahrscheinlich',
      ExtremelyLikely: 'Sehr wahrscheinlich',
      ThanksDoYouWantToTellUsWhy: 'Vielen Dank! Wollen Sie uns, warum sagen?',
      Create: 'Erstellen',
      Books: 'Bücher',
      Feed: 'Futter',
      ANewStory: 'eine neue Geschichte',
      FailedToGetInvitations: 'Fehlgeschlagen Einladungen zu erhalten',
      unauthorizedPrivateBook:
        'Dieses Buch ist privat und nicht auf dieses Konto zur Verfügung.',
      invalidToken:
        'Einladung ist nicht gültig für dieses Konto oder ist abgelaufen.',
      RaiseYourReaderLimit: 'Heben Sie Ihre Leser Grenze (zur Zeit {{x}})',
      InviteYourFriends: 'Lade deine Freunde ein',
      referralText:
        '<0> Holen Sie sich bis zu 10 zusätzliche Leser-Slots, indem Sie Ihre Freunde zu BetaReader.io einladen! </0> <1> Für jeden Freund, der dank Ihnen ein validiertes Autorenkonto erstellt und ein Manuskript auflistet, geben wir Ihnen und Ihrem Freund Jeweils 1 Lesersteckplatz, maximal 10 Steckplätze. </1> <2> Sie können Ihr Leserlimit auch erhöhen, indem Sie 100.000 Wörter über unsere <1> <0> Leser-App </0> </ 1 lesen und überprüfen > oder durch <3> <0> Aktualisieren </0> </3> Ihres Kontos! </2>',
      INVITED: 'Eingeladen',
      CREATED_ACCOUNT: 'Erstellt Konto',
      CONFIRMED: 'Bestätigt',
      RegistrationDate: 'Registrierungsdatum',
      Profile: 'Profil',
      EditProfile: 'Profil bearbeiten',
      GetMoreReaders: 'Mehr Leser',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Sie haben Ihre Lesergrenze erreicht. <1>Upgrade von</1>, um weitere Einladungen in unseren Reader-Index zu senden oder Leseanforderungen zu genehmigen. In unserem freien Plan können Sie <3>Swap</3> mit anderen Autoren vorschlagen oder so viele Leser einladen, die Sie direkt über ihre E-Mail-Adresse wünschen.</0>',
      SwapsDontAffectReaderLimit:
        'Das Austauschen von Manuskripten zählt nicht für Ihr Leserlimit.',
      maxBooksLimitMessage:
        '<0> Sie haben Ihr Manuskript Grenze erreicht. <1> Erhöhen Sie die Grenze </ 1> in der Lage mehrere Manuskripte parallel zu testen. </ 0>',
      Type: 'Art',
      'referral-type_REFERRAL': 'VERWEISUNG',
      'referral-type_SIGNUP_BONUS': 'ANMELDEBONUS',
      'referral-type_READ_AND_REVIEW': 'LESEN & REVIEW',
      unlockBonusText:
        '<0> Entsperren Ihre Anmeldebonus </ 0> <1> Es sieht aus wie Sie von einem Freund bezeichnet wurden -! Die folgenden Schritten folgen, um die gegenseitige Anmeldebonus entsperren </ 1> <2> <0> <0 > </ 0> <1> <0> Validate Konto </ 0> <1> Sie benötigen eine gültige Namen und E-Mail-Adresse </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Liste Manuskript </ 0> <1> Liste des Manuskript Sie testen mögen </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> warten Sie </ 0> <1> Es dauert etwa 3 Tage mit uns zu bestätigen </ 1> </ 1> </ 2> </ 2>',
      titleDiscoveryRecommended: 'für dich empfohlen',
      subheaderDiscoveryRecommended:
        'Etwas Neues auf der Grundlage Ihrer ausgewählten Genres',
      titleDiscoveryRecentlyAdded: 'Kürzlich hinzugefügt',
      subheaderDiscoveryRecentlyAdded: 'Kürzlich hinzugefügt Titel',
      discoveryRecentlyAddedTitlesInGenre:
        'Kürzlich Titel in Genre hinzugefügt {{x}}',
      titleDiscoveryPremium: 'Ausgewählte Titel',
      subheaderDiscoveryPremium: 'Titel von Premium-Autoren',
      referralMessage:
        'Ich lade Sie zu BetaReader.io! Wenn Sie auf diesen Link registrieren verwenden, haben wir beide einen Bonus erhalten!',
      referralBody: 'Melden Sie sich hier: {{x}}',
      ThisCannotBeUndone: 'Das kann nicht rückgängig gemacht werden',
      DeletedVersion: 'Die Version wurde gelöscht',
      InvalidEmail: 'Ungültige E-Mail',
      EmailNotVerified: 'Nicht überprüft, überprüfen Sie bitte Ihre E-Mail',
      SelectFavoriteGenres: 'Wählen Sie Ihre Lieblings-Genres',
      Loading: 'Wird geladen',
      ChapterIdentification: 'Kapitel Identifizierung',
      ChapterAnalysis: 'Kapitel Analyse',
      CompleteImport: 'Füllen Sie das Import',
      Import: 'Importieren',
      Processing: 'Verarbeitung von Datei ...',
      ThisMightTakeAWhile: 'Dies könnte eine Weile dauern,',
      LikeThisCover: 'Wie dieses Buch-Cover?',
      DownloadFailedMsg:
        'Der Download ist fehlgeschlagen. Wurde es von einem Pop-up-Blocker blockiert?',
      ShareAuthorAppEmailSuccessMessage:
        'Einladung versendet. Überprüfen Sie den Status <1> hier </ 1>.',
      failedToUpdateReader: 'Fehler beim Update-Reader',
      activeReaders: 'aktive Leser',
      ReadingList: 'Lese liste',
      Users: 'Benutzer',
      InviteUser: 'laden Sie Benutzer',
      Role: 'Rolle',
      member_role_admin: 'Administrator',
      member_role_member: 'Mitglied',
      couponsOrDiscountsWillNotBeKept:
        '<0> Vorhandene Rabatte werden nicht auf den neuen Plan folgen. </ 0>',
      FailedToFetchX: 'Fehler beim fetch {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Sie haben {{x}} s BetaReader.io Konto eingeladen.',
      AcceptAccountInvitationMsg:
        'Wenn Sie die Einladung annehmen, werden Sie in der Lage sein, alle Manuskripte auf diesem Konto, um zu sehen und zu bearbeiten.',
      InvitedMembersMsg:
        'Kontomitglieder können alle Manuskripte in Ihrem Konto anzeigen und bearbeiten, sehen jedoch keine Rechnungsdetails. Informationen zu den Kosten pro Kontomitglied finden Sie unter betareader.io/pricing.',
      InviteCollaborator: 'einladen Mitarbeiter',
      InviteCollaboratorMsg:
        'Mitarbeiter können die Handschrift und Leser Details ansehen und auf das Feedback reagieren.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Sie haben die Zusammenarbeit eingeladen auf {{book}} von {{author}}',
      AcceptCollaborationInvitationMsg:
        'Wenn Sie die Einladung annehmen, werden Sie in der Lage sein, das Manuskript und Leser Details anzuzeigen, und reagiert auf Feedback.',
      Collaborators: 'Mitarbeiter',
      ThisIsABetaFeature:
        'Dies ist eine Beta-Funktion. Lassen Sie uns wissen, ob, wenn Sie irgendwelche Probleme mit ihm auftreten, oder wenn Sie ein Feedback zu teilen.',
      UserNotFoundMsg:
        'Es sieht aus wie Sie noch kein Konto haben. Erstellen Sie ein <1> hier </ 1>!',
      AuthorName: 'Autorenname',
      Details: 'Einzelheiten',
      Settings: 'die Einstellungen',
      ReviewAtLeastXwords: 'Bewertung mindestens {{x}} Worte',
      CheckBonus: 'Prüfen Sie Bonus',
      FailedToAddCommentOrReaction: 'Fehler beim Hinzufügen Kommentar Reaktion',
      ThisIsAWIP: 'Dies ist ein WIP',
      WIPExplanation:
        'Flag Ihre Geschichte als WIP (Work In Progress), wenn er nicht beendet ist, z.B. Wenn Sie alle Kapitel hinzugefügt.',
      MatureContent: 'Inhalte für Erwachsene',
      PublicationDate: 'Veröffentlichungsdatum',
      TimePlan: 'Zeitplan',
      NotSpecified: 'Unbestimmt',
      WhatIsThisStoryAbout: 'Worum geht es in der Geschichte?',
      WhatDoYouNeedHelpWith: 'Was brauchen Sie Hilfe?',
      StartWriting: 'Start Schreiben',
      CreateManuscript: 'erstellen Manuskript',
      ImportManuscript: 'Import Manuskript',
      ImportManuscriptStepDescription:
        'Laden oder erstellen Sie Ihr Manuskript',
      InviteReaders: 'Laden Sie Ihre Leser',
      InviteReadersStepDescription: 'Sie entscheiden, wer lesen kann',
      CollectFeedback: 'Collect Feedback',
      CollectFeedbackStepDescription: 'Analysieren Kommentare & Lesemuster',
      YouDontHaveAnyManuscripts: 'Sie haben noch keine Manuskripte',
      AuthorsAndPublishers: 'Autoren und Verlage',
      FindOutWhatYourReadersThink:
        'Finden Sie heraus, was Ihre Leser denken Sie an Ihr Buch wirklich',
      ReadAndInfluenceTheNextBestseller: 'Lesen und Einfluss kommt Bestseller',
      PrivateSharing: 'Privates Sharing',
      PrivateSharingDescription:
        'Teilen Sie Ihr Manuskript privat mit den Lesern',
      CollectUnbiasedFeedback: 'Collect unvoreingenommenes Feedback',
      CollectUnbiasedFeedbackDescription:
        'Lassen Sie Ihre Leser kommentieren ohne jeweils anderen Feedback zu sehen',
      AnalyzeReadingPatterns: 'Analysieren Lesemuster',
      AnalyzeReadingPatternsDescription:
        'Finden Sie heraus, wo Ihre Leser das Interesse verlieren oder von der Geschichte süchtig',
      ReadItFirst: 'Lesen Sie es zuerst',
      ReadItFirstDescription:
        'Lesen Sie kommen Romane, während sie noch in der Betaphase sind',
      InfluenceTheStory: 'Einfluss auf die Geschichte',
      InfluenceTheStoryDescription:
        'Ihr Feedback wird das Endergebnis beeinflussen',
      ConnectWithAuthors: 'Verbinden Sie sich mit den Autoren',
      ConnectWithAuthorsDescription:
        'Helfen Sie Ihren Lieblings-Autoren einen Teil ihrer Beta-Team immer',
      YourBooks: 'Deine Bücher',
      New: 'Neu',
      Members: 'Mitglieder',
      Contacts: 'Impressum',
      AddMembersFromContacts: 'Mitglieder hinzufügen aus der Kontaktliste',
      ConversationSettings: 'Conversation-Einstellungen',
      PremiumAuthorInfo:
        'Dies ist ein Manuskript von einem Premium-Autor. Dies bedeutet normalerweise, dass der Autor mehr in dem Projekt investiert wird und dass Sie erwarten können, sie zu tun, was es braucht, dieses Buch zu veröffentlichen.',
      StillWorkingOnThis:
        'Wir bauen nach wie vor aus BetaReader.io. Lassen Sie uns wissen, ob es etwas Bestimmtes ist man hier sehen wollen.',
      Engagement: 'Engagement',
      AssemblingLetters: 'Montage Buchstaben ...',
      ConstructingAlphabet: 'Constructing Alphabet ...',
      BuildingSentences: 'Der Aufbau Sätze ...',
      BecomingSentient: 'Werden empfindungsfähigen ...',
      BooksRead: 'Bücher lesen: {{x}}',
      WordsReviewed: 'Worte im Test: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Durchschnittliche Bearbeitungszeit pro 1000 Wörter: {{count}} Tage',
      AvgTurnAroundTimePer1kWords_plural:
        'Durchschnittliche Bearbeitungszeit pro 1000 Wörter: {{count}} Tage',
      ViewAsReader: 'Darstellung als Leser',
      EditMode: 'Bearbeitungsmodus',
      BookCover: 'Einband',
      WelcomeToBRReadathon: 'Willkommen in der BetaReader.io Readathon!',
      ReadathonHasEnded: 'Die Readathon wird beendet.',
      ReadathonInfo:
        'Die BetaReader.io Readathon dauert zwischen dem 26. September und dem 31. Oktober 2019. Wer und Bewertungen mindestens ein Buch über BetaReader.io während dieser Zeit partizipiert liest, und Sie können Ihre Gewinnchance durch Lesen erhöhen und mehr Bücher zu überprüfen. Der Gewinner wird am 1. November gezogen werden, und desto mehr Wörter Sie haben gelesen und dann überprüft, desto höher sind Ihre Chancen zu gewinnen. Zum Beispiel, jemand, der gelesen hat und 50.000 Wörter überprüft haben 5x hohe Gewinnchancen als jemand, und 10.000 Wörter überprüft gelesen hat.',
      ReadathonPrize1: '1. Preis: $ 50 Amazon Geschenkkarte.',
      ReadathonPrize23: '2. - 3. Preis: $ 20 Amazon Geschenkkarte.',
      SignInToJoin: 'Anmeldung beitreten',
      WordsReviewedByYou: 'Wörter von Ihnen überprüft',
      WordsReviewedByEveryone: 'Worte von allen überprüft',
      ChanceOfWinning: 'Aktuelle Gewinnchance',
      Completed: 'Abgeschlossen',
      CompletedAt: 'abgeschlossen bei',
      Pending: 'steht aus',
      Position: 'Position',
      FindSomethingToRead: 'Finden Sie etwas zu lesen',
      DontKnow: 'Sie wissen nicht,',
      IsThisReadyToPublish:
        'Glauben Sie, dass dieses Buch fertig ist veröffentlicht werden?',
      UpdateProfileHeader: 'Wir brauchen mehr Informationen für Ihr Profil',
      WhatsYourName: 'Was ist Ihr Name oder Pseudonym?',
      WhereAreYouFrom: 'Woher kommst du?',
      WhenWereYouBorn: 'In welchem ​​Jahr Sie geboren?',
      WhichGenresDoYouPrefer: 'Welche Genres bevorzugen Sie?',
      WhichLanguagesDoYouRead: 'Welche Sprachen lesen Sie?',
      BioPlaceholder:
        'Sagen Sie uns, wer Sie sind, und wenn Sie irgendwelche Websites haben zu beziehen. Ein stärker Bio werden Ihnen helfen, mehr Manuskripte genehmigt zu bekommen.',
      Thanks: 'Vielen Dank!',
      WhenDoYouWantToHearFromUs: 'Wenn Sie möchten, dass Sie von uns hören?',
      ReaderListConsentHeader:
        'Wollen Sie in unserer Leser-Datenbank aufgenommen werden?',
      ReaderListConsentInfo:
        'Autoren mit Werken Ihren Wünschen entsprechen, werden in der Lage Sie auf den Link, um ihre Bücher zu schicken, aber Sie sind nicht zu lesen, wenn Sie oder haben nicht die Zeit, wollen nicht verpflichtet.',
      RoleHeader: 'Sind Sie ein Schriftsteller oder ein Leser?',
      RoleInfo:
        'Wir verwenden diese Informationen Ihre Erfahrung zu optimieren.',
      OnYourProfile: 'Dies wird auf Ihrem Benutzerprofil angezeigt werden.',
      ProfileBuilderThankYouMessage:
        '<0> Danke! </ 0> <1> Sie können jederzeit Ihr Profil aktualisieren <1> hier </ 1>. </ 1>',
      DoThisLater: 'Tun Sie dies später',
      '2MonthsFree': '2 Monate kostenlos',
      Joined: 'Trat bei',
      UserInfo: 'Benutzerinformation',
      Age: 'Alter',
      Bio: 'Bio',
      ReaderList: 'Finden Sie Leser',
      Popular: 'Beliebt',
      BookHas0Words: '0 Wörter',
      BookHas0WordsDescription:
        '<0> Dieses Buch hat mindestens eine Version mit 0 Wörtern. Sie können Kapitel </ 0> <1> hier </ 1> hinzufügen oder hochladen.',
      LeaveBeta: 'Leave beta',
      DiscoverNewBooks: 'Entdecken Sie neue Bücher',
      FailedToFindReader: 'Fehlgeschlagen Leser finden',
      ApprovedReadRequest: 'Leseanforderung genehmigt',
      Approved: 'Genehmigt',
      UserAlreadyInvited: '{{User}} ist bereits eingeladen',
      SelectBook: 'Wählen Sie Buch',
      InvitationMessagePlaceholder:
        'Fügen Sie eine persönliche Nachricht in Ihrer Einladung Ihre Chancen, angenommen zu erhöhen.',
      YouHaveAPendingInvitation: 'Sie haben eine ausstehende Einladung',
      ReaderIndexInfo:
        'Der Leser Datenbank enthält BetaReader.io Benutzer, die angegeben haben, dass sie von den Autoren angegangen sind, offen zu sein. Schicken Sie ihnen eine Einladung mit dem besten Spielfeld, aber im Kopf behalten, dass sie aus irgendeinem Grunde frei sinken Ihre Einladung sind.',
      ReaderLimitReached: 'Sie haben Ihre Leser Grenze erreicht',
      Invite: 'Einladen',
      Languages: 'Sprachen',
      LinkWasSentToX: 'Wir haben ein Anmelde-Link an {{x}}',
      Verifying: 'Überprüfen ...',
      FoundNoAvailableManuscripts: 'Wir fanden keine verfügbaren Manuskripte',
      FindASpecificQuestion: 'Filter Fragen',
      ReadAndReviewBonusInfo:
        'Ihr LESEN & REVIEW Bonus-Updates, nachdem Sie beenden und ein Buch überprüfen.',
      ManuscriptStatus: 'Manuskript-Status',
      MSStatusDescription_alpha:
        'Dies ist ein frühes Stadium oder Teil Manuskript.',
      MSStatusDescription_beta:
        'Dies ist ein Spätstadium Manuskript eines ganzen Buchs.',
      SelectStatus: 'Wählen Sie Status',
      MaxAllowedGenres: 'Sie können nur bis zu 5 Genres wählen',
      TooLongDescription:
        'Ihre Beschreibung ist zu lang (maximal 1000 Zeichen)',
      Duplicate: 'Duplikat',
      Download: 'Herunterladen',
      DuplicateVersionInfo:
        'Wenn Sie eine Version duplizieren, alle Kapitel und Umfragen werden auf die neue Version kopiert werden, aber Ihre Leser auf der alten Version bleiben.',
      NoBookMsg:
        '<0> Es gibt hier nichts! </ 0> <1> <0> Möchten Sie </ 0> <1> Ihr eigenes Manuskript hinzufügen? </ 1> </ 1>',
      CommentsCopiedFromOriginal:
        'Kommentare wurden aus Originaldokument kopiert',
      CopyComments: 'Kopieren Sie alle Kommentare',
      MigrateReaders: 'Migrate Leser',
      Posts: 'Beiträge',
      Sent: 'Geschickt',
      Username: 'Nutzername',
      UsernameAlreadyTaken: 'Dieser Benutzername wird bereits benutzt',
      Followers: 'Verfolger',
      ReadersAndFollowers: 'Leser und Anhänger',
      WhoCanSeeThis: 'Wer kann das sehen?',
      FollowCount: '{{Count}} Folger',
      FollowCount_plural: '{{Count}} Anhänger',
      Follow: 'Folgen',
      Following: 'folgende',
      MarkAllAsRead: 'Markiere alle als gelesen',
      Working: 'Arbeiten...',
      Audience: 'Publikum',
      ShowAllComments: 'Alle Kommentare anzeigen ...',
      Publish: 'Veröffentlichen',
      InviteByEmail: 'Einladung per E-Mail',
      InviteByLink: 'Laden Sie über den Link',
      Chat: 'Plaudern',
      WelcomeToSupportMessage:
        'Hallo {{x}}! Willkommen bei Betareader Unterstützung 👋, versuchen wir so schnell wie möglich zu beantworten. Mit was können wir dir helfen?',
      TalkToSupport: 'Sprechen Sie mit unserem Support-Team',
      StartSupportChat: 'Start-Support-Chat',
      StartSupportChatPMessage:
        '<0> Sie können uns jederzeit über unser Chat erreichen und wir werden so schnell antworten, wie wir können. </ 0>',
      FindHelpArticlesMessage:
        '<0> Hilfe nötig? Besuche unsere <1> Hilfe </ 1> Abschnitt, voller Hilfeartikel Sie zu führen. </ 0>',
      HelpArticles: 'Hilfeartikel',
      CloseConversationAlertHeader: 'Vertrauliches Gespräch',
      CloseConversationAlertMessage: 'Wollen Sie dieses Gespräch schließen?',
      EmailSupportMessage:
        '<0> Senden Sie eine E-Mail an <1> {{mail}} </ 1>, und wir werden antworten, sobald wir können. </ 0>',
      Includes: 'Enthält',
      DoesNotInclude: 'Beinhaltet nicht',
      IncludesAll: 'Eingeschlossen sind alle',
      RemoveFilter: 'Filter entfernen',
      AddFilter: 'Filter hinzufügen',
      SelectGenres: 'Wählen Genres',
      And: 'Und',
      ContinueWithGoogle: 'Fahren Sie mit Google',
      ContinueWithFacebook: 'Weiter mit Facebook',
      ContinueWithApple: 'Weiter mit Apple',
      NewOnBetareader: 'Neu bei BetarReader?',
      AlreadyHaveAccount: 'Sie haben bereits ein Konto?',
      SignUpWithFacebook: 'Mit Facebook anmelden',
      SignUpWithGoogle: 'Melden Sie sich bei Google an',
      SignUpWithApple: 'Registrieren mit Apple',
      OpportunityWithoutDescriptionMessage:
        'Der Autor hat keine Beschreibung zur Verfügung gestellt.',
      SignInToViewContent: 'Anmelden, um diese Inhalte anzuzeigen',
      ContentBelongsToAnotherAccount:
        'Dieser Inhalt gehört zu einem anderen Konto als Ihre aktiven.',
      SwitchAccount: 'Benutzer wechseln',
      OwnBooks: 'Eigene Bücher',
      Collaborations: 'Collaborations',
      UploadImage: 'Bild hochladen',
      SplitChapter: 'Teilen Sie das Kapitel',
      UpgradeToUnlock: 'Aktualizieren zum Entsperren',
      '3ReaderSlots': '3 Leseplätze',
      UnlimitedReaderSlots: 'Unbegrenzte Leserplätze',
      ImportWarning: 'Importwarnung',
      ImportExeedLenghMessage:
        '<0><0>Eines oder mehrere Ihrer Kapitel scheinen zu lang zum Importieren zu sein. Stellen Sie sicher, dass Sie unsere<1> Importrichtlinien befolgt haben. </1></0><1>Tipp: Sie können den Mauszeiger über den Kapiteltext bewegen und das Sizzor-Tool verwenden, um größere Kapitel zu teilen</1></0>',
      OneTeamMember: '1 Teammitglied',
      AdditionalTeamMembers: 'Fügen Sie Teammitglieder hinzu',
      PerMonth: 'pro Monat',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Reichen Sie Ihr Manuskript ein',
      SubmitTo: 'Senden an',
      YourSubmittedManuscripts: 'Ihre eingereichten Manuskripte',
      SelectManuscriptToSubmit: 'Wählen Sie das einzureichende Manuskript aus',
      AvailableToRead: 'Verfügbar zu lesen',
      ManuscriptSubmissionSuccess: 'Verfügbar zu lesen',
      ManuscriptSubmittedTo:
        'Ihr Manuskript {{_manuscriptName}} wurde an {{_inboxName}} gesendet',
      SubmittedBy: 'Eingereicht von {{_userName}}',
      ReceivedManuscripts: 'Erhaltene Manuskripte',
      InviteToRead: 'Zum Lesen einladen',
      YourReview: 'Ihre Bewertung',
      UserReviewPlaceholder: 'Nutzerkritiken sind für jedermann sichtbar.',
      LeaveReviewError:
        'Sie können nur Benutzer bewerten, die eines Ihrer Manuskripte zu Ende gelesen haben.',
      NotEnoughData: 'Nicht genug Daten',
      EditSignUpSurveyQuestions: 'Nicht genug Daten',
      AddSignUpSurveyQuestions: 'Nicht genug Daten',
      To: 'Zu',
      FinishedReadingAt: 'Mit dem Lesen fertig um {{x}}',
      FailedToSendInvitation:
        'Die Einladung konnte nicht gesendet werden. Bitte überprüfen Sie die E-Mail-Adresse',
      EditInvitation: 'Einladung bearbeiten',
      ChapterSeparator: 'Kapiteltrennzeichen',
      CancelReason:
        'Würde es Ihnen etwas ausmachen, uns zu verraten, warum, bevor Sie fortfahren?',
      cancel_reason_technical_issues: 'Technische Probleme',
      cancel_reason_too_expensive: 'Das Produkt ist zu teuer',
      cancel_reason_finished_my_beta: 'Ich beendete mein Projekt',
      cancel_reason_switching_to_another_product:
        'Ich bin die Umstellung auf ein anderes Produkt',
      cancel_reason_missing_features: 'Mir fehlen wichtige Features',
      cancel_reason_did_not_meet_expectations:
        'Die Plattform entsprach nicht meinen Erwartungen',
      cancel_reason_other: 'Andere Gründe',
      TellUsMore: 'Erzählen Sie uns mehr',
      DeleteAccount: 'Konto löschen',
      YourAccountHasBeenDeleted: 'Ihr Konto wurde gelöscht',
      DeleteAccountConfirmation:
        'Sind Sie sicher, dass Sie Ihr Konto und alle seine Daten löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
      GoodbyeMessage:
        'Es tut uns leid, dass Sie uns verlassen, und wir hoffen, dass Sie in Zukunft wiederkommen.',
      EditContent: 'Inhalt bearbeiten',
      AffectedReadersMessage:
        'Ein Leser hat dieses Kapitel bereits gelesen. Klicken Sie hier, um ein Update zu Ihren Änderungen zu veröffentlichen.',
      AffectedReadersMessage_plural:
        '{{count}} Leser haben dieses Kapitel bereits gelesen. Klicken Sie hier, um ein Update zu Ihren Änderungen zu veröffentlichen.',
      ImportingChapterXofY: 'Kapitel {{_x}} / {{_y}} wird jetzt importiert',
      ImportStarted: 'Import gestartet',
      XVersions: '{{count}} Version',
      XVersions_plural: '{{count}} Versionen',
      UnsubscribeSuccessful:
        'Sie wurden abgemeldet. Sie können Ihre Einstellungen jederzeit über Ihr Profil aktualisieren.',
      UnsubscribeUnsuccessful:
        'Abmeldung fehlgeschlagen. Senden Sie eine Nachricht an support@betareader.io, wenn das Problem weiterhin besteht.',
      GoodbyeHeader: 'Auf Wiedersehen!',
      BulkAddReaders: 'Leser aus CSV hinzufügen',
      Home: 'Heim',
      Actions: 'Aktionen',
      Invitations: 'Einladungen',
      SelectCSVWithReaders: 'Wählen Sie CSV mit Lesern aus',
      Synopsis: 'Zusammenfassung',
      SynopsisDescription:
        'Lassen Sie unsere KI, BetaBot, eine Zusammenfassung basierend auf Ihrem Text erstellen. Es ist für Ihre Leser nicht sichtbar.',
      LastUpdated: 'Zuletzt aktualisiert',
      CreateSynopsis: 'Erstellen Sie eine Zusammenfassung',
      RefreshSynopsis: 'Zusammenfassung aktualisieren',
      ElevatorPitch: 'Aufzugsstellplatz',
      ThisIsAPremiumFeature: 'Premium-Funktion',
      GoBack: 'Geh zurück',
      Retry: 'Wiederholen'
    }
  },
  sv: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Observera att när du skickar ett meddelande till en läsare kommer ditt användarnamn och inte din pseudonym vara synligt',
      Library: 'Bibliotek',
      Account: 'Konto',
      EmptyLibrary: 'Biblioteket är tomt.',
      EmptyLibraryAction:
        'Förväntade du dig att hitta något här? Kontakta författaren, eller skicka ett e-post på {{_email}}, så ska vi se vad vi kan göra.',
      ByAuthor: 'av {{_authorName}}',
      ExceedChapterLenghtWarning: 'Kapitlet är för långt för att importera',
      WordCount: '{{_wordCount}} ord',
      WordCountString: '{{count}} ord',
      WordCountString_plural: '{{count}} ord',
      CharCountString: '{{count}} tecken',
      CharCountString_plural: '{{count}} tecken',
      SurveyCount: '{{count}} undersökning',
      SurveyCount_plural: '{{count}} undersökningar',
      ChapterCount: '{{count}} kapitel',
      ChapterCount_plural: '{{count}} kapitel',
      QuestionCountString: '{{count}} fråga',
      QuestionCountString_plural: '{{count}} frågor',
      LoginToChangeEmail: 'Logga in igen för att ändra din e-post',
      SendVerification: 'Skicka verifieringslänk',
      Name: 'Namn',
      Email: 'E-post',
      EnterYourName: 'Fyll i ditt namn',
      ReceiveCompanyUpdates: 'Jag vill få e-post och nyheter om BetaReader',
      ReceiveBookEmails: 'Jag vill få e-post om nya böcker',
      ReceiveBookActivityUpdates:
        'Jag vill få e-post om aktivitet kring mina böcker',
      ReceiveMessageUpdates: 'Jag vill få e-post när jag får nya meddelanden',
      IncludeProfileInReaderList: 'Inkludera min profil i läsardatabasen',
      PrivacyPolicy: 'Integritetspolicy',
      TermsOfService: 'Användarvillkor',
      ReadOurBlog: 'Läs vår blogg',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Nästa',
      Back: 'Tillbaka',
      CreateAccount: 'Skapa konto',
      CreateAccountPrompt:
        '<0>Har du inget konto?<1> <0>Skapa ett här!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Har du glömt ditt lösenord?<1> <0>Återställ det här! </0></1></0>',
      SignIn: 'Logga in',
      SignOut: 'Logga ut',
      SendResetLink: 'Skicka återställningslänk',
      ResetPassword: 'Återställ lösenord',
      Password: 'Lösenord',
      OrUse: 'eller använd',
      NoConnection: 'Off-line...',
      AcceptTermsMessage: 'Acceptera användarvillkoren',
      AcceptToSToCreateAccount:
        'Du måste acceptera våra användarvillkor för att skapa ett konto.',
      Answer: 'Svar',
      FailedToLoadBook: 'Kunde inte ladda boken.',
      PullDownToRefresh: 'Dra ned för att uppdatera.',
      ContactSupport: 'Kontakta supporten',
      Support: 'Support',
      LastRead: 'Senast lästa',
      XInvitations: '{{count}} inbjudan',
      XInvitations_plural: '{{count}} inbjudningar',
      EnterComment: 'Kommentera...',
      EnterReply: 'Svara...',
      Reply: 'Svar',
      Reply_plural: 'Svar',
      Reply_verb: 'Svara',
      show: 'visa',
      hide: 'dölj',
      Comments: 'Kommentarer',
      InlineComment: 'Inline kommentar',
      Reject: 'Avvisa',
      Accept: 'Acceptera',
      Error: 'Fel',
      failed: 'misslyckades',
      SelectedText: 'Vald text',
      FailedToFetchUserProfile: 'Det gick inte att hämta användarprofilen.',
      ProblemPersistContactSupport:
        'Om problemet kvarstår, kontakta support på:',
      clickToAddComment: 'Klicka här för att lämna en kommentar',
      ColorMode: 'Färgläge',
      FontSize: 'Textstorlek',
      Discover: 'Upptäck',
      MyAccount: 'Mitt konto',
      MyManuscripts: 'Mina manuskript',
      Sweden: 'Sverige',
      USA: 'USA',
      UK: 'Storbritannien',
      Germany: 'Tyskland',
      France: 'Frankrike',
      Spain: 'Spanien',
      Country: 'Land',
      Gender: 'Kön',
      YoB: 'Födelseår',
      PreferredGenres: 'Föredragna genres',
      PreferredLanguages: 'Föredragna språk',
      EmailPreferences: 'E-postinställningar',
      chapters: 'kapitel',
      words: 'ord',
      YouShouldBetaRead: 'Du borde läsa',
      onBetaReader: 'på BetaReader.io',
      Share: 'Dela',
      NoGuidelines: 'Författaren har inte lämnat några feedback-riktlinjer.',
      Read: 'Läs',
      on: 'på',
      Author: 'Författare',
      JoinBeta: 'Jag vill läsa den här',
      joinConsentString:
        'Jag förstår att genom att begära att delta i denna beta, kommer jag att dela min e-postadress med författaren.',
      Submit: 'Skicka in',
      AnswerSurvey: 'Svara!',
      SendJoinRequest: 'Skicka in',
      IJustWantToRead: 'Jag vill bara läsa',
      CopyrightInfo: 'Copyright-info',
      RequestToRead: 'Skicka läsbegäran',
      Feedback: 'Feedback',
      NoComment: 'Ingen har kommenterat.',
      OnceUponATime: 'Det var en gång ...',
      Review: 'Recension',
      Overall: 'Övergripande',
      Plot: 'Intrig',
      Grammar: 'Grammatik',
      Title: 'Titel',
      AddReview: 'Lägg till en recension',
      EnterReviewText: 'Skriv en kort recension',
      OverallRating: 'Övergripande',
      OverallRatingRequired: 'Lägg övergripande betyg',
      PlotRating: 'Intrig',
      GrammarRating: 'Grammatik',
      SubmitReview: 'Lämna recension',
      LeaveReview: 'Lämna recension',
      checkOutAuthorApp:
        '<0> Är du en författare?</0><1></1><2>Kolla in vår <1>författarapp</1></2>',
      MyLibrary: 'Mitt bibliotek',
      ReadingPreferences: 'Läsinställningar',
      'E.g.': 'T.ex.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Jag vill få e-post från BetaReader.io när författaren gör ändringar eller uppdateringar',
      'I want to receive emails about BetaReader news and updates.':
        'Jag vill få e-post om BetaReader nyheter och uppdateringar.',
      'I want to receive emails about new books that match my reading preferences.':
        'Jag vill få e-post om nya böcker som matchar mina läspreferenser.',
      'this book': 'denna boken',
      'Email us on': 'Mejla oss på',
      "and we'll see what we can do :)": 'se ska vi se se vad vi kan göra :)',
      'Want an author account?': 'Vill du ha ett författarkonto?',
      Preferences: 'Inställningar',
      Language: 'Språk',
      Browse: 'Bläddra',
      'to beginning': 'till början',
      'to chapter...': 'kapitel ...',
      Location: 'Plats',
      Previous: 'Tidigare',
      Finish: 'Avsluta',
      Description: 'Beskrivning',
      FeedbackGuidelines: 'Feedbackriktlinjer',
      'Start reading': 'Börja läsa',
      StartReading: 'Börja läsa',
      LeaveComment: 'Kommentera',
      replies: 'svar',
      Show: 'Visa',
      Hide: 'Dölj',
      'Add Reply': 'Svara',
      PendingInvitation: 'Väntande inbjudan',
      Decline: 'Avböj',
      DeclinedInvitationMessage:
        'Du har avböjd denna inbjudan, men du kan fortfarande ändra dig och acceptera.',
      Welcome: 'Välkommen',
      by: 'av',
      to: 'till',
      AcceptInvitation: 'Acceptera inbjudan',
      InvitationAccepted: 'Inbjudan godkänd',
      WaitForBook: 'Vänligen vänta medan vi förbereder din bok',
      PasswordLength: 'Lösenordet måste vara minst 6 tecken långt',
      AgreeToPolicyAndTerms:
        'Du måste godkänna BetaReader integritetspolicy och användarvillkor för att kunna registrera dig.',
      'Sending...': 'Skickar...',
      VerificationSent: 'Verifieringsmailet har skickats.',
      VerificationEmailFailed: 'Det gick inte att skicka verifieringsmailet.',
      IAcceptToC:
        '<0>Jag accepterar BetaReader.io:s <1>integritetspolicy</1> & <3> villkor</3></0>',
      AccountSettings: 'Kontoinställningar',
      ContactSettings: 'Kontaktinställningar',
      Chapters: 'Kapitel',
      Options: 'Alternativ',
      Option: 'Alternativ',
      BetaLanguageMessage:
        '<0>Vi arbetar på förbättrat språkstöd och är supertacksamma för all hjälp vi kan få. Om du hittar några felöversättningar, tveka inte att kontakta oss på: <1>hello@betareader.io</1></0>',
      Genres: 'Genrer',
      PressEnterToSave: 'Tryck Enter för att spara',
      female: 'kvinna',
      male: 'man',
      other: 'icke-binär',
      DontWantToShare: 'privat',
      SignInWithPassword: 'Logga in med lösenord',
      GoToBook: 'Till boken',
      Requested: 'Din intresseanmälan väntar',
      AnsweredOrRequestedNotice:
        'Du har begärt att delta i denna beta. Författaren kommer att återkomma till dig så snart hen kan.',
      RequestedWithDifferentEmail:
        'Du har begärt att delta i denna beta med en annan e-post än den du var inbjuden med. Författaren behöver godkänna denna förfrågan och återkommer så snart som möjligt.',
      LoadingLibrary: 'Letar efter böcker ...',
      PreparingBook: 'Förbereder bok',
      InLineCommentPlaceholder:
        'Lämna dina kommentarer och tryck på enter för att spara',
      ExitSurvey: 'Exit-undersökning',
      WhyExitQuestion: 'Varför vill du lämna denna beta?',
      LostInterest: 'Jag tappade intresset',
      NotEnoughTime: 'Jag har inte tillräckligt med tid',
      Other: 'Annan orsak',
      reason_lost_interest: 'Jag tappade intresset',
      reason_no_time: 'Jag har inte tillräckligt med tid',
      reason_other: 'Annan orsak',
      reason_: 'Ej angivet',
      ExitFeedbackQuestion: 'Vill du lämna några kommentarer till författaren?',
      ExitFeedbackPlaceholder: 'All feedback är användbar',
      ThankYouForYourFeebdack: 'Tack för din feedback!',
      AppVersion: 'Version',
      AppBuildNumber: '#',
      Like: 'Gilla',
      Love: 'Älska',
      Laugh: 'Haha',
      Surprise: 'Överraskad',
      Sad: 'Ledsen',
      Angry: 'Arg',
      NoBooksInList: 'Inga böcker här.',
      Update: 'Uppdatera',
      Overview: 'Översikt',
      Content: 'Innehåll',
      Readers: 'Läsare',
      NotificationSettings: 'Meddelandeinställningar',
      SendEmailOnComments: 'Skicka e-post när läsare lämnar kommentarer',
      SendEmailOnSurveyComplete:
        'Skicka e-post när läsare svarar på en undersökning',
      CoverUploadMsg:
        'Klicka för att ladda upp din omslagsbild (png eller jpg, 980x1568px) eller släpp den här.',
      DefaultNamePlaceholder: 'Default: {{displayName}}',
      SelectUpTo5Genres: 'Välj upp till 5 genrer',
      AdvancedSettings: 'Avancerade inställningar',
      EnableComments: 'Aktivera kommentarer',
      EnableBookReviews: 'Aktivera bokrecensioner',
      DeleteBook: 'Ta bort boken',
      DeleteCover: 'Radera bokomslag',
      DeleteCoverConfirmation: 'Är du säker på att du vill radera bokomslaget?',
      YesBinIt: 'Ja, ta bort det!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Är du säker på att du vill radera <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} har tagits bort.',
      No: 'Nej',
      Yes: 'Ja',
      UserCommentedOn: 'kommenterade',
      ShowXReplies: 'Visa {{count}} svar',
      ShowXReplies_plural: 'Visa {{count}} svar',
      HideXReplies: 'Dölj {{count}} svar',
      HideXReplies_plural: 'Dölj {{count}} svar',
      NoInLineComments: 'Inga inline kommentarer.',
      Chapter: 'Kapitel',
      NoCommentsToShow: 'Inga kommentarer att visa.',
      Save: 'Spara',
      TooLongTitle: 'Din titel är för lång (max 60 tecken)',
      ClickToUploadDoc:
        'Klicka för att ladda upp filen (docx), eller släpp den här.',
      LongChapterWarning: 'Lång kapitel är detta korrekt?',
      Upload: 'Ladda upp',
      UploadChapters: 'Ladda upp kapitel',
      ImportBooksHelpMsg:
        'Ingen kommer att kunna se ditt manuskript utan ditt godkännande. Läs mer om att <1> importera böcker </1>.',
      Add: 'Lägg till',
      NewChapter: 'Nytt kapitel',
      NewSurvey: 'Ny undersökning',
      SelectedPart: 'vald del',
      SelectPart: 'Välj del',
      NotifyMe: 'Meddela mig',
      WhenReadersStart: 'vid start',
      WhenReadersComplete: 'vid avslut',
      ManageChapter: 'Hantera kapitel',
      ManageSurvey: 'Hantera undersökning',
      ManageReaders: 'Hantera läsare',
      MoveUp: 'Flytta upp',
      MoveDown: 'Flytta ner',
      SaveSurvey: 'Spara undersökning',
      Saved: 'Sparat',
      EnterSurveyName: 'Ge din undersökning ett namn',
      EnterSurveyDescription: 'Beskriv din undersökning',
      SelectAtLeast: 'Välj minst',
      SelectAtMost: 'Välj högst',
      LabelOptional: 'Etikett (valfritt)',
      MyBooks: 'Mina böcker',
      ChangeRoleToWriterToCreateBooks:
        'Byt roll till författare för att skapa böcker.',
      UsingXOutOfYBooks: 'Du använder {{x}} av {{y}} böcker.',
      UsingXOutOfYReaders: 'Du använder {{x}} av {{y}} läsare.',
      NothingHere: 'Ingenting här just nu.',
      OpenReaderWeb: 'Öppna läsaren',
      PrivacyLevel: 'Delningsstatus',
      PublishingStatus: 'Delningsstatus',
      PrivacyLevel_infotext_unpublished:
        '<0> Din bok är offline. Flytta den till <1> privat </1>, <3> stängd </3> eller <5> listad </5> för att läsare ska kunna hitta den. </0>',
      PrivacyLevel_infotext_private:
        '<0> Din bok är tillgänglig endast för inbjudna läsare. </0>',
      PrivacyLevel_infotext_closed:
        '<0> Din presentation kan ses av vem som helst med <1> den här länken </1>. Läsare kan begära en inbjudan, men du måste godkänna varje begäran. </0>',
      PrivacyLevel_infotext_listed:
        '<0> Din bok är listad <1>här</1>. Läsare kan begära en inbjudan, men du måste godkänna varje begäran. </0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Privat',
      PrivacyLevel_state_closed: 'Stängd',
      PrivacyLevel_state_listed: 'Listad',
      SendReminder: 'Skicka påminnelse',
      YouCanSendAReminderEvery3Days:
        'Du kan skicka en påminnelse var tredje dag',
      LastSeenAt: 'Sågs senast vid',
      ReminderSentAt: 'Påminnelse skickad',
      XReadersInactiveForYDays:
        '{{count}} läsare har inte avslutat boken och har inte varit aktiv under de senaste {{days}} dagarna.',
      XReadersInactiveForYDays_plural:
        '{{count}} läsare har inte avslutat boken och har inte varit aktiva under de senaste {{days}} dagarna.',
      InactiveReaders: 'Inaktiva läsare',
      XReadersNotStartedForYDays:
        '{{count}} läsare har inte accepterat din fler än {{days}} dagar gamla inbjudan',
      XReadersNotStartedForYDays_plural:
        '{{count}} läsare har inte accepterat din fler än {{days}} dagar gamla inbjudan',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Läsare som inte har accepterat inbjudan',
      YouHaveXJoinRequests: 'Du har {{count}} begäran om att läsa din bok',
      YouHaveXJoinRequests_plural:
        'Du har {{count}} frågningar att läsa din bok',
      JoinRequests: 'Läsförfrågningar',
      Approve: 'Godkänn',
      RequestedAt: 'Begärd',
      NoAnswer: 'Inget svar',
      SendSurveyReminder: 'Skicka enkät-påminnelse',
      Remove: 'Ta bort',
      Created: 'Skapad',
      RequestedToRead: 'Begärde att läsa',
      InvitationSent: 'Inbjudan skickad',
      Reminded: 'Påmind',
      Question: 'Fråga',
      YouCanAskUpToXQuestions:
        'Du kan lägga till upp till {{maxQuestions}} frågor som läsarna måste svara på när de anmäler intresse att läsa din bok.',
      NewQuestion: 'Ny fråga',
      ReaderSignUpForm: 'Anmälningsformulär',
      NoReadersFound: 'Inga läsare hittade',
      ReachedPartTitle: 'Nått {{partTitle}}',
      ReasonToQuit: 'Anledning',
      YourBookDoesNotHaveAnyChaptersYet: 'Din bok har inga kapitel ännu',
      Invited: 'Inbjudna',
      Started: 'Startade',
      Finished: 'Färdiga',
      Abandoned: 'Övergivna',
      AvgOverallRating: 'Övergripande',
      AvgPlotRating: 'Intrig',
      AvgGrammarRating: 'Grammatik',
      YouDoNotHaveReviewsYet: 'Du har inga recensioner ännu.',
      PersonalLink: 'Personlig länk',
      HelpUsReachMoreWriters:
        'Tycker du om att använda BetaReader.io? Använd din personliga länk för att hjälpa oss att hjälpa fler författare!',
      Reports: 'Rapporter',
      WeNeedYourEmail: 'E-post behövs',
      MissingEmailMsg:
        'Vi måste kunna kontakta dig angående ditt konto. Vi kommer inte att dela den med någon, och endast kontakta dig om absolut nödvändigt.',
      EmailNotVerifiedPrompt:
        'E-postadressen har inte verifierats. <1> Klicka här för att skicka </1> ett verifieringsmail. (kolla din skräppostmapp om det inte dyker upp inom ett par minuter)',
      Skip: 'Skippa',
      RoleWriter: 'Författare',
      RoleReader: 'Läsare',
      RoleBoth: 'Båda',
      AddReader: 'Lägg till ny läsare',
      DownloadReaderList: 'Hämta läsare lista',
      'Show only': 'Visa endast',
      StatusNEW: 'Ny',
      StatusTODO: 'Att göra',
      StatusDOING: 'Håller på med',
      StatusDONE: 'Gjort',
      StatusIGNORE: 'Ignorera',
      RemoveFilters: 'Ta bort filter',
      ReadingDataReport: 'Läsdata',
      SurveyAnswersReport: 'Enkätsvar',
      Reviews: 'Recensioner',
      AbandonedReadersReport: 'Förlorade läsare',
      SignUpMsg:
        '<0>Tack för att du registrerade dig!</0> <1>Ett verifieringsmeddelande har skickats till <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Kopierade {{x}} till klippbordet.',
      DoYouWantToBeMyBetaReader: 'Vill du bli min betaläsare?',
      LookingForBetaReadersForBookTitle:
        'Jag söker betaläsare för {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Jag söker betaläsare för {{bookTitle}}. Följ den här länken för att läsa mer och registrera dig: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Social delning är inte möjlig för privata böcker.',
      ShareTheLinkToYourBook: 'Dela länken till din bok!',
      Messages: 'Meddelanden',
      FailedToLoadMessage: 'Vi misslyckades med att läsa detta meddelande.',
      FailedToSendMessage: 'Vi misslyckades med att skicka meddelandet.',
      FailedToStartConversation:
        'Vi misslyckades med att starta konversationen.',
      NewMessage: 'Nytt meddelande',
      MessageSubject: 'Ämne',
      MessageBody: 'Skriv ditt meddelande ...',
      MessageFrom: 'Från',
      MessageTo: 'Till',
      SendMessage: 'Skicka',
      Message: 'Meddelande',
      ReaderMarketplaceBetaInfoText:
        'Vi experimenterar med att låta våra författare betala vanliga läsare och bokmalar en symbolisk summa för tjänsten att läsa ett manuskript. Vi erbjuder kontrollmekanismer för att validera återkoppling och kontroll som läsarna har läst hela manuskriptet innan någon får betalt. Om du vill prova den här funktionen, ange hur många läsare du behöver.',
      ReaderMarketplaceBetaFullText:
        'Detta program är för närvarande fullt. Vill du att vi ska meddela dig när vi får en öppning?',
      Sum: 'Summa',
      XUSDPerReader: '${{x}} / läsare',
      AmountOfReaders: 'Mängd läsare',
      PayForTestReaders: 'Betala för testläsare',
      GoToCheckout: 'Gå till kassan',
      YesPlease: 'Ja tack!',
      BETA: 'BETA',
      NoMessages: 'Du har inga meddelanden',
      OnBoarding_import_book:
        'Behöver <0> Välkommen till BetaReader.io! </0> För att dela din bok behöver du först importera den, antingen genom att <2>ladda upp den</2>, eller genom att skapa den direkt i plattformen. Allt på BetaReader.io är privat som default, så ingen kommer att se boken om du inte bjuder in dem.',
      Onboarding_book_overview:
        'Under Översikt hanterar du övergripande information om din bok. Titel, genrer och feedbackriktlinjer hanteras här, men även om du vill att läsarna att kunna kommentera och lämna recensioner.',
      Onboarding_book_content:
        'Under Innehåll styr du över innehållets ordning. Lägg in dina kapitel och undersökningar här.',
      Onboarding_book_readers:
        'Under Läsare styr du vem som får läsa din bok. Skicka privata inbjudningar, dela din sign-up länk via Twitter, eller lägg upp din bok i vår Discovery-lista för att nå fler läsare.',
      Onboarding_book_feedback:
        'Under Feedback får du en överblick av all feedback som läsarna har lämnat. Svara på kommentarer och filtrera bort oviktiga saker.',
      Onboarding_book_reports:
        'Under Rapporter får du en överblick över hur det går för din bok. Hur många läsare har bjudits in, hur många har börjat läsa, övergivna, lämnade lysande recensioner, och så vidare.',
      Close: 'Stäng',
      Last: 'Sista',
      DuplicatedBook: '<0> <0> </0> Kopierade bok {{bookTitle}} </0>',
      PenName: 'Pseudonym',
      MissingNameMsg:
        'Du måste ange ett namn för att kunna kommunicera med dina läsare.',
      NameConversation: 'Namnge denna konversation',
      With: 'med...',
      SelectPeople: 'Välj personer',
      UploadAsNewVersion: '... som ny version',
      UploadAsExtraChapters: '... till den här versionen',
      Cancel: 'Avbryt',
      Unnamed: 'namnlös',
      NewVersion: 'Ny version',
      NoContacts: 'Inga kontakter',
      GetContacts:
        'Författare av de böcker du läst, och läsare av böcker du skriver, blir dina kontakter.',
      NoConversations: 'Inga konversationer ...',
      AllVersionsNeedContent:
        'Din nuvarande version behöver innehåll innan du kan skapa en ny.',
      InviteToVersion: 'Bjud in till version',
      latestVersion: 'senaste',
      VersionName: 'Version {{x}}',
      Reader: 'Läsare',
      StartedReading: 'Började läsa',
      CurrentPosition: 'Nuvarande position',
      PickVersion: 'Välj version',
      Filters: 'Filter',
      Versions: 'versioner',
      ChapterInitial: 'k',
      AvgTimeToComplete: 'Genomsnittlig tid att läsa kapitel',
      ReaderProgress: 'Läsarpositioner',
      SelectFilter: 'Välj ett filter',
      CreatePost: 'Dela en uppdatering',
      NewBookPostPlaceholder: 'Vad vill du säga?',
      BookUpdateForX: 'Uppdatering för bok {{x}}',
      OnSaveChapterMessage: 'Låt dina läsare vet vad du ändrat',
      SampleChapterUpdatePost: 'Jag ändrade detta ...',
      SaveWithoutPosting: 'Spara utan inlägg ...',
      SaveAndPost: 'Spara och publicera ...',
      DiscoverSearch: 'Sök efter en titel eller genre ...',
      LoadMore: 'Ladda fler...',
      PendingReadInvitations: 'Väntande inbjudningar',
      PendingReadRequests: 'Väntande förfrågningar',
      UserReactedOn: 'reagerade på',
      ChapterComment: 'Kapitelkommentar',
      InlineCommentNotFound:
        'Denna kommentar hittades inte i texten. Har kommenterade texten tagits bort?',
      Or: 'Eller',
      Version: 'Version',
      Updates: 'Uppdateringar',
      CurrentBetaIsFullMessage:
        'Denna beta är för närvarande full, kontakta författaren för att öppna upp fler platser.',
      Edit: 'Redigera',
      Delete: 'Radera',
      DropFilesHere: 'Släpp dina filer här',
      ShowOnly: 'Visa endast',
      FeedbackSearch: 'Textsökning',
      'BuySubscriptionToS&PPMessage':
        '<0>Genom att köpa ett abonnemang från BetaReader.io anger att du har läst och accepterar våra <1> användarvillkor </ en> & <5> sekretesspolicy </ 5> </ 0>',
      Inbox: 'Inkorg',
      CofirmMovePart:
        'Vill du verkligen vill flytta denna del? Det kommer att påverka följande läsare:',
      'AreYouSure?': 'Är du säker?',
      LetYourReadersKnowWhatYouChanged: 'Låt dina läsare vet vad du ändrat',
      ThisChangeAffectsTheFollowingReaders:
        'Denna förändring påverkar följande läsare:',
      MovedXFromYtoZ: 'Flyttade {{x}} från {{y}} till {{z}}',
      Subscription: 'Prenumeration',
      Subscriptions: 'Prenumerationer',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Basplanen är gratis för alltid.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Bra för hobbyperfektionister',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription:
        'Perfekt för professionella indieförfattare',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Perfekt för förlag',
      CancelSubscription: 'Avsluta prenumeration',
      YourSubscriptionHasBeenCancelled: 'Din prenumeration har avbrutits',
      CancelSubscriptionConfirmation:
        'Är du säker på att du vill avsluta din prenumeration?',
      ValidUntil: 'Giltig till',
      UpgradeSubscriptionTitle: 'Uppgradera',
      UpgradeSubscriptionPromoText: 'Uppgradera ditt konto',
      Abort: 'Avbryt',
      BuyNow: 'Köp',
      Current: 'Nuvarande plan',
      Monthly: 'Månatlig',
      Yearly: 'Årlig',
      monthly: 'månatlig',
      yearly: 'år',
      month: 'månad',
      XCheaper: '{{x}} billigare!',
      Downgrade: 'Nedgradera',
      Forever: 'för evigt',
      ChangePlan: 'Byt plan',
      TrackReaderProgress: 'Följ dina läsare i realtid',
      InlineComments: 'In-line kommentarer och reaktioner',
      ReaderSurveys: 'Läsarundersökningar',
      NativeApps: 'Android och iOS-app',
      MultipleVersions: 'Manusversionering',
      '1ActiveManuscript': 'ett manuskript',
      '5ActiveManuscripts': 'Upp till 5 manuskript',
      '20ActiveManuscripts': 'Upp till 20 manuskript',
      UnlimitedActiveManuscripts: 'Obegränsat antal manuskript',
      '3ActiveReadersPerManuscript': 'Upp till 3 läsare',
      '20ActiveReadersPerManuscript': 'Upp till 20 läsare',
      '50ActiveReadersPerManuscript': 'Upp till 50 läsare',
      UnlimitedActiveReaders: 'Obegränsat antal läsare',
      Zero: 'Ingenting',
      NoCreditCardNeeded: 'Inget kreditkort behövs',
      Recommended: 'Rekommenderad',
      SomethingWentWrong: 'Något gick snett',
      SubscriptionChangeError: 'Misslyckades med att ändra prenumerationen',
      SubscriptionChangeSuccess: 'Vi uppdaterade din prenumeration',
      ActionCancelled: 'Åtgärden avbröts',
      Cancelled: 'Inställt',
      NextPayment: 'Nästa betalning',
      PaymentPastDueMessage:
        'Din betalning är förfallen. Nästa försök: {{nextAttempt}}',
      ChangePaymentInformation: 'Ändra faktureringsinformation',
      DashboardWelcomeMessage:
        '<0> Välkommen till BetaReader.io! </ 0> <1> Börja med <2> ladda upp </ 2> eller <6> lägga </ 6> ditt manus </ en> <2> Discovery </ 2> < 3> Glöm inte att lista ditt manuskript i vår <2> upptäck </ 2> sektion för att få tillgång till flera beta läsare. </ 3> <4> <0> Här </ 0> är en snabb genomgång av hur man göra det. </ 4> <5> Support </ 5> <6> Vänligen ta en titt på vår <2> support </ 2> portal eller pinga oss direkt genom att trycka på (?) längst upp till höger på sidan. < / 6> <7> Du kan alltid skicka oss ett vänligt mail på <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Manus',
      CurrentlyReading: 'Läser nu',
      WeeklyReaders: 'Senaste veckan',
      TotalReaders: 'Totalt',
      FailedToDownloadReport: 'Det gick inte att hämta rapporten',
      WaitingForConfirmation: 'Väntar på bekräftelse',
      ChangePublishingStatusToAddReaders:
        'Ändra publiceringsstatus för att lägga till läsare',
      SuggestASwap: 'Föreslå ett byte',
      WithdrawRequest: 'Återkalla förfrågan',
      Confirm: 'Bekräfta',
      ThisIsASwapRequestForX:
        'Detta är en bytesförfrågan för <2>{{x}}</2>. Om du godkänner förfrågan läggs du till som läsare på {{x}}.',
      ApprovedSwapRequestForX: 'Detta är en bytesförfrågan för <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Detta är en bytesförfrågan. Om du godkänner denna förfrågan, kommer du att läggas till som läsare för',
      SwapRequest: 'Bytesförfrågan',
      ApproveAndJoin: 'Godkänn och gå med',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} har erbjudit sig att läsa {{y}} i utbyte mot att du läser den här boken.',
      GoToSwapRequest: 'Gå till bytesförfrågan',
      SwitchVersion: 'Byt version',
      SendMagicLinkToX: 'Skicka en magisk länk till {{x}}',
      notification_verb_comment: 'kommenterade på',
      notification_verb_react: 'reagerade på',
      notification_verb_reply: 'svarade på en kommentar på',
      notification_verb_abandon: 'övergav din bok',
      notification_verb_request_to_join: 'har begärt att få läsa {{y}}',
      notification_verb_message: 'skickat ett meddelande',
      notification_verb_sent_join_approval:
        'har godkänt din begäran om att läsa',
      notification_verb_invitation_to_join: 'har bjudit in dig att läsa {{y}}',
      notification_verb_manuscript_submitted:
        'har skickat in ett manus till {{y}}',
      'SaveAnd...': 'Spara och ...',
      Send: 'Skicka',
      OnlySave: 'Spara bara',
      YourNotifications: 'Dina aviseringar',
      Notifications: 'Aviseringar',
      Images: 'Bilder',
      '365DaysReadingData': '365 dagars läsarhistorik',
      '90DaysReadingData': '90 dagars läsarhistorik',
      '30DaysReadingData': '30 dagars läsarhistorik',
      alerts_PaymentPastDue:
        'Din betalning är förfallen. Uppdatera dina faktureringsuppgifter',
      alerts_MissingName:
        'Vi saknar ditt namn (eller pseudonym). Lägg till det här:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} kvar för att hävda din livstidsrabatt. Ange LAUNCH i kupongfältet när du uppgraderar:',
      RequestedToSwapForX: 'Du har begärt att byta en för {{x}}',
      PaymentSuccessMessage:
        '<0> <0> Tack för ditt köp! </ 0> <1> <0> </ 0> </ en> </ 0>',
      Dashboard: 'Startsida',
      year: 'år',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Hur sannolikt är det att du skulle rekommendera BetaReader.io till en vän?',
      NotAtAllLikely: 'Inte troligt',
      ExtremelyLikely: 'Mycket troligt',
      ThanksDoYouWantToTellUsWhy: 'Tack! Vill du berätta varför?',
      Create: 'Skapa',
      Books: 'Böcker',
      Feed: 'Feed',
      ANewStory: 'en ny berättelse',
      FailedToGetInvitations: 'Det gick inte att hämta inbjudningar',
      unauthorizedPrivateBook:
        'Den här boken är privat och inte är tillgänglig för det här kontot.',
      invalidToken:
        'Inbjudan är inte giltig för det här kontot eller har löpt ut.',
      RaiseYourReaderLimit: 'Höj din läsargräns (för närvarande {{x}})',
      InviteYourFriends: 'Bjud in dina vänner',
      referralText:
        '<0> Få upp till tio extra läsarslots genom att bjuda in dina vänner till BetaReader.io! </0> <1> För varje vän som skapar ett validerat författarkonto och listar ett manuskript tack vare dig ger vi dig och din vän 1 läsarslot var, upp till maximalt 10 slots. </1> <2> Du kan också höja läsargränsen genom att läsa och recensera 100 000 ord via vår <1> <0> läsarapp </0> </1>, eller genom att <3><0> uppgradera</0> </3> ditt konto! </2>',
      INVITED: 'Inbjuden',
      CREATED_ACCOUNT: 'Skapat konto',
      CONFIRMED: 'Bekräftad',
      RegistrationDate: 'Registreringsdatum',
      Profile: 'Profil',
      EditProfile: 'Redigera profil',
      GetMoreReaders: 'Få fler läsare',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Du har nått din läsargräns. <1>Uppgradera</1> för att skicka fler inbjudningar i vårt läsarindex eller godkänna läsförfrågningar. I vår gratisplan kan du föreslå ett <3>byte</3> med andra författare eller bjuda så många läsare du vill direkt via deras e-postadresser.</0>',
      SwapsDontAffectReaderLimit:
        'Manusbyten räknas inte mot i din läsargräns.',
      maxBooksLimitMessage:
        '<0> Du har nått din manusgräns. <1> Höj gränsen </1> för att kunna testa flera manuskript parallellt. </0>',
      Type: 'Typ',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> Lås upp din registreringsbonus! </ 0> <1> Det verkar som om du var refererad av en vän - följ nedanstående steg för att låsa upp er ömsesidiga registreringsbonus! </ 1> <2> <0> <0 > </ 0> <1> <0> Validera konto </ 0> <1> Du behöver ett giltigt namn och en e-postadress </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Lista manuskript </ 0> <1> Lista manuskriptet du vill testa </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> Vänta </ 0> <1> Det tar ungefär 3 dagar för oss att validera </ 1> </ 1> </ 2> </ 2>',
      titleDiscoveryRecommended: 'Rekommenderat för dig',
      subheaderDiscoveryRecommended: 'Något nytt utifrån dina valda genrer',
      titleDiscoveryRecentlyAdded: 'Nyligen tillagd',
      subheaderDiscoveryRecentlyAdded: 'Nyligen tillagda titlar',
      discoveryRecentlyAddedTitlesInGenre:
        'Nyligen tillagda titlar i genren {{x}}',
      titleDiscoveryPremium: 'Utvalda titlar',
      subheaderDiscoveryPremium: 'Titlar av premiumförfattare',
      referralMessage:
        'Jag bjuder in dig till BetaReader.io! Om du registrerar dig med den här länken får vi båda en bonus!',
      referralBody: 'Registrera dig här: {{x}}',
      ThisCannotBeUndone: 'Detta kan inte göras ogjort',
      DeletedVersion: 'Versionen raderades',
      InvalidEmail: 'Ogiltig e-postadress',
      EmailNotVerified: 'Inte verifierat, kontrollera din e-post',
      SelectFavoriteGenres: 'Välj dina favoritgenrer',
      Loading: 'Läser in',
      ChapterIdentification: 'Kapitelidentifikation',
      ChapterAnalysis: 'Kapitelanalys',
      CompleteImport: 'Slutför importen',
      Import: 'Importera',
      Processing: 'Bearbetar fil...',
      ThisMightTakeAWhile: 'Det här kan ta ett tag',
      LikeThisCover: 'Gillar du bokomslaget?',
      DownloadFailedMsg:
        'Hämtningen misslyckades. Blockerades den av en popup-blockerare?',
      ShareAuthorAppEmailSuccessMessage:
        'Inbjudan skickad. Kontrollera statusen <1> här </1>.',
      failedToUpdateReader: 'Misslyckades med att uppdatera läsaren',
      activeReaders: 'Aktiva läsare',
      ReadingList: 'Läslista',
      Users: 'Användare',
      InviteUser: 'Bjud in användare',
      Role: 'Roll',
      member_role_admin: 'Administratör',
      member_role_member: 'Medlem',
      couponsOrDiscountsWillNotBeKept:
        '<0> Befintliga rabatter kommer inte att följa med till den nya planen. </ 0>',
      FailedToFetchX: 'Misslyckades med att hämta {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Du har blivit inbjuden att gå med i {{x}}s BetaReader.io-konto.',
      AcceptAccountInvitationMsg:
        'Om du accepterar inbjudan kan du se och redigera alla manuskript på det här kontot.',
      InvitedMembersMsg:
        'Kontomedlemmar kommer att kunna se och redigera alla manuskript på ditt konto, men de ser inga faktureringsuppgifter. Se betareader.io/pricing för information om kostnaden per kontomedlem.',
      InviteCollaborator: 'Bjud in en medarbetare',
      InviteCollaboratorMsg:
        'Medarbetare kan se manuskriptet och läsardetaljer samt svara på feedback.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Du har blivit inbjuden att samarbeta på {{book}} av {{author}}',
      AcceptCollaborationInvitationMsg:
        'Om du accepterar inbjudan kommer du att kunna se manuskriptet och läsardetaljer samt svara på feedback.',
      Collaborators: 'Medarbetare',
      ThisIsABetaFeature:
        'Det här är en beta-funktion. Låt oss veta om du upplever några problem med den, eller om du har någon feedback att dela med dig av.',
      UserNotFoundMsg:
        'Det verkar som om du inte har ett konto. Skapa ett <1> här </ 1>!',
      AuthorName: 'Författarens namn',
      Details: 'Detaljer',
      Settings: 'Inställningar',
      ReviewAtLeastXwords: '{{x}} ord recenserade',
      CheckBonus: 'Kontrollera din bonus',
      FailedToAddCommentOrReaction:
        'Gick inte att lägga kommentar eller reaktion',
      ThisIsAWIP: 'Detta är ett WIP',
      WIPExplanation:
        'Flagga din berättelse som WIP (Work In Progress) när den inte är klar, t.ex. när du inte har lagt alla kapitel.',
      MatureContent: 'Vuxeninnehåll',
      PublicationDate: 'Publicering',
      TimePlan: 'Tidsplan',
      NotSpecified: 'Ej angivet',
      WhatIsThisStoryAbout: 'Vad handlar den här berättelsen om?',
      WhatDoYouNeedHelpWith: 'Vad behöver du hjälp med?',
      StartWriting: 'Starta från början',
      CreateManuscript: 'Skapa manuskript',
      ImportManuscript: 'Importera manuskript',
      ImportManuscriptStepDescription: 'Ladda upp eller skapa ditt manus',
      InviteReaders: 'Bjud in dina läsare',
      InviteReadersStepDescription: 'Du bestämmer vem som får läsa',
      CollectFeedback: 'Samla in feedback',
      CollectFeedbackStepDescription: 'Analysera kommentarer och läsmönster',
      YouDontHaveAnyManuscripts: 'Du har ännu inga manuskript',
      AuthorsAndPublishers: 'Författare och förläggare',
      FindOutWhatYourReadersThink:
        'Ta reda på vad dina läsare verkligen tycker om din bok',
      ReadAndInfluenceTheNextBestseller: 'Läs och påverka kommande bästsäljare',
      PrivateSharing: 'Privat delning',
      PrivateSharingDescription: 'Dela din manuskript privat med läsarna',
      CollectUnbiasedFeedback: 'Samla opartisk feedback',
      CollectUnbiasedFeedbackDescription:
        'Låt dina läsare kommentera utan att se varandras feedback',
      AnalyzeReadingPatterns: 'Analysera läsmönster',
      AnalyzeReadingPatternsDescription:
        'Ta reda på var dina läsare tappar intresset eller sugs in i berättelsen',
      ReadItFirst: 'Läs det först',
      ReadItFirstDescription:
        'Läs kommande romaner medan de fortfarande är i beta',
      InfluenceTheStory: 'Påverka berättelsen',
      InfluenceTheStoryDescription:
        'Din feedback kommer att påverka slutresultatet',
      ConnectWithAuthors: 'Diskutera med författarna',
      ConnectWithAuthorsDescription:
        'Hjälp dina favoritförfattare genom att bli en del av deras betateam',
      YourBooks: 'Dina böcker',
      New: 'Ny',
      Members: 'Medlemmar',
      Contacts: 'Kontakter',
      AddMembersFromContacts: 'Lägg till medlemmar från kontaktlistan',
      ConversationSettings: 'Konversationsinställningar',
      PremiumAuthorInfo:
        'Det här är ett manuskript av en premiumförfattare. Detta innebär vanligtvis att författaren är mer investerad i projektet och att du kan förvänta dig att de ska göra vad som krävs för att denna bok ska publiceras.',
      StillWorkingOnThis:
        'Vi bygger fortfarande ut BetaReader.io. Låt oss veta om det finns något särskilt du vill se här.',
      Engagement: 'Engagemang',
      AssemblingLetters: 'Letar upp bokstäver...',
      ConstructingAlphabet: 'Bygger alfabet...',
      BuildingSentences: 'Sätter ihop meningar...',
      BecomingSentient: 'Bygger medvetande...',
      BooksRead: 'Lästa böcker: {{x}}',
      WordsReviewed: 'Ord granskas: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Genomsnittlig turn-around-tid per 1000 ord: {{x}} dag',
      AvgTurnAroundTimePer1kWords_plural:
        'Genomsnittlig turn-around-tid per 1000 ord: {{count}} dagar',
      ViewAsReader: 'Visa som läsare',
      EditMode: 'Redigeringsläge',
      BookCover: 'Bokomslag',
      WelcomeToBRReadathon: 'Välkommen till BetaReader.io Readathon!',
      ReadathonHasEnded: 'Readathon är nu avslutat.',
      ReadathonInfo:
        'BetaReader.io Readathon varar mellan den 26 september och den 31 oktober 2019. Den som läser och recenserar minst en bok på BetaReader.io under denna tid deltar i tävlingen, och du kan öka din chans att vinna genom att läsa och recensera fler böcker. Vinnaren dras den 1 november 2019. Ju fler ord du har läst och recenserat då, desto högre är din chans att vinna. Till exempel så har en som läst och granskat 50000 ord 5x så stor chans att vinna som någon som läst och granskat 10.000 ord.',
      ReadathonPrize1:
        '1:a pris: $50 presentkort på Amazon.com eller 500kr presentkort på Adlibris.',
      ReadathonPrize23:
        '2:a - 3:e pris: $20 presentkort på Amazon.com eller 200kr presentkort på Adlibris.',
      SignInToJoin: 'Logga in för att vara med',
      WordsReviewedByYou: 'Ord recenserade av dig',
      WordsReviewedByEveryone: 'Ord recenserade av alla läsare',
      ChanceOfWinning: 'Din chans att vinna just nu',
      Completed: 'Klar',
      CompletedAt: 'Klar',
      Pending: 'Väntande',
      Position: 'Position',
      FindSomethingToRead: 'Hitta något att läsa',
      DontKnow: 'Vet ej',
      IsThisReadyToPublish:
        'Tycker du att den här boken är redo att publiceras?',
      UpdateProfileHeader: 'Vi behöver lite mer information för din profil',
      WhatsYourName: 'Vad är ditt namn eller pseudonym?',
      WhereAreYouFrom: 'Var kommer du ifrån?',
      WhenWereYouBorn: 'Vilket år är du född?',
      WhichGenresDoYouPrefer: 'Vilka genrer föredrar du?',
      WhichLanguagesDoYouRead: 'Vilka språk läser du?',
      BioPlaceholder:
        'Berätta vem du är och om du har några webbplatser att hänvisa till. En starkare bio kommer att hjälpa dig att bli godkänd för fler manuskript.',
      Thanks: 'Tack!',
      WhenDoYouWantToHearFromUs: 'När vill du höra från oss?',
      ReaderListConsentHeader: 'Vill du ingå i vår läsardatabas?',
      ReaderListConsentInfo:
        'Författare med verk som matchar dina preferenser kommer att kunna skicka dig länken till sina böcker, men du är inte skyldig att läsa om du inte vill eller inte har tid.',
      RoleHeader: 'Är du en författare eller läsare?',
      RoleInfo:
        'Vi använder denna information för att optimera din upplevelse.',
      OnYourProfile: 'Detta kommer att visas på din användarprofil.',
      ProfileBuilderThankYouMessage:
        '<0>Tack!</0><1>Du kan alltid uppdatera din profil <1>här</1>.</1>',
      DoThisLater: 'Gör detta senare',
      '2MonthsFree': '2 månader gratis',
      Joined: 'Blev medlem',
      UserInfo: 'Användarinformation',
      Age: 'Ålder',
      Bio: 'Bio',
      ReaderList: 'Hitta läsare',
      Popular: 'Populär',
      BookHas0Words: '0 ord',
      BookHas0WordsDescription:
        '<0> Denna bok har åtminstone en version med 0 ord. Du kan lägga till eller ladda upp kapitel </0> <1> här </1>.',
      LeaveBeta: 'Lämna beta',
      DiscoverNewBooks: 'Upptäck nya böcker',
      FailedToFindReader: 'Det gick inte att hitta läsaren',
      ApprovedReadRequest: 'Läsbegäran har godkänts',
      Approved: 'Godkänd',
      UserAlreadyInvited: '{{user}} är redan inbjuden',
      SelectBook: 'Välj bok',
      InvitationMessagePlaceholder:
        'Inkludera ett personligt meddelande i din inbjudan för att öka dina chanser att få accepteras.',
      YouHaveAPendingInvitation: 'Du har en väntande inbjudan',
      ReaderIndexInfo:
        'Läsardatabasen innehåller BetaReader.io-användare som har angett att de är öppna för att bli kontaktade av författare. Skicka en inbjudan med din bästa pitch, men kom ihåg att de är fria att avböja din inbjudan av valfri anledning.',
      ReaderLimitReached: 'Du har nått max antal läsare',
      Invite: 'Bjud in',
      Languages: 'Språk',
      LinkWasSentToX: 'Vi har skickat en inloggningslänk till {{x}}',
      Verifying: 'Verifierar ...',
      FoundNoAvailableManuscripts: 'Vi hittade inga tillgängliga manuskript',
      FindASpecificQuestion: 'Filtrera frågor',
      ReadAndReviewBonusInfo:
        'Din READ & REVIEW-bonus uppdateras när du är klar och har recenserat en bok.',
      ManuscriptStatus: 'Manusstatus',
      MSStatusDescription_alpha:
        'Detta är ett manuskript i ett tidigt stadium eller delvis.',
      MSStatusDescription_beta:
        'Detta är ett komplett manus som närmar sig utgivning.',
      SelectStatus: 'Välj status',
      MaxAllowedGenres: 'Du kan bara välja upp till 5 genrer',
      TooLongDescription: 'Din beskrivning är för lång (max 1000 tecken)',
      Duplicate: 'Duplicera',
      Download: 'Ladda ner',
      DuplicateVersionInfo:
        'När du duplicerar en version kopieras alla kapitel och undersökningar till den nya versionen, men dina läsare blir kvar på den gamla versionen.',
      NoBookMsg:
        '<0> Det finns ingenting här! </0> <1> <0> Vill du </0> <1> lägga till ditt eget manuskript? </1> </1>',
      CommentsCopiedFromOriginal:
        'Kommentarer kopierades från originaldokumentet',
      CopyComments: 'Kopiera alla kommentarer',
      MigrateReaders: 'Migrera läsare',
      Posts: 'Inlägg',
      Sent: 'Skickat',
      Username: 'Användarnamn',
      UsernameAlreadyTaken: 'Det här användarnamnet är taget',
      Followers: 'Följare',
      ReadersAndFollowers: 'Läsare och följare',
      WhoCanSeeThis: 'Vem kan se det här?',
      FollowCount: '{{count}} följare',
      FollowCount_plural: '{{count}} följare',
      Follow: 'Följ',
      Following: 'Följer',
      MarkAllAsRead: 'Markera allt som läst',
      Working: 'Arbetar...',
      Audience: 'Publik',
      ShowAllComments: 'Visa alla kommentarer...',
      Publish: 'Publicera',
      InviteByEmail: 'Bjud in via e-post',
      InviteByLink: 'Bjud in via länk',
      Chat: 'Chatt',
      WelcomeToSupportMessage:
        'Hej {{x}}! Välkommen till Betareader support 👋,  vi försöker svara så fort vi kan. Vad kan vi hjälpa till med?',
      TalkToSupport: 'Prata med vårt supportteam',
      StartSupportChat: 'Starta supportchat',
      StartSupportChatPMessage:
        '<0> Du kan alltid nå oss via vår chat och vi svarar så fort vi kan. </ 0>',
      FindHelpArticlesMessage:
        '<0> Behöver du hjälp? kolla in våra <1> hjälpsidor </ 1>, här hittar du svar på de vanligaste frågorna. </ 0>',
      HelpArticles: 'Hjälpsidor',
      CloseConversationAlertHeader: 'Stäng konversation',
      CloseConversationAlertMessage: 'Vill du stänga denna konversation?',
      EmailSupportMessage:
        '<0> Skicka ett e-postmeddelande till <1> support@betareader.io </1>, så svarar vi så snart vi kan. </0>',
      Includes: 'Inkluderar',
      DoesNotInclude: 'Inkluderar ej',
      IncludesAll: 'Inkluderar alla',
      RemoveFilter: 'Ta bort filter',
      AddFilter: 'Lägg till filter',
      SelectGenres: 'Välj genre',
      And: 'Och',
      ContinueWithGoogle: 'Fortsätt med Google',
      ContinueWithFacebook: 'Fortsätt med Facebook',
      ContinueWithApple: 'Fortsätt med Apple',
      NewOnBetareader: 'Ny på Betareader?',
      AlreadyHaveAccount: 'Har du redan ett konto?',
      SignUpWithFacebook: 'Skapa konto med Facebook',
      SignUpWithGoogle: 'Skapa konto med Google',
      SignUpWithApple: 'Skapa konto med Apple',
      OpportunityWithoutDescriptionMessage:
        'Författaren har inte lagt till någon beskrivning.',
      SignInToViewContent: 'Logga in för att se innehållet.',
      ContentBelongsToAnotherAccount:
        'Innehållet hör till ett annat konto än det du är inloggad på.',
      SwitchAccount: 'Byt konto',
      OwnBooks: 'Egna böcker',
      Collaborations: 'Samarbeten',
      UploadImage: 'Ladda upp bild',
      SplitChapter: 'Dela kapitlet',
      UpgradeToUnlock: 'Uppgradera för att låsa upp',
      '3ReaderSlots': '3 läsarslots',
      UnlimitedReaderSlots: 'Obegränsat med läsare',
      ImportWarning: 'Importeringsvarning',
      ImportExeedLenghMessage:
        '<0><0>Ett eller flera av dina kapitel är för långa för att importera, vänligen läs våra<1> import guidelines. </1></0><1>Tips: genom att hovra över kapiteltexten kan får du tillgång till saxverktyget för att dela större kapitel</1></0>',
      OneTeamMember: '1 lagmedlem',
      AdditionalTeamMembers: 'Lägg till teammedlemmar',
      PerMonth: 'per månad',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Skicka in ditt manus',
      SubmitTo: 'Skicka till',
      YourSubmittedManuscripts: 'Dina inskickade manus',
      SelectManuscriptToSubmit: 'Välj manus att skicka in',
      AvailableToRead: 'Tillgänglig att läsa',
      ManuscriptSubmissionSuccess: 'Manuset är skickat',
      ManuscriptSubmittedTo:
        'Ditt manus, {{_manuscriptName}}, har skickats till {{_inboxName}}',
      SubmittedBy: 'Inskickat av {{_userName}}',
      ReceivedManuscripts: 'Mottagna manus',
      InviteToRead: 'Bjud in att läsa',
      YourReview: 'Din recension',
      UserReviewPlaceholder: 'Användarrecensioner är synliga för alla.',
      LeaveReviewError:
        'Du kan bara recensera användare som har läst färdigt minst ett av dina manus.',
      NotEnoughData: 'Inte tillräckligt med data',
      EditSignUpSurveyQuestions: 'Redigera anmälningsfrågor',
      AddSignUpSurveyQuestions: 'Lägg till anmälningsfrågor',
      To: 'Till',
      FinishedReadingAt: 'Läste färdigt {{x}}',
      FailedToSendInvitation:
        'Det gick inte att skicka inbjudan, vänligen verifera epostadressen',
      EditInvitation: 'Redigera inbjudan',
      ChapterSeparator: 'Kapitelavgränsare',
      CancelReason: 'Innan du fortsätter, skulle du vilja berätta varför?',
      cancel_reason_technical_issues: 'Tekniska problem',
      cancel_reason_too_expensive: 'Produkten är för dyr',
      cancel_reason_finished_my_beta: 'Jag avslutade mitt projekt',
      cancel_reason_switching_to_another_product:
        'Jag byter till en annan produkt',
      cancel_reason_missing_features: 'Jag saknar viktiga funktioner',
      cancel_reason_did_not_meet_expectations:
        'Plattformen uppfyllde inte mina förväntningar',
      cancel_reason_other: 'Annan anledning',
      TellUsMore: 'Berätta mer',
      DeleteAccount: 'Ta bort konto',
      YourAccountHasBeenDeleted: 'Ditt konto har tagits bort',
      DeleteAccountConfirmation:
        'Är du säker på att du vill radera ditt konto och all dess data? Denna åtgärd kan inte ångras.',
      GoodbyeMessage:
        'Vi beklagar att du lämnar oss och hoppas att du kommer tillbaka i framtiden.',
      EditContent: 'Redigera innehåll',
      AffectedReadersMessage:
        '{{count}} läsare har redan läst detta kapitel. Klicka här för att skicka en uppdatering om dina ändringar.',
      AffectedReadersMessage_plural:
        '{{count}} läsare har redan läst det här kapitlet. Klicka här för att skicka en uppdatering om dina ändringar.',
      ImportingChapterXofY: 'Importerar kapitel {{_x}} / {{_y}}',
      ImportStarted: 'Importen startad',
      XVersions: '{{count}} version',
      XVersions_plural: '{{count}} versioner',
      UnsubscribeSuccessful:
        'Du har avslutats. Du kan alltid uppdatera dina preferenser via din profil.',
      UnsubscribeUnsuccessful:
        'Det gick inte att avsluta prenumerationen. Skicka ett meddelande till support@betareader.io om problemet kvarstår.',
      GoodbyeHeader: 'Hejdå!',
      BulkAddReaders: 'Lägg till läsare från CSV',
      Home: 'Hem',
      Actions: 'Åtgärder',
      Invitations: 'Inbjudningar',
      SelectCSVWithReaders: 'Välj CSV med läsare',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'Låt vår AI, BetaBot, generera en synopsis baserat på din text. Det kommer inte att vara synligt för dina läsare.',
      LastUpdated: 'Regenerera synopsis',
      CreateSynopsis: 'Skapa synopsis',
      RefreshSynopsis: 'Uppdatera synopsis',
      ElevatorPitch: 'Hisshöjd',
      ThisIsAPremiumFeature: 'Premiumfunktion',
      GoBack: 'Gå tillbaka',
      Retry: 'Försöka igen'
    }
  },
  da: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Bemærk venligst, at når du sender en læser dit brugernavn og ikke din pseudonym, vil være synligt',
      Library: 'Bibliotek',
      Account: 'Konto',
      EmptyLibrary: 'Dit bibliotek er tom.',
      EmptyLibraryAction:
        'Var du forventer at finde noget her? Kontakt forfatteren, eller send os en e-mail på {{_email}}, og vi vil se, hvad vi kan gøre.',
      ByAuthor: 'af {{_authorName}}',
      ExceedChapterLenghtWarning: 'Kapitlet er for langt til at importere',
      WordCount: '{{_wordCount}} ord',
      WordCountString: '{{count}} ord',
      WordCountString_plural: '{{count}} ord',
      CharCountString: '{{count}} tegn',
      CharCountString_plural: '{{count}} tegn',
      SurveyCount: '{{count}} undersøgelse',
      SurveyCount_plural: '{{count}} undersøgelser',
      ChapterCount: '{{count}} kapitel',
      ChapterCount_plural: '{{count}} kapitler',
      QuestionCountString: '{{count}} spørgsmål',
      QuestionCountString_plural: '{{count}} spørgsmål',
      LoginToChangeEmail: 'Venligst logge ind igen for at ændre din e-mail',
      SendVerification: 'Send bekræftelseslink',
      Name: 'Navn',
      Email: 'E-mail',
      EnterYourName: 'Vær sød at skrive dit navn',
      ReceiveCompanyUpdates: 'Modtag e-mails og nyheder fra BetaReader',
      ReceiveBookEmails: 'Modtag e-mails om nye bøger',
      ReceiveBookActivityUpdates: 'Modtag e-mails om aktivitet på mine bøger',
      ReceiveMessageUpdates: 'Modtag e-mails, når jeg får nye meddelelser',
      IncludeProfileInReaderList: 'Inkluder min profil i læserdatabasen',
      PrivacyPolicy: 'Fortrolighedspolitik',
      TermsOfService: 'Servicevilkår',
      ReadOurBlog: 'Læs vores blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Næste',
      Back: 'Tilbage',
      CreateAccount: 'Opret konto',
      CreateAccountPrompt:
        '<0>Har du ikke en konto?<1> <0>Opret en her!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Har du glemt din adgangskode?<1> <0>Nulstil det her!</0></1></0>',
      SignIn: 'Log ind',
      SignOut: 'Log ud',
      SendResetLink: 'Send link til nulstilling',
      ResetPassword: 'Nulstille kodeord',
      Password: 'Adgangskode',
      OrUse: 'eller brug',
      NoConnection: 'Offline ...',
      AcceptTermsMessage: 'Acceptér servicevilkårene',
      AcceptToSToCreateAccount:
        'Du er nødt til at acceptere vores servicevilkår for at oprette en konto.',
      Answer: 'Svar',
      FailedToLoadBook: 'Kunne ikke indlæse bogen.',
      PullDownToRefresh: 'Træk ned for at opdatere.',
      ContactSupport: 'Kontakt support',
      Support: 'Support',
      LastRead: 'Sidste læsning',
      XInvitations: '{{count}} invitation',
      XInvitations_plural: '{{count}} opfordringer',
      EnterComment: 'Kommenter...',
      EnterReply: 'Svar...',
      Reply: 'Svar',
      Reply_plural: 'Svar',
      Reply_verb: 'Svar',
      show: 'vis',
      hide: 'skjul',
      Comments: 'Kommentarer',
      InlineComment: 'Inline kommentar',
      Reject: 'Afvise',
      Accept: 'Acceptere',
      Error: 'Fejl',
      failed: 'mislykkedes',
      SelectedText: 'Markeret tekst',
      FailedToFetchUserProfile: 'Siden kunne ikke hentes brugerprofil.',
      ProblemPersistContactSupport:
        'Hvis problemet fortsætter, kontakt support på:',
      clickToAddComment: 'Klik her for at tilføje kommentar',
      ColorMode: 'Farve-tilstand',
      FontSize: 'Skriftstørrelse',
      Discover: 'Opdage',
      MyAccount: 'Min konto',
      MyManuscripts: 'Mine manuskripter',
      Sweden: 'Sverige',
      USA: 'USA',
      UK: 'Storbritannien',
      Germany: 'Tyskland',
      France: 'Frankrig',
      Spain: 'Spanien',
      Country: 'Land',
      Gender: 'Køn',
      YoB: 'Fødselsår',
      PreferredGenres: 'Foretrukne genrer',
      PreferredLanguages: 'Foretrukne sprog',
      EmailPreferences: 'E-mail-præferencer',
      chapters: 'kapitler',
      words: 'ord',
      YouShouldBetaRead: 'Du burde læse',
      onBetaReader: 'på BetaReader.io',
      Share: 'Dele',
      NoGuidelines:
        'Forfatteren har ikke givet nogen retningslinjer for feedback.',
      Read: 'Læse',
      on: 'på',
      Author: 'Forfatter',
      JoinBeta: 'Jeg vil gerne læse dette',
      joinConsentString:
        'Jeg forstår, at ved at anmode om at deltage i denne beta, vil jeg dele min e-mailadresse med forfatteren.',
      Submit: 'Indsend',
      AnswerSurvey: 'Svar!',
      SendJoinRequest: 'Sende',
      IJustWantToRead: 'Jeg vil bare læse',
      CopyrightInfo: 'Copyright info',
      RequestToRead: 'Send læseanmodning',
      Feedback: 'Feedback',
      NoComment: 'Ingen har kommenteret.',
      OnceUponATime: 'Der var engang...',
      Review: 'Gennemgå',
      Overall: 'Samlet set',
      Plot: 'Intrige',
      Grammar: 'Grammatik',
      Title: 'Titel',
      AddReview: 'Tilføj en anmeldelse',
      EnterReviewText: 'Indtast en kort anmeldelse',
      OverallRating: 'Samlet set',
      OverallRatingRequired: 'Tilføj samlet vurdering',
      PlotRating: 'Intrige',
      GrammarRating: 'Grammatik',
      SubmitReview: 'Send anmeldelse',
      LeaveReview: 'Skriv anmeldelse',
      checkOutAuthorApp:
        '<0>Er du en forfatter?</0><1></1><2>Tjek vores <1>app til forfattere</1>!</2>',
      MyLibrary: 'Mit bibliotek',
      ReadingPreferences: 'Læsepræferencer',
      'E.g.': 'F.eks.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Jeg ønsker at modtage e-mails fra BetaReader.io, når forfatteren foretager ændringer eller opdateringer til',
      'I want to receive emails about BetaReader news and updates.':
        'Jeg vil gerne modtage e-mails om BetaReader nyheder og opdateringer.',
      'I want to receive emails about new books that match my reading preferences.':
        'Jeg vil gerne modtage e-mails om nye bøger, der matcher mine læsepræferencer.',
      'this book': 'denne bog',
      'Email us on': 'Email os på',
      "and we'll see what we can do :)": 'og så må vi se hvad vi kan gøre :)',
      'Want an author account?': 'Vil du have en forfatterkonto?',
      Preferences: 'Præferencer',
      Language: 'Sprog',
      Browse: 'Gennemse',
      'to beginning': 'til begyndelse',
      'to chapter...': 'til kapitel...',
      Location: 'Beliggenhed',
      Previous: 'Tidligere',
      Finish: 'Slutte',
      Description: 'Beskrivelse',
      FeedbackGuidelines: 'Feedbackretningslinjer',
      'Start reading': 'Begynd at læse',
      StartReading: 'Begynd at læse',
      LeaveComment: 'Kommentar',
      replies: 'svarer',
      Show: 'Vise',
      Hide: 'Skjule',
      'Add Reply': 'Tilføj svar',
      PendingInvitation: 'Afventer invitation',
      Decline: 'Afvise',
      DeclinedInvitationMessage:
        'Du har afvist denne invitation til at læse, men du kan stadig acceptere den, hvis du har ombestemt dig.',
      Welcome: 'Velkomst',
      by: 'af',
      to: 'til',
      AcceptInvitation: 'Accepter invitation',
      InvitationAccepted: 'Invitation accepteret',
      WaitForBook: 'Vent venligst, mens vi forbereder din bog',
      PasswordLength: 'Dit kodeord skal være på mindst 6 tegn',
      AgreeToPolicyAndTerms:
        'Du skal acceptere BetaReaders privatlivspolitik og servicevilkår for at tilmelde dig.',
      'Sending...': 'Sender...',
      VerificationSent: 'Bekræftelsesmail sendt.',
      VerificationEmailFailed: 'Kunne ikke sende bekræftelses-e-mail.',
      IAcceptToC:
        '<0>Jeg accepterer BetaReader.io:s <1>Privatlivspolitik</1> & <3>Servicebetingelser og vilkår</3></0>',
      AccountSettings: 'Kontoindstillinger',
      ContactSettings: 'Kontaktindstillinger',
      Chapters: 'Kapitler',
      Options: 'Valgmuligheder',
      Option: 'Valgmulighed',
      BetaLanguageMessage:
        '<0>Vi arbejder på en forbedret sprogstøtte, og er super taknemmelig for enhver hjælp vi kan få. Hvis du finder fejl på sitet, er du velkommen til at kontakte os på: <1>hello@betareader.io</1></0>',
      Genres: 'Genrer',
      PressEnterToSave: 'Tryk på Enter for at gemme',
      female: 'kvinde',
      male: 'mand',
      other: 'ikke-binær',
      DontWantToShare: 'privat',
      SignInWithPassword: 'Log ind med adgangskode',
      GoToBook: 'Gå til bog',
      Requested: 'Din anmodning afventer',
      AnsweredOrRequestedNotice:
        'Du har anmodet om at deltage i denne beta. Forfatteren vil vende tilbage til dig, så snart de kan.',
      RequestedWithDifferentEmail:
        'Du har anmodet om at deltage i denne beta med en anden e-mail, end du blev inviteret med. Forfatteren vil vende tilbage til dig, så snart de kan.',
      LoadingLibrary: 'Leder efter bøger...',
      PreparingBook: 'Forbereder bog',
      InLineCommentPlaceholder:
        'Efterlad din kommentar og tryk på enter for at gemme',
      ExitSurvey: 'Exit-undersøgelse',
      WhyExitQuestion: 'Hvorfor vil du forlade denne beta?',
      LostInterest: 'Jeg mistede interessen',
      NotEnoughTime: 'Jeg har ikke tid nok',
      Other: 'Anden grund',
      reason_lost_interest: 'Jeg mistede interessen',
      reason_no_time: 'Jeg har ikke tid nok',
      reason_other: 'Anden grund',
      reason_: 'Ikke specificeret',
      ExitFeedbackQuestion: 'Vil du give en feedback til forfatteren?',
      ExitFeedbackPlaceholder: 'Al feedback er nyttig',
      ThankYouForYourFeebdack: 'Tak for din feedback!',
      AppVersion: 'Version',
      AppBuildNumber: '#',
      Like: 'Like',
      Love: 'Kærlighed',
      Laugh: 'Haha',
      Surprise: 'Overraskelse',
      Sad: 'Sørgelig',
      Angry: 'Vred',
      NoBooksInList: 'Ingen bøger her.',
      Update: 'Opdater',
      Overview: 'Oversigt',
      Content: 'Tilfreds',
      Readers: 'Læsere',
      NotificationSettings: 'Notifikationsindstillinger',
      SendEmailOnComments: 'Send e-mail, når læserne skriver kommentarer',
      SendEmailOnSurveyComplete:
        'Send e-mail, når læserne har gennemført en undersøgelse',
      CoverUploadMsg:
        'Klik for at uploade dit forsidebillede (png eller jpg, 980x1568px), eller slip det i dette område.',
      DefaultNamePlaceholder: 'Standard: {{displayName}}',
      SelectUpTo5Genres: 'Vælg op til 5 genrer',
      AdvancedSettings: 'Avancerede indstillinger',
      EnableComments: 'Aktiver kommentarer',
      EnableBookReviews: 'Aktiver boganmeldelser',
      DeleteBook: 'Slet bogen',
      DeleteCover: 'Slet bogomslaget',
      DeleteCoverConfirmation: 'Er du sikker på, at du vil slette bogomslaget?',
      YesBinIt: 'Ja, slet den!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Er du sikker på at du vil slette <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} er blevet slettet.',
      No: 'Ingen',
      Yes: 'Ja',
      UserCommentedOn: 'kommenterede',
      ShowXReplies: 'Vis {{count}} svar',
      ShowXReplies_plural: 'Vis {{count}} svar',
      HideXReplies: 'Skjul {{count}} svar',
      HideXReplies_plural: 'Skjul {{count}} svar',
      NoInLineComments: 'Ingen indlejrede kommentarer.',
      Chapter: 'Kapitel',
      NoCommentsToShow: 'Ingen kommentarer at vise.',
      Save: 'Spare',
      TooLongTitle: 'Din titel er for lang (maks. 60 tegn)',
      ClickToUploadDoc:
        'Klik for at tilføje eller slippe din docx-fil for at importere den',
      LongChapterWarning: 'Langt kapitel, er dette korrekt?',
      Upload: 'Upload',
      UploadChapters: 'Upload kapitler',
      ImportBooksHelpMsg:
        'Ingen vil kunne se dit manuskript uden din godkendelse. Læs om at <1> importere bøger </1>.',
      Add: 'Tilføje',
      NewChapter: 'Nyt kapitel',
      NewSurvey: 'Ny undersøgelse',
      SelectedPart: 'valgt del',
      SelectPart: 'Vælg del',
      NotifyMe: 'Giv mig besked',
      WhenReadersStart: 'ved start',
      WhenReadersComplete: 'på ende',
      ManageChapter: 'Administrer kapitel',
      ManageSurvey: 'Administrer undersøgelse',
      ManageReaders: 'Administrer læsere',
      MoveUp: 'Flyt op',
      MoveDown: 'Flyt ned',
      SaveSurvey: 'Gem undersøgelse',
      Saved: 'Gemt',
      EnterSurveyName: 'Giv din undersøgelse et navn',
      EnterSurveyDescription: 'Beskriv din undersøgelse',
      SelectAtLeast: 'Vælg mindst',
      SelectAtMost: 'Vælg højst',
      LabelOptional: 'Etiket (valgfrit)',
      MyBooks: 'Mine bøger',
      ChangeRoleToWriterToCreateBooks:
        'Skift venligst rolle til forfatter for at skabe bøger.',
      UsingXOutOfYBooks: 'Du bruger {{x}} ud af {{y}} bøger.',
      UsingXOutOfYReaders: 'Du bruger {{x}} ud af {{y}} læsere',
      NothingHere: 'Intet her i øjeblikket.',
      OpenReaderWeb: 'Åbn læseren',
      PrivacyLevel: 'Delingsstatus',
      PublishingStatus: 'Delingsstatus',
      PrivacyLevel_infotext_unpublished:
        '<0> Din bog er offline. Flyt den til <1> privat </1>, <3> lukket </3> eller <5> listet </5> for at læserne skal kunne finde den. </0>',
      PrivacyLevel_infotext_private:
        '<0> Din bog er ikke opført nogen steder. Kun du kan invitere læsere. </0>',
      PrivacyLevel_infotext_closed:
        '<0> Din bog præsentation kan ses af alle med <1> linket </1>. Læsere kan anmode om en invitation, men du skal godkende hver enkelt anmodning. </0>',
      PrivacyLevel_infotext_listed:
        '<0> Din bog er opført <1>her</1>. Læsere kan anmode om en invitation, men du skal godkende hver enkelt anmodning. </0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Privat',
      PrivacyLevel_state_closed: 'Lukket',
      PrivacyLevel_state_listed: 'Opført',
      SendReminder: 'Send påmindelse',
      YouCanSendAReminderEvery3Days:
        'Du kan sende en påmindelse hver tredje dag',
      LastSeenAt: 'Sidst set på',
      ReminderSentAt: 'Påmindelse sendt',
      XReadersInactiveForYDays:
        '{{count}} læser har ikke færdig med bogen og har ikke været aktivitet de seneste {{days}} dage.',
      XReadersInactiveForYDays_plural:
        '{{count}} læsere har ikke færdig med bogen og har ikke været aktivitet de seneste {{days}} dage.',
      InactiveReaders: 'Inaktive læsere',
      XReadersNotStartedForYDays:
        '{{count}} læser har ikke accepteret din flere end {{days}} dage gammel invitation',
      XReadersNotStartedForYDays_plural:
        '{{count}} læsere har ikke accepteret din flere end {{days}} dage gammel invitation',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Læsere, der ikke har accepteret invitationen',
      YouHaveXJoinRequests: 'Du har {{count}} opfordring til at læse din bog',
      YouHaveXJoinRequests_plural:
        'Du har {{count}} anmodninger om at læse din bog',
      JoinRequests: 'Læsanmodninger',
      Approve: 'Godkende',
      RequestedAt: 'Anmodet',
      NoAnswer: 'Intet svar',
      SendSurveyReminder: 'Send undersøgelse-påmindelse',
      Remove: 'Fjerne',
      Created: 'Lavet',
      RequestedToRead: 'Anmodede om at læse',
      InvitationSent: 'Invitation sendt',
      Reminded: 'Mindet',
      Question: 'Spørgsmål',
      YouCanAskUpToXQuestions:
        'Du kan tilføje op til {{maxQuestions}} spørgsmål, som læserne er forpligtet til at svare, når du tilmelder dig.',
      NewQuestion: 'Nyt spørgsmål',
      ReaderSignUpForm: 'Tilmeldingsformular',
      NoReadersFound: 'Ingen læsere fundet',
      ReachedPartTitle: 'Nået {{partTitle}}',
      ReasonToQuit: 'Årsag',
      YourBookDoesNotHaveAnyChaptersYet: 'Din bog har endnu ingen kapitler',
      Invited: 'Inviteret',
      Started: 'Startede',
      Finished: 'Færdig',
      Abandoned: 'Forladt',
      AvgOverallRating: 'Samlet set',
      AvgPlotRating: 'Intrige',
      AvgGrammarRating: 'Grammatik',
      YouDoNotHaveReviewsYet: 'Du har ingen anmeldelser endnu.',
      PersonalLink: 'Personlig link',
      HelpUsReachMoreWriters:
        'Kan du lide at bruge BetaReader.io? Brug dit personlige link til at hjælpe os med at hjælpe flere forfattere!',
      Reports: 'Rapporter',
      WeNeedYourEmail: 'Email nødvendig',
      MissingEmailMsg:
        'Vi skal være i stand til at kontakte dig angående din konto. Vi deler det ikke med nogen og kontakter dig kun, hvis det er strengt nødvendigt.',
      EmailNotVerifiedPrompt:
        'E-mail ikke bekræftet. <1> Klik her for at sende </1> kontrollen email. (Tjek din spam-mappe, hvis du ikke modtager den)',
      Skip: 'Springe',
      RoleWriter: 'Forfatter',
      RoleReader: 'Læser',
      RoleBoth: 'Begge',
      AddReader: 'Tilføj ny læser',
      DownloadReaderList: 'Download læseliste',
      'Show only': 'Vis kun',
      StatusNEW: 'Ny',
      StatusTODO: 'At gøre',
      StatusDOING: 'Gør',
      StatusDONE: 'Færdig',
      StatusIGNORE: 'Ignorere',
      RemoveFilters: 'Fjern filtre',
      ReadingDataReport: 'Læser data',
      SurveyAnswersReport: 'Undersøgelsessvar',
      Reviews: 'Anmeldelser',
      AbandonedReadersReport: 'Mistede læsere',
      SignUpMsg:
        '<0>Tak for tilmeldingen!</0> <1>En bekræftelses-e-mail er sendt til <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Kopieret {{x}} til udklipsholderen.',
      DoYouWantToBeMyBetaReader: 'Vil du være min beta-læser?',
      LookingForBetaReadersForBookTitle:
        'Jeg leder efter beta læsere for {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Jeg leder efter beta læsere for {{bookTitle}}. Følg dette link for at læse mere og tilmelde dig: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Social deling er ikke mulig for private bøger.',
      ShareTheLinkToYourBook: 'Del linket til din bog!',
      Messages: 'Beskeder',
      FailedToLoadMessage: 'Vi kunne ikke indlæse denne besked.',
      FailedToSendMessage: 'Vi kunne ikke sende din besked.',
      FailedToStartConversation: 'Det lykkedes ikke at starte samtalen.',
      NewMessage: 'Ny besked',
      MessageSubject: 'Emne',
      MessageBody: 'Skriv din besked...',
      MessageFrom: 'Fra',
      MessageTo: 'Til',
      SendMessage: 'Sende',
      Message: 'Besked',
      ReaderMarketplaceBetaInfoText:
        'Vi eksperimenterer med at lade vores forfattere betale almindelige læsere og bogorme en symbolsk sum for tjenesten med at læse et manuskript. Vi tilbyder kontrolmekanismer til at validere feedbacken og kontrollere, at læserne har læst hele manuskriptet, før nogen bliver betalt. Hvis du vil prøve denne funktion, skal du angive, hvor mange læsere du har brug for.',
      ReaderMarketplaceBetaFullText:
        'Dette program er i øjeblikket fyldt. Vil du have, at vi giver dig besked, når vi får en åbning?',
      Sum: 'Sum',
      XUSDPerReader: '${{x}} / læser',
      AmountOfReaders: 'Antal læsere',
      PayForTestReaders: 'Betal for testlæsere',
      GoToCheckout: 'Gå til kassen',
      YesPlease: 'Ja tak!',
      BETA: 'BETA',
      NoMessages: 'Du har ingen beskeder',
      OnBoarding_import_book:
        '<0> Velkommen til BetaReader.io! </0> For at dele din bog, skal du først importere den, enten ved <2> uploader det </2>, eller ved at skabe det direkte på platformen. Gå videre og prøve det! Alt på BetaReader.io er privat som standard, så ingen vil se det, medmindre du invitere dem.',
      Onboarding_book_overview:
        'Bogoversigten er der, hvor du konfigurerer detaljer om din bog. Retningslinjer for titel, genrer og feedback findes her, men også om du ønsker, at læserne skal kunne kommentere og skrive anmeldelser.',
      Onboarding_book_content:
        'Fanen Indhold er der, hvor du styrer tingenes rækkefølge. Tilføj dine kapitler og undersøgelser her.',
      Onboarding_book_readers:
        'Fanen Læsere er der, hvor du styrer, hvem der skal læse din bog. Send private invitationer, del dit tilmeldingslink via Twitter, eller føj din bog til vores Discovery-liste for at nå ud til flere læsere.',
      Onboarding_book_feedback:
        'Fanen Feedback giver dig et overblik over al den feedback, som læserne har givet. Svar på kommentarer og filtrer uvæsentlige ting væk.',
      Onboarding_book_reports:
        'Fanen Rapport giver dig et overblik over, hvordan din bog klarer sig. Hvor mange læsere er blevet inviteret, hvor mange er begyndt at læse, efterladt rosende anmeldelser og så videre.',
      Close: 'Tæt',
      Last: 'Sidst',
      DuplicatedBook: '<0> <0> </0> Kopieret bog {{bookTitle}} </0>',
      PenName: 'Pennavn',
      MissingNameMsg:
        'Du skal indtaste et navn for at kunne kommunikere med dine læsere.',
      NameConversation: 'Navngiv denne samtale',
      With: 'med...',
      SelectPeople: 'Vælg personer',
      UploadAsNewVersion: '...som ny version',
      UploadAsExtraChapters: '...til denne version',
      Cancel: 'Ophæve',
      Unnamed: 'unavngivne',
      NewVersion: 'Ny version',
      NoContacts: 'Ingen kontakter',
      GetContacts:
        'Forfattere af de bøger, du læser, og læsere af de bøger, du skriver, bliver dine kontakter.',
      NoConversations: 'Ingen samtaler...',
      AllVersionsNeedContent:
        'Din nuværende version har brug for indhold, før du kan oprette en ny.',
      InviteToVersion: 'Inviter til version',
      latestVersion: 'seneste',
      VersionName: 'Version {{x}}',
      Reader: 'Læser',
      StartedReading: 'Begyndte at læse',
      CurrentPosition: 'Nuværende stilling',
      PickVersion: 'Vælg version',
      Filters: 'Filtre',
      Versions: 'versioner',
      ChapterInitial: 'k',
      AvgTimeToComplete: 'Gennemsnitlig tid til at læse kapitel',
      ReaderProgress: 'Stillinger',
      SelectFilter: 'Vælg et filter',
      CreatePost: 'Del en opdatering',
      NewBookPostPlaceholder: 'Hvad vil du sige?',
      BookUpdateForX: 'Opdatering for bog {{x}}',
      OnSaveChapterMessage: 'Fortæl dine læsere, hvad du har ændret',
      SampleChapterUpdatePost: 'Jeg ændrede dette...',
      SaveWithoutPosting: 'Gem uden at sende...',
      SaveAndPost: 'Gem og post...',
      DiscoverSearch: 'Søg efter en titel eller genre...',
      LoadMore: 'Indlæs mere...',
      PendingReadInvitations: 'Afventende invitationer',
      PendingReadRequests: 'Afventende anmodninger',
      UserReactedOn: 'reagerede på',
      ChapterComment: 'Kapitelkommentar',
      InlineCommentNotFound:
        'Denne kommentar blev ikke fundet i teksten. Er den kommenterede tekst blevet fjernet?',
      Or: 'Eller',
      Version: 'Version',
      Updates: 'Opdateringer',
      CurrentBetaIsFullMessage:
        'Denne beta er i øjeblikket fuld, kontakt venligst forfatteren for at åbne flere slots.',
      Edit: 'Redigere',
      Delete: 'Slet',
      DropFilesHere: 'Slip dine filer her',
      ShowOnly: 'Vis kun',
      FeedbackSearch: 'Tekstsøgning',
      'BuySubscriptionToS&PPMessage':
        '<0> Køb et abonnement fra BetaReader.io angiver, at du har læst og accepteret vores <1> servicevilkår </ 1> & <5> privatlivspolitik </ 5> </ 0>',
      Inbox: 'Indbakke',
      CofirmMovePart:
        'Vil du virkelig flytte denne del? Det vil påvirke følgende læsere:',
      'AreYouSure?': 'Er du sikker?',
      LetYourReadersKnowWhatYouChanged:
        'Fortæl dine læsere, hvad du har ændret',
      ThisChangeAffectsTheFollowingReaders:
        'Denne ændring påvirker følgende læsere:',
      MovedXFromYtoZ: 'Flyttet {{x}} fra {{y}} til {{z}}',
      Subscription: 'Abonnement',
      Subscriptions: 'Abonnementer',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Grundplan, for altid fri.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'God til hobby-perfektionister',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription:
        'Fantastisk til professionelle indieforfattere',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Perfekt til udgivere',
      CancelSubscription: 'Annuller abonnement',
      YourSubscriptionHasBeenCancelled: 'Dit abonnement er blevet annulleret',
      CancelSubscriptionConfirmation:
        'Er du sikker på, at du vil annullere dit abonnement?',
      ValidUntil: 'Gyldig indtil',
      UpgradeSubscriptionTitle: 'Opgrader',
      UpgradeSubscriptionPromoText: 'Opgrader din konto',
      Abort: 'Afbryd',
      BuyNow: 'Købe',
      Current: 'Nuværende plan',
      Monthly: 'Månedlige',
      Yearly: 'Årlig',
      monthly: 'månedlige',
      yearly: 'år',
      month: 'måned',
      XCheaper: '{{x}} billigere!',
      Downgrade: 'Nedgrader',
      Forever: 'for evigt',
      ChangePlan: 'Skift plan',
      TrackReaderProgress: 'Følg dine læsere i realtid',
      InlineComments: 'In-line kommentarer og reaktioner',
      ReaderSurveys: 'Læserundersøgelser',
      NativeApps: 'Android og iOS app',
      MultipleVersions: 'Manuskriptversionering',
      '1ActiveManuscript': '1 manuskript',
      '5ActiveManuscripts': 'Op til 5 manuskripter',
      '20ActiveManuscripts': 'Op til 20 manuskripter',
      UnlimitedActiveManuscripts: 'Ubegrænset manuskripter',
      '3ActiveReadersPerManuscript': 'Op til 3 læsere',
      '20ActiveReadersPerManuscript': 'Op til 20 læsere',
      '50ActiveReadersPerManuscript': 'Op til 50 læsere',
      UnlimitedActiveReaders: 'Ubegrænset antal læsere',
      Zero: 'Nul',
      NoCreditCardNeeded: 'Intet kreditkort kræves',
      Recommended: 'Anbefales',
      SomethingWentWrong: 'Noget gik galt',
      SubscriptionChangeError: 'Kunne ikke ændre abonnement',
      SubscriptionChangeSuccess: 'Vi har opdateret dit abonnement',
      ActionCancelled: 'Handlingen blev annulleret',
      Cancelled: 'Annulleret',
      NextPayment: 'Næste betaling',
      PaymentPastDueMessage:
        'Din betaling er forfalden. Næste forsøg: {{nextAttempt}}',
      ChangePaymentInformation: 'Skift faktureringsoplysninger',
      DashboardWelcomeMessage:
        '<0> Velkommen til BetaReader.io! </ 0> <1> Start med at <2> upload </ 2> eller <6> tilføje </ 6> dit manuskript </ 1> <2> Discovery </ 2> < 3> Glem ikke at liste dit manuskript i vores <2> opdagelse </ 2> sektionen, at få adgang til mere beta læsere. </ 3> <4> <0> Her </ 0> er der en hurtig gennemgang af, hvordan man gøre det. </ 4> <5> Support </ 5> <6> Venligst tjek vores <2> help </ 2> portal eller pinge os direkte ved at trykke på (?) øverst til højre på siden. < / 6> <7> Du kan altid sende os en venlig mail på <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Manuskripter',
      CurrentlyReading: 'Læser i øjeblikket',
      WeeklyReaders: 'Ugentlige læsere',
      TotalReaders: 'Samlede læsere',
      FailedToDownloadReport: 'Rapporten kunne ikke downloades',
      WaitingForConfirmation: 'Venter på bekræftelse',
      ChangePublishingStatusToAddReaders:
        'Skift udgivelsesstatus for at tilføje læsere',
      SuggestASwap: 'Foreslå en bytte',
      WithdrawRequest: 'Tilbagekald anmodning',
      Confirm: 'Bekræfte',
      ThisIsASwapRequestForX:
        'Dette er en bytteforespørgsel til <2>{{x}}</2>. Hvis du godkender bytteforespørgslen, bliver du tilføjet som læser på {{x}}.',
      ApprovedSwapRequestForX:
        'Dette er en bytteforespørgsel til <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Dette er en bytteanmodning. Hvis du godkender denne anmodning, vil du blive tilføjet som læser for:',
      SwapRequest: 'Anmodning swap',
      ApproveAndJoin: 'Godkend og deltag',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} har tilbudt at læse {{y}} i bytte for at læse denne bog.',
      GoToSwapRequest: 'Gå til bytteforespørgsel',
      SwitchVersion: 'Skift version',
      SendMagicLinkToX: 'Send et magisk link til {{x}}',
      notification_verb_comment: 'kommenterede på',
      notification_verb_react: 'reagerede på',
      notification_verb_reply: 'svarede på en kommentar til',
      notification_verb_abandon: 'opgav din bog',
      notification_verb_request_to_join: 'har bedt om at læse',
      notification_verb_message: 'har sendt dig en besked',
      notification_verb_sent_join_approval:
        'har godkendt din anmodning om at læse',
      notification_verb_invitation_to_join: 'inviterede dig til at læse {{y}}',
      notification_verb_manuscript_submitted:
        'har indsendt et manuskript til {{y}}',
      'SaveAnd...': 'Gem og...',
      Send: 'Sende',
      OnlySave: 'Gem kun',
      YourNotifications: 'Dine meddelelser',
      Notifications: 'Underretninger',
      Images: 'Billeder',
      '365DaysReadingData': '365 dages læserhistorie',
      '90DaysReadingData': '90 dages læserhistorie',
      '30DaysReadingData': '30 dages læserhistorie',
      alerts_PaymentPastDue:
        'Din betaling er forfalden. Opdater dine faktureringsoplysninger',
      alerts_MissingName:
        'Vi mangler dit navn (eller pennenavn). Opdater den her:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} tilbage for at gøre krav på din levetidsrabat. Indtast LAUNCH i kuponfeltet, når du opgraderer:',
      RequestedToSwapForX: 'Du har bedt om at bytte denne ene for {{x}}',
      PaymentSuccessMessage: '<0><0>Tak for dit køb!</0><1><0></0></1></0>',
      Dashboard: 'Startside',
      year: 'år',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Hvor sandsynligt er det, at du vil anbefale BetaReader.io til en ven?',
      NotAtAllLikely: 'Ikke sandsynligt',
      ExtremelyLikely: 'Yderst sandsynligt',
      ThanksDoYouWantToTellUsWhy: 'Tak! Vil du fortælle os hvorfor?',
      Create: 'Skabe',
      Books: 'Bøger',
      Feed: 'Feed',
      ANewStory: 'en ny historie',
      FailedToGetInvitations: 'Det lykkedes ikke at hente invitationer',
      unauthorizedPrivateBook:
        'Denne bog er privat og ikke tilgængelig for denne konto.',
      invalidToken:
        'Invitationen er ikke gyldig for denne konto eller er udløbet.',
      RaiseYourReaderLimit: 'Hæv din læsegrænse (i øjeblikket {{x}})',
      InviteYourFriends: 'Inviter dine venner',
      referralText:
        '<0> Få op til 10 ekstra læsepladser ved at invitere dine venner til BetaReader.io! </0> <1> For hver ven, der opretter en valideret forfatterkonto og lister et manuskript tak til dig, giver vi dig og din ven 1 læseråbning hver, op til maksimalt 10 pladser. </1> <2> Du kan også hæve din læsergrænse ved at læse og gennemgå 100.000 ord via vores <1> <0> læserapp </0> </1>, eller ved at <3><0> opgradere</0> </3> din konto! </2>',
      INVITED: 'Inviteret',
      CREATED_ACCOUNT: 'Oprettet konto',
      CONFIRMED: 'Bekræftet',
      RegistrationDate: 'Registreringsdato',
      Profile: 'Profil',
      EditProfile: 'Rediger profil',
      GetMoreReaders: 'Få flere læsere',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Du har nået din læsergrænse. <1>Opgrader </1> For at sende flere invitationer i vores læserindeks eller godkende læseforespørgsler. I vores gratis plan kan du foreslå <3>swap</3> med andre forfattere eller invitere så mange læsere, du ønsker direkte via deres e-mail-adresse.</0>',
      SwapsDontAffectReaderLimit:
        'Manuskriptsbytning tæller ikke med i din læsergrænse.',
      maxBooksLimitMessage:
        '<0> Du har nået din manuskriptgrænse. <1> Hæv grænsen </1> for at kunne teste flere manuskripter parallelt. </0>',
      Type: 'Type',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> Lås din tilmeldingsbonus! </ 0> <1> Det ser ud som om du blev henvist af en ven - følg nedenstående trin for at låse din gensidige tilmeldingsbonus op! </ 1> <2> <0> <0 > </ 0> <1> <0> Validér konto </ 0> <1> Du har brug for et gyldigt navn og en e-mailadresse </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Liste manuskript </ 0> <1> Skriv manuskriptet, du vil teste </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> Vent </ 0> <1> Det tager cirka 3 dage for os at validere </ 1> </ 1> </ 2> </ 2>',
      titleDiscoveryRecommended: 'Anbefalet til dig',
      subheaderDiscoveryRecommended:
        'Noget nyt baseret på dine udvalgte genrer',
      titleDiscoveryRecentlyAdded: 'For nylig tilføjet',
      subheaderDiscoveryRecentlyAdded: 'For nylig tilføjede titler',
      discoveryRecentlyAddedTitlesInGenre:
        'Nyligt tilføjede titler i genren {{x}}',
      titleDiscoveryPremium: 'Udvalgte titler',
      subheaderDiscoveryPremium: 'Titler med premiumforfattere',
      referralMessage:
        'Jeg inviterer dig til BetaReader.io! Hvis du registrerer ved hjælp af dette link, får vi begge en bonus!',
      referralBody: 'Tilmeld dig her: {{x}}',
      ThisCannotBeUndone: 'Dette kan ikke fortrydes',
      DeletedVersion: 'Versionen blev slettet',
      InvalidEmail: 'Ugyldig e-mail',
      EmailNotVerified: 'Ikke bekræftet, tjek venligst din e-mail',
      SelectFavoriteGenres: 'Vælg dine yndlingsgenrer',
      Loading: 'Indlæser',
      ChapterIdentification: 'Kapitelidentifikation',
      ChapterAnalysis: 'Kapitelanalyse',
      CompleteImport: 'Færdiggør importen',
      Import: 'Importere',
      Processing: 'Behandler fil...',
      ThisMightTakeAWhile: 'Dette kan tage et stykke tid',
      LikeThisCover: 'Kan du lide dette bogomslag?',
      DownloadFailedMsg:
        'Downloaden mislykkedes. Blev den blokeret af en pop-up-blokering?',
      ShareAuthorAppEmailSuccessMessage:
        'Invitation sendt. Kontrollér status <1> her </1>.',
      failedToUpdateReader: 'Kunne ikke opdatere læseren',
      activeReaders: 'Aktive læsere',
      ReadingList: 'Læseliste',
      Users: 'Brugere',
      InviteUser: 'Inviter bruger',
      Role: 'Rolle',
      member_role_admin: 'Administrator',
      member_role_member: 'Medlem',
      couponsOrDiscountsWillNotBeKept:
        '<0>Eksisterende rabatter følger ikke den nye plan.</0>',
      FailedToFetchX: 'Kunne ikke få {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Du er blevet inviteret til at deltage i {{x}} s BetaReader.io-konto.',
      AcceptAccountInvitationMsg:
        'Hvis du accepterer invitationen, vil du kunne se og redigere alle manuskripter på denne konto.',
      InvitedMembersMsg:
        'Kontomedlemmer kan se og redigere alle manuskripter på din konto, men de kan ikke se nogen faktureringsoplysninger. Se betareader.io/pricing for information om prisen pr. Medlem af kontoen.',
      InviteCollaborator: 'Inviter en samarbejdspartner',
      InviteCollaboratorMsg:
        'Samarbejdspartnere kan se manuskript og læserdetaljer og svare på feedback.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Du er blevet inviteret til at samarbejde om {{book}} af {{author}}',
      AcceptCollaborationInvitationMsg:
        'Hvis du accepterer invitationen, vil du kunne se manuskript og læserdetaljer og svare på feedback.',
      Collaborators: 'Samarbejdspartnere',
      ThisIsABetaFeature:
        'Dette er en beta-funktion. Lad os vide, om hvis du oplever problemer med det, eller hvis du har nogen feedback at dele.',
      UserNotFoundMsg:
        'Det ser ud til, at du ikke har en konto. Opret en <1> her </ 1>!',
      AuthorName: 'Forfatternavn',
      Details: 'Detaljer',
      Settings: 'Indstillinger',
      ReviewAtLeastXwords: '{{x}} ord gennemgået',
      CheckBonus: 'Tjek din bonus',
      FailedToAddCommentOrReaction:
        'Kunne ikke tilføje kommentar eller reaktion',
      ThisIsAWIP: 'Dette er en WIP',
      WIPExplanation:
        'Flag din historie som WIP (Work In Progress), når den ikke er færdig, f.eks. når du ikke har tilføjet alle kapitlerne.',
      MatureContent: '18+',
      PublicationDate: 'Publisering',
      TimePlan: 'Tidsplan',
      NotSpecified: 'Ikke specificeret',
      WhatIsThisStoryAbout: 'Hvad handler denne historie om?',
      WhatDoYouNeedHelpWith: 'Hvad har du brug for hjælp til?',
      StartWriting: 'Begynd at skrive',
      CreateManuscript: 'Opret manuskript',
      ImportManuscript: 'Importer manuskript',
      ImportManuscriptStepDescription: 'Upload eller opret dit manuskript',
      InviteReaders: 'Inviter dine læsere',
      InviteReadersStepDescription: 'Du bestemmer, hvem der kan læse',
      CollectFeedback: 'Indsamle feedback',
      CollectFeedbackStepDescription: 'Analyser kommentarer & læsemønstre',
      YouDontHaveAnyManuscripts: 'Du har endnu ikke nogen manuskripter',
      AuthorsAndPublishers: 'Forfattere og forlag',
      FindOutWhatYourReadersThink:
        'Find ud af, hvad dine læsere virkelig synes om din bog',
      ReadAndInfluenceTheNextBestseller: 'Læs og påvirke kommende bestsellere',
      PrivateSharing: 'Privat deling',
      PrivateSharingDescription: 'Del dit manuskript privat med læserne',
      CollectUnbiasedFeedback: 'Indsaml uvildig feedback',
      CollectUnbiasedFeedbackDescription:
        'Lad dine læsere kommentere uden at se hinandens feedback',
      AnalyzeReadingPatterns: 'Analyser læsemønstre',
      AnalyzeReadingPatternsDescription:
        'Find ud af, hvor dine læsere mister interessen eller bliver bundet af historien',
      ReadItFirst: 'Læs det først',
      ReadItFirstDescription: 'Læs kommende romaner, mens de stadig er i beta',
      InfluenceTheStory: 'Påvirke historien',
      InfluenceTheStoryDescription:
        'Din feedback vil påvirke det endelige resultat',
      ConnectWithAuthors: 'Diskuter med forfatterne',
      ConnectWithAuthorsDescription:
        'Hjælp dine yndlingsforfattere ved at blive en del af deres betateam',
      YourBooks: 'Dine bøger',
      New: 'Ny',
      Members: 'Medlemmer',
      Contacts: 'Kontakter',
      AddMembersFromContacts: 'Tilføj medlemmer fra din kontaktliste',
      ConversationSettings: 'Samtaleindstillinger',
      PremiumAuthorInfo:
        'Dette er et manuskript af en premiumforfatter. Dette betyder typisk, at forfatteren er mere investeret i projektet, og at du kan forvente, at de gør, hvad det kræver for at få denne bog udgivet.',
      StillWorkingOnThis:
        'Vi er stadig ved at udvide BetaReader.io. Lad os vide, hvis der er noget specifikt, du ønsker at se her.',
      Engagement: 'Engagement',
      AssemblingLetters: 'Samling breve ...',
      ConstructingAlphabet: 'Konstruktion alfabet ...',
      BuildingSentences: 'Bygning sætninger ...',
      BecomingSentient: 'At blive følende ...',
      BooksRead: 'Læst: {{x}}',
      WordsReviewed: 'Ord revideret: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Gennemsnitlig turn-around-tid per 1000 ord: {{count}} dag',
      AvgTurnAroundTimePer1kWords_plural:
        'Gennemsnitlig turn-around-tid per 1000 ord: {{count}} dage',
      ViewAsReader: 'Vis som læser',
      EditMode: 'redigeringstilstand',
      BookCover: 'Bogomslag',
      WelcomeToBRReadathon: 'Velkommen til BetaReader.io Readathon!',
      ReadathonHasEnded: 'Readathon er nu afsluttet.',
      ReadathonInfo:
        'Den BetaReader.io Readathon varer mellem 26 september og 31. oktober 2019. Enhver, der læser og anmeldelser mindst en bog via BetaReader.io i løbet af denne tid deltager, og du kan øge din chance for at vinde ved at læse og gennemgå flere bøger. Vinderen vil blive trukket den 1. november og jo flere ord, du har læst og gennemgået inden da, de højere er dine chancer for at vinde. For eksempel en person, der har læst og gennemgået 50.000 ord vil have 5x har stor chance for at vinde som en, der har læst og gennemgået 10.000 ord.',
      ReadathonPrize1: '1. præmie: $ 50 Amazon gavekort.',
      ReadathonPrize23: '2. - 3. præmie: $ 20 Amazon gavekort.',
      SignInToJoin: 'Log ind for at slutte sig til',
      WordsReviewedByYou: 'Ord gennemgået af dig',
      WordsReviewedByEveryone: 'Ord gennemgået af alle',
      ChanceOfWinning: 'Aktuel chance for at vinde',
      Completed: 'afsluttet',
      CompletedAt: 'afsluttet på',
      Pending: 'Verserende',
      Position: 'Position',
      FindSomethingToRead: 'Find noget at læse',
      DontKnow: 'Ved ikke',
      IsThisReadyToPublish:
        'Tror du, at denne bog er klar til at blive offentliggjort?',
      UpdateProfileHeader:
        'Vi har brug for nogle flere oplysninger til din profil',
      WhatsYourName: 'Hvad er dit navn eller pseudonym?',
      WhereAreYouFrom: 'Hvor er du fra?',
      WhenWereYouBorn: 'Hvilket år er du født?',
      WhichGenresDoYouPrefer: 'Hvilke genrer foretrækker du?',
      WhichLanguagesDoYouRead: 'Hvilke sprog læser du?',
      BioPlaceholder:
        'Fortæl os, hvem du er, og hvis du har nogen websteder, vi kan henvise til. En stærkere bio vil hjælpe dig med at blive godkendt til flere manuskripter.',
      Thanks: 'Tak!',
      WhenDoYouWantToHearFromUs: 'Hvornår vil du høre fra os?',
      ReaderListConsentHeader:
        'Ønsker du at blive inkluderet i vores læsedatabase?',
      ReaderListConsentInfo:
        'Forfattere med værker, der matcher dine præferencer, vil kunne sende dig linket til deres bøger, men du er ikke forpligtet til at læse, hvis du ikke vil eller ikke har tid.',
      RoleHeader: 'Er du en forfatter eller en læser?',
      RoleInfo: 'Vi bruger disse oplysninger til at optimere din oplevelse.',
      OnYourProfile: 'Dette vil blive vist på din brugerprofil.',
      ProfileBuilderThankYouMessage:
        '<0>Tak!</0><1>Du kan altid opdatere din profil <1>her</1>.</1>',
      DoThisLater: 'Gør dette senere',
      '2MonthsFree': '2 måneder gratis',
      Joined: 'Tiltrådte',
      UserInfo: 'Bruger info',
      Age: 'Alder',
      Bio: 'Bio',
      ReaderList: 'Find læsere',
      Popular: 'Populær',
      BookHas0Words: '0 ord',
      BookHas0WordsDescription:
        '<0> Denne bog har mindst én version med 0 ord. Du kan tilføje eller uploade kapitler </0> <1> her </1>.',
      LeaveBeta: 'Forlad beta',
      DiscoverNewBooks: 'Oplev nye bøger',
      FailedToFindReader: 'Kunne ikke finde læser',
      ApprovedReadRequest: 'Læseanmodning er godkendt',
      Approved: 'Godkendt',
      UserAlreadyInvited: '{{user}} er allerede inviteret',
      SelectBook: 'Vælg bog',
      InvitationMessagePlaceholder:
        'Inkluder en personlig besked i din invitation for at øge dine chancer for at blive accepteret.',
      YouHaveAPendingInvitation: 'Du har en afventende invitation',
      ReaderIndexInfo:
        'Læserdatabasen indeholder BetaReader.io-brugere, der har angivet, at de er åbne for at blive kontaktet af forfattere. Send dem en invitation med dit bedste toneleje, men husk, at de frit kan afvise din invitation af en eller anden grund.',
      ReaderLimitReached: 'Du har nået din læsergrænse',
      Invite: 'Invitere',
      Languages: 'Sprog',
      LinkWasSentToX: 'Vi har sendt et login-link til {{x}}',
      Verifying: 'Bekræfter...',
      FoundNoAvailableManuscripts: 'Vi fandt ingen tilgængelige manuskripter',
      FindASpecificQuestion: 'Filtrer spørgsmål',
      ReadAndReviewBonusInfo:
        'Din READ & REVIEW bonus opdateres, når du er færdig og har gennemgået en bog.',
      ManuscriptStatus: 'Manuskriptstatus',
      MSStatusDescription_alpha:
        'Dette er et tidligt stadium eller et delvist manuskript.',
      MSStatusDescription_beta:
        'Dette er et manuskript i et sent stadium af en fuld bog.',
      SelectStatus: 'Vælg status',
      MaxAllowedGenres: 'Du kan kun vælge op til 5 genrer',
      TooLongDescription: 'Din beskrivelse er for lang (maks. 1000 tegn)',
      Duplicate: 'Duplikere',
      Download: 'Download',
      DuplicateVersionInfo:
        'Når du kopierer en version, kopieres alle kapitler og undersøgelser til den nye version. Dine læsere forbliver på den gamle version.',
      NoBookMsg:
        '<0> Der er intet her! </0> <1> <0> Vil du </0> <1> tilføje dit eget manuskript? </1> </ 1',
      CommentsCopiedFromOriginal:
        'Kommentarer blev kopieret fra originaldokumentet',
      CopyComments: 'Kopier alle kommentarer',
      MigrateReaders: 'Migrer læsere',
      Posts: 'Indlæg',
      Sent: 'Sendt',
      Username: 'Brugernavn',
      UsernameAlreadyTaken: 'Dette brugernavn er taget',
      Followers: 'Tilhængere',
      ReadersAndFollowers: 'Læsere og følgere',
      WhoCanSeeThis: 'Hvem kan se dette?',
      FollowCount: '{{count}} følger',
      FollowCount_plural: '{{count}} følgere',
      Follow: 'Følg',
      Following: 'Følger',
      MarkAllAsRead: 'Marker alle som læst',
      Working: 'Arbejder...',
      Audience: 'Publikum',
      ShowAllComments: 'Vis alle kommentarer...',
      Publish: 'Offentliggøre',
      InviteByEmail: 'Inviter via e-mail',
      InviteByLink: 'Inviter via link',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        'Hej {{x}}! Velkommen til Betareader support 👋, vi prøver at svare så hurtigt som muligt. Hvad kan vi hjælpe med?',
      TalkToSupport: 'Tal med vores supportteam',
      StartSupportChat: 'Start supportchat',
      StartSupportChatPMessage:
        '<0>Du kan altid kontakte os via vores chat, og vi vil svare så hurtigt vi kan.</0>',
      FindHelpArticlesMessage:
        '<0>Har du brug for hjælp? tjek vores <1> hjælpe </1>sektion, fuld af hjælpeartikler til at guide dig.</0>',
      HelpArticles: 'Hjælpeartikler',
      CloseConversationAlertHeader: 'Tæt samtale',
      CloseConversationAlertMessage: 'Vil du lukke denne samtale?',
      EmailSupportMessage:
        '<0> Send en e-mail til <1>{{mail}}</1>, så svarer vi så snart vi kan. </0>',
      Includes: 'Inkluderer',
      DoesNotInclude: 'Inkluderer ikke',
      IncludesAll: 'Inkluderer alle',
      RemoveFilter: 'Fjern filteret',
      AddFilter: 'Tilføj filter',
      SelectGenres: 'Vælg genrer',
      And: 'Og',
      ContinueWithGoogle: 'Fortsæt med Google',
      ContinueWithFacebook: 'Fortsæt med Facebook',
      ContinueWithApple: 'Fortsæt med Apple',
      NewOnBetareader: 'Ny hos BetarReader?',
      AlreadyHaveAccount: 'Har du allerede en konto?',
      SignUpWithFacebook: 'Tilmeld dig med Facebook',
      SignUpWithGoogle: 'Tilmeld dig med Google',
      SignUpWithApple: 'Tilmeld dig hos Apple',
      OpportunityWithoutDescriptionMessage:
        'Forfatteren har ikke givet en beskrivelse.',
      SignInToViewContent: 'Log ind for at se dette indhold',
      ContentBelongsToAnotherAccount:
        'Dette indhold tilhører en anden konto end din aktive.',
      SwitchAccount: 'Skift konto',
      OwnBooks: 'Egne bøger',
      Collaborations: 'Samarbejde',
      UploadImage: 'Upload billede',
      SplitChapter: 'Del kapitlet',
      UpgradeToUnlock: 'Opgrader for at låse op',
      '3ReaderSlots': '3 læsepladser',
      UnlimitedReaderSlots: 'Ubegrænsede læsepladser',
      ImportWarning: 'Import advarsel',
      ImportExeedLenghMessage:
        '<0><0>Et eller flere af dine kapitler ser ud til at være for lange at importere. Sørg for, at du har fulgt vores<1> importretningslinjer. </1></0><1>Tip: Du kan holde markøren over kapitelteksten og bruge sizzor-værktøjet til at opdele større kapitler</1></0>',
      OneTeamMember: '1 teammedlem',
      AdditionalTeamMembers: 'Tilføj teammedlemmer',
      PerMonth: 'om måneden',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Indsend dit manuskript',
      SubmitTo: 'Indsend til',
      YourSubmittedManuscripts: 'Dine indsendte manuskripter',
      SelectManuscriptToSubmit: 'Vælg manuskript at indsende',
      AvailableToRead: 'Kan læses',
      ManuscriptSubmissionSuccess: 'Manuskript indsendt',
      ManuscriptSubmittedTo:
        'Dit manuskript, {{_manuscriptName}}, er sendt til {{_inboxName}}',
      SubmittedBy: 'Indsendt af {{_userName}}',
      ReceivedManuscripts: 'Modtaget manuskripter',
      InviteToRead: 'Inviter til at læse',
      YourReview: 'Din anmeldelse',
      UserReviewPlaceholder: 'Brugeranmeldelser er synlige for alle.',
      LeaveReviewError:
        'Du kan kun anmelde brugere, der er færdige med at læse et af dine manuskripter.',
      NotEnoughData: 'Ikke nok data',
      EditSignUpSurveyQuestions:
        'Rediger spørgsmål til tilmeldingsundersøgelse',
      AddSignUpSurveyQuestions: 'Tilføj tilmeldingsspørgsmål',
      To: 'Til',
      FinishedReadingAt: 'Fuldførte læsning kl. {{x}}',
      FailedToSendInvitation:
        'Kunne ikke sende invitationen. Bekræft venligst e-mail-adressen',
      EditInvitation: 'Rediger invitation',
      ChapterSeparator: 'Kapitel separator',
      CancelReason:
        'Før du fortsætter, vil du så have noget imod at fortælle os hvorfor?',
      cancel_reason_technical_issues: 'Tekniske problemer',
      cancel_reason_too_expensive: 'Produktet er for dyrt',
      cancel_reason_finished_my_beta: 'Jeg er færdig med min projekt',
      cancel_reason_switching_to_another_product:
        'Jeg skifter til et andet produkt',
      cancel_reason_missing_features: 'Jeg mangler vigtige funktioner',
      cancel_reason_did_not_meet_expectations:
        'Platformen ikke opfyldte mine forventninger',
      cancel_reason_other: 'Andre grunde',
      TellUsMore: 'Fortæl os mere',
      DeleteAccount: 'Slet konto',
      YourAccountHasBeenDeleted: 'Din konto er blevet slettet',
      DeleteAccountConfirmation:
        'Er du sikker på, at du vil slette din konto og alle dens data? Denne handling kan ikke fortrydes.',
      GoodbyeMessage:
        'Vi beklager at se dig forlade os og håber at du vender tilbage i fremtiden.',
      EditContent: 'Rediger indhold',
      AffectedReadersMessage:
        '{{count}} læser har allerede læst dette kapitel. Klik her for at sende en opdatering om dine ændringer.',
      AffectedReadersMessage_plural:
        '{{count}} læsere har allerede læst dette kapitel. Klik her for at sende en opdatering om dine ændringer.',
      ImportingChapterXofY: 'Importerer kapitel {{_x}} / {{_y}}',
      ImportStarted: 'Importen started',
      XVersions: '{{count}} version',
      XVersions_plural: '{{count}} versioner',
      UnsubscribeSuccessful:
        'Du er blevet afmeldt. Du kan altid opdatere dine præferencer via din profil.',
      UnsubscribeUnsuccessful:
        'Kunne ikke afmelde. Send en besked til support@betareader.io, hvis problemet fortsætter.',
      GoodbyeHeader: 'Farvel!',
      BulkAddReaders: 'Tilføj læsere fra CSV',
      Home: 'Hjem',
      Actions: 'Handlinger',
      Invitations: 'Invitationer',
      SelectCSVWithReaders: 'Vælg CSV med læsere',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'Lad vores AI, BetaBot, generere en synopsis baseret på din tekst. Det vil ikke være synligt for dine læsere.',
      LastUpdated: 'Regenerera synopsis',
      CreateSynopsis: 'Opret synopsis',
      RefreshSynopsis: 'Opdater synopsis',
      ElevatorPitch: 'Elevatorplads',
      ThisIsAPremiumFeature: 'Premium funktion',
      GoBack: 'Gå tilbage',
      Retry: 'Prøv igen'
    }
  },
  ar: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'يرجى ملاحظة أنه عند مراسلة قارئ اسم المستخدم الخاص بك وليس اسم مستعار سيكون مرئيا',
      Library: 'مكتبة',
      Account: 'الحساب',
      EmptyLibrary: 'مكتبتك فارغة.',
      EmptyLibraryAction:
        'هل كنت تتوقع أن تجد شيئا هنا؟ الاتصال المؤلف، أو مراسلتنا على البريد الإلكتروني على {{_email}}، وسنرى ما يمكننا القيام به.',
      ByAuthor: 'بواسطة {{_authorName}}',
      ExceedChapterLenghtWarning: 'الفصل طويل جدًا بحيث لا يمكن استيراده',
      WordCount: 'كلمات {{_wordCount}}',
      WordCountString: '{{عدد}} كلمة',
      WordCountString_plural: 'عدد {{}} كلمات',
      CharCountString: '{{count}} حرف',
      CharCountString_plural: '{{count}} حرف',
      SurveyCount: '{{count}} مسح',
      SurveyCount_plural: '{{count}} الاستطلاعات',
      ChapterCount: '{{count}} الفصل',
      ChapterCount_plural: '{{count}} عدد الفصول',
      QuestionCountString: '{{count}} الأسئلة',
      QuestionCountString_plural: '{{count}} الأسئلة',
      LoginToChangeEmail:
        'الرجاء الدخول مرة أخرى لتغيير البريد الإلكتروني الخاص بك',
      SendVerification: 'إرسال رابط التحقق',
      Name: 'اسم',
      Email: 'بريد إلكتروني',
      EnterYourName: 'يرجى إدخال اسمك',
      ReceiveCompanyUpdates:
        'استقبال رسائل البريد الإلكتروني والأخبار من BetaReader',
      ReceiveBookEmails: 'رسائل البريد الإلكتروني تلقي حول الكتب الجديدة',
      ReceiveBookActivityUpdates:
        'تلقي رسائل البريد الإلكتروني حول النشاط على كتبي',
      ReceiveMessageUpdates:
        'استقبال رسائل البريد الإلكتروني عندما أحصل على الرسائل الجديدة',
      IncludeProfileInReaderList: 'تضمين ملفي الشخصي في قاعدة بيانات القارئ',
      PrivacyPolicy: 'سياسة خاصة',
      TermsOfService: 'شروط الخدمة',
      ReadOurBlog: 'اقرأ بلوق',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'التالي',
      Back: 'عودة',
      CreateAccount: 'إنشاء حساب',
      CreateAccountPrompt:
        '<0>هل لا يكون لديك حساب؟<1> <0>إنشاء واحد هنا!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>هل نسيت كلمة المرور؟<1> <0>إعادة تعيينها هنا! </0></1></0>',
      SignIn: 'تسجيل الدخول',
      SignOut: 'خروج',
      SendResetLink: 'إرسال رابط إعادة تعيين',
      ResetPassword: 'إعادة تعيين كلمة المرور',
      Password: 'كلمه السر',
      OrUse: 'او استعمل',
      NoConnection: 'غير متصل على الانترنت...',
      AcceptTermsMessage: 'قبول شروط الخدمة',
      AcceptToSToCreateAccount: 'عليك أن تقبل شروط الخدمة لإنشاء حساب.',
      Answer: 'إجابة',
      FailedToLoadBook: 'لا يمكن تحميل الكتاب.',
      PullDownToRefresh: 'للتحديث اسحب للأسفل.',
      ContactSupport: 'اتصل بالدعم',
      Support: 'الدعم',
      LastRead: 'قراءة مشاركة',
      XInvitations: 'لديك {{count}} دعوة',
      XInvitations_plural: 'لديك {{count}} دعوات',
      EnterComment: 'يرجى التعليق...',
      EnterReply: 'الرد...',
      Reply: 'رد',
      Reply_plural: ' ردود',
      Reply_verb: 'الرد',
      show: 'اظهر',
      hide: 'المخفي',
      Comments: 'تعليقات',
      InlineComment: 'تعليق مضمنة',
      Reject: 'رفض',
      Accept: 'يقبل',
      Error: 'خطأ',
      failed: 'فشل',
      SelectedText: 'النص المحدد',
      FailedToFetchUserProfile: 'فشل في جلب ملف تعريف المستخدم.',
      ProblemPersistContactSupport:
        'إذا كانت هذه المشكلة قائمة، اتصل بالدعم في:',
      clickToAddComment: 'انقر هنا لإضافة تعليق',
      ColorMode: 'وضع اللون',
      FontSize: 'حجم الخط',
      Discover: 'اكتشف',
      MyAccount: 'حسابي',
      MyManuscripts: 'مخطوطاتي',
      Sweden: 'السويد',
      USA: 'الولايات المتحدة الأمريكية',
      UK: 'المملكة المتحدة',
      Germany: 'ألمانيا',
      France: 'فرنسا',
      Spain: 'إسبانيا',
      Country: 'دولة',
      Gender: 'جنس',
      YoB: 'سنة الميلاد',
      PreferredGenres: 'الأنواع المفضلة',
      PreferredLanguages: 'اللغات المفضلة',
      EmailPreferences: 'تفضيلات البريد الإلكتروني',
      chapters: 'فصول',
      words: 'كلمات',
      YouShouldBetaRead: 'يجب أن تقرأ',
      onBetaReader: 'على BetaReader.io',
      Share: 'يشارك',
      NoGuidelines: 'لم يقدم المؤلف أي إرشادات للتعليقات.',
      Read: 'يقرأ',
      on: 'على',
      Author: 'مؤلف',
      JoinBeta: 'أريد أن أقرأ هذا',
      joinConsentString:
        'أدرك أنه من خلال طلب الانضمام إلى هذا الإصدار التجريبي، سأشارك عنوان بريدي الإلكتروني مع المؤلف.',
      Submit: 'يُقدِّم',
      AnswerSurvey: 'إجابة!',
      SendJoinRequest: 'يرسل',
      IJustWantToRead: 'أنا فقط أريد أن أقرأ',
      CopyrightInfo: 'معلومات حقوق الطبع والنشر',
      RequestToRead: 'إرسال طلب القراءة',
      Feedback: 'تعليق',
      NoComment: 'لم يعلق أحد.',
      OnceUponATime: 'كان ياما كان ...',
      Review: 'مراجعة',
      Overall: 'إجمالي',
      Plot: 'دسيسة',
      Grammar: 'القواعد',
      Title: 'عنوان',
      AddReview: 'إضافة مراجعة',
      EnterReviewText: 'أدخل مراجعة قصيرة',
      OverallRating: 'إجمالي',
      OverallRatingRequired: 'إضافة التقييم العام',
      PlotRating: 'دسيسة',
      GrammarRating: 'القواعد',
      SubmitReview: 'إرسال المراجعة',
      LeaveReview: 'ترك المراجعة',
      checkOutAuthorApp:
        '<0> هل أنت كاتبة؟ </0> <1> </1> <2> تحقق من <1> التطبيق للمؤلفين </1>!</ 2>',
      MyLibrary: 'مكتبتي',
      ReadingPreferences: 'تفضيلات القراءة',
      'E.g.': 'على سبيل المثال.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'أرغب في تلقي رسائل بريد إلكتروني من BetaReader.io عندما يقوم المؤلف بإجراء تغييرات أو تحديثات على',
      'I want to receive emails about BetaReader news and updates.':
        'أريد تلقي رسائل بريد إلكتروني حول أخبار وتحديثات BetaReader.',
      'I want to receive emails about new books that match my reading preferences.':
        'أريد تلقي رسائل بريد إلكتروني حول الكتب الجديدة التي تتوافق مع تفضيلاتي في القراءة.',
      'this book': 'هذا الكتاب',
      'Email us on': 'راسلنا عبر البريد الإلكتروني على',
      "and we'll see what we can do :)": 'وسنرى ما يمكننا فعله :)',
      'Want an author account?': 'هل تريد حساب المؤلف؟',
      Preferences: 'التفضيلات',
      Language: 'لغة',
      Browse: 'تصفح',
      'to beginning': 'إلى البداية',
      'to chapter...': 'إلى الفصل...',
      Location: 'موقع',
      Previous: 'سابق',
      Finish: 'ينهي',
      Description: 'وصف',
      FeedbackGuidelines: 'المبادئ التوجيهية ردود الفعل',
      'Start reading': 'ابدأ القراءة',
      StartReading: 'ابدأ القراءة',
      LeaveComment: 'تعليق',
      replies: 'الردود',
      Show: 'يعرض',
      Hide: 'يخفي',
      'Add Reply': 'أضف الرد',
      PendingInvitation: 'دعوة معلقة',
      Decline: 'رفض',
      DeclinedInvitationMessage:
        'لقد رفضت هذه الدعوة للقراءة، ولكن لا يزال بإمكانك قبولها إذا غيرت رأيك.',
      Welcome: 'مرحباً',
      by: 'من',
      to: 'ل',
      AcceptInvitation: 'قبول الدعوة',
      InvitationAccepted: 'تم قبول الدعوة',
      WaitForBook: 'يرجى الانتظار بينما نقوم بإعداد كتابك',
      PasswordLength:
        'يجب أن تكون كلمة المرور الخاصة بك مكونة من 6 أحرف على الأقل',
      AgreeToPolicyAndTerms:
        'يجب عليك الموافقة على سياسة الخصوصية وشروط الخدمة الخاصة بـ BetaReader حتى تتمكن من التسجيل.',
      'Sending...': 'إرسال...',
      VerificationSent: 'تم إرسال رسالة التحقق بالبريد الإلكتروني.',
      VerificationEmailFailed: 'فشل في إرسال البريد الإلكتروني للتحقق.',
      IAcceptToC:
        '<0> أقبل BetaReader.io من <1> سياسة الخصوصية </1> & <3> شروط الخدمة </3></0>',
      AccountSettings: 'إعدادات الحساب',
      ContactSettings: 'اتصل إعدادات',
      Chapters: 'فصول',
      Options: 'خيارات',
      Option: 'خيار',
      BetaLanguageMessage:
        '<0> ونحن نعمل على تحسين دعم اللغة، وممتنون لأية مساعدة يمكن أن نحصل فائقة. إذا وجدت أي أخطاء على الموقع، لا تترددوا في الاتصال بنا على: <1> hello@betareader.io </1></0>',
      Genres: 'الأنواع',
      PressEnterToSave: 'اضغط على Enter للحفظ',
      female: 'أنثى',
      male: 'ذكر',
      other: 'غير ثنائي',
      DontWantToShare: 'خاص',
      SignInWithPassword: 'تسجيل الدخول باستخدام كلمة المرور',
      GoToBook: 'اذهب إلى الكتاب',
      Requested: 'طلبك معلق',
      AnsweredOrRequestedNotice:
        'لقد طلبت الانضمام إلى هذا الإصدار التجريبي. سيعود المؤلف إليك في أقرب وقت ممكن.',
      RequestedWithDifferentEmail:
        'لقد طلبت الانضمام إلى هذا الإصدار التجريبي باستخدام بريد إلكتروني آخر غير الذي تمت دعوتك به. سيعود المؤلف إليك في أقرب وقت ممكن.',
      LoadingLibrary: 'أبحث عن الكتب...',
      PreparingBook: 'كتاب إعداد',
      InLineCommentPlaceholder: 'اترك تعليقك ثم اضغط على زر الإدخال للحفظ',
      ExitSurvey: 'الخروج من الاستطلاع',
      WhyExitQuestion: 'لماذا تريد ترك هذا الإصدار التجريبي؟',
      LostInterest: 'لقد فقدت الاهتمام',
      NotEnoughTime: 'ليس لدي ما يكفي من الوقت',
      Other: 'سبب آخر',
      reason_lost_interest: 'لقد فقدت الاهتمام',
      reason_no_time: 'ليس لدي ما يكفي من الوقت',
      reason_other: 'سبب آخر',
      reason_: 'غير محدد',
      ExitFeedbackQuestion: 'هل تريد أن تترك بعض ردود الفعل للمؤلف؟',
      ExitFeedbackPlaceholder: 'جميع ردود الفعل مفيدة',
      ThankYouForYourFeebdack: 'شكرا لتعليقاتك!',
      AppVersion: 'إصدار',
      AppBuildNumber: '#',
      Like: 'يحب',
      Love: 'حب',
      Laugh: 'هاها',
      Surprise: 'مفاجأة',
      Sad: 'حزين',
      Angry: 'غاضب',
      NoBooksInList: 'لا كتب هنا.',
      Update: 'تحديث',
      Overview: 'ملخص',
      Content: 'محتوى',
      Readers: 'القراء',
      NotificationSettings: 'إعدادات الإخطار',
      SendEmailOnComments: 'أرسل بريدًا إلكترونيًا عندما يترك القراء تعليقاتهم',
      SendEmailOnSurveyComplete:
        'أرسل بريدًا إلكترونيًا عندما يكمل القراء الاستبيان',
      CoverUploadMsg:
        'انقر لتحميل صورة الغلاف (png أو jpg، 980x1568px)، أو قم بإسقاطها في هذه المنطقة.',
      DefaultNamePlaceholder: 'الافتراضي: {{displayName}}',
      SelectUpTo5Genres: 'حدد ما يصل إلى 5 أنواع',
      AdvancedSettings: 'الإعدادات المتقدمة',
      EnableComments: 'تمكين التعليقات',
      EnableBookReviews: 'تمكين مراجعات الكتب',
      DeleteBook: 'حذف الكتاب',
      DeleteCover: 'حذف غلاف الكتاب',
      DeleteCoverConfirmation: 'هل أنت متأكد أنك تريد حذف غلاف الكتاب؟',
      YesBinIt: 'نعم، حذفه!',
      AreYouSureYouWantToDeleteBookName:
        '<0>هل أنت متأكد أنك تريد حذف <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} قد تم حذفه.',
      No: 'لا',
      Yes: 'نعم',
      UserCommentedOn: 'علق على',
      ShowXReplies: 'عرض {{count}} الرد',
      ShowXReplies_plural: 'عرض {{count}} ردود',
      HideXReplies: 'إخفاء {{count}} الرد',
      HideXReplies_plural: 'إخفاء {{count}} ردود',
      NoInLineComments: 'لا توجد تعليقات مضمنة.',
      Chapter: 'الفصل',
      NoCommentsToShow: 'لا توجد تعليقات لإظهارها.',
      Save: 'يحفظ',
      TooLongTitle: 'العنوان طويل جدًا (60 حرفًا كحد أقصى)',
      ClickToUploadDoc: 'انقر لإضافة أو إسقاط ملف docx الخاص بك لاستيراده',
      LongChapterWarning: 'الفصل طويل هل هذا صحيح؟',
      Upload: 'رفع',
      UploadChapters: 'تحميل الفصول',
      ImportBooksHelpMsg:
        'لن يتمكن أحد من رؤية مخطوطة دون موافقتك. اقرأ عن <1> استيراد الكتب </1>.',
      Add: 'يضيف',
      NewChapter: 'فصل جديد',
      NewSurvey: 'مسح جديد',
      SelectedPart: 'الجزء المحدد',
      SelectPart: 'حدد الجزء',
      NotifyMe: 'أعلمني',
      WhenReadersStart: 'في البداية',
      WhenReadersComplete: 'في النهاية',
      ManageChapter: 'إدارة الفصل',
      ManageSurvey: 'إدارة الاستطلاع',
      ManageReaders: 'إدارة القراء',
      MoveUp: 'تحرك للأعلى',
      MoveDown: 'تحرك للأسفل',
      SaveSurvey: 'حفظ الاستطلاع',
      Saved: 'أنقذ',
      EnterSurveyName: 'قم بتسمية الاستطلاع الخاص بك',
      EnterSurveyDescription: 'قم بوصف الاستطلاع الخاص بك',
      SelectAtLeast: 'اختر على الأقل',
      SelectAtMost: 'اختر على الأكثر',
      LabelOptional: 'التسمية (اختياري)',
      MyBooks: 'كتبي',
      ChangeRoleToWriterToCreateBooks:
        'يرجى تغيير الدور إلى الكاتب لإنشاء الكتب.',
      UsingXOutOfYBooks: 'كنت تستخدم {{س}} من {{ص}} الكتب.',
      UsingXOutOfYReaders: 'كنت تستخدم {{س}} من {{ص}} قراء',
      NothingHere: 'لا شيء هنا في الوقت الراهن.',
      OpenReaderWeb: 'افتح القارئ',
      PrivacyLevel: 'حالة المشاركة',
      PublishingStatus: 'حالة المشاركة',
      PrivacyLevel_infotext_unpublished:
        '<0> كتابك غير متصل بالإنترنت. انقله إلى <1> خاص </1> أو <3> مغلق </ 3> أو <5> مدرج </5> ليتمكن القراء من العثور عليه. </0>',
      PrivacyLevel_infotext_private:
        '<0> لم يتم سرد كتابك في أي مكان. يمكنك فقط دعوة القراء. </0>',
      PrivacyLevel_infotext_closed:
        '<0> عرض كتابك يمكن أن ينظر إليها من قبل أي شخص مع <1> الرابط </1>. يمكن للقراء طلب دعوة، ولكن تحتاج إلى موافقة كل طلب. </0>',
      PrivacyLevel_infotext_listed:
        '<0> يتم سرد كتابك <1> هنا </1>. يمكن للقراء طلب دعوة، ولكن تحتاج إلى موافقة كل طلب. </0>',
      PrivacyLevel_state_unpublished: 'غير متصل',
      PrivacyLevel_state_private: 'خاص',
      PrivacyLevel_state_closed: 'مغلق',
      PrivacyLevel_state_listed: 'مدرج',
      SendReminder: 'إرسال تذكير',
      YouCanSendAReminderEvery3Days: 'يمكنك إرسال تذكير كل يوم ثالث',
      LastSeenAt: 'آخر ينظر القارئ في',
      ReminderSentAt: 'تم إرسال التذكير',
      XReadersInactiveForYDays:
        '{{س}} القارئ لم ينته الكتاب ولم يكن نشطا على {{ص}} أيام الماضي.',
      XReadersInactiveForYDays_plural:
        'و{{س}} القراء لم تنته الكتاب والتي لم تكن نشطة خلال {{ص}} أيام الماضي.',
      InactiveReaders: 'قراء غير نشطة',
      XReadersNotStartedForYDays:
        '{{س}} القارئ لم تقبل أكثر من {{ص}} أيام دعوة القديمة',
      XReadersNotStartedForYDays_plural:
        '{{س}} القراء لم تقبل أكثر من {{ص}} أيام دعوة القديمة',
      ReadersWhoHaveNotAcceptedTheInvitation: 'القراء الذين لم يقبلوا الدعوة',
      YouHaveXJoinRequests: 'لديك {{count}} طلب لقراءة كتابك',
      YouHaveXJoinRequests_plural: 'لديك {{count}} طلبات لقراءة كتابك',
      JoinRequests: 'قراءة طلبات',
      Approve: 'يعتمد',
      RequestedAt: 'مطلوب',
      NoAnswer: 'لا إجابة',
      SendSurveyReminder: 'إرسال تذكير المسح',
      Remove: 'يزيل',
      Created: 'مخلوق',
      RequestedToRead: 'طلبت القراءة',
      InvitationSent: 'تم إرسال الدعوة',
      Reminded: 'ذكر',
      Question: 'سؤال',
      YouCanAskUpToXQuestions:
        'يمكنك إضافة ما يصل إلى {{maxQuestions}} الأسئلة التي القراء مطلوبة للإجابة عند الاشتراك.',
      NewQuestion: 'سؤال جديد',
      ReaderSignUpForm: 'نموذج التسجيل',
      NoReadersFound: 'لم يتم العثور على القراء',
      ReachedPartTitle: 'وصل {{س}}',
      ReasonToQuit: 'سبب',
      YourBookDoesNotHaveAnyChaptersYet: 'كتابك لا يحتوي على أي فصول حتى الآن',
      Invited: 'مدعو',
      Started: 'بدأت',
      Finished: 'انتهى',
      Abandoned: 'متروك',
      AvgOverallRating: 'إجمالي',
      AvgPlotRating: 'دسيسة',
      AvgGrammarRating: 'القواعد',
      YouDoNotHaveReviewsYet: 'ليس لديك أي تعليقات حتى الآن.',
      PersonalLink: 'ارتباط شخصي',
      HelpUsReachMoreWriters:
        'هل تستمتع باستخدام BetaReader.io؟ استخدم الرابط الشخصي الخاص بك لمساعدتنا في مساعدة المزيد من المؤلفين!',
      Reports: 'التقارير',
      WeNeedYourEmail: 'البريد الإلكتروني مطلوب',
      MissingEmailMsg:
        'يجب أن نكون قادرين على الاتصال بك بخصوص حسابك. لن نشاركها مع أي شخص، ولن نتصل بك إلا إذا لزم الأمر.',
      EmailNotVerifiedPrompt:
        'البريد الإلكتروني لم يتم التحقق. <1> انقر هنا لإعادة </1> البريد الإلكتروني التحقق. (مراجعة مجلد الرسائل غير المرغوب فيها إذا كنت لا تحصل عليه)',
      Skip: 'يتخطى',
      RoleWriter: 'الكاتب',
      RoleReader: 'قارئ',
      RoleBoth: 'كلاهما',
      AddReader: 'إضافة قارئ جديد',
      DownloadReaderList: 'تحميل قائمة القراء',
      'Show only': 'اعرض فقط',
      StatusNEW: 'جديد',
      StatusTODO: 'للقيام به',
      StatusDOING: 'عمل',
      StatusDONE: 'منتهي',
      StatusIGNORE: 'يتجاهل',
      RemoveFilters: 'إزالة المرشحات',
      ReadingDataReport: 'قراءة البيانات',
      SurveyAnswersReport: 'إجابات الاستطلاع',
      Reviews: 'التعليقات',
      AbandonedReadersReport: 'القراء المفقودون',
      SignUpMsg:
        '<0>شكرًا على التسجيل!</0> <1>تم إرسال بريد إلكتروني للتحقق إلى <1>{{email}}</1></1>',
      CopiedXToClipboard: 'تم نسخ {{x}} إلى الحافظة.',
      DoYouWantToBeMyBetaReader: 'هل تريد أن تكون قارئي التجريبي؟',
      LookingForBetaReadersForBookTitle:
        'أنا أبحث عن القراء بيتا ل{{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'أنا أبحث عن القراء بيتا ل{{bookTitle}}. اتبع هذا الرابط لقراءة المزيد والاشتراك: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'المشاركة الاجتماعية غير ممكنة للكتب الخاصة.',
      ShareTheLinkToYourBook: 'شارك الرابط لكتابك!',
      Messages: 'رسائل',
      FailedToLoadMessage: 'لقد فشلنا في تحميل هذه الرسالة.',
      FailedToSendMessage: 'لقد فشلنا في إرسال رسالتك.',
      FailedToStartConversation: 'لقد فشلنا في بدء المحادثة.',
      NewMessage: 'رسالة جديدة',
      MessageSubject: 'موضوع',
      MessageBody: 'أكتب رسالتك...',
      MessageFrom: 'من',
      MessageTo: 'ل',
      SendMessage: 'يرسل',
      Message: 'رسالة',
      ReaderMarketplaceBetaInfoText:
        'نحن نجرب السماح لمؤلفينا بدفع مبلغ رمزي للقراء العاديين ومحبي الكتب مقابل خدمة قراءة المخطوطة. نحن نقدم آليات مراقبة للتحقق من صحة التعليقات والتحكم في أن القراء قد قرأوا المخطوطة الكاملة قبل أن يحصل أي شخص على المال. إذا كنت ترغب في تجربة هذه الميزة، يرجى الإشارة إلى عدد القراء الذي تحتاجه.',
      ReaderMarketplaceBetaFullText:
        'هذا البرنامج ممتلئ حاليًا. هل تريد منا أن نخطرك عندما نحصل على افتتاح؟',
      Sum: 'مجموع',
      XUSDPerReader: '${{س}} / القارئ',
      AmountOfReaders: 'عدد القراء',
      PayForTestReaders: 'الدفع لقراء الاختبار',
      GoToCheckout: 'اذهب إلى الخروج',
      YesPlease: 'نعم من فضلك!',
      BETA: 'بيتا',
      NoMessages: 'ليس لديك رسائل',
      OnBoarding_import_book:
        '<0> مرحبا بكم في BetaReader.io! </0> من أجل تقاسم كتابك، تحتاج أولا إلى استيراده، إما عن طريق <2> تحميلها </2>، أو عن طريق إنشاء مباشرة على المنصة. المضي قدما ومحاولة الخروج منها! كل شيء على BetaReader.io هي خاصة افتراضيا، لذلك لا احد سوف نرى ذلك إلا إذا كنت دعوتهم.',
      Onboarding_book_overview:
        'نظرة عامة على الكتاب هي المكان الذي تقوم فيه بتكوين تفاصيل حول كتابك. تظهر هنا إرشادات العنوان والأنواع والتعليقات، ولكن أيضًا ما إذا كنت تريد أن يتمكن القراء من التعليق وترك التعليقات أم لا.',
      Onboarding_book_content:
        'علامة التبويب "المحتوى" هي المكان الذي يمكنك فيه التحكم في ترتيب الأشياء. أضف الفصول والاستطلاعات الخاصة بك هنا.',
      Onboarding_book_readers:
        'علامة تبويب القراء هي المكان الذي يمكنك من خلاله التحكم في من يمكنه قراءة كتابك. أرسل دعوات خاصة، أو شارك رابط التسجيل الخاص بك عبر Twitter، أو أضف كتابك إلى قائمة Discovery الخاصة بنا للوصول إلى المزيد من القراء.',
      Onboarding_book_feedback:
        'تمنحك علامة التبويب "التعليقات" نظرة عامة على جميع التعليقات التي تركها القراء. الرد على التعليقات وتصفية الأشياء غير المهمة.',
      Onboarding_book_reports:
        'تمنحك علامة التبويب "التقرير" نظرة عامة على أداء كتابك. كم عدد القراء الذين تمت دعوتهم، وكم عدد الذين بدأوا القراءة، وتركوا مراجعات رائعة، وما إلى ذلك.',
      Close: 'يغلق',
      Last: 'آخر',
      DuplicatedBook: '<0> <0> </0> كتاب نسخها {{س}} </0>',
      PenName: 'اسم القلم',
      MissingNameMsg: 'تحتاج إلى إدخال اسم لتتمكن من التواصل مع القراء.',
      NameConversation: 'اسم هذه المحادثة',
      With: 'مع...',
      SelectPeople: 'حدد الأشخاص',
      UploadAsNewVersion: '... كإصدار جديد',
      UploadAsExtraChapters: '...إلى هذا الإصدار',
      Cancel: 'يلغي',
      Unnamed: 'غير مسمى',
      NewVersion: 'نسخة جديدة',
      NoContacts: 'لا اتصالات',
      GetContacts:
        'يصبح مؤلفو الكتب التي تقرأها وقراء الكتب التي تكتبها جهات اتصالك.',
      NoConversations: 'لا محادثات...',
      AllVersionsNeedContent:
        'يحتاج الإصدار الحالي لديك إلى محتوى قبل أن تتمكن من إنشاء إصدار جديد.',
      InviteToVersion: 'دعوة إلى الإصدار',
      latestVersion: 'آخر',
      VersionName: 'الإصدار {{x}}',
      Reader: 'قارئ',
      StartedReading: 'القراءة التي',
      CurrentPosition: 'المنصب الحالي',
      PickVersion: 'اختر الإصدار',
      Filters: 'المرشحات',
      Versions: 'الإصدارات',
      ChapterInitial: 'ا',
      AvgTimeToComplete: 'متوسط ​​الوقت لقراءة الفصل',
      ReaderProgress: 'المواقف',
      SelectFilter: 'حدد مرشحًا',
      CreatePost: 'مشاركة التحديث',
      NewBookPostPlaceholder: 'ماذا تريد أن تقول؟',
      BookUpdateForX: 'تحديث للكتاب {{x}}',
      OnSaveChapterMessage: 'دع القراء يعرفون ما قمت بتغييره',
      SampleChapterUpdatePost: 'لقد غيرت هذا...',
      SaveWithoutPosting: 'احفظ بدون نشر...',
      SaveAndPost: 'احفظ وانشر...',
      DiscoverSearch: 'ابحث عن عنوان أو نوع...',
      LoadMore: 'تحميل المزيد...',
      PendingReadInvitations: 'الدعوات المعلقة',
      PendingReadRequests: 'الطلبات المعلقة',
      UserReactedOn: 'تم التفاعل على',
      ChapterComment: 'تعليق الفصل',
      InlineCommentNotFound:
        'لم يتم العثور على هذا التعليق في النص. هل تمت إزالة النص الذي تم التعليق عليه؟',
      Or: 'أو',
      Version: 'إصدار',
      Updates: 'التحديثات',
      CurrentBetaIsFullMessage:
        'هذا الإصدار التجريبي ممتلئ حاليًا، يرجى الاتصال بالمؤلف لفتح المزيد من الفتحات.',
      Edit: 'يحرر',
      Delete: 'يمسح',
      DropFilesHere: 'قم بإسقاط ملفاتك هنا',
      ShowOnly: 'اعرض فقط',
      FeedbackSearch: 'البحث عن النص',
      'BuySubscriptionToS&PPMessage':
        '<0>Buying a subscription from BetaReader.io indicates that you have read and accept our<1> terms of service</1> & <5>privacy policy</5></0>',
      Inbox: 'البريد الوارد',
      CofirmMovePart: 'هل تريد حقًا نقل هذا الجزء؟ سيؤثر على القراء التاليين:',
      'AreYouSure?': 'هل أنت متأكد؟',
      LetYourReadersKnowWhatYouChanged: 'دع القراء يعرفون ما قمت بتغييره',
      ThisChangeAffectsTheFollowingReaders:
        'يؤثر هذا التغيير على القراء التاليين:',
      MovedXFromYtoZ: 'تم نقل {{x}} من {{y}} إلى {{z}}',
      Subscription: 'اشتراك',
      Subscriptions: 'الاشتراكات',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'الخطة الأساسية ، مجانا إلى الأبد.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'جيد للهواة الكمال',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'عظيم للكتاب إيندي المهنية',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'مثالية للناشرين',
      CancelSubscription: 'إلغاء الاشتراك',
      YourSubscriptionHasBeenCancelled: 'تم إلغاء اشتراكك',
      CancelSubscriptionConfirmation: 'هل أنت متأكد من أنك تريد إلغاء اشتراكك؟',
      ValidUntil: 'صالحة حتى',
      UpgradeSubscriptionTitle: 'حدث',
      UpgradeSubscriptionPromoText: 'ترقية حسابك',
      Abort: 'إحباط',
      BuyNow: 'يشترى',
      Current: 'الخطه الحاليه',
      Monthly: 'شهريا',
      Yearly: 'سنوي',
      monthly: 'شهريا',
      yearly: 'عام',
      month: 'شهر',
      XCheaper: '{{x}} أرخص!',
      Downgrade: 'تخفيض',
      Forever: 'إلى الأبد',
      ChangePlan: 'خطة التغيير',
      TrackReaderProgress: 'اتبع القراء في الوقت الحقيقي',
      InlineComments: 'التعليقات المباشرة وردود الفعل',
      ReaderSurveys: 'استطلاعات القارئ',
      NativeApps: 'تطبيق Android و iOS',
      MultipleVersions: 'إصدار المخطوط',
      '1ActiveManuscript': '1 مخطوطة',
      '5ActiveManuscripts': 'ما يصل إلى 5 مخطوطات',
      '20ActiveManuscripts': 'ما يصل إلى 20 مخطوطة',
      UnlimitedActiveManuscripts: 'مخطوطات غير محدودة',
      '3ActiveReadersPerManuscript': 'ما يصل إلى 3 قراء',
      '20ActiveReadersPerManuscript': 'ما يصل إلى 20 قارئا',
      '50ActiveReadersPerManuscript': 'ما يصل إلى 50 قارئا',
      UnlimitedActiveReaders: 'عدد غير محدود من القراء',
      Zero: 'صفر',
      NoCreditCardNeeded: 'لا حاجة لبطاقات الائتمان',
      Recommended: 'موصى به',
      SomethingWentWrong: 'ذهب شيء منحرف',
      SubscriptionChangeError: 'فشل في تغيير الاشتراك',
      SubscriptionChangeSuccess: 'قمنا بتحديث اشتراكك',
      ActionCancelled: 'تم إلغاء الإجراء',
      Cancelled: 'تم الإلغاء',
      NextPayment: 'الدفعة القادمة',
      PaymentPastDueMessage:
        'الدفع الخاص بك هو الماضي بسبب. المحاولة التالية: {{س}}',
      ChangePaymentInformation: 'تغيير تفاصيل الفواتير',
      DashboardWelcomeMessage:
        '<0>مرحبًا بك في BetaReader.io!</0><1>ابدأ عن طريق <2>تحميل</2> أو <6>إضافة</6> مخطوطتك</1><2>الاستكشاف</2>< 3>لا تنس إدراج مخطوطتك في قسم <2>الاكتشاف</2>، للوصول إلى المزيد من القراء التجريبيين.</3><4><0>هنا</0> يوجد شرح سريع لكيفية ذلك يفعل ذلك.</4><5>الدعم</5><6>يُرجى مراجعة بوابة <2>المساعدة</2> أو مراسلتنا مباشرة بالضغط على (؟) في أعلى يسار الصفحة.</ 6><7>يمكنك دائمًا إرسال رسالة ودية إلينا على <2>hello@betareader.io</2></7>',
      Manuscripts: 'المخطوطات',
      CurrentlyReading: 'القراءة حاليا',
      WeeklyReaders: 'القراء الأسبوعية',
      TotalReaders: 'إجمالي القراء',
      FailedToDownloadReport: 'فشل تنزيل التقرير',
      WaitingForConfirmation: 'انتظار تأكيد',
      ChangePublishingStatusToAddReaders: 'تغيير حالة النشر لإضافة القراء',
      SuggestASwap: 'أقترح مبادلة',
      WithdrawRequest: 'سحب الطلب',
      Confirm: 'تؤكد',
      ThisIsASwapRequestForX:
        'هذا طلب مبادلة لـ <2>{{x}}</2>. إذا وافقت على طلب المبادلة ، فستتم إضافتك كقارئ على {{x}}.',
      ApprovedSwapRequestForX: 'هذا طلب مبادلة لـ <2>{{x}}<2>',
      ThisIsASwapRequestFor:
        'هذا هو طلب المبادلة. إذا وافقت على هذا الطلب، سيتم إضافتك كقارئ لـ:',
      SwapRequest: 'طلب مبادلة',
      ApproveAndJoin: 'الموافقة والانضمام',
      AuthorXHasProposedToSwapThisForY:
        'عرض {{x}} قراءة {{y}} في مقابل قراءة هذا الكتاب.',
      GoToSwapRequest: 'انتقل إلى طلب المبادلة',
      SwitchVersion: 'تبديل الإصدار',
      SendMagicLinkToX: 'إرسال رابط سحري إلى {{x}}',
      notification_verb_comment: 'علق على',
      notification_verb_react: 'تفاعل مع',
      notification_verb_reply: 'رد على تعليق على',
      notification_verb_abandon: 'تخلى عن كتابك',
      notification_verb_request_to_join: 'طلب قراءة',
      notification_verb_message: 'أرسلت لك رسالة',
      notification_verb_sent_join_approval: 'لقد وافق على طلبك للقراءة',
      notification_verb_invitation_to_join: 'دعاك لقراءة {{y}}',
      notification_verb_manuscript_submitted: 'أرسل مخطوطة إلى {{y}}',
      'SaveAnd...': 'احفظ و...',
      Send: 'إرسال',
      OnlySave: 'فقط حفظ',
      YourNotifications: 'التنبيهات الواردة',
      Notifications: 'إخطارات',
      Images: 'الصور',
      '365DaysReadingData': '365 يوما تاريخ القارئ',
      '90DaysReadingData': '90 يوما تاريخ القارئ',
      '30DaysReadingData': '30 يوما تاريخ القارئ',
      alerts_PaymentPastDue:
        'لقد تجاوز موعد استحقاق دفعتك. قم بتحديث تفاصيل الفواتير الخاصة بك',
      alerts_MissingName: 'نحن نفتقد اسمك (أو اسم القلم). قم بتحديثه هنا:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} اليسار للمطالبة بخصم مدى حياتك. أدخل LAUNCH في حقل القسيمة عند الترقية:',
      RequestedToSwapForX: 'لقد طلبت لمبادلة هذا واحد ل{{س}}',
      PaymentSuccessMessage: '<0><0>شكرًا على شرائك!</0><1><0></0></1></0>',
      Dashboard: 'أبدأ الصفحة',
      year: 'سنة',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'ما مدى احتمالية أن توصي بـ BetaReader.io لصديق؟',
      NotAtAllLikely: 'ليس من المرجح',
      ExtremelyLikely: 'من المرجح للغاية',
      ThanksDoYouWantToTellUsWhy: 'شكر! هل تريد أن تخبرنا لماذا؟',
      Create: 'يخلق',
      Books: 'كتب',
      Feed: 'تدفق',
      ANewStory: 'قصة جديدة',
      FailedToGetInvitations: 'فشل تحميل دعوات',
      unauthorizedPrivateBook: 'هذا الكتاب خاص وغير متاح لهذا الحساب.',
      invalidToken: 'الدعوة غير صالحة لهذا الحساب أو انتهت صلاحيتها.',
      RaiseYourReaderLimit: 'رفع حد القارئ الخاص بك (حاليًا {{x}})',
      InviteYourFriends: 'قم بدعوة أصدقائك',
      referralText:
        '<0> احصل على ما يصل إلى 10 فتحات قارئ إضافية من خلال دعوة أصدقائك إلى BetaReader.io! </0> <1> لكل صديق يقوم بإنشاء حساب مؤلف تم التحقق من صحته وقوائم مخطوطة شكرًا لك ، سنمنحك أنت وصديقك فتحة قارئ واحدة لكل منها ، بحد أقصى 10 فتحات. </1> <2> يمكنك أيضًا رفع حد القارئ الخاص بك عن طريق قراءة ومراجعة 100 ألف كلمة عبر <1> <0> تطبيق القارئ </0> </1> أو عن طريق <3><0> ترقية </0> </3> حسابك! </2>',
      INVITED: 'مدعو',
      CREATED_ACCOUNT: 'تم إنشاء الحساب',
      CONFIRMED: 'مؤكد',
      RegistrationDate: 'تاريخ التسجيل',
      Profile: 'حساب تعريفي',
      EditProfile: 'تحرير الملف الشخصي',
      GetMoreReaders: 'الحصول على المزيد من القراء',
      Status: 'حالة',
      maxReadersLimitMessage:
        '<0>لقد وصلت إلى الحد الأقصى لعدد القراء. <1>الترقية</1> لإرسال المزيد من الدعوات في فهرس القراء أو الموافقة على طلبات القراءة. في خطتنا المجانية، يمكنك اقتراح <3>المبادلة</3> مع كتاب آخرين أو دعوة أي عدد تريده من القراء مباشرة من خلال عناوين بريدهم الإلكتروني.</0>',
      SwapsDontAffectReaderLimit:
        'لا يتم احتساب تبادل المخطوطات ضمن حد القارئ الخاص بك.',
      maxBooksLimitMessage:
        '<0> لقد وصلت إلى حد المخطوطة. <1> ارفع الحد </1> لتتمكن من اختبار العديد من المخطوطات بالتوازي. </0>',
      Type: 'يكتب',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> إلغاء قفل مكافأة التسجيل الخاصة بك! </0> <1> يبدو أنك قد أحيلت إليك من قبل صديق - اتبع الخطوات أدناه لإلغاء تأمين مكافأة تسجيلك المشترك! </ 1> <2> <0> <0 > </0> <1> <0> التحقق من صحة الحساب </0> <1> أنت بحاجة إلى اسم صالح وعنوان بريد إلكتروني </ 1> </1> </0> <1> <0> </0> <1> <0> سرد المخطوطة </0> <1> سرد المخطوطة التي تريد اختبارها </ 1> </1> </1> <2> <0> </0> <1> <0> انتظر </0> <1> يستغرق التحقق من الصحة حوالي 3 أيام </ 1> </1> </2> </2>',
      titleDiscoveryRecommended: 'موصى به لك',
      subheaderDiscoveryRecommended: 'شيء جديد يعتمد على الأنواع التي اخترتها',
      titleDiscoveryRecentlyAdded: 'تمت إضافتها مؤخرًا',
      subheaderDiscoveryRecentlyAdded: 'العناوين المضافة مؤخرا',
      discoveryRecentlyAddedTitlesInGenre:
        'العناوين المضافة مؤخرًا في النوع {{x}}',
      titleDiscoveryPremium: 'العناوين المميزة',
      subheaderDiscoveryPremium: 'الألقاب من قبل المؤلفين قسط',
      referralMessage:
        'أدعوك إلى BetaReader.io! إذا قمت بالتسجيل باستخدام هذا الرابط ، فسنحصل على مكافأة!',
      referralBody: 'اشترك هنا: {{x}}',
      ThisCannotBeUndone: 'لا يمكن التراجع عن هذا',
      DeletedVersion: 'تم حذف النسخة',
      InvalidEmail: 'بريد إلكتروني غير صالح',
      EmailNotVerified:
        'لم يتم التحقق، يرجى التحقق من البريد الإلكتروني الخاص بك',
      SelectFavoriteGenres: 'حدد الأنواع المفضلة لديك',
      Loading: 'تحميل',
      ChapterIdentification: 'تحديد الفصل',
      ChapterAnalysis: 'تحليل الفصل',
      CompleteImport: 'أكمل الاستيراد',
      Import: 'يستورد',
      Processing: 'جارٍ معالجة الملف...',
      ThisMightTakeAWhile: 'وهذا قد يستغرق بعض الوقت',
      LikeThisCover: 'مثل هذا غلاف الكتاب؟',
      DownloadFailedMsg:
        'فشل التنزيل. هل تم حظره بواسطة مانع النوافذ المنبثقة؟',
      ShareAuthorAppEmailSuccessMessage:
        'دعوة التي وجهت. تحقق من الحالة <1> هنا </1>.',
      failedToUpdateReader: 'فشل تحديث القارئ',
      activeReaders: 'القراء النشطين',
      ReadingList: 'قائمة القراءة',
      Users: 'المستخدمين',
      InviteUser: 'دعوة المستخدم',
      Role: 'دور',
      member_role_admin: 'المسؤول',
      member_role_member: 'عضو',
      couponsOrDiscountsWillNotBeKept:
        '<0>لن تتبع الخصومات الحالية الخطة الجديدة.</0>',
      FailedToFetchX: 'أخفق الحصول على {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'تمت دعوتك للانضمام إلى {{x}} حساب BetaReader.io.',
      AcceptAccountInvitationMsg:
        'إذا قبلت الدعوة ، فستتمكن من رؤية وتعديل جميع المخطوطات على هذا الحساب.',
      InvitedMembersMsg:
        'سيتمكن أعضاء الحساب من رؤية جميع المخطوطات الموجودة في حسابك وتحريرها ، لكنهم لن يروا أي تفاصيل خاصة بالفواتير. راجع betareader.io/pricing للحصول على معلومات حول التكلفة لكل عضو في الحساب.',
      InviteCollaborator: 'دعوة متعاون',
      InviteCollaboratorMsg:
        'يمكن للمتعاونين عرض تفاصيل المخطوطة والقارئ والرد على الملاحظات.',
      YouHaveBeenInvitedToCollaborateOnX:
        'تمت دعوتك للتعاون في {{book}} بواسطة {{author}}',
      AcceptCollaborationInvitationMsg:
        'إذا قبلت الدعوة ، فستتمكن من عرض تفاصيل المخطوطة والقارئ والرد على الملاحظات.',
      Collaborators: 'المتعاونون',
      ThisIsABetaFeature:
        'هذه ميزة تجريبية. اسمحوا لنا أن نعرف إذا كنت تواجه أي مشاكل معها ، أو إذا كان لديك أي ملاحظات للمشاركة.',
      UserNotFoundMsg: 'يبدو أنك لا تملك حسابًا. قم بإنشاء <1> هنا </ 1>!',
      AuthorName: 'اسم المؤلف',
      Details: 'تفاصيل',
      Settings: 'إعدادات',
      ReviewAtLeastXwords: '{{x}} الكلمات التي تمت مراجعتها',
      CheckBonus: 'تحقق من المكافأة الخاصة بك',
      FailedToAddCommentOrReaction: 'فشل في إضافة تعليق أو رد فعل',
      ThisIsAWIP: 'هذا هو العمل قيد التقدم',
      WIPExplanation:
        'ضع علامة على قصتك على أنها WIP (العمل قيد التقدم) عندما لا تنتهي، على سبيل المثال. عندما لم تقم بإضافة كافة الفصول.',
      MatureContent: 'للبالغين',
      PublicationDate: 'تاريخ النشر',
      TimePlan: 'الخطة الزمنية',
      NotSpecified: 'غير محدد',
      WhatIsThisStoryAbout: 'عم تدور تلك القصة؟',
      WhatDoYouNeedHelpWith: 'ماذا تحتاج للمساعدة؟',
      StartWriting: 'ابدأ بالكتابة',
      CreateManuscript: 'إنشاء مخطوطة',
      ImportManuscript: 'استيراد المخطوطة',
      ImportManuscriptStepDescription: 'قم بتحميل أو إنشاء مخطوطتك',
      InviteReaders: 'ادع القراء',
      InviteReadersStepDescription: 'عليك أن تقرر من يستطيع القراءة',
      CollectFeedback: 'جمع ردود الفعل',
      CollectFeedbackStepDescription: 'تحليل التعليقات وأنماط القراءة',
      YouDontHaveAnyManuscripts: 'ليس لديك أي مخطوطات بعد',
      AuthorsAndPublishers: 'المؤلفين والناشرين',
      FindOutWhatYourReadersThink: 'اكتشف ما يفكر فيه القراء حقًا في كتابك',
      ReadAndInfluenceTheNextBestseller:
        'قراءة والتأثير على الكتب الأكثر مبيعا القادمة',
      PrivateSharing: 'مشاركة خاصة',
      PrivateSharingDescription:
        'مشاركة المخطوطة الخاصة بك على انفراد مع القراء',
      CollectUnbiasedFeedback: 'جمع ردود فعل غير متحيزة',
      CollectUnbiasedFeedbackDescription:
        'اسمح لقرائك بالتعليق دون رؤية تعليقات بعضهم البعض',
      AnalyzeReadingPatterns: 'تحليل أنماط القراءة',
      AnalyzeReadingPatternsDescription:
        'تعرف على الأماكن التي يفقد فيها القراء اهتمامك أو تعامل مع القصة',
      ReadItFirst: 'اقرأها أولاً',
      ReadItFirstDescription:
        'قراءة الروايات القادمة في حين أنها لا تزال في مرحلة تجريبية',
      InfluenceTheStory: 'التأثير على القصة',
      InfluenceTheStoryDescription:
        'ردود الفعل الخاصة بك وسوف تؤثر على النتيجة النهائية',
      ConnectWithAuthors: 'ناقش مع المؤلفين',
      ConnectWithAuthorsDescription:
        'ساعد المؤلفين المفضلين لديك من خلال الانضمام إلى فريق الإصدار التجريبي',
      YourBooks: 'كتبك',
      New: 'جديد',
      Members: 'أفراد',
      Contacts: 'جهات الاتصال',
      AddMembersFromContacts: 'إضافة أعضاء من قائمة الاتصال الخاصة بك',
      ConversationSettings: 'إعدادات المحادثة',
      PremiumAuthorInfo:
        'هذه مخطوطة كتبها مؤلف متميز. هذا يعني عادةً أن المؤلف أكثر استثمارًا في المشروع ويمكنك أن تتوقع منهم أن يفعلوا ما يلزم لنشر هذا الكتاب.',
      StillWorkingOnThis:
        'نحن لا نزال في بناء خارج BetaReader.io. واسمحوا لنا أن نعرف إذا كان هناك أي شيء محدد كنت تريد أن ترى هنا.',
      Engagement: 'ارتباط',
      AssemblingLetters: 'تجميع الرسائل ...',
      ConstructingAlphabet: 'بناء الأبجدية ...',
      BuildingSentences: 'الجمل بناء ...',
      BecomingSentient: 'أصبح واع ...',
      BooksRead: 'قراءة الكتب: {{س}}',
      WordsReviewed: 'كلمات حول: {{س}}',
      AvgTurnAroundTimePer1kWords:
        'متوسط ​​المدة الزمنية لكل 1000 كلمة: {{عدد}} اليوم',
      AvgTurnAroundTimePer1kWords_plural:
        'متوسط ​​المدة الزمنية لكل 1000 كلمة: {{عدد}} أيام',
      ViewAsReader: 'مشاهدة قارئ',
      EditMode: 'وضع التحرير',
      BookCover: 'غلاف الكتاب',
      WelcomeToBRReadathon: 'مرحبا بكم في BetaReader.io Readathon!',
      ReadathonHasEnded: 'لقد انتهى الآن ريداثون.',
      ReadathonInfo:
        'وBetaReader.io Readathon تستمر ما بين 26 سبتمبر و 31 أكتوبر، 2019. ومن يقرأ ويستعرض كتاب واحد على الأقل عبر BetaReader.io خلال يشارك هذه المرة، وكنت قد يزيد من فرصتك في الفوز من خلال قراءة ومراجعة المزيد من الكتب. وسيتم اختيار الفائز في 1 نوفمبر، وأكثر من الكلمات كنت قد قرأت ومراجعتها من قبل ذلك الحين، هي أعلى فرصك للفوز. على سبيل المثال، شخص قد قرأ واستعرض 50،000 الكلمات سوف يكون 5X ديه فرصة كبيرة للفوز كمن قرأ واستعرض 10000 كلمة.',
      ReadathonPrize1: 'جائزة 1ST: $ 50 الأمازون بطاقة هدية.',
      ReadathonPrize23: '2 - جائزة 3: $ 20 الأمازون بطاقة هدية.',
      SignInToJoin: 'تسجيل الدخول إلى الانضمام',
      WordsReviewedByYou: 'الكلمات التي استعرضها لكم',
      WordsReviewedByEveryone: 'الكلمات مراجعتها من قبل الجميع',
      ChanceOfWinning: 'فرصة للفوز الحالية',
      Completed: 'منجز',
      CompletedAt: 'الانتهاء في',
      Pending: 'قيد الانتظار',
      Position: 'موضع',
      FindSomethingToRead: 'العثور على شيء لقراءة',
      DontKnow: 'لا اعرف',
      IsThisReadyToPublish: 'هل تعتقد أن هذا الكتاب جاهز للنشر؟',
      UpdateProfileHeader: 'نحن بحاجة الى بعض مزيد من المعلومات لملفك الشخصي',
      WhatsYourName: 'ما هو اسمك أو اسم مستعار؟',
      WhereAreYouFrom: 'من أي بلد أنت؟',
      WhenWereYouBorn: 'أي سنة لدت؟',
      WhichGenresDoYouPrefer: 'التي الأنواع تفضل؟',
      WhichLanguagesDoYouRead: 'اللغات التي تقرأ؟',
      BioPlaceholder:
        'أخبرنا من أنت وما إذا كان لديك أي مواقع ويب تشير إليها. سوف تساعدك الحيوية القوية في الحصول على موافقة لمزيد من المخطوطات.',
      Thanks: 'شكرًا!',
      WhenDoYouWantToHearFromUs: 'متى تريد أن تسمع منا؟',
      ReaderListConsentHeader:
        'هل تريد أن يتم تضمينك في قاعدة بيانات القراء لدينا؟',
      ReaderListConsentInfo:
        'سيتمكن المؤلفون الذين لديهم أعمال تتوافق مع تفضيلاتك من إرسال رابط كتبهم إليك، لكنك لست ملزمًا بالقراءة إذا كنت لا ترغب في ذلك أو ليس لديك الوقت.',
      RoleHeader: 'هل أنت كاتب أم قارئ؟',
      RoleInfo: 'نحن نستخدم هذه المعلومات لتحسين تجربتك.',
      OnYourProfile: 'سيتم عرض هذا في ملف تعريف المستخدم الخاص بك.',
      ProfileBuilderThankYouMessage:
        '<0>شكرا لك!</0><1>يمكنك دائما تحديث ملفك الشخصي <1>هنا</1>.</1>',
      DoThisLater: 'افعل هذا لاحقًا',
      '2MonthsFree': 'شهرين مجانا',
      Joined: 'انضم',
      UserInfo: 'معلومات المستخدم',
      Age: 'عمر',
      Bio: 'السيرة الذاتية',
      ReaderList: 'اعثر على القراء',
      Popular: 'شائع',
      BookHas0Words: '0 كلمة',
      BookHas0WordsDescription:
        '<0> هذا الكتاب نسخة واحدة على الأقل مع 0 الكلمات. يمكنك إضافة أو تحميل الفصول </0> <1> هنا </1>.',
      LeaveBeta: 'ترك بيتا',
      DiscoverNewBooks: 'اكتشاف كتب جديدة',
      FailedToFindReader: 'فشل العثور على القارئ',
      ApprovedReadRequest: 'تمت الموافقة على طلب القراءة',
      Approved: 'موافقة',
      UserAlreadyInvited: '{{user}} مدعو بالفعل',
      SelectBook: 'حدد الكتاب',
      InvitationMessagePlaceholder:
        'قم بتضمين رسالة شخصية في دعوتك لزيادة فرص قبولك.',
      YouHaveAPendingInvitation: 'لديك دعوة معلقة',
      ReaderIndexInfo:
        'تحتوي قاعدة بيانات القارئ على مستخدمي BetaReader.io الذين أشاروا إلى أنهم منفتحون للاتصال بهم من قبل المؤلفين. أرسل إليهم دعوة مع أفضل درجات الملعب ، لكن ضع في اعتبارك أن لديهم الحرية في رفض دعوتك لأي سبب.',
      ReaderLimitReached: 'لقد وصلت إلى حد القارئ الخاص بك',
      Invite: 'يدعو',
      Languages: 'اللغات',
      LinkWasSentToX: 'لقد أرسلنا رابط تسجيل الدخول إلى {{x}}',
      Verifying: 'جارٍ التحقق...',
      FoundNoAvailableManuscripts: 'لم نعثر على أي مخطوطات متاحة',
      FindASpecificQuestion: 'تصفية الأسئلة',
      ReadAndReviewBonusInfo:
        'تحديثات المكافآت READ & REVIEW بعد الانتهاء من مراجعة كتاب ومراجعته',
      ManuscriptStatus: 'حالة المخطوطة',
      MSStatusDescription_alpha: 'هذه مرحلة مبكرة أو مخطوطة جزئية.',
      MSStatusDescription_beta: 'هذه مخطوطة مرحلة متأخرة من كتاب كامل.',
      SelectStatus: 'اختر الحالة',
      MaxAllowedGenres: 'يمكنك تحديد ما يصل إلى 5 أنواع فقط',
      TooLongDescription: 'الوصف طويل جدًا (الحد الأقصى 1000 حرف)',
      Duplicate: 'ينسخ',
      Download: 'تحميل',
      DuplicateVersionInfo:
        'عند تكرار نسخة ، سيتم نسخ جميع الفصول والاستطلاعات إلى الإصدار الجديد.',
      NoBookMsg:
        '<0> لا يوجد شيء هنا! </0> <1> <0> هل تريد </0> <1> إضافة مخطوطة خاصة بك؟ </1> </1>',
      CommentsCopiedFromOriginal: 'تم نسخ التعليقات من المستند الأصلي',
      CopyComments: 'انسخ جميع التعليقات',
      MigrateReaders: 'ترحيل القراء',
      Posts: 'المشاركات',
      Sent: 'مرسل',
      Username: 'اسم المستخدم',
      UsernameAlreadyTaken: 'تم أخذ اسم المستخدم هذا',
      Followers: 'المتابعون',
      ReadersAndFollowers: 'القراء والمتابعين',
      WhoCanSeeThis: 'من يستطيع رؤية هذا؟',
      FollowCount: '{{count}} عدد أتباع',
      FollowCount_plural: '{{count}} عدد أتباع',
      Follow: 'يتبع',
      Following: 'التالية',
      MarkAllAsRead: 'وضع علامة على الكل كمقروءة',
      Working: 'عمل...',
      Audience: 'جمهور',
      ShowAllComments: 'عرض كافة التعليقات...',
      Publish: 'نشر',
      InviteByEmail: 'دعوة عن طريق البريد الإلكتروني',
      InviteByLink: 'الدعوة عن طريق الرابط',
      Chat: 'محادثة',
      WelcomeToSupportMessage:
        'مرحبًا {{x}}! مرحبًا بك في دعم Betareader 👋، نحاول الإجابة في أقرب وقت ممكن. ما الذي يمكننا مساعدتك به؟',
      TalkToSupport: 'تحدث إلى فريق الدعم لدينا',
      StartSupportChat: 'ابدأ محادثة الدعم',
      StartSupportChatPMessage:
        '<0>يمكنك دائمًا التواصل معنا عبر الدردشة وسنقوم بالرد في أقرب وقت ممكن.</0>',
      FindHelpArticlesMessage:
        '<0>هل تحتاج إلى مساعدة؟ راجع قسم <1> المساعدة </1>، المليء بمقالات المساعدة لإرشادك.</0>',
      HelpArticles: 'مقالات مساعدة',
      CloseConversationAlertHeader: 'محادثة قريبة',
      CloseConversationAlertMessage: 'هل تريد إغلاق هذه المحادثة؟',
      EmailSupportMessage:
        '<0> ارسل رسالة إلكترونية إلى <1> {{mail}} </ 1>، وسوف نقوم بالرد في أقرب وقت ممكن. </ 0>',
      Includes: 'يشمل',
      DoesNotInclude: 'لا يشمل',
      IncludesAll: 'يشمل الكل',
      RemoveFilter: 'قم بإزالة الفلتر',
      AddFilter: 'إضافة مرشح',
      SelectGenres: 'حدد الأنواع',
      And: 'و',
      ContinueWithGoogle: 'تواصل مع جوجل',
      ContinueWithFacebook: 'تواصل مع الفيسبوك',
      ContinueWithApple: 'تواصل مع أبل',
      NewOnBetareader: 'هل أنت جديد في BetarReader؟',
      AlreadyHaveAccount: 'هل لديك حساب بالفعل؟',
      SignUpWithFacebook: 'قم بالتسجيل باستخدام الفيسبوك',
      SignUpWithGoogle: 'قم بالتسجيل مع جوجل',
      SignUpWithApple: 'قم بالتسجيل مع أبل',
      OpportunityWithoutDescriptionMessage: 'المؤلف لم يقدم وصفا.',
      SignInToViewContent: 'قم بتسجيل الدخول لمشاهدة هذا المحتوى',
      ContentBelongsToAnotherAccount:
        'ينتمي هذا المحتوى إلى حساب مختلف عن حسابك النشط.',
      SwitchAccount: 'تبديل الحساب',
      OwnBooks: 'الكتب الخاصة',
      Collaborations: 'التعاون',
      UploadImage: 'تحميل الصورة',
      SplitChapter: 'اقسم الفصل',
      UpgradeToUnlock: 'قم بالترقية للفتح',
      '3ReaderSlots': '3 فتحات للقارئ',
      UnlimitedReaderSlots: 'فتحات قارئ غير محدودة',
      ImportWarning: 'تحذير الاستيراد',
      ImportExeedLenghMessage:
        '<0><0>يبدو أن واحدًا أو أكثر من فصولك طويل جدًا بحيث لا يمكن استيراده، تأكد من أنك اتبعت <1> إرشادات الاستيراد الخاصة بنا. </1></0><1>نصيحة: يمكنك تمرير مؤشر الماوس فوق نص الفصل واستخدام أداة الحجم لتقسيم الفصول الأكبر حجمًا</1></0>',
      OneTeamMember: '1 عضو في الفريق',
      AdditionalTeamMembers: 'أضف أعضاء الفريق',
      PerMonth: 'كل شهر',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'أرسل مخطوطتك',
      SubmitTo: 'يقدم إلى',
      YourSubmittedManuscripts: 'المخطوطات المقدمة الخاصة بك',
      SelectManuscriptToSubmit: 'حدد المخطوطة لتقديمها',
      AvailableToRead: 'متاح للقراءة',
      ManuscriptSubmissionSuccess: 'تم تقديم المخطوطة',
      ManuscriptSubmittedTo:
        'تم إرسال مخطوطتك ، {{_manuscriptName}} ، إلى {{_inboxName}}',
      SubmittedBy: 'تم الإرسال بواسطة {{_userName}}',
      ReceivedManuscripts: 'المخطوطات المستلمة',
      InviteToRead: 'دعوة للقراءة',
      YourReview: 'رأيك',
      UserReviewPlaceholder: 'تعليقات المستخدم مرئية لأي شخص.',
      LeaveReviewError:
        'يمكنك فقط مراجعة المستخدمين الذين أنهوا قراءة إحدى مخطوطاتك.',
      NotEnoughData: 'لا توجد بيانات كافية',
      EditSignUpSurveyQuestions: 'تحرير أسئلة استطلاع التسجيل',
      AddSignUpSurveyQuestions: 'أضف أسئلة استبيان التسجيل',
      To: 'ل',
      FinishedReadingAt: 'أنهيت القراءة في {{x}}',
      FailedToSendInvitation:
        'فشل إرسال الدعوة، يرجى التحقق من عنوان البريد الإلكتروني',
      EditInvitation: 'تعديل الدعوة',
      ChapterSeparator: 'فاصل الفصل',
      CancelReason: 'قبل المتابعة، هل تمانع في إخبارنا لماذا؟',
      cancel_reason_technical_issues: 'مشكلة تقنية',
      cancel_reason_too_expensive: 'المنتج مكلف للغاية',
      cancel_reason_finished_my_beta: 'انتهيت من مشروعي',
      cancel_reason_switching_to_another_product: 'انا التحول إلى منتج آخر',
      cancel_reason_missing_features: 'أنا في عداد المفقودين الميزات الهامة',
      cancel_reason_did_not_meet_expectations: 'لم منصة لا تفي توقعاتي',
      cancel_reason_other: 'أسباب أخرى',
      TellUsMore: 'إخبرنا المزيد',
      DeleteAccount: 'حذف الحساب',
      YourAccountHasBeenDeleted: 'لقد تم حذف حسابك',
      DeleteAccountConfirmation:
        'هل أنت متأكد أنك تريد حذف حسابك وجميع بياناته؟ لا يمكن التراجع عن هذا الإجراء.',
      GoodbyeMessage: 'نأسف لمغادرتك لنا ، ونأمل أن تعود في المستقبل.',
      EditContent: 'تحرير المحتوى',
      AffectedReadersMessage:
        '{{count}} قرأ القارئ هذا الفصل بالفعل. انقر هنا لنشر تحديث حول التغييرات الخاصة بك.',
      AffectedReadersMessage_plural:
        '{{عدد}} قرأ القراء هذا الفصل بالفعل. انقر هنا لنشر تحديث حول التغييرات الخاصة بك.',
      ImportingChapterXofY: 'استيراد الفصل {{_x}} / {{_y}}',
      ImportStarted: 'بدأ الاستيراد',
      XVersions: 'إصدار {{count}}',
      XVersions_plural: '{{count}} إصدارات',
      UnsubscribeSuccessful:
        'لقد تم إلغاء اشتراكك. يمكنك دائمًا تحديث تفضيلاتك عبر ملفك الشخصي.',
      UnsubscribeUnsuccessful:
        'فشل في إلغاء الاشتراك. أرسل رسالة إلى support@betareader.io إذا استمرت المشكلة.',
      GoodbyeHeader: 'مع السلامة!',
      BulkAddReaders: 'أضف قراء من CSV',
      Home: 'مسكن',
      Actions: 'أجراءات',
      Invitations: 'الدعوات',
      SelectCSVWithReaders: 'حدد CSV مع القراء',
      Synopsis: 'ملخص',
      SynopsisDescription:
        'اسمح للذكاء الاصطناعي الخاص بنا، BetaBot، بإنشاء ملخص بناءً على النص الخاص بك. ولن تكون مرئية لقرائك.',
      LastUpdated: 'آخر تحديث',
      CreateSynopsis: 'إنشاء ملخص',
      RefreshSynopsis: 'تحديث الملخص',
      ElevatorPitch: 'الملعب المصعد',
      ThisIsAPremiumFeature: 'ميزة متميزة',
      GoBack: 'عُد',
      Retry: 'أعد المحاولة'
    }
  },
  nb: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Vær oppmerksom på at når du meldinger til en leser ditt brukernavn og ikke ditt pseudonym, vil være synlig',
      Library: 'Bibliotek',
      Account: 'Konto',
      EmptyLibrary: 'Biblioteket er tom.',
      EmptyLibraryAction:
        'Ønsket du å finne noe her? Kontakt forfatteren, eller send oss ​​en e-post på {{_email}}, og vi får se hva vi kan gjøre.',
      ByAuthor: 'av {{_authorName}}',
      ExceedChapterLenghtWarning: 'Kapittelet er for langt til å importeres',
      WordCount: '{{_wordCount}} ord',
      WordCountString: '{{count}} ord',
      WordCountString_plural: '{{count}} ord',
      CharCountString: '{{count}} tegn',
      CharCountString_plural: '{{count}} tegn',
      SurveyCount: '{{count}} undersøkelse',
      SurveyCount_plural: '{{count}} undersøkelser',
      ChapterCount: '{{count}} kapittel',
      ChapterCount_plural: '{{count}} kapitler',
      QuestionCountString: '{{count}} spørsmål',
      QuestionCountString_plural: '{{count}} spørsmål',
      LoginToChangeEmail: 'Vennligst logg inn igjen for å endre din e-post',
      SendVerification: 'Send bekreftelseslink',
      Name: 'Navn',
      Email: 'E-post',
      EnterYourName: 'Vennligst skriv inn navnet ditt',
      ReceiveCompanyUpdates: 'Motta e-post og nyheter fra BetaReader',
      ReceiveBookEmails: 'Motta e-post om nye bøker',
      ReceiveBookActivityUpdates:
        'Motta e-poster om aktiviteten på bøkene mine',
      ReceiveMessageUpdates: 'Motta e-post når jeg får nye meldinger',
      IncludeProfileInReaderList: 'Inkluder profilen min i leserdatabasen',
      PrivacyPolicy: 'personvern',
      TermsOfService: 'Vilkår for bruk',
      ReadOurBlog: 'Les bloggen vår',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Neste',
      Back: 'Tilbake',
      CreateAccount: 'Opprett konto',
      CreateAccountPrompt:
        '<0>Har du ikke en konto?<1> <0>Opprett en her!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Glemt passord?<1> <0>Reset det her!</0></1></0>',
      SignIn: 'Logg inn',
      SignOut: 'Logg ut',
      SendResetLink: 'Send reset lenke',
      ResetPassword: 'tilbakestille passord',
      Password: 'Passord',
      OrUse: 'eller bruk',
      NoConnection: 'Offline ...',
      AcceptTermsMessage: 'Godta vilkårene for tjenesten',
      AcceptToSToCreateAccount:
        'Du må akseptere våre vilkår for å opprette en konto.',
      Answer: 'Svare',
      FailedToLoadBook: 'Kunne ikke laste boken.',
      PullDownToRefresh: 'Trekk ned for å oppdatere.',
      ContactSupport: 'Kontakt support',
      Support: 'Brukerstøtte',
      LastRead: 'Siste lese',
      XInvitations: '{{count}} invitasjon',
      XInvitations_plural: '{{count}} invitasjoner',
      EnterComment: 'Kommenter...',
      EnterReply: 'Svare...',
      Reply: 'Svar',
      Reply_plural: 'Svar',
      Reply_verb: 'Svare',
      show: 'vis',
      hide: 'skjul',
      Comments: 'Kommentarer',
      InlineComment: 'Inline kommentar',
      Reject: 'Avvis',
      Accept: 'Akseptere',
      Error: 'Feil',
      failed: 'mislyktes',
      SelectedText: 'Markert tekst',
      FailedToFetchUserProfile: 'Kunne ikke hente brukerprofil.',
      ProblemPersistContactSupport:
        'Hvis dette problemet vedvarer, kontakter du kundestøtte på:',
      clickToAddComment: 'Klikk her for å legge til kommentar',
      ColorMode: 'Farve-tilstand',
      FontSize: 'Skriftstørrelse',
      Discover: 'Oppdage',
      MyAccount: 'Min konto',
      MyManuscripts: 'Mine manuskripter',
      Sweden: 'Sverige',
      USA: 'USA',
      UK: 'Storbritannia',
      Germany: 'Tyskland',
      France: 'Frankrike',
      Spain: 'Spania',
      Country: 'Land',
      Gender: 'Kjønn',
      YoB: 'Fødselsår',
      PreferredGenres: 'Foretrukket sjangere',
      PreferredLanguages: 'Foretrukket språk',
      EmailPreferences: 'E-postpreferanser',
      chapters: 'kapitler',
      words: 'ord',
      YouShouldBetaRead: 'Du bør lese',
      onBetaReader: 'på BetaReader.io',
      Share: 'Dele',
      NoGuidelines: 'Forfatteren har ikke gitt noen retningslinjer.',
      Read: 'Lese',
      on: 'på',
      Author: 'Forfatter',
      JoinBeta: 'Jeg vil lese dette',
      joinConsentString:
        'Jeg forstår at ved å be om å bli med i denne betaen, vil jeg dele e-postadressen min med forfatteren.',
      Submit: 'Send inn',
      AnswerSurvey: 'Svare!',
      SendJoinRequest: 'Sende',
      IJustWantToRead: 'Jeg vil bare lese',
      CopyrightInfo: 'Opphavsrettsinformasjon',
      RequestToRead: 'Send leseforespørsel',
      Feedback: 'Tilbakemelding',
      NoComment: 'Ingen har kommentert.',
      OnceUponATime: 'Det var en gang...',
      Review: 'Gjennomgå',
      Overall: 'Totalt sett',
      Plot: 'Intrige',
      Grammar: 'Grammatikk',
      Title: 'Tittel',
      AddReview: 'Legg til en anmeldelse',
      EnterReviewText: 'Skriv inn en kort anmeldelse',
      OverallRating: 'Totalt sett',
      OverallRatingRequired: 'Legg til totalvurdering',
      PlotRating: 'Intrige',
      GrammarRating: 'Grammatikk',
      SubmitReview: 'Send inn anmeldelse',
      LeaveReview: 'Legg igjen en anmeldelse',
      checkOutAuthorApp:
        '<0>Er du en forfatter?</0><1></1><2>Sjekk ut vår <1>app for forfatterne</1></2>',
      MyLibrary: 'Mitt bibliotek',
      ReadingPreferences: 'Lesepreferanser',
      'E.g.': 'f.eks.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Jeg ønsker å motta e-poster fra BetaReader.io når forfatteren gjør endringer eller oppdateringer til',
      'I want to receive emails about BetaReader news and updates.':
        'Jeg ønsker å motta e-poster om BetaReader-nyheter og oppdateringer.',
      'I want to receive emails about new books that match my reading preferences.':
        'Jeg ønsker å motta e-poster om nye bøker som samsvarer med mine lesepreferanser.',
      'this book': 'denne boken',
      'Email us on': 'Send oss ​​en e-post',
      "and we'll see what we can do :)": 'så får vi se hva vi får til :)',
      'Want an author account?': 'Vil du ha en forfatterkonto?',
      Preferences: 'Preferanser',
      Language: 'Språk',
      Browse: 'Bla gjennom',
      'to beginning': 'til begynnelsen',
      'to chapter...': 'til kapittel...',
      Location: 'Sted',
      Previous: 'Tidligere',
      Finish: 'Fullfør',
      Description: 'Beskrivelse',
      FeedbackGuidelines: 'Retningslinjer tilbakemelding',
      'Start reading': 'Begynn å lese',
      StartReading: 'Begynn å lese',
      LeaveComment: 'Kommentar',
      replies: 'svarer',
      Show: 'Vise',
      Hide: 'Gjemme',
      'Add Reply': 'Legg til svar',
      PendingInvitation: 'Ventende invitasjon',
      Decline: 'Avslå',
      DeclinedInvitationMessage:
        'Du har avslått denne invitasjonen til å lese, men du kan fortsatt godta den hvis du har ombestemt deg.',
      Welcome: 'Velkomst',
      by: 'ved',
      to: 'til',
      AcceptInvitation: 'Godta invitasjon',
      InvitationAccepted: 'Invitasjon akseptert',
      WaitForBook: 'Vennligst vent mens vi forbereder boken din',
      PasswordLength: 'Passordet ditt må være minst 6 tegn langt',
      AgreeToPolicyAndTerms:
        'Du må godta BetaReaders personvernpolicy og vilkår for bruk for å registrere deg.',
      'Sending...': 'Sender...',
      VerificationSent: 'Bekreftelses-e-post sendt.',
      VerificationEmailFailed: 'Kunne ikke sende bekreftelses-e-post.',
      IAcceptToC:
        '<0>Jeg aksepterer BetaReader.io:s <1>Personvern</1> & <3>vilkår</3></0>',
      AccountSettings: 'Kontoinnstillinger',
      ContactSettings: 'Kontaktinnstillinger',
      Chapters: 'Kapitler',
      Options: 'Alternativer',
      Option: 'Alternativ',
      BetaLanguageMessage:
        '<0>Vi jobber med å bedre språkstøtte, og er super takknemlig for all hjelp vi kan få. Hvis du finner noen feil på nettstedet, ikke nøl med å kontakte oss på: <1>hello@betareader.io</1></0>',
      Genres: 'Sjangere',
      PressEnterToSave: 'Trykk Enter for å lagre',
      female: 'hunn',
      male: 'mann',
      other: 'ikke-binær',
      DontWantToShare: 'privat',
      SignInWithPassword: 'Logg på med passord',
      GoToBook: 'Gå til boken',
      Requested: 'Forespørselen din venter',
      AnsweredOrRequestedNotice:
        'Du har bedt om å bli med i denne betaversjonen. Forfatteren vil komme tilbake til deg så snart de kan.',
      RequestedWithDifferentEmail:
        'Du har bedt om å bli med i denne betaen med en annen e-post enn du ble invitert med. Forfatteren vil komme tilbake til deg så snart de kan.',
      LoadingLibrary: 'Ser etter bøker...',
      PreparingBook: 'Forbereder bok',
      InLineCommentPlaceholder:
        'Legg igjen kommentaren din og trykk enter for å lagre',
      ExitSurvey: 'Exit-undersøkelse',
      WhyExitQuestion: 'Hvorfor vil du forlate denne betaversjonen?',
      LostInterest: 'Jeg mistet interessen',
      NotEnoughTime: 'Jeg har ikke nok tid',
      Other: 'Annen grunn',
      reason_lost_interest: 'Jeg mistet interessen',
      reason_no_time: 'Jeg har ikke nok tid',
      reason_other: 'Annen grunn',
      reason_: 'Ikke spesifisert',
      ExitFeedbackQuestion: 'Vil du gi noen tilbakemeldinger til forfatteren?',
      ExitFeedbackPlaceholder: 'All tilbakemelding er nyttig',
      ThankYouForYourFeebdack: 'Takk for tilbakemeldingen!',
      AppVersion: 'Versjon',
      AppBuildNumber: '#',
      Like: 'Like',
      Love: 'Kjærlighet',
      Laugh: 'Haha',
      Surprise: 'Overraskelse',
      Sad: 'Trist',
      Angry: 'Sint',
      NoBooksInList: 'Ingen bøker her.',
      Update: 'Oppdater',
      Overview: 'Oversikt',
      Content: 'Innhold',
      Readers: 'Lesere',
      NotificationSettings: 'Varslingsinnstillinger',
      SendEmailOnComments: 'Send e-post når lesere legger igjen kommentarer',
      SendEmailOnSurveyComplete:
        'Send e-post når leserne har fullført en undersøkelse',
      CoverUploadMsg:
        'Klikk for å laste opp forsidebildet ditt (png eller jpg, 980x1568px), eller slipp det i dette området.',
      DefaultNamePlaceholder: 'Standard: {{displayName}}',
      SelectUpTo5Genres: 'Velg opptil 5 sjangere',
      AdvancedSettings: 'Avanserte innstillinger',
      EnableComments: 'Aktiver kommentarer',
      EnableBookReviews: 'Aktiver bokanmeldelser',
      DeleteBook: 'Slett boken',
      DeleteCover: 'Slett bokomslag',
      DeleteCoverConfirmation: 'Er du sikker på at du vil slette bokomslaget?',
      YesBinIt: 'Ja, slett den!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Er du sikker på at du vil slette <1>{{bookTitle}}</1></0>',
      DeletedBookX: '{{x}} har blitt slettet.',
      No: 'Ingen',
      Yes: 'Ja',
      UserCommentedOn: 'kommentert',
      ShowXReplies: 'Vis {{count}} svar',
      ShowXReplies_plural: 'Vis {{count}} svar',
      HideXReplies: 'Skjul {{count}} svar',
      HideXReplies_plural: 'Skjul {{count}} svar',
      NoInLineComments: 'Ingen innebygde kommentarer.',
      Chapter: 'Kapittel',
      NoCommentsToShow: 'Ingen kommentarer å vise.',
      Save: 'Spare',
      TooLongTitle: 'Tittelen din er for lang (maks 60 tegn)',
      ClickToUploadDoc:
        'Klikk for å legge til eller slippe docx-filen for å importere den',
      LongChapterWarning: 'Langt kapittel, er dette riktig?',
      Upload: 'Last opp',
      UploadChapters: 'Last opp kapitler',
      ImportBooksHelpMsg:
        'Ingen vil kunne se manuskriptet ditt uten din godkjenning. Les om <1> importere bøker </1>.',
      Add: 'Legge til',
      NewChapter: 'Nytt kapittel',
      NewSurvey: 'Ny undersøkelse',
      SelectedPart: 'valgt del',
      SelectPart: 'Velg del',
      NotifyMe: 'Gi meg beskjed',
      WhenReadersStart: 'ved start',
      WhenReadersComplete: 'på slutten',
      ManageChapter: 'Administrer kapittel',
      ManageSurvey: 'Administrer undersøkelse',
      ManageReaders: 'Administrer lesere',
      MoveUp: 'Flytt opp',
      MoveDown: 'Flytt ned',
      SaveSurvey: 'Lagre undersøkelsen',
      Saved: 'Lagret',
      EnterSurveyName: 'Gi undersøkelsen et navn',
      EnterSurveyDescription: 'Beskriv undersøkelsen din',
      SelectAtLeast: 'Velg minst',
      SelectAtMost: 'Velg på det meste',
      LabelOptional: 'Etikett (valgfritt)',
      MyBooks: 'Bøkene mine',
      ChangeRoleToWriterToCreateBooks:
        'Vennligst bytt rolle til forfatter for å lage bøker.',
      UsingXOutOfYBooks: 'Du bruker {{x}} av {{y}} bøker.',
      UsingXOutOfYReaders: 'Du bruker {{x}} av {{y}} lesere.',
      NothingHere: 'Ingenting her for øyeblikket.',
      OpenReaderWeb: 'Åpne leseren',
      PrivacyLevel: 'Deling status',
      PublishingStatus: 'Deling status',
      PrivacyLevel_infotext_unpublished:
        '<0> Boken din er frakoblet. Flytt den til <1> privat </1>, <3> lukket </3> eller <5> oppført </5> for at leserne skal kunne finne den. </0>',
      PrivacyLevel_infotext_private:
        '<0> Boken er ikke oppført noe sted. Bare du kan invitere lesere. </0>',
      PrivacyLevel_infotext_closed:
        '<0> Boken presentasjon kan sees av alle med <1> lenken </1>. Leserne kan be om en invitasjon, men du må godkjenne hver forespørsel. </0>',
      PrivacyLevel_infotext_listed:
        '<0> Boken er oppført <1>her</1>. Leserne kan be om en invitasjon, men du må godkjenne hver forespørsel. </0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Privat',
      PrivacyLevel_state_closed: 'Lukket',
      PrivacyLevel_state_listed: 'Oppført',
      SendReminder: 'Send påminnelse',
      YouCanSendAReminderEvery3Days:
        'Du kan sende en påminnelse hver tredje dag',
      LastSeenAt: 'Sist sett på',
      ReminderSentAt: 'Påminnelse sendt',
      XReadersInactiveForYDays:
        '{{count}} leseren har ikke ferdig bok og ikke har vært aktiv i løpet av de siste {{days}} dager.',
      XReadersInactiveForYDays_plural:
        '{{count}} lesere har ikke ferdig med boken, og har ikke vært aktiv de siste {{days}} dager.',
      InactiveReaders: 'Inaktive lesere',
      XReadersNotStartedForYDays:
        '{{count}} leser har ikke godtatt mer enn {{days}} dager gammel invitasjon',
      XReadersNotStartedForYDays_plural:
        '{{count}} lesere har ikke godtatt mer enn {{days}} dager gammel invitasjon',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Lesere som ikke har takket ja til invitasjonen',
      YouHaveXJoinRequests: 'Du har {{count}} forespørsel til å lese boken din',
      YouHaveXJoinRequests_plural:
        'Du har {{count}} forespørsler for å lese boken din',
      JoinRequests: 'Lesforespørsler',
      Approve: 'Vedta',
      RequestedAt: 'Forespurt',
      NoAnswer: 'Ikke noe svar',
      SendSurveyReminder: 'Send spørreundersøkelse-påminnelse',
      Remove: 'Fjerne',
      Created: 'Laget',
      RequestedToRead: 'Ba om å lese',
      InvitationSent: 'Invitasjon sendt',
      Reminded: 'Minnet',
      Question: 'Spørsmål',
      YouCanAskUpToXQuestions:
        'Du kan legge til {{maxQuestions}} spørsmål som leserne er pålagt å svare når du registrerer deg.',
      NewQuestion: 'Nytt spørsmål',
      ReaderSignUpForm: 'Påmeldingsskjema',
      NoReadersFound: 'Ingen lesere funnet',
      ReachedPartTitle: 'Nådd {{partTitle}}',
      ReasonToQuit: 'Grunn',
      YourBookDoesNotHaveAnyChaptersYet: 'Boken din har ingen kapitler ennå',
      Invited: 'Invitert',
      Started: 'Startet',
      Finished: 'Ferdig',
      Abandoned: 'Forlatt',
      AvgOverallRating: 'Totalt sett',
      AvgPlotRating: 'Intrige',
      AvgGrammarRating: 'Grammatikk',
      YouDoNotHaveReviewsYet: 'Du har ingen anmeldelser ennå.',
      PersonalLink: 'Personlige link',
      HelpUsReachMoreWriters:
        'Liker du å bruke BetaReader.io? Bruk din personlige lenke for å hjelpe oss å hjelpe flere forfattere!',
      Reports: 'Rapporter',
      WeNeedYourEmail: 'E-post er nødvendig',
      MissingEmailMsg:
        'Vi må kunne kontakte deg angående kontoen din. Vi vil ikke dele det med noen, og bare kontakte deg hvis det er strengt nødvendig.',
      EmailNotVerifiedPrompt:
        'Send e-post ikke bekreftet. <1> Klikk her for å sende </1> e-postbekreftelsen. (Sjekk din spam mappe hvis du ikke får det)',
      Skip: 'Hopp over',
      RoleWriter: 'Forfatter',
      RoleReader: 'Leser',
      RoleBoth: 'Både',
      AddReader: 'Legg til ny leser',
      DownloadReaderList: 'Last ned leserliste',
      'Show only': 'Vis kun',
      StatusNEW: 'Ny',
      StatusTODO: 'Å gjøre',
      StatusDOING: 'Gjør',
      StatusDONE: 'Ferdig',
      StatusIGNORE: 'Overse',
      RemoveFilters: 'Fjern filtre',
      ReadingDataReport: 'Leser data',
      SurveyAnswersReport: 'Undersøkelsessvar',
      Reviews: 'Anmeldelser',
      AbandonedReadersReport: 'Mistede lesere',
      SignUpMsg:
        '<0>Takk for at du registrerte deg!</0> <1>En bekreftelses-e-post er blitt sendt til <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Kopierte {{x}} til utklippstavlen.',
      DoYouWantToBeMyBetaReader: 'Vil du være min beta-leser?',
      LookingForBetaReadersForBookTitle:
        'Jeg leter etter beta lesere for {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Jeg leter etter beta lesere for {{bookTitle}}. Følg denne linken for å lese mer og registrere deg: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Sosial deling er ikke mulig for private bøker.',
      ShareTheLinkToYourBook: 'Del linken til boken din!',
      Messages: 'Meldinger',
      FailedToLoadMessage: 'Vi kunne ikke laste inn denne meldingen.',
      FailedToSendMessage: 'Vi kunne ikke sende meldingen din.',
      FailedToStartConversation: 'Vi klarte ikke å starte samtalen.',
      NewMessage: 'Ny melding',
      MessageSubject: 'Tema',
      MessageBody: 'Skriv meldingen din...',
      MessageFrom: 'Fra',
      MessageTo: 'Til',
      SendMessage: 'Sende',
      Message: 'Beskjed',
      ReaderMarketplaceBetaInfoText:
        'Vi eksperimenterer med å la våre forfattere betale vanlige lesere og bokormer en symbolsk sum for tjenesten med å lese et manuskript. Vi tilbyr kontrollmekanismer for å validere tilbakemeldingene og kontrollere at leserne har lest hele manuskriptet før noen får betalt. Hvis du vil prøve denne funksjonen, vennligst angi hvor mange lesere du trenger.',
      ReaderMarketplaceBetaFullText:
        'Dette programmet er for øyeblikket fullt. Vil du at vi skal varsle deg når vi får åpning?',
      Sum: 'Sum',
      XUSDPerReader: '${{x}} / leser',
      AmountOfReaders: 'Antall lesere',
      PayForTestReaders: 'Betal for testlesere',
      GoToCheckout: 'Gå til kassen',
      YesPlease: 'Ja takk!',
      BETA: 'BETA',
      NoMessages: 'Du har ingen meldinger',
      OnBoarding_import_book:
        '<0> Velkommen til BetaReader.io! </0> For å dele boken din, må du først importere den, enten ved <2> laste den </2>, eller ved å opprette det direkte på plattformen. Gå videre og prøve det ut! Alt på BetaReader.io er private som standard, slik at ingen vil se det med mindre du inviterer dem.',
      Onboarding_book_overview:
        'Bokoversikten er der du konfigurerer detaljer om boken din. Tittel, sjangere og retningslinjer for tilbakemeldinger går her, men også om du vil at leserne skal kunne kommentere og legge igjen anmeldelser.',
      Onboarding_book_content:
        'Innhold-fanen er der du kontrollerer rekkefølgen på tingene. Legg til kapitler og undersøkelser her.',
      Onboarding_book_readers:
        'Lesere-fanen er der du kontrollerer hvem som får lese boken din. Send private invitasjoner, del registreringslinken din via Twitter, eller legg til boken din på oppdagelseslisten vår for å nå flere lesere.',
      Onboarding_book_feedback:
        'Tilbakemeldingsfanen gir deg en oversikt over alle tilbakemeldingene som leserne har lagt igjen. Svar på kommentarer og filtrer bort uviktige ting.',
      Onboarding_book_reports:
        'Rapport-fanen gir deg en oversikt over hvordan det går med boken din. Hvor mange lesere som har blitt invitert, hvor mange har begynt å lese, skrevet strålende anmeldelser, og så videre.',
      Close: 'Lukke',
      Last: 'Siste',
      DuplicatedBook: '<0> <0> </0> kopiert boken {{bookTitle}} </0>',
      PenName: 'Pennenavn',
      MissingNameMsg:
        'Du må skrive inn et navn for å kunne kommunisere med leserne dine.',
      NameConversation: 'Gi denne samtalen navn',
      With: 'med...',
      SelectPeople: 'Utvalgte personer',
      UploadAsNewVersion: '...som ny versjon',
      UploadAsExtraChapters: '...til denne versjonen',
      Cancel: 'Kansellere',
      Unnamed: 'ikke navngitt',
      NewVersion: 'Ny versjon',
      NoContacts: 'Ingen kontakter',
      GetContacts:
        'Forfattere av bøkene du leser, og lesere av bøkene du skriver, blir kontaktene dine.',
      NoConversations: 'Ingen samtaler...',
      AllVersionsNeedContent:
        'Din nåværende versjon trenger innhold før du kan lage en ny.',
      InviteToVersion: 'Inviter til versjon',
      latestVersion: 'siste',
      VersionName: 'Versjon {{x}}',
      Reader: 'Leser',
      StartedReading: 'Startet lesing',
      CurrentPosition: 'Nåværende posisjon',
      PickVersion: 'Velg versjon',
      Filters: 'Filtre',
      Versions: 'versjoner',
      ChapterInitial: 'k',
      AvgTimeToComplete: 'Gjennomsnittlig tid til å lese kapittel',
      ReaderProgress: 'Posisjoner',
      SelectFilter: 'Velg et filter',
      CreatePost: 'Del en oppdatering',
      NewBookPostPlaceholder: 'Hva vil du si?',
      BookUpdateForX: 'Oppdatering for bok {{x}}',
      OnSaveChapterMessage: 'Fortell leserne dine hva du har endret',
      SampleChapterUpdatePost: 'Jeg endret dette...',
      SaveWithoutPosting: 'Lagre uten å legge ut...',
      SaveAndPost: 'Lagre og legg ut...',
      DiscoverSearch: 'Søk etter en tittel eller sjanger...',
      LoadMore: 'Last inn mer...',
      PendingReadInvitations: 'Ventende invitasjoner',
      PendingReadRequests: 'Ventende forespørsler',
      UserReactedOn: 'reagerte på',
      ChapterComment: 'Kapittelkommentar',
      InlineCommentNotFound:
        'Denne kommentaren ble ikke funnet i teksten. Er den kommenterte teksten fjernet?',
      Or: 'Eller',
      Version: 'Versjon',
      Updates: 'Oppdateringer',
      CurrentBetaIsFullMessage:
        'Denne betaversjonen er for øyeblikket full, vennligst kontakt forfatteren for å åpne opp flere spilleautomater.',
      Edit: 'Redigere',
      Delete: 'Slett',
      DropFilesHere: 'Slipp filene dine her',
      ShowOnly: 'Vis kun',
      FeedbackSearch: 'Tekstsøk',
      'BuySubscriptionToS&PPMessage':
        '<0>Ved å kjøpe et abonnement fra BetaReader.io indikerer du at du har lest og godtatt våre<1> vilkår for bruk</1> og <5>personvernregler</5></0>',
      Inbox: 'Innboks',
      CofirmMovePart:
        'Vil du virkelig flytte denne delen? Det vil påvirke følgende lesere:',
      'AreYouSure?': 'Er du sikker?',
      LetYourReadersKnowWhatYouChanged:
        'Fortell leserne dine hva du har endret',
      ThisChangeAffectsTheFollowingReaders:
        'Denne endringen påvirker følgende lesere:',
      MovedXFromYtoZ: 'Flyttet {{x}} fra {{y}} til {{z}}',
      Subscription: 'Abonnement',
      Subscriptions: 'Abonnementer',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Grunnleggende plan, for alltid fri.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Bra for hobby-perfeksjonister',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Great for professional indie writers',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Perfekt for utgivere',
      CancelSubscription: 'Avbestille abonnementet',
      YourSubscriptionHasBeenCancelled: 'Abonnementet ditt er kansellert',
      CancelSubscriptionConfirmation:
        'Er du sikker på at du vil kansellere abonnementet ditt?',
      ValidUntil: 'Gyldig til',
      UpgradeSubscriptionTitle: 'Oppgrader',
      UpgradeSubscriptionPromoText: 'Oppgrader din konto',
      Abort: 'Avbryt',
      BuyNow: 'Kjøpe',
      Current: 'Nåværende plan',
      Monthly: 'Månedlig',
      Yearly: 'Årlig',
      monthly: 'månedlig',
      yearly: 'år',
      month: 'måned',
      XCheaper: '{{x}} billigere!',
      Downgrade: 'Nedgradere',
      Forever: 'for alltid',
      ChangePlan: 'Endre plan',
      TrackReaderProgress: 'Følg leserne dine i sanntid',
      InlineComments: 'In-line kommentarer og reaksjoner',
      ReaderSurveys: 'Leserundersøkelser',
      NativeApps: 'Android og iOS app',
      MultipleVersions: 'Manuskriptversjonering',
      '1ActiveManuscript': '1 manuskript',
      '5ActiveManuscripts': 'Inntil 5 manuskripter',
      '20ActiveManuscripts': 'Opptil 20 manuskripter',
      UnlimitedActiveManuscripts: 'Ubegrenset manuskripter',
      '3ActiveReadersPerManuscript': 'Opptil 3 lesere',
      '20ActiveReadersPerManuscript': 'Opptil 20 lesere',
      '50ActiveReadersPerManuscript': 'Opptil 50 lesere',
      UnlimitedActiveReaders: 'Ubegrenset antall lesere',
      Zero: 'Ingenting',
      NoCreditCardNeeded: 'Ingen kredittkort nødvendig',
      Recommended: 'Anbefalt',
      SomethingWentWrong: 'Noe gikk galt',
      SubscriptionChangeError: 'Kunne ikke endre abonnementet',
      SubscriptionChangeSuccess: 'Vi oppdaterte abonnementet ditt',
      ActionCancelled: 'Handlingen ble kansellert',
      Cancelled: 'Kansellert',
      NextPayment: 'Neste betaling',
      PaymentPastDueMessage:
        'Betalingen er forfalt. Neste forsøk: {{nextAttempt}}',
      ChangePaymentInformation: 'Endre faktureringsdetaljer',
      DashboardWelcomeMessage:
        '<0> Velkommen til BetaReader.io! </ 0> <1> Start med <2> opplasting </ 2> eller <6> legge </ 6> manuskriptet </ 1> <2> Discovery </ 2> < 3> Ikke glem å liste manuskriptet i vår <2> oppdagelsen </ 2> delen, for å få tilgang til mer beta lesere. </ 3> <4> <0> Her </ 0> er en rask gjennomgang av hvordan du gjøre det. </ 4> <5> Support </ 5> <6> Vennligst sjekk ut vår <2> Hjelp </ 2> portal eller pinge oss direkte ved å trykke på (?) øverst til høyre på siden. < / 6> <7> Du kan alltid sende oss en vennlig mail på <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Manuskripter',
      CurrentlyReading: 'Leser for tiden',
      WeeklyReaders: 'Ukentlige lesere',
      TotalReaders: 'Totalt lesere',
      FailedToDownloadReport: 'Kunne ikke laste ned rapporten',
      WaitingForConfirmation: 'Venter på bekreftelse',
      ChangePublishingStatusToAddReaders:
        'Endre publiseringsstatus for å legge til lesere',
      SuggestASwap: 'Foreslå bytte',
      WithdrawRequest: 'Trekk forespørsel',
      Confirm: 'Bekrefte',
      ThisIsASwapRequestForX:
        'Tämä on vaihtopyyntö <2>{{x}}</2>. Jos hyväksyt swap-pyynnön, sinut lisätään lukijana {{x}}.',
      ApprovedSwapRequestForX: 'Tämä on vaihtopyyntö <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Dette er en bytteforespørsel. Hvis du godkjenner denne forespørselen, vil du bli lagt til som leser for:',
      SwapRequest: 'Swap avtale',
      ApproveAndJoin: 'Godkjenne og bli med',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} har tilbudt å lese {{y}} i bytte for at du leser denne boken.',
      GoToSwapRequest: 'Gå til bytteforespørsel',
      SwitchVersion: 'Bytt versjon',
      SendMagicLinkToX: 'Send en magisk lenke til {{x}}',
      notification_verb_comment: 'kommenterte på',
      notification_verb_react: 'reagerte på',
      notification_verb_reply: 'svarte på en kommentar på',
      notification_verb_abandon: 'forlatt boken din',
      notification_verb_request_to_join: 'har bedt om å lese',
      notification_verb_message: 'sendte deg en melding',
      notification_verb_sent_join_approval:
        'har godkjent forespørselen din om å lese',
      notification_verb_invitation_to_join: 'inviterte deg til å lese {{y}}',
      notification_verb_manuscript_submitted:
        'har sendt inn et manuskript til {{y}}',
      'SaveAnd...': 'Lagre og...',
      Send: 'Sende',
      OnlySave: 'Bare lagre',
      YourNotifications: 'Dine meldinger',
      Notifications: 'Varsler',
      Images: 'Bilder',
      '365DaysReadingData': '365-dagers leserhistorikk',
      '90DaysReadingData': '90-dagers leserhistorikk',
      '30DaysReadingData': '30-dagers leserhistorikk',
      alerts_PaymentPastDue:
        'Betalingen din er forfalt. Oppdater faktureringsdetaljene dine',
      alerts_MissingName:
        'Vi mangler navnet ditt (eller pennenavnet). Oppdater den her:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} igjen for å kreve din levetidsrabatt. Skriv inn LANSERING i kupongfeltet når du oppgraderer:',
      RequestedToSwapForX: 'Du har bedt om å bytte denne for {{x}}',
      PaymentSuccessMessage: '<0><0>Takk for kjøpet!</0><1><0></0></1></0>',
      Dashboard: 'Startside',
      year: 'år',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Hvor sannsynlig er det at du anbefaler BetaReader.io til en venn?',
      NotAtAllLikely: 'Ikke sannsynlig',
      ExtremelyLikely: 'Ekstremt sannsynlig',
      ThanksDoYouWantToTellUsWhy: 'Takk! Vil du fortelle oss hvorfor?',
      Create: 'Skape',
      Books: 'Bøker',
      Feed: 'Strømme',
      ANewStory: 'en ny historie',
      FailedToGetInvitations: 'Kunne ikke laste ned invitasjoner',
      unauthorizedPrivateBook:
        'Denne boken er privat og ikke tilgjengelig for denne kontoen.',
      invalidToken:
        'Invitasjonen er ikke gyldig for denne kontoen eller har utløpt.',
      RaiseYourReaderLimit: 'Øk lesergrensen din (for øyeblikket {{x}})',
      InviteYourFriends: 'Inviter vennene dine',
      referralText:
        '<0> Få opptil ti ekstra leserplasser ved å invitere vennene dine til BetaReader.io! </0> <1> For hver venn som oppretter en validert forfatterkonto og viser et manuskript takket være deg, vil vi gi deg og din venn 1 leserspor hver, opp til maksimalt 10 plasser. </1> <2> Du kan også øke lesergrensen ved å lese og gjennomgå 100 000 ord via <1> <0> leserappen </0> </1>, eller ved å <3><0> oppgradere</0> </3> kontoen din! </2>',
      INVITED: 'Invitert',
      CREATED_ACCOUNT: 'Opprettet kontoen',
      CONFIRMED: 'Bekreftet',
      RegistrationDate: 'Registreringsdato',
      Profile: 'Profil',
      EditProfile: 'Rediger profil',
      GetMoreReaders: 'Få flere lesere',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Du har nådd lesergrensen din. <1>Oppgrader</1> for å sende flere invitasjoner i leserindeksen eller godkjenne leseforespørsler. I vår gratis plan kan du foreslå å <3>bytte</3> med andre forfattere eller invitere så mange lesere du vil ha direkte gjennom sin e-postadresse.</0>',
      SwapsDontAffectReaderLimit:
        'Manuskriptbytte teller ikke med i lesergrensen.',
      maxBooksLimitMessage:
        '<0> Du har nådd manuskriptgrensen din. <1> Hev grensen </1> for å kunne teste flere manuskripter parallelt. </0>',
      Type: 'Type',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> Lås opp registreringsbonus! </ 0> <1> Det ser ut til at du ble henvist av en venn - følg trinnene nedenfor for å låse opp din gjensynlige bonus! </ 1> <2> <0> <0 > </ 0> <1> <0> Bekreft konto </ 0> <1> Du trenger et gyldig navn og en e-postadresse </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Liste manuskript </ 0> <1> Skriv manuskriptet du vil teste </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> Vent </ 0> <1> Det tar ca 3 dager for oss å validere </ 1> </ 1> </ 2> </ 2>',
      titleDiscoveryRecommended: 'Anbefalt for deg',
      subheaderDiscoveryRecommended: 'Noe nytt basert på dine valgte sjangere',
      titleDiscoveryRecentlyAdded: 'Nylig lagt til',
      subheaderDiscoveryRecentlyAdded: 'Nylig lagt til titler',
      discoveryRecentlyAddedTitlesInGenre:
        'Nylig lagt til titler i sjanger {{x}}',
      titleDiscoveryPremium: 'Utvalgte titler',
      subheaderDiscoveryPremium: 'Titler av premiumforfattere',
      referralMessage:
        'Jeg inviterer deg til BetaReader.io! Hvis du registrerer deg ved hjelp av denne lenken, får vi begge en bonus!',
      referralBody: 'Registrer deg her: {{x}}',
      ThisCannotBeUndone: 'Dette kan ikke angres',
      DeletedVersion: 'Versjonen ble slettet',
      InvalidEmail: 'Ugyldig e-post',
      EmailNotVerified: 'Ikke bekreftet, vennligst sjekk e-posten din',
      SelectFavoriteGenres: 'Velg dine favorittsjangre',
      Loading: 'Laster',
      ChapterIdentification: 'Kapittelidentifikasjon',
      ChapterAnalysis: 'Kapittelanalyse',
      CompleteImport: 'Fullfør importen',
      Import: 'Import',
      Processing: 'Behandler fil...',
      ThisMightTakeAWhile: 'Dette kan ta en stund',
      LikeThisCover: 'Liker du dette bokomslaget?',
      DownloadFailedMsg:
        'Nedlastingen mislyktes. Ble det blokkert av en popup-blokkering?',
      ShareAuthorAppEmailSuccessMessage:
        'Invitasjon sendt. Kontroller statusen <1> her </1>.',
      failedToUpdateReader: 'Kunne ikke oppdatere leseren',
      activeReaders: 'Aktive lesere',
      ReadingList: 'Leseliste',
      Users: 'Brukere',
      InviteUser: 'Inviter bruker',
      Role: 'Rolle',
      member_role_admin: 'Administrator',
      member_role_member: 'Medlem',
      couponsOrDiscountsWillNotBeKept:
        '<0>Eksisterende rabatter følger ikke den nye planen.</0>',
      FailedToFetchX: 'Kunne ikke få {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Du har blitt invitert til å bli med {{x}} BetaReader.io-konto.',
      AcceptAccountInvitationMsg:
        'Jos hyväksyt kutsun, voit nähdä ja muokata kaikkia tämän tilin käsikirjoituksia....',
      InvitedMembersMsg:
        'Kontomedlemmer vil kunne se og redigere alle manuskriptene på kontoen din, men de vil ikke se noen faktureringsdetaljer. Se betareader.io/pricing for informasjon om kostnaden per kontomedlem.',
      InviteCollaborator: 'Inviter en samarbeidspartner',
      InviteCollaboratorMsg:
        'Samarbeidspartnere kan se manuskript og leserinformasjon og svare på tilbakemelding.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Du har blitt invitert til å samarbeide på {{bok}} av {{author}}',
      AcceptCollaborationInvitationMsg:
        'Hvis du godtar invitasjonen, vil du kunne se manuskript og leserinformasjon, og svare på tilbakemelding.',
      Collaborators: 'Samarbeidspartnere',
      ThisIsABetaFeature:
        'Dette er en beta-funksjon. Gi oss beskjed om hvis du opplever problemer med det, eller hvis du har noen tilbakemelding å dele.',
      UserNotFoundMsg:
        'Det ser ut til at du ikke har en konto. Opprett en <1> her </ 1>!',
      AuthorName: 'Forfatternavn',
      Details: 'Detaljer',
      Settings: 'Innstillinger',
      ReviewAtLeastXwords: '{{x}} ord gjennomgått',
      CheckBonus: 'Sjekk bonusen din',
      FailedToAddCommentOrReaction:
        'Kunne ikke legge til kommentar eller reaksjon',
      ThisIsAWIP: 'Dette er en WIP',
      WIPExplanation:
        'Flagg historien din som WIP (Work In Progress) når den ikke er ferdig, f.eks. når du ikke har lagt til alle kapitlene.',
      MatureContent: 'Moden',
      PublicationDate: 'Publisering',
      TimePlan: 'Tidsplan',
      NotSpecified: 'Ikke spesifisert',
      WhatIsThisStoryAbout: 'Hva handler denne historien om?',
      WhatDoYouNeedHelpWith: 'Hva trenger du hjelp til?',
      StartWriting: 'Begynn å skrive',
      CreateManuscript: 'Lag manuskript',
      ImportManuscript: 'Importer manuskript',
      ImportManuscriptStepDescription: 'Last opp eller lag manuskriptet ditt',
      InviteReaders: 'Inviter leserne dine',
      InviteReadersStepDescription: 'Du bestemmer hvem som kan lese',
      CollectFeedback: 'Samle tilbakemeldinger',
      CollectFeedbackStepDescription: 'Analyser kommentarer og lesemønstre',
      YouDontHaveAnyManuscripts: 'Du har ikke noen manuskripter ennå',
      AuthorsAndPublishers: 'Forfattere og forlag',
      FindOutWhatYourReadersThink:
        'Finn ut hva leserne dine virkelig synes om boken din',
      ReadAndInfluenceTheNextBestseller: 'Les og påvirke kommende bestselgere',
      PrivateSharing: 'Privat deling',
      PrivateSharingDescription: 'Del manuskriptet ditt privat med leserne',
      CollectUnbiasedFeedback: 'Samle inn objektive tilbakemeldinger',
      CollectUnbiasedFeedbackDescription:
        'La leserne dine kommentere uten å se hverandres tilbakemeldinger',
      AnalyzeReadingPatterns: 'Analyser lesemønster',
      AnalyzeReadingPatternsDescription:
        'Finn ut hvor leserne dine mister interessen eller blir hekta av historien',
      ReadItFirst: 'Les den først',
      ReadItFirstDescription:
        'Les kommende romaner mens de fremdeles er i beta',
      InfluenceTheStory: 'Påvirke historien',
      InfluenceTheStoryDescription:
        'Dine tilbakemeldinger vil påvirke det endelige resultatet',
      ConnectWithAuthors: 'Diskuter med forfatterne',
      ConnectWithAuthorsDescription:
        'Hjelp favorittforfatterne dine ved å bli en del av betateamet deres',
      YourBooks: 'Dine bøker',
      New: 'Ny',
      Members: 'Medlemmer',
      Contacts: 'Kontakt',
      AddMembersFromContacts: 'Legg til medlemmer fra kontaktlisten',
      ConversationSettings: 'Samtaleinnstillinger',
      PremiumAuthorInfo:
        'Dette er et manuskript av en premiumforfatter. Dette betyr typisk at forfatteren er mer investert i prosjektet, og at du kan forvente at de gjør det som trengs for å få denne boken utgitt.',
      StillWorkingOnThis:
        'Vi er fortsatt bygge ut BetaReader.io. La oss få vite om det er noe spesielt du ønsker å se her.',
      Engagement: 'Engasjement',
      AssemblingLetters: 'Montering bokstaver ...',
      ConstructingAlphabet: 'Konstruere alfabetet ...',
      BuildingSentences: 'Bygge setninger ...',
      BecomingSentient: 'Bli sansende ...',
      BooksRead: 'Lest: {{x}}',
      WordsReviewed: 'Ord vurdert: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Gjennomsnittlig behandlingstid per 1000 ord: {{count}} dag',
      AvgTurnAroundTimePer1kWords_plural:
        'Gjennomsnittlig behandlingstid pr 1000 ord: {{count}} dager',
      ViewAsReader: 'Vis som leser',
      EditMode: 'Redigeringsmodus',
      BookCover: 'Bokomslag',
      WelcomeToBRReadathon: 'Velkommen til BetaReader.io Readathon!',
      ReadathonHasEnded: 'Readathon er nå avsluttet.',
      ReadathonInfo:
        'Den BetaReader.io Readathon varer mellom 26 september og 31. oktober 2019. Alle som leser og vurderinger minst en bok via BetaReader.io løpet av denne tiden deltar, og du kan øke din sjanse til å vinne ved å lese og vurdere flere bøker. Vinneren vil bli trukket 1. november, og flere ord du har lest og anmeldt da, jo høyere er sjansen for å vinne. For eksempel, noen som har lest og gjennomgått 50.000 ord vil ha 5x har høy sjanse til å vinne som en som har lest og gjennomgått 10.000 ord.',
      ReadathonPrize1: '1. premie: $ 50 Amazon gavekort.',
      ReadathonPrize23: 'Andre til tredje premie: $ 20 Amazon gavekort.',
      SignInToJoin: 'Logg inn for å bli med',
      WordsReviewedByYou: 'Ord anmeldt av deg',
      WordsReviewedByEveryone: 'Ord anmeldt av alle',
      ChanceOfWinning: 'Nåværende sjanse til å vinne',
      Completed: 'fullført',
      CompletedAt: 'fullført på',
      Pending: 'I påvente av',
      Position: 'Stilling',
      FindSomethingToRead: 'Finn noe å lese',
      DontKnow: 'Vet ikke',
      IsThisReadyToPublish:
        'Tror du at denne boken er klar til å bli publisert?',
      UpdateProfileHeader: 'Vi trenger litt mer informasjon for profilen din',
      WhatsYourName: 'Hva er ditt navn eller pseudonym?',
      WhereAreYouFrom: 'Hvor er du fra?',
      WhenWereYouBorn: 'Hvilket år er du født?',
      WhichGenresDoYouPrefer: 'Hvilke sjangere foretrekker du?',
      WhichLanguagesDoYouRead: 'Hvilket språk leser du?',
      BioPlaceholder:
        'Fortell oss hvem du er, og om du har noen nettsteder å vise til. En sterkere bio vil hjelpe deg med å bli godkjent for flere manuskripter.',
      Thanks: 'Takk!',
      WhenDoYouWantToHearFromUs: 'Når vil du høre fra oss?',
      ReaderListConsentHeader: 'Ønsker du å bli inkludert i vår leserdatabase?',
      ReaderListConsentInfo:
        'Forfattere med verk som samsvarer med dine preferanser vil kunne sende deg lenken til bøkene sine, men du er ikke forpliktet til å lese hvis du ikke vil eller ikke har tid.',
      RoleHeader: 'Er du en forfatter eller en leser?',
      RoleInfo:
        'Vi bruker denne informasjonen for å optimalisere opplevelsen din.',
      OnYourProfile: 'Dette vil vises på brukerprofilen din.',
      ProfileBuilderThankYouMessage:
        '<0>Takk!</0><1>Du kan alltid oppdatere profilen din <1>her</1>.</1>',
      DoThisLater: 'Gjør dette senere',
      '2MonthsFree': '2 måneder gratis',
      Joined: 'Ble med',
      UserInfo: 'Brukerinfo',
      Age: 'Alder',
      Bio: 'Bio',
      ReaderList: 'Finn lesere',
      Popular: 'Populær',
      BookHas0Words: '0 ord',
      BookHas0WordsDescription:
        '<0> Denne boken har minst en versjon med 0 ord. Du kan legge til eller laste kapitler </0> <1> her </1>.',
      LeaveBeta: 'Forlat beta',
      DiscoverNewBooks: 'Oppdag nye bøker',
      FailedToFindReader: 'Kunne ikke finne leseren',
      ApprovedReadRequest: 'Leseforespørsel er godkjent',
      Approved: 'Godkjent',
      UserAlreadyInvited: '{{user}} er allerede invitert',
      SelectBook: 'Velg bok',
      InvitationMessagePlaceholder:
        'Inkluder en personlig melding i invitasjonen for å øke sjansene dine for å bli akseptert.',
      YouHaveAPendingInvitation: 'Du har en ventende invitasjon',
      ReaderIndexInfo:
        'Leserdatabasen inneholder BetaReader.io brukere som har indikert at de er åpne for å bli kontaktet av forfattere. Send dem en invitasjon med din beste tonehøyde, men husk at de står fritt til å avslå invitasjonen din uansett grunn.',
      ReaderLimitReached: 'Du har nådd lesergrensen',
      Invite: 'Invitere',
      Languages: 'Språk',
      LinkWasSentToX: 'Vi har sendt en påloggingslink til {{x}}',
      Verifying: 'Bekrefter...',
      FoundNoAvailableManuscripts: 'Vi fant ingen tilgjengelige manuskripter',
      FindASpecificQuestion: 'Filtrer spørsmål',
      ReadAndReviewBonusInfo:
        'READ & REVIEW bonusoppdateringene dine etter at du er ferdig med og har gennemgået en bog.',
      ManuscriptStatus: 'Manuscriptstatus',
      MSStatusDescription_alpha:
        'Dette er et tidlig stadium eller delvis manuskript.',
      MSStatusDescription_beta:
        'Dette er et sent stadium manuskript av en full bok.',
      SelectStatus: 'Velg status',
      MaxAllowedGenres: 'Du kan bare velge opptil 5 sjangere',
      TooLongDescription: 'Beskrivelsen din er for lang (maks 1000 tegn)',
      Duplicate: 'Kopiere',
      Download: 'Last ned',
      DuplicateVersionInfo:
        'Når du dupliserer en versjon, vil alle kapitler og undersøkelser kopieres til den nye versjonen. Leserne dine fortsetter med den gamle versjonen.',
      NoBookMsg:
        '<0> Det er ingenting her! </0> <1> <0> Vil du </0> <1> legge til ditt eget manuskript? </1> </1>',
      CommentsCopiedFromOriginal:
        'Kommentarer ble kopiert fra originaldokumentet',
      CopyComments: 'Kopier alle kommentarer',
      MigrateReaders: 'Migrere lesere',
      Posts: 'Innlegg',
      Sent: 'Sendt',
      Username: 'Brukernavn',
      UsernameAlreadyTaken: 'Dette brukernavnet er tatt',
      Followers: 'Etterfølgere',
      ReadersAndFollowers: 'Lesere og følgere',
      WhoCanSeeThis: 'Hvem kan se dette?',
      FollowCount: '{{count}} følger',
      FollowCount_plural: '{{count}} følgere',
      Follow: 'Følge',
      Following: 'Følger',
      MarkAllAsRead: 'Merk alle som lest',
      Working: 'Jobber...',
      Audience: 'Publikum',
      ShowAllComments: 'Vis alle kommentarer...',
      Publish: 'Oublisere',
      InviteByEmail: 'Inviter på e-post',
      InviteByLink: 'Inviter via link',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        'Hei {{x}}! Velkommen til Betareader support 👋, vi prøver å svare så fort vi kan. Hva kan vi hjelpe deg med?',
      TalkToSupport: 'Snakk med supportteamet vårt',
      StartSupportChat: 'Start supportchat',
      StartSupportChatPMessage:
        '<0>Du kan alltid nå oss via chatten vår, og vi vil svare så snart vi kan.</0>',
      FindHelpArticlesMessage:
        '<0>Trenger du hjelp? sjekk ut <1>hjelpedelen </1> vår, full av hjelpeartikler for å veilede deg.</0>',
      HelpArticles: 'Hjelpeartikler',
      CloseConversationAlertHeader: 'Nær samtale',
      CloseConversationAlertMessage: 'Vil du avslutte denne samtalen?',
      EmailSupportMessage:
        '<0> Send en e-post til <1>{{mail}}</1>, så svarer vi så snart vi kan. </0>',
      Includes: 'Inkluderer',
      DoesNotInclude: 'Inkluderer ikke',
      IncludesAll: 'Inkluderer alle',
      RemoveFilter: 'Fjern filteret',
      AddFilter: 'Legg til filter',
      SelectGenres: 'Velg sjangere',
      And: 'Og',
      ContinueWithGoogle: 'Fortsett med Google',
      ContinueWithFacebook: 'Fortsett med Facebook',
      ContinueWithApple: 'Fortsett med Apple',
      NewOnBetareader: 'Ny hos BetarReader?',
      AlreadyHaveAccount: 'Har du allerede en konto?',
      SignUpWithFacebook: 'Registrer deg med Facebook',
      SignUpWithGoogle: 'Registrer deg med Google',
      SignUpWithApple: 'Registrer deg hos Apple',
      OpportunityWithoutDescriptionMessage:
        'Forfatteren har ikke gitt en beskrivelse.',
      SignInToViewContent: 'Logg på for å se dette innholdet',
      ContentBelongsToAnotherAccount:
        'Dette innholdet tilhører en annen konto enn den aktive.',
      SwitchAccount: 'Bytt konto',
      OwnBooks: 'Egne bøker',
      Collaborations: 'Samarbeid',
      UploadImage: 'Last opp bilde',
      SplitChapter: 'Del kapitlet',
      UpgradeToUnlock: 'Oppgrader for å låse opp',
      '3ReaderSlots': '3 leserspor',
      UnlimitedReaderSlots: 'Ubegrensede leserplasser',
      ImportWarning: 'Importadvarsel',
      ImportExeedLenghMessage:
        '<0><0>Et eller flere av kapitlene dine ser ut til å være for lange til å importeres, sørg for at du har fulgt våre<1> importretningslinjer. </1></0><1>Tips: du kan holde markøren over kapittelteksten og bruke sizzor-verktøyet til å dele opp større kapitler</1></0>',
      OneTeamMember: '1 lagmedlem',
      AdditionalTeamMembers: 'Legg til teammedlemmer',
      PerMonth: 'per måned',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Send inn manuskriptet ditt',
      SubmitTo: 'Send til',
      YourSubmittedManuscripts: 'Dine innsendte manuskripter',
      SelectManuscriptToSubmit: 'Velg manuskriptet som skal sendes inn',
      AvailableToRead: 'Tilgjengelig å lese',
      ManuscriptSubmissionSuccess: 'Manuskript innsendt',
      ManuscriptSubmittedTo:
        'Manuskriptet ditt, {{_manuscriptName}}, er sendt til {{_inboxName}}',
      SubmittedBy: 'Sendt av {{_userName}}',
      ReceivedManuscripts: 'Mottatt manuskripter',
      InviteToRead: 'Inviter til å lese',
      YourReview: 'Din anmeldelse',
      UserReviewPlaceholder: 'Brukeranmeldelser er synlige for alle.',
      LeaveReviewError:
        'Du kan kun anmelde brukere som har lest et av manuskriptene dine.',
      NotEnoughData: 'Ikke nok data',
      EditSignUpSurveyQuestions:
        'Rediger spørsmål i registreringsundersøkelsen',
      AddSignUpSurveyQuestions:
        'Legg til spørsmål om registreringsundersøkelse',
      To: 'Til',
      FinishedReadingAt: 'Ferdig med lesingen {{x}}',
      FailedToSendInvitation:
        'Kunne ikke sende invitasjonen, vennligst bekreft e-postadressen',
      EditInvitation: 'Rediger invitasjonen',
      ChapterSeparator: 'Kapittelseparator',
      CancelReason: 'Før du fortsetter, vil du fortelle oss hvorfor?',
      cancel_reason_technical_issues: 'Tekniske problemer',
      cancel_reason_too_expensive: 'Produktet er for dyrt',
      cancel_reason_finished_my_beta: 'Jeg avsluttet mitt prosjekt',
      cancel_reason_switching_to_another_product:
        'Jeg bytter til et annet produkt',
      cancel_reason_missing_features: 'Jeg mangler viktige funksjoner',
      cancel_reason_did_not_meet_expectations:
        'Plattformen ikke oppfyller mine forventninger',
      cancel_reason_other: 'Andre grunner',
      TellUsMore: 'Fortell oss mer',
      DeleteAccount: 'Slett konto',
      YourAccountHasBeenDeleted: 'Kontoen din er slettet',
      DeleteAccountConfirmation:
        'Er du sikker på at du vil slette kontoen din og alle dataene? Denne handlingen kan ikke angres.',
      GoodbyeMessage:
        'Vi beklager å se deg forlate oss, og håper du kommer tilbake i fremtiden.',
      EditContent: 'Rediger innhold',
      AffectedReadersMessage:
        '{{count}} leser har allerede lest dette kapittelet. Klikk her for å legge ut en oppdatering om endringene dine.',
      AffectedReadersMessage_plural:
        '{{count}} lesere har allerede lest dette kapitlet. Klikk her for å legge ut en oppdatering om endringene dine.',
      ImportingChapterXofY: 'Importerer kapittel {{_x}} / {{_y}}',
      ImportStarted: 'Importen startet',
      XVersions: '{{count}} versjon',
      XVersions_plural: '{{count}} versjoner',
      UnsubscribeSuccessful:
        'Du har blitt avmeldt. Du kan alltid oppdatere innstillingene dine via profilen din.',
      UnsubscribeUnsuccessful:
        'Kunne ikke avslutte abonnementet. Send en melding til support@betareader.io hvis problemet vedvarer.',
      GoodbyeHeader: 'Farvel!',
      BulkAddReaders: 'Legg til lesere fra CSV',
      Home: 'Hjem',
      Actions: 'Handlinger',
      Invitations: 'Invitasjoner',
      SelectCSVWithReaders: 'Velg CSV med lesere',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'La vår AI, BetaBot, generere en synopsis basert på teksten din. Det vil ikke være synlig for leserne dine.',
      LastUpdated: 'Sist oppdatert',
      CreateSynopsis: 'Lag synopsis',
      RefreshSynopsis: 'Oppdater synopsis',
      ElevatorPitch: 'Heisplass',
      ThisIsAPremiumFeature: 'Premium funksjon',
      GoBack: 'Gå tilbake',
      Retry: 'Prøv på nytt'
    }
  },
  fi: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Huomaa, että kun viestien viestit käyttäjätunnuksesi ja pseudonym ei ole näkyvissä',
      Library: 'Kirjasto',
      Account: 'Tili',
      EmptyLibrary: 'Kirjaston on tyhjä.',
      EmptyLibraryAction:
        'Odotit löytää jotain täällä? Ota yhteyttä kirjoittajaan tai lähetä meille sähköpostia osoitteessa {{_email}}, ja näemme, mitä voimme tehdä.',
      ByAuthor: 'mukaan {{_authorName}}',
      ExceedChapterLenghtWarning: 'Luku on liian pitkä tuotavaksi',
      WordCount: '{{_wordCount}} sanoja',
      WordCountString: '{{count}} sana',
      WordCountString_plural: '{{count}} sanoja',
      CharCountString: '{{count}} merkkiä',
      CharCountString_plural: '{{count}} merkkiä',
      SurveyCount: '{{count}} tutkimus',
      SurveyCount_plural: '{{count}} tutkimukset',
      ChapterCount: '{{count}} luku',
      ChapterCount_plural: '{{count}} luvuista',
      QuestionCountString: '{{count}} kysymyksiin',
      QuestionCountString_plural: '{{count}} kysymyksiin',
      LoginToChangeEmail: 'Kirjaudu uudelleen muuttaa sähköpostin',
      SendVerification: 'Send vahvistuslinkki',
      Name: 'Nimi',
      Email: 'Sähköposti',
      EnterYourName: 'Kirjoita nimesi',
      ReceiveCompanyUpdates: 'Vastaanottaa sähköposteja ja uutisia BetaReader',
      ReceiveBookEmails: 'Recieve sähköpostit uusista kirjoista',
      ReceiveBookActivityUpdates:
        'Vastaanottaa sähköposteja toimintaa teokseni',
      ReceiveMessageUpdates:
        'Vastaanottaa sähköposteja kun saan uusia viestejä',
      IncludeProfileInReaderList: 'Lisää profiilini lukija tietokantaan',
      PrivacyPolicy: 'Tietosuojakäytäntö',
      TermsOfService: 'Käyttöehdot',
      ReadOurBlog: 'Lue blogi',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Seuraavaksi',
      Back: 'Takaisin',
      CreateAccount: 'Luo tili',
      CreateAccountPrompt:
        '<0>Eikö sinulla ole tiliä?<1> <0>Avaa se tästä!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Unohtuiko salasana?<1> <0>Nollaa sen täältä!</0></1></0>',
      SignIn: 'Kirjaudu sisään',
      SignOut: 'Kirjaudu ulos',
      SendResetLink: 'Send vaihtolinkkiä',
      ResetPassword: 'Nollaa salasana',
      Password: 'Salasana',
      OrUse: 'tai käyttöä',
      NoConnection: 'Poissa ...',
      AcceptTermsMessage: 'Hyväksy käyttöehdot',
      AcceptToSToCreateAccount:
        'Sinun tulee hyväksyä palvelun käyttöehdoista luoda tilin.',
      Answer: 'Vastaus',
      FailedToLoadBook: 'Ei voinut ladata kirjan.',
      PullDownToRefresh: 'Päivitä vetämällä.',
      ContactSupport: 'Ota yhteyttä tukeen',
      Support: 'Tuki',
      LastRead: 'viimeksi luetun',
      XInvitations: '{{count}} kutsun',
      XInvitations_plural: '{{count}} kutsut',
      EnterComment: 'Kommentoikaa ...',
      EnterReply: 'Vastaa...',
      Reply: 'Vastata',
      Reply_plural: 'Vastausta',
      Reply_verb: 'Vastata',
      show: 'näytä',
      hide: 'piilottaa',
      Comments: 'Kommentit',
      InlineComment: 'Inline kommentti',
      Reject: 'Hylätä',
      Accept: 'Hyväksyä',
      Error: 'Virhe',
      failed: 'epäonnistui',
      SelectedText: 'Valitun tekstin',
      FailedToFetchUserProfile: 'Nouto epäonnistui käyttäjän profiilin.',
      ProblemPersistContactSupport:
        'Jos ongelma jatkuu, ota yhteyttä tukeen osoitteessa:',
      clickToAddComment: 'Klikkaa tästä lisää kommentti',
      ColorMode: 'väritila',
      FontSize: 'Fonttikoko',
      Discover: 'Tutustu',
      MyAccount: 'Tilini',
      MyManuscripts: 'Minun Käsikirjoitukset',
      Sweden: 'Ruotsi',
      USA: 'USA',
      UK: 'Iso-Britannia',
      Germany: 'Saksa',
      France: 'Ranska',
      Spain: 'Espanja',
      Country: 'Maa',
      Gender: 'Sukupuoli',
      YoB: 'Syntymävuosi',
      PreferredGenres: 'Suositut genret',
      PreferredLanguages: 'Suositut kielet',
      EmailPreferences: 'Sähköpostiasetukset',
      chapters: 'lukuja',
      words: 'sanoja',
      YouShouldBetaRead: 'Sinun pitäisi lukea',
      onBetaReader: 'osoitteessa BetaReader.io',
      Share: 'Jakaa',
      NoGuidelines: 'Kirjoittaja ei ole antanut palauteohjeita.',
      Read: 'Lukea',
      on: 'päällä',
      Author: 'Tekijä',
      JoinBeta: 'Haluan lukea tämän',
      joinConsentString:
        'Ymmärrän, että pyytämällä liittymistä tähän betaan jaan sähköpostiosoitteeni kirjoittajan kanssa.',
      Submit: 'Lähetä',
      AnswerSurvey: 'Vastaus!',
      SendJoinRequest: 'Lähetä',
      IJustWantToRead: 'Haluan vain lukea',
      CopyrightInfo: 'Tekijänoikeustiedot',
      RequestToRead: 'Lähetä lukupyyntö',
      Feedback: 'Palaute',
      NoComment: 'Kukaan ei ole kommentoinut.',
      OnceUponATime: 'Olipa kerran...',
      Review: 'Arvostelu',
      Overall: 'Kaiken kaikkiaan',
      Plot: 'Juoni',
      Grammar: 'Kielioppi',
      Title: 'Otsikko',
      AddReview: 'Lisää arvostelu',
      EnterReviewText: 'Kirjoita lyhyt arvostelu',
      OverallRating: 'Kaiken kaikkiaan',
      OverallRatingRequired: 'Lisää yleisarvosana',
      PlotRating: 'Juoni',
      GrammarRating: 'Kielioppi',
      SubmitReview: 'Lähetä arvostelu',
      LeaveReview: 'Päivitys tarkastelu',
      checkOutAuthorApp:
        '<0>Oletko kirjailija?</0><1></1><2>Tutustu <1>sovellus tekijöille </1>!</2>',
      MyLibrary: 'Oma kirjasto',
      ReadingPreferences: 'Lukuasetukset',
      'E.g.': 'Esim.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Haluan saada sähköposteja BetaReader.io:lta, kun kirjoittaja tekee muutoksia tai päivityksiä',
      'I want to receive emails about BetaReader news and updates.':
        'Haluan saada sähköposteja BetaReaderin uutisista ja päivityksistä.',
      'I want to receive emails about new books that match my reading preferences.':
        'Haluan saada sähköpostiviestejä uusista kirjoista, jotka vastaavat lukumieltymyksiäni.',
      'this book': 'tämä kirja',
      'Email us on': 'Lähetä meille sähköpostia',
      "and we'll see what we can do :)": 'ja katsotaan mitä voimme tehdä :)',
      'Want an author account?': 'Haluatko kirjailijatilin?',
      Preferences: 'Asetukset',
      Language: 'Kieli',
      Browse: 'Selaa',
      'to beginning': 'alkuun',
      'to chapter...': 'jaksoon...',
      Location: 'Sijainti',
      Previous: 'Edellinen',
      Finish: 'Valmis',
      Description: 'Kuvaus',
      FeedbackGuidelines: 'Palautteen ohjeet',
      'Start reading': 'Aloita lukeminen',
      StartReading: 'Aloita lukeminen',
      LeaveComment: 'Kommentti',
      replies: 'vastaa',
      Show: 'Show',
      Hide: 'Piilottaa',
      'Add Reply': 'Lisää vastaus',
      PendingInvitation: 'Odottava kutsu',
      Decline: 'Hylkäämässä',
      DeclinedInvitationMessage:
        'Olet hylännyt tämän lukukutsun, mutta voit silti hyväksyä sen, jos olet muuttanut mieltäsi.',
      Welcome: 'Tervetuloa',
      by: 'kirjoittaja',
      to: 'to',
      AcceptInvitation: 'Hyväksy kutsu',
      InvitationAccepted: 'Kutsu hyväksytty',
      WaitForBook: 'Odota, kun valmistelemme kirjaasi',
      PasswordLength: 'Salasanasi tulee olla vähintään 6 merkkiä pitkä',
      AgreeToPolicyAndTerms:
        'Sinun on hyväksyttävä BetaReaderin tietosuojakäytäntö ja käyttöehdot rekisteröityäksesi.',
      'Sending...': 'Lähetetään...',
      VerificationSent: 'Vahvistussähköposti lähetetty.',
      VerificationEmailFailed: 'Vahvistussähköpostin lähettäminen epäonnistui.',
      IAcceptToC:
        '<0>Hyväksyn BetaReader.io:n <1>Yksityisyyden suoja</1> ja <3>käyttöehdot</3></0>',
      AccountSettings: 'Tilin asetukset',
      ContactSettings: 'Yhteydenottoasetukset',
      Chapters: 'Luvuissa',
      Options: 'Vaihtoehdot',
      Option: 'Vaihtoehto',
      BetaLanguageMessage:
        '<0>Työskentelemme parannettu kielituki, ja ovat erittäin kiitollisia apua voimme saada. Jos löydät virheitä sivuston, älä epäröi ottaa yhteyttä meihin: <1>hello@betareader.io</1></0>',
      Genres: 'Tyylilajit',
      PressEnterToSave: 'Tallenna painamalla Enter',
      female: 'nainen',
      male: 'uros',
      other: 'ei-binäärinen',
      DontWantToShare: 'yksityinen',
      SignInWithPassword: 'Kirjaudu sisään salasanalla',
      GoToBook: 'Siirry kirjaan',
      Requested: 'Pyyntösi on vireillä',
      AnsweredOrRequestedNotice:
        'Olet pyytänyt liittymistä tähän betaan. Kirjoittaja ottaa sinuun yhteyttä mahdollisimman pian.',
      RequestedWithDifferentEmail:
        'Olet pyytänyt liittyä tähän beta-vaiheeseen toisella sähköpostilla kuin sait kutsun. Kirjoittaja ottaa sinuun yhteyttä mahdollisimman pian.',
      LoadingLibrary: 'Etsitkö kirjoja...',
      PreparingBook: 'Valmistelee kirja',
      InLineCommentPlaceholder: 'Jätä kommenttisi ja tallenna painamalla Enter',
      ExitSurvey: 'Exit tutkimus',
      WhyExitQuestion: 'Miksi haluat poistua tästä betasta?',
      LostInterest: 'Menetin kiinnostuksen',
      NotEnoughTime: 'Minulla ei ole tarpeeksi aikaa',
      Other: 'Muu syy',
      reason_lost_interest: 'Menetin kiinnostuksen',
      reason_no_time: 'Minulla ei ole tarpeeksi aikaa',
      reason_other: 'Muu syy',
      reason_: 'Ei määritelty',
      ExitFeedbackQuestion: 'Haluatko antaa palautetta kirjoittajalle?',
      ExitFeedbackPlaceholder: 'Kaikki palaute on hyödyllistä',
      ThankYouForYourFeebdack: 'Kiitos palautteestasi!',
      AppVersion: 'Versio',
      AppBuildNumber: '#',
      Like: 'Pitää',
      Love: 'Rakkaus',
      Laugh: 'Haha',
      Surprise: 'Yllätys',
      Sad: 'Surullinen',
      Angry: 'Vihainen',
      NoBooksInList: 'Täällä ei ole kirjoja.',
      Update: 'Päivittää',
      Overview: 'Yleiskatsaus',
      Content: 'Pitoisuus',
      Readers: 'Lukijat',
      NotificationSettings: 'Ilmoitusasetukset',
      SendEmailOnComments:
        'Lähetä sähköpostia, kun lukijat jättävät kommentteja',
      SendEmailOnSurveyComplete:
        'Lähetä sähköpostia, kun lukijat ovat täyttäneet kyselyn',
      CoverUploadMsg:
        'Napsauta ladataksesi kansikuvasi (png tai jpg, 980x1568px) tai pudota se tälle alueelle.',
      DefaultNamePlaceholder: 'Oletusarvo: {{displayName}}',
      SelectUpTo5Genres: 'Valitse enintään 5 genreä',
      AdvancedSettings: 'Lisäasetukset',
      EnableComments: 'Ota kommentit käyttöön',
      EnableBookReviews: 'Ota kirja-arvostelut käyttöön',
      DeleteBook: 'Poista kirja',
      DeleteCover: 'Poista kirjan kansi',
      DeleteCoverConfirmation: 'Haluatko varmasti poistaa kirjan kannen?',
      YesBinIt: 'Kyllä, poista se!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Oletko varma, että haluat poistaa <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} on poistettu.',
      No: 'Ei',
      Yes: 'Kyllä',
      UserCommentedOn: 'kommentoinut',
      ShowXReplies: 'Näytä {{count}} vastaus',
      ShowXReplies_plural: 'Näytä {{count}} vastausta',
      HideXReplies: 'Piilota {{count}} vastaus',
      HideXReplies_plural: 'Piilota {{count}} vastausta',
      NoInLineComments: 'Ei sisäisiä kommentteja.',
      Chapter: 'Luku',
      NoCommentsToShow: 'Ei näytettäviä kommentteja.',
      Save: 'Tallentaa',
      TooLongTitle: 'Otsikkosi on liian pitkä (enintään 60 merkkiä)',
      ClickToUploadDoc: 'Lisää tai pudota docx-tiedosto tuodaksesi sen',
      LongChapterWarning: 'Pitkä luku, pitääkö tämä paikkansa?',
      Upload: 'Upload',
      UploadChapters: 'Upload luvuissa',
      ImportBooksHelpMsg:
        'Kukaan ei voi nähdä käsikirjoituksesi ilman hyväksyntääsi. Lue <1> kirjojen tuominen </1>',
      Add: 'Lisätä',
      NewChapter: 'Uusi luku',
      NewSurvey: 'Uusi tutkimus',
      SelectedPart: 'valittu osa',
      SelectPart: 'Valitse osa',
      NotifyMe: 'Ilmoita minulle',
      WhenReadersStart: 'alussa',
      WhenReadersComplete: 'lopussa',
      ManageChapter: 'Hallitse lukua',
      ManageSurvey: 'Hallinnoi kyselyä',
      ManageReaders: 'Hallitse lukijoita',
      MoveUp: 'Siirrä ylös',
      MoveDown: 'Siirry alas',
      SaveSurvey: 'Tallenna kysely',
      Saved: 'Tallennettu',
      EnterSurveyName: 'Anna kyselyllesi nimi',
      EnterSurveyDescription: 'Kuvaile kyselyäsi',
      SelectAtLeast: 'Valitse ainakin',
      SelectAtMost: 'Valitse korkeintaan',
      LabelOptional: 'Tunniste (valinnainen)',
      MyBooks: 'Minun kirjani',
      ChangeRoleToWriterToCreateBooks:
        'Vaihda rooli kirjoittajaksi luodaksesi kirjoja.',
      UsingXOutOfYBooks: 'Käytät {{x}} ulos {{y}} kirjoja.',
      UsingXOutOfYReaders: 'Käytät {{x}} ulos {{y}} lukijoita.',
      NothingHere: 'Täällä ei tällä hetkellä mitään.',
      OpenReaderWeb: 'Avaa lukija',
      PrivacyLevel: 'Jaon tilaa',
      PublishingStatus: 'Jaon tilaa',
      PrivacyLevel_infotext_unpublished:
        '<0> Kirjasi on offline-tilassa. Siirrä se kohtaan <1> yksityinen </1>, <3> suljettu </3> tai <5> listattu </5>, jotta lukijat löytävät sen. </0>',
      PrivacyLevel_infotext_private:
        '<0> Sinun teosta ei näy missään. Vain sinä voit kutsua lukijoita. </0>',
      PrivacyLevel_infotext_closed:
        '<0>Kirjasi esityksen voi nähdä kuka tahansa <1> linkkiä </1>. Lukijat voivat pyytää kutsua, mutta sinun täytyy hyväksyä jokainen pyyntö. </0>',
      PrivacyLevel_infotext_listed:
        '<0>Teos on listattu <1>täällä</1>. Lukijat voivat pyytää kutsua, mutta sinun täytyy hyväksyä jokainen pyyntö. </0>',
      PrivacyLevel_state_unpublished: 'Offline-tilassa',
      PrivacyLevel_state_private: 'Yksityinen',
      PrivacyLevel_state_closed: 'Suljettu',
      PrivacyLevel_state_listed: 'Lueteltu',
      SendReminder: 'Lähetä muistutus',
      YouCanSendAReminderEvery3Days:
        'Voit lähettää muistutuksen joka kolmas päivä',
      LastSeenAt: 'Nähtiin viimeksi',
      ReminderSentAt: 'Muistutus lähetetty',
      XReadersInactiveForYDays:
        '{{count}} lukija ei ole lopettanut kirjan ja ei ole ollut aktiivinen viime {{days}} päivää.',
      XReadersInactiveForYDays_plural:
        '{{count}} lukijat eivät ole kirjan loppuun eivätkä ne ole olleet aktiivisia viime {{days}} päivää.',
      InactiveReaders: 'Toimeton lukijat',
      XReadersNotStartedForYDays:
        '{{count}} lukija ei ole hyväksynyt yli {{days}} päivän ikäisiä kutsu',
      XReadersNotStartedForYDays_plural:
        '{{count}} lukijat eivät hyväksyneet enintään {{days}} päivän ikäisiä kutsu',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Lukijat, jotka eivät ole hyväksyneet kutsua',
      YouHaveXJoinRequests: 'Sinulla on {{count}} pyyntö lukea kirjaa',
      YouHaveXJoinRequests_plural: 'Sinulla on {{count}} pyyntöjä lukea kirjaa',
      JoinRequests: 'Luepyynnöt',
      Approve: 'Hyväksyä',
      RequestedAt: 'Pyydetty',
      NoAnswer: 'Ei vastausta',
      SendSurveyReminder: 'Lähetä kyselymuistutus',
      Remove: 'Poistaa',
      Created: 'Luotu',
      RequestedToRead: 'Pyydetty lukemaan',
      InvitationSent: 'Kutsu lähetetty',
      Reminded: 'Muistutti',
      Question: 'Kysymys',
      YouCanAskUpToXQuestions:
        'Voit lisätä enintään {{maxQuestions}} kysymyksiä, että lukijat ovat velvollisia vastaamaan rekisteröityessään.',
      NewQuestion: 'Uusi kysymys',
      ReaderSignUpForm: 'Ilmoittautumislomake',
      NoReadersFound: 'Lukijoita ei löytynyt',
      ReachedPartTitle: 'Saavutti {{partTitle}}',
      ReasonToQuit: 'Syy',
      YourBookDoesNotHaveAnyChaptersYet: 'Kirjassasi ei ole vielä lukuja',
      Invited: 'Kutsuttu',
      Started: 'Aloitti',
      Finished: 'Valmis',
      Abandoned: 'Hylätty',
      AvgOverallRating: 'Kaiken kaikkiaan',
      AvgPlotRating: 'Juoni',
      AvgGrammarRating: 'Kielioppi',
      YouDoNotHaveReviewsYet: 'Sinulla ei ole vielä yhtään arvostelua.',
      PersonalLink: 'Henkilökohtainen side',
      HelpUsReachMoreWriters:
        'Pidätkö BetaReader.io:n käytöstä? Käytä henkilökohtaista linkkiäsi auttaaksesi meitä auttamaan lisää kirjoittajia!',
      Reports: 'Raportit',
      WeNeedYourEmail: 'Sähköpostia tarvitaan',
      MissingEmailMsg:
        'Meidän on voitava ottaa sinuun yhteyttä tiliäsi koskevissa asioissa. Emme jaa sitä kenenkään kanssa ja otamme sinuun yhteyttä vain, jos se on ehdottoman välttämätöntä.',
      EmailNotVerifiedPrompt:
        'Sähköposti ei ole vahvistettu. <1> Klikkaa tästä lähettää </1> tarkistussähköpostiviestiä. (Tarkista roskapostikansiosi, jos et saa sitä)',
      Skip: 'Ohita',
      RoleWriter: 'Kirjailija',
      RoleReader: 'Lukija',
      RoleBoth: 'Molemmat',
      AddReader: 'Lisää uusi lukija',
      DownloadReaderList: 'Lataa lukijaluettelo',
      'Show only': 'Näytä vain',
      StatusNEW: 'Uusi',
      StatusTODO: 'tehdä',
      StatusDOING: 'Tekee',
      StatusDONE: 'Tehty',
      StatusIGNORE: 'Sivuuttaa',
      RemoveFilters: 'Poista suodattimet',
      ReadingDataReport: 'Tietojen lukeminen',
      SurveyAnswersReport: 'Kyselyn vastaukset',
      Reviews: 'Arvostelut',
      AbandonedReadersReport: 'Kadonneet lukijat',
      SignUpMsg:
        '<0>Kiitos ilmoittautumisesta!</0> <1>Varmennusviesti on lähetetty osoitteeseen <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Kopioitu {{x}} leikepöydälle.',
      DoYouWantToBeMyBetaReader: 'Haluatko olla beta-lukijani?',
      LookingForBetaReadersForBookTitle: 'Etsin beta lukijoiden {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Etsin beta lukijoiden {{bookTitle}}. Seuraa tätä linkkiä ja lue lisää ja ilmoittaudu: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Sosiaalinen jakaminen ei ole mahdollista yksityisille kirjoille.',
      ShareTheLinkToYourBook: 'Jaa linkki kirjaasi!',
      Messages: 'Viestien',
      FailedToLoadMessage: 'Tämän viestin lataaminen epäonnistui.',
      FailedToSendMessage: 'Emme pystyneet lähettämään viestiäsi.',
      FailedToStartConversation: 'Emme onnistuneet aloittamaan keskustelua.',
      NewMessage: 'Uusi viesti',
      MessageSubject: 'Aihe',
      MessageBody: 'Kirjoita viestisi...',
      MessageFrom: 'From',
      MessageTo: 'Vastaanottaja',
      SendMessage: 'Lähetä',
      Message: 'Viesti',
      ReaderMarketplaceBetaInfoText:
        'Yritämme antaa kirjoittajiemme maksaa tavallisille lukijoille ja kirjatoukille symbolisen summan käsikirjoituksen lukupalvelusta. Tarjoamme valvontamekanismeja palautteen validoimiseksi ja sen, että lukijat ovat lukeneet koko käsikirjoituksen ennen kuin kukaan saa palkkaa. Jos haluat kokeilla tätä ominaisuutta, ilmoita kuinka monta lukijaa tarvitset.',
      ReaderMarketplaceBetaFullText:
        'Tämä ohjelma on tällä hetkellä täynnä. Haluatko, että ilmoitamme sinulle, kun saamme avauksen?',
      Sum: 'Summa',
      XUSDPerReader: '${{x}} / lukija',
      AmountOfReaders: 'Lukijoiden määrä',
      PayForTestReaders: 'Maksa testilukijoista',
      GoToCheckout: 'Siirry kassalle',
      YesPlease: 'Kyllä kiitos!',
      BETA: 'BEETA',
      NoMessages: 'Sinulla ei ole viestejä',
      OnBoarding_import_book:
        '<0> Tervetuloa BetaReader.io! </0> Jotta jakaa kirjan, sinun on ensin tuoda sitä, joko <2> lataamalla se </2> tai luomalla sitä suoraan lavalle. Mene ja kokeile! Kaiken BetaReader.io on oletusarvoisesti yksityinen, joten kukaan ei näe sitä, ellet kutsua heidät.',
      Onboarding_book_overview:
        'Kirjan yleiskatsauksessa voit määrittää kirjasi tiedot. Otsikko, tyylilajit ja palauteohjeet ovat täällä, mutta myös se, haluatko lukijoiden pystyvän kommentoimaan ja jättämään arvosteluja.',
      Onboarding_book_content:
        'Sisältö-välilehdellä voit hallita asioiden järjestystä. Lisää lukusi ja kyselysi tähän.',
      Onboarding_book_readers:
        'Lukijat-välilehdellä voit määrittää, kuka saa lukea kirjasi. Lähetä yksityisiä kutsuja, jaa liittymislinkkisi Twitterin kautta tai lisää kirjasi Discovery-listallemme tavoittaaksesi enemmän lukijoita.',
      Onboarding_book_feedback:
        'Palaute-välilehti tarjoaa yleiskatsauksen kaikesta lukijoiden jättämästä palautteesta. Vastaa kommentteihin ja suodata pois turhat asiat.',
      Onboarding_book_reports:
        'Raportti-välilehti antaa yleiskatsauksen kirjasi toimivuudesta. Kuinka monta lukijaa on kutsuttu, kuinka moni on aloittanut lukemisen, jättänyt ylistäviä arvosteluja ja niin edelleen.',
      Close: 'Lähellä',
      Last: 'Kestää',
      DuplicatedBook: '<0> <0> </0> kopioitiin kirja {{bookTitle}} </0>',
      PenName: 'Nimimerkki',
      MissingNameMsg:
        'Sinun on annettava nimi voidaksesi kommunikoida lukijoidesi kanssa.',
      NameConversation: 'Nimeä tämä keskustelu',
      With: 'kanssa...',
      SelectPeople: 'Valitse ihmiset',
      UploadAsNewVersion: '...uutena versiona',
      UploadAsExtraChapters: '...tähän versioon',
      Cancel: 'Peruuttaa',
      Unnamed: 'nimetön',
      NewVersion: 'Uusi versio',
      NoContacts: 'Ei kontakteja',
      GetContacts:
        'Lukemiesi kirjojen kirjoittajista ja kirjoittamiesi kirjojen lukijoista tulee yhteystietojasi.',
      NoConversations: 'Ei keskusteluja...',
      AllVersionsNeedContent:
        'Nykyinen versiosi tarvitsee sisältöä, ennen kuin voit luoda uuden.',
      InviteToVersion: 'Kutsu versioon',
      latestVersion: 'uusin',
      VersionName: 'Versio {{x}}',
      Reader: 'Lukija',
      StartedReading: 'Alkoi lukea',
      CurrentPosition: 'Nykyinen sijainti',
      PickVersion: 'Valitse versio',
      Filters: 'Suodattimet',
      Versions: 'versiot',
      ChapterInitial: 'l',
      AvgTimeToComplete: 'Keskimääräinen luvun lukemiseen kuluva aika',
      ReaderProgress: 'Kannat',
      SelectFilter: 'Valitse suodatin',
      CreatePost: 'Jaa päivitys',
      NewBookPostPlaceholder: 'Mitä haluat sanoa?',
      BookUpdateForX: 'Päivitys kirjaan {{x}}',
      OnSaveChapterMessage: 'Kerro lukijoillesi, mitä olet muuttanut',
      SampleChapterUpdatePost: 'Muutin tämän...',
      SaveWithoutPosting: 'Tallenna lähettämättä...',
      SaveAndPost: 'Tallenna ja lähetä...',
      DiscoverSearch: 'Hae otsikkoa tai genreä...',
      LoadMore: 'Lataa lisää...',
      PendingReadInvitations: 'Odottavat kutsut',
      PendingReadRequests: 'Odottavat pyynnöt',
      UserReactedOn: 'reagoinut',
      ChapterComment: 'Kappaleen kommentti',
      InlineCommentNotFound:
        'Tätä kommenttia ei löytynyt tekstistä. Onko kommentoitu teksti poistettu?',
      Or: 'Tai',
      Version: 'Versio',
      Updates: 'Päivitykset',
      CurrentBetaIsFullMessage:
        'Tämä beta on tällä hetkellä täynnä. Ota yhteyttä kirjoittajaan avataksesi lisää paikkoja.',
      Edit: 'Muokata',
      Delete: 'Poistaa',
      DropFilesHere: 'Pudota tiedostosi tähän',
      ShowOnly: 'Näytä vain',
      FeedbackSearch: 'Tekstihaku',
      'BuySubscriptionToS&PPMessage':
        '<0>Ostamalla tilauksen BetaReader.io-sivustolta osoitat, että olet lukenut ja hyväksynyt <1> käyttöehtomme</1> ja <5>tietosuojakäytäntömme</5></0>.',
      Inbox: 'Saapuneet',
      CofirmMovePart:
        'Haluatko todella siirtää tämän osan? Se vaikuttaa seuraaviin lukijoihin:',
      'AreYouSure?': 'Oletko varma?',
      LetYourReadersKnowWhatYouChanged:
        'Kerro lukijoillesi, mitä olet muuttanut',
      ThisChangeAffectsTheFollowingReaders:
        'Tämä muutos vaikuttaa seuraaviin lukijoihin:',
      MovedXFromYtoZ: 'Siirretty {{x}} paikasta {{y}} paikkaan {{z}}',
      Subscription: 'Tilaus',
      Subscriptions: 'Tilaukset',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Perussuunnitelma, ikuisesti vapaa.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Hyvä harrastusperfektionisteille',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Flott for profesjonelle indieforfattere',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Täydellinen julkaisijoille',
      CancelSubscription: 'Peruuta tilaus',
      YourSubscriptionHasBeenCancelled: 'Tilaus on peruutettu',
      CancelSubscriptionConfirmation:
        'Oletko varma, että haluat peruuttaa tilauksesi?',
      ValidUntil: 'Voimassa kunnes',
      UpgradeSubscriptionTitle: 'Päivitä tili',
      UpgradeSubscriptionPromoText: 'Päivitä tilisi',
      Abort: 'Keskeyttää',
      BuyNow: 'Ostaa',
      Current: 'Nykyinen suunnitelma',
      Monthly: 'Kuukausittain',
      Yearly: 'Vuosittain',
      monthly: 'kuukausittain',
      yearly: 'vuosi',
      month: 'kuukausi',
      XCheaper: '{{x}} halvempaa!',
      Downgrade: 'Päivitä',
      Forever: 'ikuisesti',
      ChangePlan: 'Vaihda suunnitelma',
      TrackReaderProgress: 'Seuraa lukijoita reaaliajassa',
      InlineComments: 'In-line-kommentit ja reaktiot',
      ReaderSurveys: 'Lukijakyselyt',
      NativeApps: 'Android- ja iOS-sovellukset',
      MultipleVersions: 'Käsikirjoitusversio',
      '1ActiveManuscript': '1 käsikirjoitus',
      '5ActiveManuscripts': 'Jopa 5 käsikirjoitusta',
      '20ActiveManuscripts': 'Jopa 20 käsikirjoitusta',
      UnlimitedActiveManuscripts: 'Rajoittamaton käsikirjoituksia',
      '3ActiveReadersPerManuscript': 'Jopa 3 lukijaa',
      '20ActiveReadersPerManuscript': 'Jopa 20 lukijaa',
      '50ActiveReadersPerManuscript': 'Jopa 50 lukijaa',
      UnlimitedActiveReaders: 'Rajoittamaton määrä lukijoita',
      Zero: 'Nolla',
      NoCreditCardNeeded: 'Luottokorttia ei tarvita',
      Recommended: 'Suositeltava',
      SomethingWentWrong: 'Jotain meni pieleen',
      SubscriptionChangeError: 'Liittymän muuttaminen epäonnistui',
      SubscriptionChangeSuccess: 'Päivitimme tilauksesi',
      ActionCancelled: 'Toiminto peruutettiin',
      Cancelled: 'Peruutettu',
      NextPayment: 'Seuraava maksu',
      PaymentPastDueMessage:
        'Maksusi on erääntynyt. Seuraava yritys: {{nextAttempt}}',
      ChangePaymentInformation: 'Muuta laskutustietoja',
      DashboardWelcomeMessage:
        '<0>Tervetuloa BetaReader.io-sivustoon!</0><1>Aloita <2>lataamalla</2> tai <6>lisäämällä</6> käsikirjoituksesi</1><2>Discovery</2>< 3>Älä unohda listata käsikirjoituksesi <2>löytö</2>-osioon, jotta pääset lisää beta-lukijoita.</3><4><0>Tässä</0> on nopea esittely siitä, miten tehdä että.</4><5>Tuki</5><6>Tutustu <2>ohjeportaaliimme</2> tai lähetä meille ping suoraan painamalla (?) sivun oikeassa yläkulmassa.</ 6><7>Voit aina lähettää meille ystävällistä sähköpostia osoitteeseen <2>hello@betareader.io</2></7>',
      Manuscripts: 'Käsikirjoitukset',
      CurrentlyReading: 'Parhaillaan lukemassa',
      WeeklyReaders: 'Viikoittain lukijat',
      TotalReaders: 'Lukijat yhteensä',
      FailedToDownloadReport: 'Raportin lataaminen epäonnistui',
      WaitingForConfirmation: 'Odottaa vahvistusta',
      ChangePublishingStatusToAddReaders:
        'Lisää julkaisijan tila lisäämällä lukijoita',
      SuggestASwap: 'Ehdota swap',
      WithdrawRequest: 'Poista tämä pyyntö',
      Confirm: 'Vahvistaa',
      ThisIsASwapRequestForX:
        'Tämä on vaihtopyyntö <2>{{x}}</2>. Jos hyväksyt swap-pyynnön, sinut lisätään lukijana {{x}}.',
      ApprovedSwapRequestForX: 'Tämä on vaihtopyyntö <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Tämä on vaihtopyyntö. Jos hyväksyt tämän pyynnön, sinut lisätään lukijaksi:',
      SwapRequest: 'Swap pyyntö',
      ApproveAndJoin: 'Hyväksy ja liity',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} on tarjonnut lukea {{y}} vastineeksi teille tämän kirjan lukemisen.',
      GoToSwapRequest: 'Siirry swap-pyyntöön',
      SwitchVersion: 'Vaihda versio',
      SendMagicLinkToX: 'Lähetä maaginen linkki {{x}}',
      notification_verb_comment: 'kommentoi',
      notification_verb_react: 'reagoi',
      notification_verb_reply: 'vastasi kommenttiin',
      notification_verb_abandon: 'hylkäsi kirjan',
      notification_verb_request_to_join: 'on pyytänyt lukemaan',
      notification_verb_message: 'lähetti sinulle viestin',
      notification_verb_sent_join_approval: 'on hyväksynyt lukupyyntösi',
      notification_verb_invitation_to_join: 'kutsui sinut lukemaan {{y}}',
      notification_verb_manuscript_submitted:
        'on lähettänyt käsikirjoituksen osoitteeseen {{y}}',
      'SaveAnd...': 'Tallenna ja...',
      Send: 'Lähettää',
      OnlySave: 'Tallenna vain',
      YourNotifications: 'Ilmoitukset',
      Notifications: 'Ilmoitukset',
      Images: 'Kuvat',
      '365DaysReadingData': '365 päivän lukijahistoria',
      '90DaysReadingData': '90 päivän lukijahistoria',
      '30DaysReadingData': '30 päivän lukijahistoria',
      alerts_PaymentPastDue: 'Maksusi on erääntynyt. Päivitä laskutustietosi',
      alerts_MissingName:
        'Meiltä puuttuu nimesi (tai kynänimesi). Päivitä se tänne:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} jätti vaatia elinikäistä alennusta. Syötä LAUNCH kuponkikenttään, kun päivität:',
      RequestedToSwapForX: 'Olet pyytänyt vaihtaa tämä yksi {{x}}',
      PaymentSuccessMessage: '<0><0>Kiitos ostoksestasi!</0><1><0></0></1></0>',
      Dashboard: 'Etusivu',
      year: 'vuosi',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Kuinka todennäköisesti suosittelisit BetaReader.io:ta ystävällesi?',
      NotAtAllLikely: 'Ei todennäköistä',
      ExtremelyLikely: 'Erittäin todennäköistä',
      ThanksDoYouWantToTellUsWhy: 'Kiitos! Haluatko kertoa meille miksi?',
      Create: 'Luoda',
      Books: 'Kirjat',
      Feed: 'Virtaus',
      ANewStory: 'uusi tarina',
      FailedToGetInvitations: 'Lataus epäonnistui kutsuja',
      unauthorizedPrivateBook:
        'Tämä kirja on yksityinen eikä tämän tilin käytettävissä.',
      invalidToken: 'Kutsu ei kelpaa tälle tilille tai se on vanhentunut.',
      RaiseYourReaderLimit: 'Nosta lukijarajaa (tällä hetkellä {{x}})',
      InviteYourFriends: 'Kutsu ystäväsi mukaan',
      referralText:
        '<0> Hanki jopa 10 ylimääräistä lukijapaikkaa kutsumalla ystäväsi BetaReader.io-sivustoon! </0> <1> Annamme sinulle ja ystävällesi jokaiselle ystävälle, joka luo vahvistetun kirjoittajatilin ja listaa sinulle kiitos käsikirjoituksen. Yksi lukijapaikka kussakin, enintään 10 paikkaa. </1> <2> Voit myös nostaa lukijarajaa lukemalla ja tarkistamalla 100 kt sanaa <1> <0> lukijasovelluksemme </0> </ 1 kautta. > tai <3><0> päivittämällä</0> </3> tilisi! </2>',
      INVITED: 'Kutsuttu',
      CREATED_ACCOUNT: 'Tili luotu',
      CONFIRMED: 'Vahvistettu',
      RegistrationDate: 'Rekisteröintipäivä',
      Profile: 'Profiili',
      EditProfile: 'Muokkaa profiilia',
      GetMoreReaders: 'Hanki lisää lukijoita',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Olet saavuttanut lukijan rajan. <1>Päivitys</1> Voit lähettää lisää kutsuja lukijaindeksimme tai hyväksyä lukupyynnöt. Vapaan suunnitelmamme avulla voit ehdottaa <3>swap</3> muiden kirjoittajien kanssa tai kutsua niin monta lukijaa, jotka haluat suoraan sähköpostiosoitteensa kautta.</0>',
      SwapsDontAffectReaderLimit:
        'Käsikirjoitusten vaihtoa ei lasketa lukijasi rajaan.',
      maxBooksLimitMessage:
        '<0> Olet saavuttanut käsikirjoitusrajan. <1> Nosta rajaa </1>, jotta voit testata useita käsikirjoituksia rinnakkain. </0>',
      Type: 'Tyyppi',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> Avaa ilmoittautumisbonus! </0> <1> Näyttää siltä, että ystäväsi on lähettänyt - noudata alla olevia ohjeita avataksesi keskinäisen kirjautumisbonuksen! </1> <2> <0> <0 > </0> <1> <0> Vahvista tili </0> <1> Tarvitset kelvollisen nimen ja sähköpostiosoitteen </1> </1> </0> <1> <0> </0> <1> <0> Luettele käsikirjoitus </0> <1> Luettele testattava käsikirjoitus </1> </1> </1> <2> <0> </0> <1> <0> Odota </0> <1> Vahvistamme noin 3 päivää </1> </1> </2> </2>',
      titleDiscoveryRecommended: 'Suositellaan sinulle',
      subheaderDiscoveryRecommended:
        'Jotain uutta valitsemiesi genrejen perusteella',
      titleDiscoveryRecentlyAdded: 'Äskettäin lisätty',
      subheaderDiscoveryRecentlyAdded: 'Äskettäin lisätyt otsikot',
      discoveryRecentlyAddedTitlesInGenre:
        'Äskettäin lisätyt nimikkeet genressä {{x}}',
      titleDiscoveryPremium: 'Suositellut otsikot',
      subheaderDiscoveryPremium: 'Palkkiokirjailijoiden otsikot',
      referralMessage:
        'Kehotan teitä BetaReader.io! Jos rekisteröidyt tämän linkin avulla, me molemmat saavat bonuksen!',
      referralBody: 'Rekisteröidy täällä: {{x}}',
      ThisCannotBeUndone: 'Tätä ei voi kumota',
      DeletedVersion: 'Versio poistettiin',
      InvalidEmail: 'Virheellinen sähköpostiosoite',
      EmailNotVerified: 'Ei vahvistettu, tarkista sähköpostisi',
      SelectFavoriteGenres: 'Valitse suosikkilajisi',
      Loading: 'Ladataan',
      ChapterIdentification: 'Luvun tunnistaminen',
      ChapterAnalysis: 'Lukuanalyysi',
      CompleteImport: 'Suorita tuonti loppuun',
      Import: 'Tuoda',
      Processing: 'Tiedoston käsittely...',
      ThisMightTakeAWhile: 'Tämä saattaa kestää hetken',
      LikeThisCover: 'Pidätkö tästä kirjan kannesta?',
      DownloadFailedMsg:
        'Lataus epäonnistui. Estikö sen ponnahdusikkunoiden esto?',
      ShareAuthorAppEmailSuccessMessage:
        'Kutsu lähetetty. Tarkista tila <1> täällä </1>.',
      failedToUpdateReader: 'Lukijan päivittäminen epäonnistui',
      activeReaders: 'Aktiivinen lukijat',
      ReadingList: 'Lukulista',
      Users: 'Käyttäjät',
      InviteUser: 'Kutsu käyttäjä',
      Role: 'Rooli',
      member_role_admin: 'Järjestelmänvalvoja',
      member_role_member: 'Jäsen',
      couponsOrDiscountsWillNotBeKept:
        '<0>Olemassa olevat alennukset eivät liity uuteen sopimukseen.</0>',
      FailedToFetchX: '{{X}} ei onnistunut',
      YouHaveBeenInvitedToJoinAccountX:
        'Sinut on kutsuttu liittymään {{x}} BetaReader.io-tiliin.',
      AcceptAccountInvitationMsg:
        'Jos hyväksyt kutsun, voit nähdä ja muokata kaikkia tämän tilin käsikirjoituksia.',
      InvitedMembersMsg:
        'Tilin jäsenet voivat nähdä ja muokata kaikkia tilisi käsikirjoituksia, mutta he eivät näe laskutustietoja. Katso betareader.io/pricing saadaksesi lisätietoja tilin jäsenhinnasta.',
      InviteCollaborator: 'Kutsu yhteistyökumppani',
      InviteCollaboratorMsg:
        'Yhteistyökumppanit voivat tarkastella käsikirjoituksen ja lukijan tietoja ja vastata palautteeseen.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Sinua on kutsuttu {{book}} yhteistyöhön {{author}}',
      AcceptCollaborationInvitationMsg:
        'Jos hyväksyt kutsun, voit tarkastella käsikirjoituksen ja lukijan tietoja ja vastata palautteeseen.',
      Collaborators: 'Yhteistyökumppanit',
      ThisIsABetaFeature:
        'Tämä on beta-ominaisuus. Kerro meille, jos sinulla on ongelmia, tai jos sinulla on palautetta jakaa....',
      UserNotFoundMsg:
        'Näyttää siltä, että sinulla ei ole tiliä. Luo yksi <1> täältä </1>!',
      AuthorName: 'Tekijän nimi',
      Details: 'Yksityiskohdat',
      Settings: 'Asetukset',
      ReviewAtLeastXwords: '{{x}} sanoja tarkistettiin',
      CheckBonus: 'Tarkista bonuksesi',
      FailedToAddCommentOrReaction:
        'Kommentin tai reaktion lisääminen epäonnistui',
      ThisIsAWIP: 'Tämä on WIP',
      WIPExplanation:
        'Merkitse tarinasi nimellä WIP (Work In Progress), kun se ei ole valmis, esim. kun et ole lisännyt kaikkia lukuja.',
      MatureContent: '18+',
      PublicationDate: 'Julkaisu',
      TimePlan: 'Aikasuunnitelma',
      NotSpecified: 'Ei määritelty',
      WhatIsThisStoryAbout: 'Mistä tämä tarina on?',
      WhatDoYouNeedHelpWith: 'Mihin tarvitset apua?',
      StartWriting: 'Alkaa kirjoittaa',
      CreateManuscript: 'Luo käsikirjoitus',
      ImportManuscript: 'Tuo käsikirjoitus',
      ImportManuscriptStepDescription: 'Lataa tai luo käsikirjoitus',
      InviteReaders: 'Kutsu lukijoita',
      InviteReadersStepDescription: 'Sinä päätät, kuka voi lukea',
      CollectFeedback: 'Kerää palautetta',
      CollectFeedbackStepDescription: 'Analysoi kommentteja ja lukemismalleja',
      YouDontHaveAnyManuscripts: 'Sinulla ei ole vielä käsikirjoituksia',
      AuthorsAndPublishers: 'Tekijät ja kustantajat',
      FindOutWhatYourReadersThink:
        'Ota selvää, mitä lukijat todella ajattelevat kirjastasi',
      ReadAndInfluenceTheNextBestseller:
        'Lue ja vaikuta tuleviin bestsellereihin',
      PrivateSharing: 'Yksityinen jakaminen',
      PrivateSharingDescription:
        'Jaa käsikirjoituksesi yksityisesti lukijoiden kanssa',
      CollectUnbiasedFeedback: 'Kerää puolueetonta palautetta',
      CollectUnbiasedFeedbackDescription:
        'Anna lukijoiden kommentoida näkemättä toistensa palautetta',
      AnalyzeReadingPatterns: 'Analysoi lukumallit',
      AnalyzeReadingPatternsDescription:
        'Ota selvää missä lukijasi menettävät kiinnostuksensa tai ovatko tarinan kimppu',
      ReadItFirst: 'Lue se ensin',
      ReadItFirstDescription: 'Lue tulevia romaaneja, kun ne ovat vielä beetaa',
      InfluenceTheStory: 'Vaikuta tarinaan',
      InfluenceTheStoryDescription: 'Palautteesi vaikuttaa lopputulokseen',
      ConnectWithAuthors: 'Keskustele kirjoittajien kanssa',
      ConnectWithAuthorsDescription:
        'Auta suosikkikirjailijoitasi liittymällä heidän beetatiiminsä',
      YourBooks: 'Sinun kirjasi',
      New: 'Uusi',
      Members: 'Jäsenet',
      Contacts: 'Yhteydet',
      AddMembersFromContacts: 'Lisää jäseniä yhteystietoluettelostasi',
      ConversationSettings: 'Keskusteluasetukset',
      PremiumAuthorInfo:
        'Tämä on premium-kirjoittajan käsikirjoitus. Tämä tarkoittaa tyypillisesti, että kirjoittaja on enemmän investoinut projektiin ja että voit odottaa heidän tekevän mitä tarvitaan tämän kirjan julkaisemiseksi.',
      StillWorkingOnThis:
        'Olemme edelleen laativansa BetaReader.io. Kerro meille, jos on jotain erityistä haluat nähdä täällä.',
      Engagement: 'Kihlaus',
      AssemblingLetters: 'Kokoaminen kirjeitä ...',
      ConstructingAlphabet: 'Rakentamalla aakkoset ...',
      BuildingSentences: 'Building lauseita ...',
      BecomingSentient: 'Tulossa tuntevia ...',
      BooksRead: 'Kirjoja seuraavasti: {{x}}',
      WordsReviewed: 'Sanat tarkistetaan: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Keskimääräinen läpimenoaika per 1000 sanaa: {{count}} päivä',
      AvgTurnAroundTimePer1kWords_plural:
        'Keskimääräinen läpimenoaika per 1000 sanaa: {{count}} päivää',
      ViewAsReader: 'Tarkastele lukija',
      EditMode: 'Muokkausmoodi',
      BookCover: 'Kirjan kansi',
      WelcomeToBRReadathon: 'Tervetuloa BetaReader.io Readathon!',
      ReadathonHasEnded: 'Readathon on nyt päättynyt.',
      ReadathonInfo:
        'BetaReader.io Readathon kestää syys 26.-31 lokakuu 2019. Jokainen, joka lukee ja tarkistaa ainakin yhden kirjan kautta BetaReader.io tänä aikana osallistuu, ja voit lisätä mahdollisuus voittaa lukemalla ja tarkistaa enemmän kirjoja. Voittaja arvotaan 1. marraskuuta, ja sitä enemmän sanoja olet lukenut ja tarkistaa sitten, korkeammat mahdollisuudet ovat voittaa. Esimerkiksi joku, joka on lukenut ja tarkistetaan 50000 sanaa on 5x on suuri mahdollisuus voittaa, koska joku on lukenut ja tarkistetaan 10000 sanaa.',
      ReadathonPrize1: '1. palkinto: $ 50 Amazonin lahjakortti.',
      ReadathonPrize23: 'Toinen-kolmas palkinto: $ 20 Amazon Lahjakortti.',
      SignInToJoin: 'Kirjaudu liittymään',
      WordsReviewedByYou: 'Sanat tarkistaa teitä',
      WordsReviewedByEveryone: 'Sanat tarkistaa kaikkien',
      ChanceOfWinning: 'Nykyinen mahdollisuus voittaa',
      Completed: 'valmistunut',
      CompletedAt: 'valmistui',
      Pending: 'odotettaessa',
      Position: 'asento',
      FindSomethingToRead: 'Etsi jotain luettavaa',
      DontKnow: 'En tiedä',
      IsThisReadyToPublish:
        'Luuletteko, että tämä kirja on valmis julkaistavaksi?',
      UpdateProfileHeader: 'Tarvitsemme enemmän tietoa oman profiilin',
      WhatsYourName: 'Mikä on nimesi tai nimimerkki?',
      WhereAreYouFrom: 'Mistä olet kotoisin?',
      WhenWereYouBorn: 'Minä vuonna olet syntynyt?',
      WhichGenresDoYouPrefer: 'Jotka lajityyppejä pidät eniten?',
      WhichLanguagesDoYouRead: 'Mitä kieliä lukea?',
      BioPlaceholder:
        'Kerro meille kuka olet ja jos sinulla on verkkosivustoja, joihin viitata. Vahvempi esitys auttaa sinua saamaan hyväksynnän useammille käsikirjoituksille.',
      Thanks: 'Kiitos!',
      WhenDoYouWantToHearFromUs: 'Milloin haluat kuulla meistä?',
      ReaderListConsentHeader: 'Haluatko olla mukana lukijatietokannassamme?',
      ReaderListConsentInfo:
        'Tekijät, joiden teoksia vastaavat mieltymyksiäsi, voivat lähettää sinulle linkin teoksiinsa, mutta sinun ei ole pakko lukea, jos et halua tai sinulla ei ole aikaa.',
      RoleHeader: 'Oletko kirjailija vai lukija?',
      RoleInfo: 'Käytämme näitä tietoja optimoidaksemme käyttökokemuksesi.',
      OnYourProfile: 'Tämä näkyy käyttäjäprofiilissasi.',
      ProfileBuilderThankYouMessage:
        '<0>Kiitos!</0><1>Voit aina päivittää profiilisi <1>täällä</1>.</1>',
      DoThisLater: 'Tee tämä myöhemmin',
      '2MonthsFree': '2 kuukautta ilmaiseksi',
      Joined: 'Liittyi',
      UserInfo: 'Käyttäjätiedot',
      Age: 'Ikä',
      Bio: 'Bio',
      ReaderList: 'Löydä lukijoita',
      Popular: 'Suosittu',
      BookHas0Words: '0 sanaa',
      BookHas0WordsDescription:
        '<0> Tämä kirja on ainakin yksi versio 0 sanaa. Voit lisätä tai ladata lukuja </0> <1> täällä </1>.',
      LeaveBeta: 'Poistu betasta',
      DiscoverNewBooks: 'Tutustu uusiin kirjoihin',
      FailedToFindReader: 'Lukijan löytäminen epäonnistui',
      ApprovedReadRequest: 'Lukupyyntö on hyväksytty',
      Approved: 'Hyväksytty',
      UserAlreadyInvited: '{{user}} on jo kutsuttu',
      SelectBook: 'Valitse kirja',
      InvitationMessagePlaceholder:
        'Lisää kutsuun henkilökohtainen viesti parantaaksesi mahdollisuuksiasi tulla hyväksytyksi.',
      YouHaveAPendingInvitation: 'Sinulla on odottava kutsu',
      ReaderIndexInfo:
        'Lukijatietokanta sisältää BetaReader.io-käyttäjät, jotka ovat ilmoittaneet olevansa avoimia kirjoittajien lähestymistavoille. Lähetä heille kutsu parhaalla äänitasolla, mutta muista, että he voivat vapaasti hylätä kutsun mistä tahansa syystä.',
      ReaderLimitReached: 'Olet saavuttanut lukijamääräsi',
      Invite: 'Kutsu',
      Languages: 'Kielet',
      LinkWasSentToX: 'Olemme lähettäneet kirjautumislinkin osoitteeseen {{x}}',
      Verifying: 'Vahvistetaan...',
      FoundNoAvailableManuscripts:
        'Emme löytäneet saatavilla olevia käsikirjoituksia',
      FindASpecificQuestion: 'Suodata kysymykset',
      ReadAndReviewBonusInfo:
        'READ & REVIEW bonuspäivityksesi, kun olet valmis ja tarkistanut kirjan.',
      ManuscriptStatus: 'Käsikirjoitus tila',
      MSStatusDescription_alpha:
        'Tämä on varhainen vaihe tai osittainen käsikirjoitus.',
      MSStatusDescription_beta:
        'Tämä on kokonaisen kirjan myöhäisvaiheinen käsikirjoitus.',
      SelectStatus: 'Valitse tila',
      MaxAllowedGenres: 'Voit valita enintään 5 genreä',
      TooLongDescription: 'Kuvauksesi on liian pitkä (enintään 1000 merkkiä)',
      Duplicate: 'Kopioi',
      Download: 'Lataa',
      DuplicateVersionInfo:
        'Kun kopioit versiota, kaikki luvut ja tutkimukset kopioidaan uuteen versioon. Lukijasi pysyvät vanhassa versiossa.',
      NoBookMsg:
        '<0> Täällä ei ole mitään! </0> <1> <0> Haluatko </0> <1> lisätä oman käsikirjoituksesi? </1> </1>',
      CommentsCopiedFromOriginal:
        'Kommentit kopioitiin alkuperäisestä asiakirjasta',
      CopyComments: 'Kopioi kaikki kommentit',
      MigrateReaders: 'Siirrä lukijat',
      Posts: 'Viestit',
      Sent: 'Lähetetty',
      Username: 'Käyttäjätunnus',
      UsernameAlreadyTaken: 'Tämä käyttäjätunnus on varattu',
      Followers: 'Seuraajaa',
      ReadersAndFollowers: 'Lukijat ja seuraajat',
      WhoCanSeeThis: 'Kuka voi nähdä tämän?',
      FollowCount: '{{count}} seuraaja',
      FollowCount_plural: '{{count}} seuraajaa',
      Follow: 'Seuraa',
      Following: 'Seurata',
      MarkAllAsRead: 'Merkitse kaikki luetuksi',
      Working: 'Työskentelee...',
      Audience: 'Yleisö',
      ShowAllComments: 'Näytä kaikki kommentit...',
      Publish: 'Julkaista',
      InviteByEmail: 'Kutsu sähköpostilla',
      InviteByLink: 'Kutsu linkin kautta',
      Chat: 'Chat',
      WelcomeToSupportMessage:
        'Hei {{x}}! Tervetuloa Betareader-tukeen 👋, yritämme vastata mahdollisimman pian. Missä voimme auttaa sinua?',
      TalkToSupport: 'Keskustele tukitiimimme kanssa',
      StartSupportChat: 'Aloita tukikeskustelu',
      StartSupportChatPMessage:
        '<0>Voit aina tavoittaa meidät chatin kautta, ja vastaamme mahdollisimman pian.</0>',
      FindHelpArticlesMessage:
        '<0>Tarvitsetko apua? tutustu <1> ohje </1> -osioon, joka on täynnä ohjeita opastavia artikkeleita.</0>',
      HelpArticles: 'Ohjeartikkelit',
      CloseConversationAlertHeader: 'Sulje keskustelu',
      CloseConversationAlertMessage: 'Haluatko lopettaa tämän keskustelun?',
      EmailSupportMessage:
        '<0> Lähetä sähköpostia osoitteeseen <1>{{mail}}</1>, niin vastaamme niin pian kuin mahdollista. </0>',
      Includes: 'Sisältää',
      DoesNotInclude: 'Ei sisällä',
      IncludesAll: 'Sisältää kaikki',
      RemoveFilter: 'Poista suodatin',
      AddFilter: 'Lisää suodatin',
      SelectGenres: 'Valitse genrejä',
      And: 'Ja',
      ContinueWithGoogle: 'Jatka Googlella',
      ContinueWithFacebook: 'Jatka Facebookissa',
      ContinueWithApple: 'Jatka Applella',
      NewOnBetareader: 'Uusi BetarReader?',
      AlreadyHaveAccount: 'Onko sinulla jo tili?',
      SignUpWithFacebook: 'Rekisteröidy Facebookin kautta',
      SignUpWithGoogle: 'Rekisteröidy Googlella',
      SignUpWithApple: 'Rekisteröidy Applen kanssa',
      OpportunityWithoutDescriptionMessage:
        'Kirjoittaja ei ole antanut kuvausta.',
      SignInToViewContent: 'Kirjaudu sisään nähdäksesi tämän sisällön',
      ContentBelongsToAnotherAccount:
        'Tämä sisältö kuuluu eri tiliin kuin aktiivinen tili.',
      SwitchAccount: 'Vaihda tiliä',
      OwnBooks: 'Omat kirjat',
      Collaborations: 'Yhteistyötä',
      UploadImage: 'Lataa kuva',
      SplitChapter: 'Jaa kappale',
      UpgradeToUnlock: 'Päivitä lukituksen avaamiseksi',
      '3ReaderSlots': '3 lukijapaikkaa',
      UnlimitedReaderSlots: 'Rajoittamattomat lukijapaikat',
      ImportWarning: 'Tuontivaroitus',
      ImportExeedLenghMessage:
        '<0><0>Yksi ​​tai useampi luvuistasi näyttää olevan liian pitkä tuotavaksi. Varmista, että olet noudattanut<1> tuontiohjeitamme. </1></0><1>Vinkki: voit siirtää hiiren osoittimen lukutekstin päälle ja jakaa suurempia lukuja koon-työkalun avulla.</1></0>',
      OneTeamMember: '1 tiimin jäsen',
      AdditionalTeamMembers: 'Lisää tiimin jäsenet',
      PerMonth: 'kuukaudessa',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Lähetä käsikirjoituksesi',
      SubmitTo: 'Lähetä osoitteeseen',
      YourSubmittedManuscripts: 'Lähettämäsi käsikirjoitukset',
      SelectManuscriptToSubmit: 'Valitse lähetettävä käsikirjoitus',
      AvailableToRead: 'Luettavissa',
      ManuscriptSubmissionSuccess: 'Käsikirjoitus lähetetty',
      ManuscriptSubmittedTo:
        'Käsikirjoituksesi {{_manuscriptName}} on lähetetty osoitteeseen {{_inboxName}}',
      SubmittedBy: 'Lähettäjä {{_userName}}',
      ReceivedManuscripts: 'Vastaanotettu käsikirjoituksia',
      InviteToRead: 'Kutsu lukemaan',
      YourReview: 'Sinun arvostelusi',
      UserReviewPlaceholder: 'Käyttäjien arvostelut ovat kaikkien nähtävissä.',
      LeaveReviewError:
        'Voit arvostella vain käyttäjiä, jotka ovat lukeneet jonkin käsikirjoituksistasi.',
      NotEnoughData: 'Ei tarpeeksi dataa',
      EditSignUpSurveyQuestions: 'Muokkaa liittymiskyselyn kysymyksiä',
      AddSignUpSurveyQuestions: 'Lisää liittymiskyselyyn liittyviä kysymyksiä',
      To: 'Jotta',
      FinishedReadingAt: 'Lopetin lukemisen klo {{x}}',
      FailedToSendInvitation:
        'Kutsun lähettäminen epäonnistui. Vahvista sähköpostiosoite',
      EditInvitation: 'Muokkaa kutsua',
      ChapterSeparator: 'Lukuerotin',
      CancelReason: 'Ennen kuin jatkat, voisitko kertoa meille miksi?',
      cancel_reason_technical_issues: 'Teknisiä ongelmia',
      cancel_reason_too_expensive: 'Tuote on liian kallis',
      cancel_reason_finished_my_beta: 'Päätin projekti',
      cancel_reason_switching_to_another_product:
        'Olen Vaihto toiseen tuotteeseen',
      cancel_reason_missing_features: 'Olen puuttuu tärkeitä ominaisuuksia',
      cancel_reason_did_not_meet_expectations:
        'Alusta ei vastannut odotuksiani',
      cancel_reason_other: 'Muut syyt',
      TellUsMore: 'Kerro meille lisää',
      DeleteAccount: 'Poista tili',
      YourAccountHasBeenDeleted: 'Tilisi on poistettu',
      DeleteAccountConfirmation:
        'Haluatko varmasti poistaa tilisi ja kaikki sen tiedot? Tätä toimintoa ei voi kumota.',
      GoodbyeMessage:
        'Pahoittelemme, että jätit meidät, ja toivomme, että palaat tulevaisuudessa.',
      EditContent: 'Muokkaa sisältöä',
      AffectedReadersMessage:
        'Lukija {{count}} on jo lukenut tämän luvun. Napsauta tätä, jos haluat lähettää päivityksen muutoksistasi.',
      AffectedReadersMessage_plural:
        '{{count}} lukijaa on jo lukenut tämän luvun. Napsauta tätä, jos haluat lähettää päivityksen muutoksistasi.',
      ImportingChapterXofY: 'Tuodaan luku {{_x}} / {{_y}}',
      ImportStarted: 'Tuonti aloitettu',
      XVersions: '{{count}} versiota',
      XVersions_plural: '{{count}} versiota',
      UnsubscribeSuccessful:
        'Tilauksesi on peruutettu. Voit aina päivittää asetuksiasi profiilisi kautta.',
      UnsubscribeUnsuccessful:
        'Tilauksen peruuttaminen epäonnistui. Lähetä viesti osoitteeseen support@betareader.io, jos ongelma jatkuu.',
      GoodbyeHeader: 'Hyvästi!',
      BulkAddReaders: 'Lisää lukijoita CSV-tiedostosta',
      Home: 'Koti',
      Actions: 'Toiminnot',
      Invitations: 'Kutsut',
      SelectCSVWithReaders: 'Valitse CSV lukijoiden kanssa',
      Synopsis: 'Synopsis',
      SynopsisDescription:
        'Anna tekoälymme, BetaBotin, luoda synopsis tekstisi perusteella. Se ei näy lukijoillesi.',
      LastUpdated: 'Viimeksi päivitetty',
      CreateSynopsis: 'Luo synopsis',
      RefreshSynopsis: 'Päivitä synopsis',
      ElevatorPitch: 'Hissipaikka',
      ThisIsAPremiumFeature: 'Premium-ominaisuus',
      GoBack: 'Mene takaisin',
      Retry: 'Yritä uudelleen'
    }
  },
  is: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Vinsamlegast athugaðu að þegar skilaboð er lesandi notendanafnið þitt og ekki dulnefnið þitt verður sýnilegt',
      Library: 'Bókasafn',
      Account: 'Reikningur',
      EmptyLibrary: 'Bókasafnið er tóm.',
      EmptyLibraryAction:
        'Bjóstu við að finna eitthvað hér? Hafðu samband við höfund, eða senda okkur tölvupóst á {{_email}} og við munum sjá hvað við getum gert.',
      ByAuthor: 'með {{_authorName}}',
      ExceedChapterLenghtWarning: 'Kafli er of langur til að flytja inn',
      WordCount: '{{_wordCount}} orð',
      WordCountString: '{{count}} orð',
      WordCountString_plural: '{{count}} orð',
      CharCountString: '{{count}} rittákn',
      CharCountString_plural: '{{count}} rittákn',
      SurveyCount: '{{count}} könnun',
      SurveyCount_plural: '{{count}} könnunum',
      ChapterCount: '{{count}} kafli',
      ChapterCount_plural: '{{count}} köflum',
      QuestionCountString: '{{count}} spurning',
      QuestionCountString_plural: '{{count}} spurning',
      LoginToChangeEmail: 'Vinsamlega skráðu aftur til að breyta e-mail',
      SendVerification: 'Senda staðfestingatengilinn',
      Name: 'Heiti',
      Email: 'Tölvupóstur',
      EnterYourName: 'Vinsamlegast sláðu inn nafnið þitt',
      ReceiveCompanyUpdates: 'Fá tölvupóst og fréttir frá BetaReader',
      ReceiveBookEmails: 'Recieve tölvupóst um nýjar bækur',
      ReceiveBookActivityUpdates: 'Fá tölvupóst um starfsemi á bókum mínum',
      ReceiveMessageUpdates: 'Fá tölvupóst þegar ég fæ ný skilaboð',
      IncludeProfileInReaderList:
        'Láttu prófílinn minn fylgja í lesendagagnagrunninum',
      PrivacyPolicy: 'Friðhelgisstefna',
      TermsOfService: 'Skilmálar þjónustu',
      ReadOurBlog: 'Lesa bloggið okkar',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Næst',
      Back: 'Back',
      CreateAccount: 'Búa til aðgang',
      CreateAccountPrompt:
        '<0>Ertu ekki með reikning?<1> <0>Búa til hér!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Gleymdirðu lykilorðinu þínu?<1> <0>Endurstillt hér!</0></1></0>',
      SignIn: 'Skráðu þig inn',
      SignOut: 'Útskrá',
      SendResetLink: 'Senda tengil til að endurstilla',
      ResetPassword: 'Endur stilla lykilorð',
      Password: 'Lykilorð',
      OrUse: 'eða notkun',
      NoConnection: 'Offline ...',
      AcceptTermsMessage: 'Samþykkja skilmála þjónustunnar',
      AcceptToSToCreateAccount:
        'Þú þarft að samþykkja skilmála okkar þjónustu til að búa til reikning.',
      Answer: 'Svaraðu',
      FailedToLoadBook: 'Ekki tókst að hlaða bókina.',
      PullDownToRefresh: 'Dragðu niður til að endurnýja.',
      ContactSupport: 'Viltu samband við stuðning',
      Support: 'stuðningur',
      LastRead: 'Last lesa',
      XInvitations: '{{count}} boð',
      XInvitations_plural: '{{count}} boð',
      EnterComment: 'Athugasemd ...',
      EnterReply: 'Svara ...',
      Reply: 'Svaraðu',
      Reply_plural: 'svör',
      Reply_verb: 'Svara',
      show: 'sýna',
      hide: 'fela',
      Comments: 'Athugasemdir',
      InlineComment: 'Innfelldur athugasemd',
      Reject: 'hafna',
      Accept: 'Samþykkja',
      Error: 'villa',
      failed: 'mistókst',
      SelectedText: 'Valinn texta',
      FailedToFetchUserProfile: 'Mistókst að ná notanda uppsetningu.',
      ProblemPersistContactSupport:
        'Ef vandamálið er viðvarandi, samband við þjónustudeild í:',
      clickToAddComment: 'Smelltu hér til að bæta við athugasemd',
      ColorMode: 'Litahamur',
      FontSize: 'Leturstærð',
      Discover: 'Discover',
      MyAccount: 'Minn reikningur',
      MyManuscripts: 'Handrit mín',
      Sweden: 'Svíþjóð',
      USA: 'Bandaríkin',
      UK: 'Bretlandi',
      Germany: 'Þýskalandi',
      France: 'Frakklandi',
      Spain: 'Spánn',
      Country: 'Land',
      Gender: 'Kyn',
      YoB: 'Fæðingarár',
      PreferredGenres: 'Æskilegar tegundir',
      PreferredLanguages: 'Kjörtungumálunum',
      EmailPreferences: 'Tölvupóststillingar',
      chapters: 'kafla',
      words: 'orð',
      YouShouldBetaRead: 'Þú ættir að lesa',
      onBetaReader: 'á BetaReader.io',
      Share: 'Deila',
      NoGuidelines: 'Höfundur hefur ekki veitt neinar viðmiðunarreglur.',
      Read: 'Lestu',
      on: 'á',
      Author: 'Höfundur',
      JoinBeta: 'Mig langar að lesa þetta',
      joinConsentString:
        'Ég skil að með því að biðja um að taka þátt í þessari tilraunaútgáfu mun ég deila netfanginu mínu með höfundinum.',
      Submit: 'Sendu inn',
      AnswerSurvey: 'Svaraðu!',
      SendJoinRequest: 'Senda',
      IJustWantToRead: 'Ég vil bara lesa',
      CopyrightInfo: 'Upplýsingar um höfundarrétt',
      RequestToRead: 'Sendu lestrarbeiðni',
      Feedback: 'Endurgjöf',
      NoComment: 'Enginn hefur tjáð sig.',
      OnceUponATime: 'Einu sinni var...',
      Review: 'Upprifjun',
      Overall: 'Heild',
      Plot: 'Intrigue',
      Grammar: 'Málfræði',
      Title: 'Titill',
      AddReview: 'Bæta við umsögn',
      EnterReviewText: 'Sláðu inn stutta umsögn',
      OverallRating: 'Heild',
      OverallRatingRequired: 'Bættu við heildareinkunn',
      PlotRating: 'Intrigue',
      GrammarRating: 'Málfræði',
      SubmitReview: 'Sendu umsögn',
      LeaveReview: 'Skildu eftir umsögn',
      checkOutAuthorApp:
        '<0>Ertu rithöfundur?</0><1></1><2>Skoðaðu okkar <1>app fyrir höfunda</1>!</2>',
      MyLibrary: 'Bókasafnið mitt',
      ReadingPreferences: 'Lestrarstillingar',
      'E.g.': 'T.d.',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Ég vil fá tölvupóst frá BetaReader.io þegar höfundur gerir breytingar eða uppfærslur á',
      'I want to receive emails about BetaReader news and updates.':
        'Ég vil fá tölvupósta um BetaReader fréttir og uppfærslur.',
      'I want to receive emails about new books that match my reading preferences.':
        'Ég vil fá tölvupósta um nýjar bækur sem passa við lestrarstillingar mínar.',
      'this book': 'þessa bók',
      'Email us on': 'Sendu okkur tölvupóst á',
      "and we'll see what we can do :)": 'og við sjáum hvað við getum gert :)',
      'Want an author account?': 'Viltu höfundareikning?',
      Preferences: 'Óskir',
      Language: 'Tungumál',
      Browse: 'Skoðaðu',
      'to beginning': 'að upphafi',
      'to chapter...': 'í kafla...',
      Location: 'Staðsetning',
      Previous: 'Fyrri',
      Finish: 'Ljúktu',
      Description: 'Lýsing',
      FeedbackGuidelines: 'Leiðbeiningar um endurgjöf',
      'Start reading': 'Byrjaðu að lesa',
      StartReading: 'Byrjaðu að lesa',
      LeaveComment: 'Athugasemd',
      replies: 'svarar',
      Show: 'Sýna',
      Hide: 'Fela',
      'Add Reply': 'Bæta við svari',
      PendingInvitation: 'Bíður boð',
      Decline: 'Hafna',
      DeclinedInvitationMessage:
        'Þú hefur afþakkað þetta boð um að lesa, en þú getur samt þegið það ef þú hefur skipt um skoðun.',
      Welcome: 'Velkomin',
      by: 'með',
      to: 'til',
      AcceptInvitation: 'Samþykkja boð',
      InvitationAccepted: 'Boð Samþykkt',
      WaitForBook: 'Vinsamlegast bíddu á meðan við undirbúum bókina þína',
      PasswordLength:
        'Lykilorðið þitt þarf að vera að minnsta kosti 6 stafir að lengd',
      AgreeToPolicyAndTerms:
        'Þú verður að samþykkja persónuverndarstefnu BetaReader og þjónustuskilmála til að geta skráð þig.',
      'Sending...': 'Sendir...',
      VerificationSent: 'Staðfestingartölvupóstur sendur.',
      VerificationEmailFailed: 'Mistókst að senda staðfestingarpóst.',
      IAcceptToC:
        '<0>Ég samþykki BetaReader.io:er<1>Privacy Policy</1> & <3>Þjónustuskilmálar</3></0>',
      AccountSettings: 'Reikningsstillingar',
      ContactSettings: 'Contact',
      Chapters: 'Kaflar',
      Options: 'Valmöguleikar',
      Option: 'Valkostur',
      BetaLanguageMessage:
        '<0>Við erum að vinna í að bæta tungumál styðja, og eru frábær þakklát fyrir alla þá aðstoð sem við getum fengið. Ef þú finnur einhverjar villur á staðnum, þá skaltu ekki hika við að hafa samband við okkur á: <1>hello@betareader.io</1></0>',
      Genres: 'Tegundir',
      PressEnterToSave: 'Ýttu á Enter til að vista',
      female: 'kvenkyns',
      male: 'karlkyns',
      other: 'ekki tvíundir',
      DontWantToShare: 'einkaaðila',
      SignInWithPassword: 'Skráðu þig inn með lykilorði',
      GoToBook: 'Farðu í bók',
      Requested: 'Beiðni þín er í bið',
      AnsweredOrRequestedNotice:
        'Þú hefur beðið um að vera með í þessari tilraunaútgáfu. Höfundur mun hafa samband við þig eins fljótt og hann getur.',
      RequestedWithDifferentEmail:
        'Þú hefur beðið um að taka þátt í þessari tilraunaútgáfu með öðrum tölvupósti en þér var boðið með. Höfundur mun hafa samband við þig eins fljótt og hann getur.',
      LoadingLibrary: 'Er að leita að bókum...',
      PreparingBook: 'Undirbúa bók',
      InLineCommentPlaceholder:
        'Skildu eftir athugasemdina þína og ýttu á Enter til að vista',
      ExitSurvey: 'Exit könnun',
      WhyExitQuestion: 'Af hverju viltu yfirgefa þessa tilraunaútgáfu?',
      LostInterest: 'Ég missti áhugann',
      NotEnoughTime: 'Ég hef ekki nægan tíma',
      Other: 'Önnur ástæða',
      reason_lost_interest: 'Ég missti áhugann',
      reason_no_time: 'Ég hef ekki nægan tíma',
      reason_other: 'Önnur ástæða',
      reason_: 'Ekki tilgreint',
      ExitFeedbackQuestion: 'Viltu gefa höfundinum smá álit?',
      ExitFeedbackPlaceholder: 'Öll endurgjöf er gagnleg',
      ThankYouForYourFeebdack: 'Þakka þér fyrir álit þitt!',
      AppVersion: 'Útgáfa',
      AppBuildNumber: '#',
      Like: 'Eins og',
      Love: 'Ást',
      Laugh: 'Haha',
      Surprise: 'Koma á óvart',
      Sad: 'Sorglegt',
      Angry: 'Reiður',
      NoBooksInList: 'Engar bækur hér.',
      Update: 'Uppfæra',
      Overview: 'Yfirlit',
      Content: 'Efni',
      Readers: 'Lesendur',
      NotificationSettings: 'Tilkynningarstillingum',
      SendEmailOnComments:
        'Sendu tölvupóst þegar lesendur skilja eftir athugasemdir',
      SendEmailOnSurveyComplete:
        'Sendu tölvupóst þegar lesendur hafa lokið könnun',
      CoverUploadMsg:
        'Smelltu til að hlaða upp forsíðumyndinni þinni (png eða jpg, 980x1568px), eða slepptu henni á þetta svæði.',
      DefaultNamePlaceholder: 'Sjálfgefið: Varðandi {{displayName}}',
      SelectUpTo5Genres: 'Veldu allt að 5 tegundir',
      AdvancedSettings: 'Ítarlegar stillingar',
      EnableComments: 'Virkja athugasemdir',
      EnableBookReviews: 'Virkja bókagagnrýni',
      DeleteBook: 'Eyða bókina',
      DeleteCover: 'Eyða bók kápa',
      DeleteCoverConfirmation: 'Ertu viss um að þú viljir eyða bókarkápunni?',
      YesBinIt: 'Já, eyða því!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Ertu viss um að þú viljir eyða <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} hefur verið eytt.',
      No: 'Nei',
      Yes: 'Já',
      UserCommentedOn: 'gerði athugasemd við',
      ShowXReplies: 'Sýna {{count}} svar',
      ShowXReplies_plural: 'Sýna {{count}} svör',
      HideXReplies: 'Fela {{count}} svar',
      HideXReplies_plural: 'Fela {{count}} svör',
      NoInLineComments: 'Engar innbyggðar athugasemdir.',
      Chapter: 'kafli',
      NoCommentsToShow: 'Engar athugasemdir til að sýna.',
      Save: 'Vista',
      TooLongTitle: 'Titillinn þinn er of langur (hámark 60 stafir)',
      ClickToUploadDoc:
        'Smelltu til að bæta við eða sleppa docx skránni þinni til að flytja hana inn',
      LongChapterWarning: 'Langur kafli, er þetta rétt?',
      Upload: 'Hlaða upp',
      UploadChapters: 'Sækja kafla',
      ImportBooksHelpMsg:
        'Enginn mun geta séð handritið þitt án samþykkis þíns. Lestu um <1> flytja inn bækur </1>',
      Add: 'Bæta við',
      NewChapter: 'Nýr kafli',
      NewSurvey: 'Nýtt könnun',
      SelectedPart: 'valinn hluti',
      SelectPart: 'Veldu hluta',
      NotifyMe: 'Látið mig vita',
      WhenReadersStart: 'í byrjun',
      WhenReadersComplete: 'á enda',
      ManageChapter: 'Stjórna kafla',
      ManageSurvey: 'Stjórna könnun',
      ManageReaders: 'Stjórna lesendum',
      MoveUp: 'Færðu þig upp',
      MoveDown: 'Færa niður',
      SaveSurvey: 'Vista könnun',
      Saved: 'Vistuð',
      EnterSurveyName: 'Gefðu könnuninni nafn',
      EnterSurveyDescription: 'Lýstu könnuninni þinni',
      SelectAtLeast: 'Veldu að minnsta kosti',
      SelectAtMost: 'Veldu í mesta lagi',
      LabelOptional: 'Merki (valfrjálst)',
      MyBooks: 'Bækurnar mínar',
      ChangeRoleToWriterToCreateBooks:
        'Vinsamlegast breyttu hlutverki í rithöfund til að búa til bækur.',
      UsingXOutOfYBooks: 'Þú ert að nota {{x}} frá {{y}} bókum.',
      UsingXOutOfYReaders: 'Þú ert að nota {{x}} frá {{y}} lesendum.',
      NothingHere: 'Ekkert hérna í augnablikinu.',
      OpenReaderWeb: 'Opnaðu lesandann',
      PrivacyLevel: 'Staða hlutdeild',
      PublishingStatus: 'Staða hlutdeild',
      PrivacyLevel_infotext_unpublished:
        '<0> Bókin þín er ótengd. Færðu það á <1> lokað </1>, <3> lokað </3> eða <5> skráð </5> til að lesendur geti fundið það. </0>',
      PrivacyLevel_infotext_private:
        '<0> Bókin þín er skráð ekki hvar. Aðeins þú getur boðið lesendum. </0>',
      PrivacyLevel_infotext_closed:
        '<0> Kynning bókarinnar þinnar má með því að einhver með <1> tengill </1>. Lesendur geta óskað boð, en þú þarft að samþykkja allar beiðnir. </0>',
      PrivacyLevel_infotext_listed:
        '<0> Bókin þín er skráð <1>hér</1>. Lesendur geta óskað boð, en þú þarft að samþykkja allar beiðnir.</0>',
      PrivacyLevel_state_unpublished: 'Offline',
      PrivacyLevel_state_private: 'Einkamál',
      PrivacyLevel_state_closed: 'Lokað',
      PrivacyLevel_state_listed: 'Skráð',
      SendReminder: 'Sendu áminningu',
      YouCanSendAReminderEvery3Days: 'Þú getur sent áminningu þriðja hvern dag',
      LastSeenAt: 'Síðast séð í',
      ReminderSentAt: 'Áminning send',
      XReadersInactiveForYDays:
        '{{count}} lesandinn hafi ekki búin með bókina og hefur ekki verið virkir síðustu {{days}} daga.',
      XReadersInactiveForYDays_plural:
        '{{count}} lesendur hafa ekki búin með bókina og hafa ekki verið virkir síðustu {{days}} daga.',
      InactiveReaders: 'Óvirk lesendur',
      XReadersNotStartedForYDays:
        '{{count}} lesandi hefur ekki samþykkt fleiri en {{days}} daga gömul boð þitt',
      XReadersNotStartedForYDays_plural:
        '{{count}} lesendur hafa ekki tekið meira en {{days}} daga gömul boð þitt',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Lesendur sem ekki hafa þegið boðið',
      YouHaveXJoinRequests: 'Þú ert {{count}} beiðni til að lesa bókina þína',
      YouHaveXJoinRequests_plural:
        'Þú ert með {{count}} beiðnir til að lesa bókina þína',
      JoinRequests: 'Lesa beiðnir',
      Approve: 'Samþykkja',
      RequestedAt: 'Óskað eftir',
      NoAnswer: 'Ekkert svar',
      SendSurveyReminder: 'Sendu áminningu um könnun',
      Remove: 'Fjarlægja',
      Created: 'Búið',
      RequestedToRead: 'Beðinn um að lesa',
      InvitationSent: 'Boð sent',
      Reminded: 'Minnti',
      Question: 'Spurning',
      YouCanAskUpToXQuestions:
        'Þú getur bætt við allt að {{maxQuestions}} spurningum sem lesendur eru nauðsynlegar til að svara þegar þú skráir þig.',
      NewQuestion: 'Nýtt spurning',
      ReaderSignUpForm: 'Skráningareyðublað',
      NoReadersFound: 'Engir lesendur fundust',
      ReachedPartTitle: 'Náð {{partTitle}}',
      ReasonToQuit: 'Ástæða',
      YourBookDoesNotHaveAnyChaptersYet: 'Bókin þín hefur enga kafla ennþá',
      Invited: 'Boðið',
      Started: 'Byrjaði',
      Finished: 'Lokið',
      Abandoned: 'Yfirgefin',
      AvgOverallRating: 'Á heildina litið',
      AvgPlotRating: 'Intrigue',
      AvgGrammarRating: 'Málfræði',
      YouDoNotHaveReviewsYet: 'Þú hefur engar umsagnir ennþá.',
      PersonalLink: 'Personal tengilinn',
      HelpUsReachMoreWriters:
        'Finnst þér gaman að nota BetaReader.io? Notaðu persónulega hlekkinn þinn til að hjálpa okkur að hjálpa fleiri höfundum!',
      Reports: 'Skýrslur',
      WeNeedYourEmail: 'Tölvupóstur þarf',
      MissingEmailMsg:
        'Við þurfum að geta haft samband við þig varðandi reikninginn þinn. Við munum ekki deila því með neinum og hafa aðeins samband við þig ef stranglega er krafist.',
      EmailNotVerifiedPrompt:
        'Sendu ekki staðfest. <1> Smelltu hér til að senda </1> staðfestingarpóst. (Athuga spam mappa ef þú færð hana ekki)',
      Skip: 'Sleppa',
      RoleWriter: 'Rithöfundur',
      RoleReader: 'Lesandi',
      RoleBoth: 'Bæði',
      AddReader: 'Bættu við nýjum lesanda',
      DownloadReaderList: 'Sækja lista yfir lesendur',
      'Show only': 'Sýna aðeins',
      StatusNEW: 'Nýtt',
      StatusTODO: 'Að gera',
      StatusDOING: 'Að gera',
      StatusDONE: 'Búið',
      StatusIGNORE: 'Hunsa',
      RemoveFilters: 'Fjarlægja síur',
      ReadingDataReport: 'Að lesa gögn',
      SurveyAnswersReport: 'Könnunarsvör',
      Reviews: 'Umsagnir',
      AbandonedReadersReport: 'Týndir lesendur',
      SignUpMsg:
        '<0>Takk fyrir að skrá þig!</0> <1>Staðfestingarpóstur hefur verið sendur til <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Afritaði {{x}} á klippiborðið.',
      DoYouWantToBeMyBetaReader: 'Viltu vera beta lesandi minn?',
      LookingForBetaReadersForBookTitle:
        'Ég er að leita að beta lesendur til {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Ég er að leita að beta lesendur til {{bookTitle}}. Fylgdu þessari slóð til að lesa meira og skrá sig: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Samfélagsmiðlun er ekki möguleg fyrir einkabækur.',
      ShareTheLinkToYourBook: 'Deildu tenglinum á bókina þína!',
      Messages: 'Skilaboð',
      FailedToLoadMessage: 'Ekki tókst að hlaða þessum skilaboðum.',
      FailedToSendMessage: 'Okkur tókst ekki að senda skilaboðin þín.',
      FailedToStartConversation: 'Okkur tókst ekki að hefja samtalið.',
      NewMessage: 'Ný skilaboð',
      MessageSubject: 'Efni',
      MessageBody: 'Skrifaðu skilaboðin þín...',
      MessageFrom: 'Frá',
      MessageTo: 'Til',
      SendMessage: 'Senda',
      Message: 'Skilaboð',
      ReaderMarketplaceBetaInfoText:
        'Við erum að gera tilraunir með að láta höfunda okkar borga venjulegum lesendum og bókaormum táknræna upphæð fyrir þá þjónustu að lesa handrit. Við bjóðum upp á stýrikerfi til að sannreyna endurgjöfina og stjórna því að lesendur hafi lesið handritið í heild sinni áður en einhver fær greitt. Ef þú vilt prófa þennan eiginleika skaltu vinsamlega tilgreina hversu marga lesendur þú þarft.',
      ReaderMarketplaceBetaFullText:
        'Þetta prógramm er nú fullt. Viltu að við látum þig vita þegar við fáum opnun?',
      Sum: 'Summa',
      XUSDPerReader: '${{x}} / lesari',
      AmountOfReaders: 'Fjöldi lesenda',
      PayForTestReaders: 'Borgaðu fyrir próflesendur',
      GoToCheckout: 'Farðu í kassa',
      YesPlease: 'Já takk!',
      BETA: 'BETA',
      NoMessages: 'Þú átt engin skilaboð',
      OnBoarding_import_book:
        '<0> Velkomin BetaReader.io! </0> Til að deila bókina þína, þú þarft fyrst að flytja það, annaðhvort með <2> hlaða hana </2>, eða með því að búa það beint á vettvang. Fara á undan og reyna það út! Allt um BetaReader.io er persónulegur sjálfgefið, svo enginn mun sjá það nema þú býður þeim.',
      Onboarding_book_overview:
        'Bókayfirlitið er þar sem þú stillir upplýsingar um bókina þína. Titill, tegundir og viðmiðunarreglur fara hér, en einnig hvort þú viljir að lesendur geti skrifað athugasemdir og skilið eftir umsagnir.',
      Onboarding_book_content:
        'Efnisflipi er þar sem þú stjórnar röð hlutanna. Bættu við köflum þínum og könnunum hér.',
      Onboarding_book_readers:
        'Lesendur flipinn er þar sem þú stjórnar því hver fær að lesa bókina þína. Sendu einkaboð, deildu skráningartenglinum þínum í gegnum Twitter eða bættu bókinni þinni við uppgötvunarlistann okkar til að ná til fleiri lesenda.',
      Onboarding_book_feedback:
        'Feedback flipinn gefur þér yfirlit yfir öll endurgjöf sem lesendur hafa skilið eftir. Svaraðu athugasemdum og síaðu burt óverulegt efni.',
      Onboarding_book_reports:
        'Skýrsla flipinn gefur þér yfirsýn yfir hvernig bókin þín gengur. Hversu mörgum lesendum hefur verið boðið, hversu margir eru farnir að lesa, skilið eftir góða dóma og svo framvegis.',
      Close: 'Loka',
      Last: 'Síðast',
      DuplicatedBook: '<0> <0> </0> Afritað bók {{bookTitle}} </0>',
      PenName: 'Penni nafn',
      MissingNameMsg:
        'Þú þarft að slá inn nafn til að geta átt samskipti við lesendur þína.',
      NameConversation: 'Nefnið þetta samtal',
      With: 'með...',
      SelectPeople: 'Veldu Fólk',
      UploadAsNewVersion: '...sem ný útgáfa',
      UploadAsExtraChapters: '... við þessa útgáfu',
      Cancel: 'Hætta við',
      Unnamed: 'ónefndur',
      NewVersion: 'Ný útgáfa',
      NoContacts: 'Engir tengiliðir',
      GetContacts:
        'Höfundar bókanna sem þú lest og lesendur bókanna sem þú skrifar verða tengiliðir þínir.',
      NoConversations: 'Engin samtöl...',
      AllVersionsNeedContent:
        'Núverandi útgáfa þín þarf efni áður en þú getur búið til nýja.',
      InviteToVersion: 'Bjóða til útgáfu',
      latestVersion: 'nýjasta',
      VersionName: 'Útgáfa {{x}}',
      Reader: 'Lesandi',
      StartedReading: 'Byrjaði að lesa',
      CurrentPosition: 'Núverandi staða',
      PickVersion: 'Veldu útgáfu',
      Filters: 'Síur',
      Versions: 'útgáfur',
      ChapterInitial: 'k',
      AvgTimeToComplete: 'Meðaltími til að lesa kafla',
      ReaderProgress: 'Stöður',
      SelectFilter: 'Veldu síu',
      CreatePost: 'Deildu uppfærslu',
      NewBookPostPlaceholder: 'Hvað viltu segja?',
      BookUpdateForX: 'Uppfærsla fyrir bók {{x}}',
      OnSaveChapterMessage: 'Láttu lesendur vita hverju þú breyttir',
      SampleChapterUpdatePost: 'ég breytti þessu...',
      SaveWithoutPosting: 'Vista án þess að birta...',
      SaveAndPost: 'Vista og birta...',
      DiscoverSearch: 'Leitaðu að titli eða tegund...',
      LoadMore: 'Hlaða meira...',
      PendingReadInvitations: 'Boð í bið',
      PendingReadRequests: 'Beiðnir í bið',
      UserReactedOn: 'brugðist við',
      ChapterComment: 'kafla athugasemd',
      InlineCommentNotFound:
        'Þessi athugasemd fannst ekki í textanum. Er búið að fjarlægja ummælatextann?',
      Or: 'Eða',
      Version: 'Útgáfa',
      Updates: 'Uppfærslur',
      CurrentBetaIsFullMessage:
        'Þessi beta er full eins og er, vinsamlegast hafðu samband við höfundinn til að opna fleiri spilakassa.',
      Edit: 'Breyta',
      Delete: 'Eyða',
      DropFilesHere: 'Slepptu skránum þínum hér',
      ShowOnly: 'Sýna aðeins',
      FeedbackSearch: 'Textaleit',
      'BuySubscriptionToS&PPMessage':
        '<0>Með því að kaupa áskrift frá BetaReader.io gefur þú til kynna að þú hafir lesið og samþykkt<1> þjónustuskilmála</1> okkar og <5>persónuverndarstefnu</5></0>',
      Inbox: 'Innhólf',
      CofirmMovePart:
        'Viltu virkilega færa þennan hluta? Það mun hafa áhrif á eftirfarandi lesendur:',
      'AreYouSure?': 'Ertu viss?',
      LetYourReadersKnowWhatYouChanged:
        'Láttu lesendur vita hverju þú breyttir',
      ThisChangeAffectsTheFollowingReaders:
        'Þessi breyting hefur áhrif á eftirfarandi lesendur:',
      MovedXFromYtoZ: 'Fært {{x}} frá {{y}} til {{z}}',
      Subscription: 'Áskrift',
      Subscriptions: 'Áskriftir',
      FreeSubscriptionTitle: 'Free',
      FreeSubscriptionDescription: 'Grunnplan, að eilífu ókeypis.',
      StandardSubscriptionTitle: 'Standard',
      StandardSubscriptionDescription: 'Gott fyrir fullkomnunaráráttu áhugamál',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Fínt fyrir faglegar rithöfundar',
      ProSubscriptionTitle: 'Pro',
      ProSubscriptionDescription: 'Fullkomið fyrir útgefendur',
      CancelSubscription: 'Hætta við áskrift',
      YourSubscriptionHasBeenCancelled: 'Áskrift þín hefur verið lokað',
      CancelSubscriptionConfirmation:
        'Ertu viss um að þú viljir hætta við áskriftina þína?',
      ValidUntil: 'Gildir til',
      UpgradeSubscriptionTitle: 'Uppfærsla',
      UpgradeSubscriptionPromoText: 'Uppfærðu reikninginn þinn',
      Abort: 'Afnema',
      BuyNow: 'Kaupa',
      Current: 'Núverandi áætlun',
      Monthly: 'Mánaðarlega',
      Yearly: 'Árlega',
      monthly: 'mánaðarlega',
      yearly: 'ári',
      month: 'mánuði',
      XCheaper: '{{x}} ódýrara!',
      Downgrade: 'Lækkaðu',
      Forever: 'að eilífu',
      ChangePlan: 'Breyta áætlun',
      TrackReaderProgress: 'Fylgjast með lesendum þínum í rauntíma',
      InlineComments: 'Athugasemdir og viðbrögð í línu',
      ReaderSurveys: 'Lesandi spurningalistar',
      NativeApps: 'Android og IOS app',
      MultipleVersions: 'Handrit útgáfa',
      '1ActiveManuscript': '1 handrit',
      '5ActiveManuscripts': 'Allt að 5 handrit',
      '20ActiveManuscripts': 'Allt að 20 handrit',
      UnlimitedActiveManuscripts: 'Ótakmarkað handrit',
      '3ActiveReadersPerManuscript': 'Allt að 3 lesendur',
      '20ActiveReadersPerManuscript': 'Allt að 20 lesendur',
      '50ActiveReadersPerManuscript': 'Allt að 50 lesendur',
      UnlimitedActiveReaders: 'Ótakmarkaður fjöldi lesenda',
      Zero: 'Ekkert',
      NoCreditCardNeeded: 'Ekkert kreditkort þarf',
      Recommended: 'Mælt með',
      SomethingWentWrong: 'Eitthvað gekk skakkur',
      SubscriptionChangeError: 'Mistókst að breyta áskrift',
      SubscriptionChangeSuccess: 'Við uppfærðum áskriftina þína',
      ActionCancelled: 'Aðgerðin var hætt',
      Cancelled: 'Hætt við',
      NextPayment: 'Næsta greiðsla',
      PaymentPastDueMessage:
        'Greiðsla er í vanskilum. Next tilraun: {{nextAttempt}}',
      ChangePaymentInformation: 'Breyta innheimtuupplýsingum',
      DashboardWelcomeMessage:
        '<0> Velkomin BetaReader.io! </ 0> <1> Byrja með <2> upphleðslu </ 2> eða <6> bæta </ ​​6> handritið þitt </ 1> <2> Discovery </ 2> < 3> Ekki gleyma að skrá handritið í okkar <2> uppgötvun </ 2> lið, til að fá aðgang meiri beta lesendur. </ 3> <4> <0> Hér </ 0> er a fljótur walkthrough um hvernig á að gera það. </ 4> <5> Stuðningur </ 5> <6> Vinsamlegast skoðaðu <2> hjálp </ 2> vefgátt eða ping okkur beint með því að ýta á (?) efst til hægri á síðunni. < / 6> <7> Þú getur alltaf sent okkur vingjarnlegur póst á <2> hello@betareader.io </ 2> </ 7>',
      Manuscripts: 'Handrit',
      CurrentlyReading: 'Er að lesa núna',
      WeeklyReaders: 'Vikulegir lesendur',
      TotalReaders: 'Samtals lesendur',
      FailedToDownloadReport: 'Mistókst að hlaða niður skýrslu',
      WaitingForConfirmation: 'Bíð eftir staðfestingu',
      ChangePublishingStatusToAddReaders:
        'Breyttu útgáfustöðu til að bæta við lesendum',
      SuggestASwap: 'Bjóða upp á skipti',
      WithdrawRequest: 'Afturkalla beiðni',
      Confirm: 'Staðfesta',
      ThisIsASwapRequestForX:
        'Þetta er skiptibeiðni um <2>{{x}}</2>. Ef þú samþykkir skiptabeiðnina verður þú bætt við sem lesandi á {{x}}.',
      ApprovedSwapRequestForX: 'Þetta er skiptibeiðni um <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Þetta er skiptabeiðni. Ef þú samþykkir þessa beiðni verður þér bætt við sem lesanda fyrir:',
      SwapRequest: 'Swap beiðni',
      ApproveAndJoin: 'Samþykkja og taka þátt',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} hefur boðið að lesa {{y}} í skiptum fyrir að lesa þessa bók.',
      GoToSwapRequest: 'Farðu í skiptibeiðni',
      SwitchVersion: 'Skiptu útgáfu',
      SendMagicLinkToX: 'Sendu töfra tengil á {{x}}',
      notification_verb_comment: 'skrifaði ummæli við',
      notification_verb_react: 'brugðist við',
      notification_verb_reply: 'svaraði athugasemd við',
      notification_verb_abandon: 'yfirgefin bókina þína',
      notification_verb_request_to_join: 'hefur beðið um að lesa',
      notification_verb_message: 'sendi þér skilaboð',
      notification_verb_sent_join_approval:
        'hefur samþykkt beiðni þína um að lesa',
      notification_verb_invitation_to_join: 'bauð þér að lesa {{y}}',
      notification_verb_manuscript_submitted:
        'hefur sent inn handrit til {{y}}',
      'SaveAnd...': 'Vista og...',
      Send: 'Senda',
      OnlySave: 'Vistaðu aðeins',
      YourNotifications: 'Tilkynningar þínar',
      Notifications: 'Tilkynningar',
      Images: 'Myndir',
      '365DaysReadingData': '365 daga lesandi saga',
      '90DaysReadingData': '90 daga lesandi saga',
      '30DaysReadingData': '30 daga lesandi saga',
      alerts_PaymentPastDue:
        'Greiðsla þín er á gjalddaga. Uppfærðu innheimtuupplýsingar þínar',
      alerts_MissingName:
        'Okkur vantar nafnið þitt (eða pennanafnið). Uppfærðu það hér:',
      alerts_TimeLeftOnLaunchOffer:
        '{{x}} eftir til að krefjast líftíma afslátt þinnar. Sláðu inn LAUNCH í afsláttarmiða þegar þú ert að uppfæra:',
      RequestedToSwapForX: 'Þú hefur beðið um að skipta þetta einn fyrir {{x}}',
      PaymentSuccessMessage: '<0><0>Takk fyrir kaupin!</0><1><0></0></1></0>',
      Dashboard: 'Heim',
      year: 'ári',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Hversu líklegt er að þú mælir með BetaReader.io við vin?',
      NotAtAllLikely: 'Ekki líklegt',
      ExtremelyLikely: 'Mjög líklegt',
      ThanksDoYouWantToTellUsWhy: 'Takk! Viltu segja okkur hvers vegna?',
      Create: 'Búa til',
      Books: 'Bækur',
      Feed: 'Flow',
      ANewStory: 'ný saga',
      FailedToGetInvitations: 'Mistókst að sækja boð',
      unauthorizedPrivateBook:
        'Þessi bók er einkamál og ekki tiltæk á þessum reikningi.',
      invalidToken: 'Boð er ekki gilt fyrir þennan reikning eða er útrunnið.',
      RaiseYourReaderLimit: 'Hækkaðu lesendamörkin þín (nú {{x}})',
      InviteYourFriends: 'Bjóddu vinum þínum',
      referralText:
        '<0> Fáðu allt að tíu auka lesarauf með því að bjóða vinum þínum á BetaReader.io! </0> <1> Fyrir alla vini sem stofna fullgiltan höfundareikning og skráir handrit þökk fyrir þig munum við gefa þér og vini þínum 1 lesarauf hver, að hámarki 10 raufar. </1> <2> Þú getur einnig hækkað lesendamörkin með því að lesa og skoða 100 þúsund orð í gegnum <1> <0> lesaraforritið okkar </0> </1>, eða með því að <3><0> uppfæra</0> </3> reikninginn þinn! </2>',
      INVITED: 'Boðið',
      CREATED_ACCOUNT: 'Búið reikning',
      CONFIRMED: 'Staðfest',
      RegistrationDate: 'Skráningardagur',
      Profile: 'Prófíll',
      EditProfile: 'Breyta prófíl',
      GetMoreReaders: 'Fáðu fleiri lesendur',
      Status: 'Staða',
      maxReadersLimitMessage:
        '<0>Þú hefur náð lesandanum þínum. <1>Uppfærsla</1> Til að senda fleiri boð í lesendavísitölu okkar eða samþykkja lesa beiðnir. Í ókeypis áætluninni okkar er hægt að stinga upp á <3>skipti</3> með öðrum rithöfundum eða bjóða eins mörgum lesendum sem þú vilt beint í gegnum netfangið sitt. </0>',
      SwapsDontAffectReaderLimit:
        'Handritaskipti teljast ekki með lesendamörkum þínum.',
      maxBooksLimitMessage:
        '<0> Þú hefur náð handritamarkmiðum þínum. <1> Hækka takmörk </1> til að geta prófað nokkur handrit samhliða. </0>',
      Type: 'Tegund',
      'referral-type_REFERRAL': 'REFERRAL',
      'referral-type_SIGNUP_BONUS': 'SIGNUP BONUS',
      'referral-type_READ_AND_REVIEW': 'READ & REVIEW',
      unlockBonusText:
        '<0> Opnaðu innskráningarbónusinn þinn! </ 0> <1> Það lítur út fyrir að þú varst vísað af vini - fylgdu eftirfarandi skrefum til að opna gagnkvæma innskráningarbónusinn þinn! </ 1> <2> <0> <0 > </ 0> <1> <0> Staðfestu reikninginn </ 0> <1> Þú þarft gilt nafn og netfang </ 1> </ 1> </ 0> <1> <0> </ 0> <1> <0> Listi handrit </ 0> <1> Skráðu handritið sem þú vilt prófa </ 1> </ 1> </ 1> <2> <0> </ 0> <1> <0> Bíddu </ 0> <1> Það tekur um 3 daga fyrir okkur að staðfesta </ 1> </ 1> </ 2> </ 2>',
      titleDiscoveryRecommended: 'Mælt með fyrir þig',
      subheaderDiscoveryRecommended:
        'Eitthvað nýtt byggt á völdum tegundum þínum',
      titleDiscoveryRecentlyAdded: 'Nýlega bætt við',
      subheaderDiscoveryRecentlyAdded: 'Nýlega bætt við titlum',
      discoveryRecentlyAddedTitlesInGenre:
        'Nýlega bætt við titlum í tegundinni {{x}}',
      titleDiscoveryPremium: 'Valdir titlar',
      subheaderDiscoveryPremium: 'Titlar eftir höfunda úrvals',
      referralMessage:
        'Ég býð þér BetaReader.io! Ef þú skráir þig með þennan tengil fáum við bæði bónus!',
      referralBody: 'Skráðu þig hér: {{x}}',
      ThisCannotBeUndone: 'Þetta er ekki hægt að afturkalla',
      DeletedVersion: 'Útgáfan var eytt',
      InvalidEmail: 'Ógilt netfang',
      EmailNotVerified:
        'Ekki staðfest, vinsamlegast athugaðu tölvupóstinn þinn',
      SelectFavoriteGenres: 'Veldu uppáhalds tegundirnar þínar',
      Loading: 'Hleðsla',
      ChapterIdentification: 'Kenni auðkenning',
      ChapterAnalysis: 'Kafli greining',
      CompleteImport: 'Ljúktu innflutningi',
      Import: 'Innflutningur',
      Processing: 'Vinnsla skrá...',
      ThisMightTakeAWhile: 'Þetta gæti tekið smá stund',
      LikeThisCover: 'Líkar við þessa bókarkápu?',
      DownloadFailedMsg:
        'Niðurhalið mistókst. Var það lokað af sprettigluggavörn?',
      ShareAuthorAppEmailSuccessMessage:
        'Boð sent. Athugaðu stöðu <1> hér </1>.',
      failedToUpdateReader: 'Mistókst að uppfæra lesanda',
      activeReaders: 'Virkir lesendur',
      ReadingList: 'Leslisti',
      Users: 'Notendur',
      InviteUser: 'Bjóddu notanda',
      Role: 'Hlutverk',
      member_role_admin: 'Atjórnandi',
      member_role_member: 'Meðlimur',
      couponsOrDiscountsWillNotBeKept:
        '<0>Núverandi afslættir munu ekki fylgja nýju áætluninni.</0>',
      FailedToFetchX: 'Mistókst að fá {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Þú hefur verið boðið að taka þátt í {{x}} BetaReader.io reikningnum.',
      AcceptAccountInvitationMsg:
        'Ef þú samþykkir boðið verður þú að geta séð og breytt öllum handritum á þessum reikningi.',
      InvitedMembersMsg:
        'Reikningsaðilar geta séð og breytt öllum handritum á reikningnum þínum, en þeir sjá engar greiðsluupplýsingar. Sjá betareader.io/pricing fyrir upplýsingar um kostnað á hvern meðlim í reikningi.',
      InviteCollaborator: 'Bjóddu samstarfsaðila',
      InviteCollaboratorMsg:
        'Samstarfsaðilar geta skoðað handritið og lesandann upplýsingar og svarað endurgjöf.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Þú hefur verið boðið að vinna með {{book}} af {{author}}',
      AcceptCollaborationInvitationMsg:
        'Ef þú samþykkir boðið verður þú að geta skoðað handritið og upplýsingar um lesandann og svarað endurgjöf.',
      Collaborators: 'Samstarfsmenn',
      ThisIsABetaFeature:
        'Þetta er beta lögun. Láttu okkur vita ef þú finnur fyrir einhverjum vandamálum við það eða ef þú hefur einhverjar athugasemdir til að deila.',
      UserNotFoundMsg:
        'Það lítur út fyrir að þú hafir ekki reikning. Búðu til einn <1> hér </ 1>!',
      AuthorName: 'Nafn höfundar',
      Details: 'Upplýsingar',
      Settings: 'Stillingar',
      ReviewAtLeastXwords: '{{x}} orð endurskoðuð',
      CheckBonus: 'Athugaðu bónusinn þinn',
      FailedToAddCommentOrReaction:
        'Ekki tókst að bæta við athugasemd eða viðbrögðum',
      ThisIsAWIP: 'Þetta er WIP',
      WIPExplanation:
        'Flaggaðu söguna þína sem WIP (Work In Progress) þegar henni er ekki lokið, t.d. þegar þú hefur ekki bætt við öllum köflum.',
      MatureContent: '18+',
      PublicationDate: 'Birting',
      TimePlan: 'Tímaáætlun',
      NotSpecified: 'Ekki tilgreint',
      WhatIsThisStoryAbout: 'Um hvað fjallar þessi saga?',
      WhatDoYouNeedHelpWith: 'Hvað vantar þig aðstoð við?',
      StartWriting: 'Byrja að skrifa',
      CreateManuscript: 'Búðu til handrit',
      ImportManuscript: 'Flytja inn handrit',
      ImportManuscriptStepDescription: 'Hladdu upp eða búðu til handritið þitt',
      InviteReaders: 'Bjóddu lesendum þínum',
      InviteReadersStepDescription: 'Þú ræður hver má lesa',
      CollectFeedback: 'Safnaðu endurgjöf',
      CollectFeedbackStepDescription: 'Greindu athugasemdir og lestramynstur',
      YouDontHaveAnyManuscripts: 'Þú ert ekki enn með nein handrit',
      AuthorsAndPublishers: 'Höfundar og útgefendur',
      FindOutWhatYourReadersThink:
        'Finndu út hvað lesendum þínum finnst raunverulega um bók þína',
      ReadAndInfluenceTheNextBestseller:
        'Lestu og hafðu áhrif á komandi metsölulista',
      PrivateSharing: 'Einkaskipting',
      PrivateSharingDescription: 'Deildu handritinu þínu einslega með lesendum',
      CollectUnbiasedFeedback: 'Safnaðu óhlutdrægri endurgjöf',
      CollectUnbiasedFeedbackDescription:
        'Leyfðu lesendum þínum að tjá sig án þess að sjá viðbrögð hvers annars',
      AnalyzeReadingPatterns: 'Greindu lesmynstur',
      AnalyzeReadingPatternsDescription:
        'Finndu út hvar lesendur þínir missa áhugann eða festu þig í sögunni',
      ReadItFirst: 'Lestu það fyrst',
      ReadItFirstDescription:
        'Lestu komandi skáldsögur meðan þær eru enn í beta',
      InfluenceTheStory: 'Áhrif sögunnar',
      InfluenceTheStoryDescription:
        'Svar þitt mun hafa áhrif á endanlega niðurstöðu',
      ConnectWithAuthors: 'Ræddu við höfunda',
      ConnectWithAuthorsDescription:
        'Hjálpaðu þínum uppáhalds höfundum með því að gerast hluti af beta-teyminu sínu',
      YourBooks: 'bækurnar þínar',
      New: 'nýtt',
      Members: 'Meðlimir',
      Contacts: 'Tengiliðir',
      AddMembersFromContacts: 'Bæta við meðlimum úr tengiliðalistanum',
      ConversationSettings: 'Stillingar samtals',
      PremiumAuthorInfo:
        'Þetta er handrit eftir aukahöfund. Þetta þýðir venjulega að höfundurinn er meira fjárfest í verkefninu og að þú getur búist við því að þeir geri það sem þarf til að þessi bók verði gefin út.',
      StillWorkingOnThis:
        'Við erum enn að byggja út BetaReader.io. Láttu okkur vita ef það er eitthvað sérstakur sem þú vilt sjá hér.',
      Engagement: 'Trúlofun',
      AssemblingLetters: 'Samsetningu bréf ...',
      ConstructingAlphabet: 'Byggingu stafrófið ...',
      BuildingSentences: 'Building setningar ...',
      BecomingSentient: 'Verða sentient ...',
      BooksRead: 'Books lesa: {{x}}',
      WordsReviewed: 'Words uppfært: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Meðalafgreiðslutími sinni á 1000 orð: {{count}} minn',
      AvgTurnAroundTimePer1kWords_plural:
        'Meðalafgreiðslutími sinni á 1000 orð: {{count}} daga',
      ViewAsReader: 'Skoða sem lesandi',
      EditMode: 'Breyta ham',
      BookCover: 'Bókakápa',
      WelcomeToBRReadathon: 'Velkomin í BetaReader.io Readathon!',
      ReadathonHasEnded: 'Readathon hefur nú lokið.',
      ReadathonInfo:
        'The BetaReader.io Readathon stendur milli 26. september og 31. október, 2019. Sá sem les og hefur farið að minnsta kosti eina bók í gegnum BetaReader.io á þessum tíma þátt og þú gætir aukið möguleika þína á að vinna með því að lesa og skoða fleiri bækur. Sigurvegarinn verður vakin á 1. nóvember, og fleiri orð sem þú hefur lesið og endurskoðuð af þá eru meiri líkur þínar á að vinna. Til dæmis, einhver sem hefur lesið og endurskoðuð 50.000 orð verða 5x hefur mikla möguleika á að vinna eins og einhver sem hefur lesið og skoðaðar 10.000 orð.',
      ReadathonPrize1: '1. verðlaun: $ 50 Amazon Gift Card.',
      ReadathonPrize23: '2 - 3 verðlaun: 20 $ Amazon Gift Card.',
      SignInToJoin: 'Skráðu þig inn til að taka þátt',
      WordsReviewedByYou: 'Orð hlotið af þér',
      WordsReviewedByEveryone: 'Orð skoðaðar af öllum',
      ChanceOfWinning: 'Núverandi tækifæri á að vinna',
      Completed: 'lokið',
      CompletedAt: 'lokið',
      Pending: 'Bíður',
      Position: 'staða',
      FindSomethingToRead: 'Finna eitthvað til að lesa',
      DontKnow: 'Veit ekki',
      IsThisReadyToPublish:
        'Telur þú að þessi bók er tilbúin til að vera birt?',
      UpdateProfileHeader: 'Við þurfum meiri upplýsingar um prófílinn þinn',
      WhatsYourName: 'Hvað er nafn þitt eða dulnefni?',
      WhereAreYouFrom: 'Hvaðan ertu?',
      WhenWereYouBorn: 'Hvaða ár ert þú fædd?',
      WhichGenresDoYouPrefer: 'Hvaða tegund gera þú vilja?',
      WhichLanguagesDoYouRead: 'Hvaða tungumál ertu að lesa?',
      BioPlaceholder:
        'Segðu okkur hver þú ert og ef þú hefur einhverjar vefsíður til að vísa til. Sterkari líffræðingur mun hjálpa þér við að fá fleiri handrit.',
      Thanks: 'Takk!',
      WhenDoYouWantToHearFromUs: 'Hvenær vilt þú heyra í okkur?',
      ReaderListConsentHeader: 'Viltu vera með í lesendagagnagrunninum okkar?',
      ReaderListConsentInfo:
        'Höfundar með verk sem passa við óskir þínar munu geta sent þér hlekkinn á bækurnar sínar, en þú ert ekki skyldug til að lesa ef þú vilt ekki eða hefur ekki tíma.',
      RoleHeader: 'Ertu rithöfundur eða lesandi?',
      RoleInfo: 'Við notum þessar upplýsingar til að hámarka upplifun þína.',
      OnYourProfile: 'Þetta mun birtast á notandaprófílnum þínum.',
      ProfileBuilderThankYouMessage:
        '<0>Takk!</0><1>Þú getur alltaf uppfært prófíl þínum <1>hér</1>.</1>',
      DoThisLater: 'Gerðu þetta seinna',
      '2MonthsFree': '2 mánuðir ókeypis',
      Joined: 'Skráði sig',
      UserInfo: 'Notendaupplýsingar',
      Age: 'Aldur',
      Bio: 'Bio',
      ReaderList: 'Finndu lesendur',
      Popular: 'Vinsælt',
      BookHas0Words: '0 orð',
      BookHas0WordsDescription:
        '<0> Þessi bók hefur að minnsta kosti einn útgáfu með 0 orðum. Þú getur bætt við eða hlaða kaflar </0> <1> hér </1>.',
      LeaveBeta: 'Skildu beta',
      DiscoverNewBooks: 'Uppgötvaðu nýjar bækur',
      FailedToFindReader: 'Mistókst að finna lesanda',
      ApprovedReadRequest: 'Lestrarbeiðni er samþykkt',
      Approved: 'Samþykkt',
      UserAlreadyInvited: '{{user}} hefur þegar verið boðið',
      SelectBook: 'Veldu bók',
      InvitationMessagePlaceholder:
        'Láttu persónuleg skilaboð fylgja boðinu þínu til að auka líkurnar á að þú verðir samþykktur.',
      YouHaveAPendingInvitation: 'Þú ert með boð í bið',
      ReaderIndexInfo:
        'Lesendagagnagrunnurinn inniheldur BetaReader.io notendur sem hafa gefið til kynna að þeir séu opnir til að leita til höfunda. Sendu þeim boð með þínum bestu tónhæð, en hafðu í huga að þeim er frjálst að hafna boðinu þínu af hvaða ástæðu sem er.',
      ReaderLimitReached: 'Þú hefur náð hámarki lesenda',
      Invite: 'Bjóða',
      Languages: 'Tungumál',
      LinkWasSentToX: 'Við höfum sent innskráningartengil á {{x}}',
      Verifying: 'Staðfestir...',
      FoundNoAvailableManuscripts: 'Við fundum engin handrit tiltæk',
      FindASpecificQuestion: 'Sía spurningar',
      ReadAndReviewBonusInfo:
        'READ & REVIEW bónusuppfærslurnar þínar eftir að þú hefur lokið við og skoðað bók.',
      ManuscriptStatus: 'Staða handritið',
      MSStatusDescription_alpha: 'Þetta er handrit á frumstigi eða að hluta.',
      MSStatusDescription_beta: 'Þetta er handrit seint stigs af fullri bók.',
      SelectStatus: 'Veldu stöðu',
      MaxAllowedGenres: 'Þú getur aðeins valið allt að 5 tegundir',
      TooLongDescription: 'Lýsingin þín er of löng (hámark 1000 stafir)',
      Duplicate: 'Afrit',
      Download: 'Sækja',
      DuplicateVersionInfo:
        'Þegar þú afritar útgáfu verða allir kaflar og kannanir afritaðir í nýju útgáfuna. Lesendur þínir verða áfram á gömlu útgáfunni.',
      NoBookMsg:
        '<0> Täällä ei ole mitään! </0> <1> <0> Haluatko </0> <1> lisätä oman käsikirjoituksesi? </1> </1>...',
      CommentsCopiedFromOriginal:
        'Athugasemdir voru afritaðar úr upprunalegu skjali',
      CopyComments: 'Afritaðu allar athugasemdir',
      MigrateReaders: 'Flytja lesendur',
      Posts: 'Færslur',
      Sent: 'Sendi',
      Username: 'Notendanafn',
      UsernameAlreadyTaken: 'Þetta notendanafn er tekið',
      Followers: 'Fylgjendur',
      ReadersAndFollowers: 'Lesendur og fylgjendur',
      WhoCanSeeThis: 'Hver getur séð þetta?',
      FollowCount: '{{count}} fylgjandi',
      FollowCount_plural: '{{count}} fylgjendur',
      Follow: 'Fylgstu með',
      Following: 'Framhaldi',
      MarkAllAsRead: 'Merkja allt sem lesið',
      Working: 'Að vinna...',
      Audience: 'Áhorfendur',
      ShowAllComments: 'Sýna allar athugasemdir...',
      Publish: 'Birta',
      InviteByEmail: 'Boðið með tölvupósti',
      InviteByLink: 'Bjóða með hlekk',
      Chat: 'Spjall',
      WelcomeToSupportMessage:
        'Hæ {{x}}! Velkomin í Betareader stuðning 👋, við reynum að svara eins fljótt og við getum. Hvað getum við hjálpað þér með?',
      TalkToSupport: 'Talaðu við stuðningsteymi okkar',
      StartSupportChat: 'Byrjaðu stuðningsspjall',
      StartSupportChatPMessage:
        '<0>Þú getur alltaf náð í okkur í gegnum spjallið okkar og við munum svara eins fljótt og við getum.</0>',
      FindHelpArticlesMessage:
        '<0>Þarftu hjálp? skoðaðu<1> hjálparhluta </1> okkar, fullan af hjálpargreinum til að leiðbeina þér.</0>',
      HelpArticles: 'Hjálpargreinar',
      CloseConversationAlertHeader: 'Lokið samtal',
      CloseConversationAlertMessage: 'Viltu loka þessu samtali?',
      EmailSupportMessage:
        '<0> Sendu tölvupóst á <1>{{mail}}</1> og við svörum eins fljótt og við getum. </0>',
      Includes: 'Inniheldur',
      DoesNotInclude: 'Innifalið ekki',
      IncludesAll: 'Inniheldur allt',
      RemoveFilter: 'Fjarlægðu síuna',
      AddFilter: 'Bæta við síu',
      SelectGenres: 'Veldu tegundir',
      And: 'Og',
      ContinueWithGoogle: 'Haltu áfram með Google',
      ContinueWithFacebook: 'Haltu áfram með Facebook',
      ContinueWithApple: 'Haltu áfram með Apple',
      NewOnBetareader: 'Nýr á BetarReader?',
      AlreadyHaveAccount: 'Ertu nú þegar með reikning?',
      SignUpWithFacebook: 'Skráðu þig með Facebook',
      SignUpWithGoogle: 'Skráðu þig hjá Google',
      SignUpWithApple: 'Skráðu þig hjá Apple',
      OpportunityWithoutDescriptionMessage:
        'Höfundur hefur ekki gefið upp lýsingu.',
      SignInToViewContent: 'Skráðu þig inn til að skoða þetta efni',
      ContentBelongsToAnotherAccount:
        'Þetta efni tilheyrir öðrum reikningi en þínum virka.',
      SwitchAccount: 'Skiptu um reikning',
      OwnBooks: 'Eigin bækur',
      Collaborations: 'Samstarf',
      UploadImage: 'Hladdu upp mynd',
      SplitChapter: 'Skiptu um kaflann',
      UpgradeToUnlock: 'Uppfærðu til að opna',
      '3ReaderSlots': '3 lesararými',
      UnlimitedReaderSlots: 'Ótakmarkaður lesendatími',
      ImportWarning: 'Innflutningsviðvörun',
      ImportExeedLenghMessage:
        '<0><0>Einn eða fleiri af köflum þínum virðast vera of langir til að flytja inn, vertu viss um að þú hafir fylgt<1> innflutningsleiðbeiningum okkar. </1></0><1>Ábending: þú getur farið yfir kaflatextann og notað sizzor tólið til að skipta stærri köflum</1></0>',
      OneTeamMember: '1 liðsmaður',
      AdditionalTeamMembers: 'Bættu við liðsmönnum',
      PerMonth: 'á mánuði',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Sendu inn handritið þitt',
      SubmitTo: 'Leggðu fram',
      YourSubmittedManuscripts: 'Innsend handrit þín',
      SelectManuscriptToSubmit: 'Veldu handrit til að senda inn',
      AvailableToRead: 'Hægt að lesa',
      ManuscriptSubmissionSuccess: 'Handritið lögð',
      ManuscriptSubmittedTo:
        'Handritið þitt, {{_manuscriptName}}, hefur verið sent á {{_inboxName}}',
      SubmittedBy: 'Sent inn af {{_userName}}',
      ReceivedManuscripts: 'Fékk handrit',
      InviteToRead: 'Bjóddu að lesa',
      YourReview: 'Þín umsögn',
      UserReviewPlaceholder: 'Umsagnir notenda eru sýnilegar öllum.',
      LeaveReviewError:
        'Þú getur aðeins skoðað notendur sem hafa lokið við að lesa eitt af handritunum þínum.',
      NotEnoughData: 'Ekki næg gögn',
      EditSignUpSurveyQuestions: 'Breyttu spurningum um skráningarkönnun',
      AddSignUpSurveyQuestions: 'Bættu við spurningum um skráningarkönnun',
      To: 'Til',
      FinishedReadingAt: 'Kláraði lestur á {{x}}',
      FailedToSendInvitation:
        'Ekki tókst að senda boð, vinsamlegast staðfestu netfangið',
      EditInvitation: 'Breyta boði',
      ChapterSeparator: 'Kaflaskilja',
      CancelReason:
        'Áður en þú heldur áfram, værirðu til í að segja okkur hvers vegna?',
      cancel_reason_technical_issues: 'tæknileg atriði',
      cancel_reason_too_expensive: 'Varan er of dýr',
      cancel_reason_finished_my_beta: 'Ég kláraði verkefnið mitt',
      cancel_reason_switching_to_another_product: 'Ég skipta yfir í aðra vöru',
      cancel_reason_missing_features: 'Ég vantar mikilvægar aðgerðir',
      cancel_reason_did_not_meet_expectations:
        'The pallur stóðst ekki væntingar mínar',
      cancel_reason_other: 'Aðrar ástæður',
      TellUsMore: 'Segðu okkur meira',
      DeleteAccount: 'Eyða reikningi',
      YourAccountHasBeenDeleted: 'Reikningnum þínum hefur verið eytt',
      DeleteAccountConfirmation:
        'Ertu viss um að þú viljir eyða reikningnum þínum og öllum gögnum hans? Ekki er hægt að afturkalla þessa aðgerð.',
      GoodbyeMessage:
        'Okkur þykir leitt að sjá þig fara frá okkur og vonum að þú komir aftur í framtíðinni.',
      EditContent: 'Breyttu efni',
      AffectedReadersMessage:
        '{{count}} lesandi hefur þegar lesið þennan kafla. Smelltu hér til að senda uppfærslu um breytingar þínar.',
      AffectedReadersMessage_plural:
        '{{count}} lesendur hafa þegar lesið þennan kafla. Smelltu hér til að senda uppfærslu um breytingar þínar.',
      ImportingChapterXofY: 'Flytur inn kafla {{_x}} / {{_y}}',
      ImportStarted: 'Innflutningur hafinn',
      XVersions: '{{count}} útgáfa',
      XVersions_plural: '{{count}} útgáfur',
      UnsubscribeSuccessful:
        'Þú hefur verið afskráður. Þú getur alltaf uppfært kjörstillingar þínar í gegnum prófílinn þinn.',
      UnsubscribeUnsuccessful:
        'Ekki tókst að segja upp áskrift. Sendu skilaboð á support@betareader.io ef vandamálið er viðvarandi.',
      GoodbyeHeader: 'Bless!',
      BulkAddReaders: 'Bættu við lesendum frá CSV',
      Home: 'Heim',
      Actions: 'Aðgerðir',
      Invitations: 'Boð',
      SelectCSVWithReaders: 'Veldu CSV með lesendum',
      Synopsis: 'Yfirlit',
      SynopsisDescription:
        'Láttu gervigreind okkar, BetaBot, búa til yfirlit byggða á textanum þínum. Það mun ekki vera sýnilegt lesendum þínum.',
      LastUpdated: 'Síðast uppfært',
      CreateSynopsis: 'Búðu til samantekt',
      RefreshSynopsis: 'Endurnýjaðu samantekt',
      ElevatorPitch: 'Lyftuvöllur',
      ThisIsAPremiumFeature: 'Premium eiginleiki',
      GoBack: 'Farðu til baka',
      Retry: 'Reyndu aftur'
    }
  },
  pt: {
    translations: {
      NoteThatChatWillUseYourUserName:
        'Por favor, note que ao enviar um mensagens de leitor seu nome de usuário e não o pseudônimo será visível',
      Library: 'Biblioteca',
      Account: 'Conta',
      EmptyLibrary: 'A sua biblioteca está vazia.',
      EmptyLibraryAction:
        'você estava esperando encontrar alguma coisa aqui? Fale com o autor, ou envie-nos um e-mail em {{_email}}, e vamos ver o que podemos fazer.',
      ByAuthor: 'por {{_authorName}}',
      ExceedChapterLenghtWarning:
        'Capítulo es demasiado larga para la importación',
      WordCount: '{{_wordCount}} palavras',
      WordCountString: '{{count}} palavra',
      WordCountString_plural: '{{count}} palavras',
      CharCountString: '{{count}} caráter',
      CharCountString_plural: '{{count}} caracteres',
      SurveyCount: '{{count}} pesquisa',
      SurveyCount_plural: '{{count}} pesquisas',
      ChapterCount: '{{count}} capítulo',
      ChapterCount_plural: '{{count}} capítulos',
      QuestionCountString: '{{count}} questão',
      QuestionCountString_plural: '{{count}} perguntas',
      LoginToChangeEmail:
        'Por favor, acesse novamente para alterar o seu e-mail',
      SendVerification: 'Enviar link de verificação',
      Name: 'Nome',
      Email: 'E-mail',
      EnterYourName: 'Por favor, insira seu nome',
      ReceiveCompanyUpdates: 'Receber e-mails e notícias do BetaReader',
      ReceiveBookEmails: 'Receber e-mails sobre novos livros',
      ReceiveBookActivityUpdates:
        'Receber e-mails sobre atividades em meu livro',
      ReceiveMessageUpdates: 'Receber e-mails quando chegar novas mensagens ',
      IncludeProfileInReaderList: 'Incluir meu perfil no banco de dados leitor',
      PrivacyPolicy: 'Política de Privacidade',
      TermsOfService: 'Termos de serviço',
      ReadOurBlog: 'Leia o nosso blog',
      Copyright: '© BetaReader Sweden AB 2017-2019',
      Next: 'Próximo',
      Back: 'Voltar',
      CreateAccount: 'Criar Conta',
      CreateAccountPrompt:
        '<0>Você não tem uma conta? <1><0>Crie uma aqui!</0></1></0>',
      ForgotPasswordPrompt:
        '<0>Esqueceu sua senha? <1><0>Redefina aqui!</0></1></0>',
      SignIn: 'Entre',
      SignOut: 'Sair',
      SendResetLink: 'Enviar link de redefinição',
      ResetPassword: 'redefinição de senha',
      Password: 'Senha',
      OrUse: 'ou use',
      NoConnection: 'Sem conexão',
      AcceptTermsMessage: 'Aceite os termos de serviço',
      AcceptToSToCreateAccount:
        'Você tem que aceitar nossos termos de serviço para criar uma conta.',
      Answer: 'Responda',
      FailedToLoadBook: 'Não foi possível carregar o livro.',
      PullDownToRefresh: 'Puxe para baixo para atualizar.',
      ContactSupport: 'Entre em contato com o suporte',
      Support: 'Suporte',
      LastRead: 'última leitura',
      XInvitations: '{{count}} convite',
      XInvitations_plural: '{{count}} convites',
      EnterComment: 'Comente...',
      EnterReply: 'Resposta...',
      Reply: 'Resposta',
      Reply_plural: 'Respostas',
      Reply_verb: 'Resposta',
      show: 'Mostrar',
      hide: 'ocultar',
      Comments: 'Comentários',
      InlineComment: 'comentário em linha',
      Reject: 'Rejeitar',
      Accept: 'Aceitar',
      Error: 'Erro',
      failed: 'fracassado',
      SelectedText: 'O texto selecionado',
      FailedToFetchUserProfile: 'Falha ao buscar perfil de usuário.',
      ProblemPersistContactSupport:
        'Se o problema persistir, contate o suporte em:',
      clickToAddComment: 'Clique aqui para adicionar comentário',
      ColorMode: 'Cores',
      FontSize: 'Tamanho da fonte',
      Discover: 'Descobrir',
      MyAccount: 'Minha conta',
      MyManuscripts: 'Meus manuscritos',
      Sweden: 'Suécia',
      USA: 'EUA',
      UK: 'Reino Unido',
      Germany: 'Alemanha',
      France: 'França',
      Spain: 'Espanha',
      Country: 'País',
      Gender: 'Gênero',
      YoB: 'Ano de nascimento',
      PreferredGenres: 'Gêneros preferidos',
      PreferredLanguages: 'Idiomas preferidos',
      EmailPreferences: 'Preferências de Email',
      chapters: 'capítulos',
      words: 'palavras',
      YouShouldBetaRead: 'Você deveria ler',
      onBetaReader: 'em BetaReader.io',
      Share: 'Compartilhar',
      NoGuidelines: 'O autor não forneceu orientações de feedback',
      Read: 'Ler',
      on: 'em',
      Author: 'Autor',
      JoinBeta: 'Eu quero ler isto',
      joinConsentString:
        'Eu entendo que, solicitando a aderir ao beta, vou compartilhar o meu endereço de e-mail com o autor.',
      Submit: 'Enviar',
      AnswerSurvey: 'Responda!',
      SendJoinRequest: 'Enviar',
      IJustWantToRead: 'Eu só quero ler',
      CopyrightInfo: 'Informações de Copyright',
      RequestToRead: 'Enviar solicitação de leitura',
      Feedback: 'Comentários',
      NoComment: 'Ninguém comentou ainda',
      OnceUponATime: 'Era uma vez ...',
      Review: 'Resenha',
      Overall: 'Geral',
      Plot: 'Enredo',
      Grammar: 'Gramática',
      Title: 'Título',
      AddReview: 'Adicione uma resenha',
      EnterReviewText: 'Digite uma breve revisão',
      OverallRating: 'Geral',
      OverallRatingRequired: 'Adicionar uma avaliação geral',
      PlotRating: 'Enredo',
      GrammarRating: 'Gramática',
      SubmitReview: 'Enviar uma resenha',
      LeaveReview: 'Sair da resenha',
      checkOutAuthorApp:
        '<0>Você é um escritor?</0> <1></1> <2> Confira nosso <1>app para autores</1>!</2>',
      MyLibrary: 'Minha biblioteca',
      ReadingPreferences: 'preferências de leitura',
      'E.g.': '?',
      'I want to receive emails from BetaReader.io when the author makes changes or updates to':
        'Eu quero receber e-mails de BetaReader.io quando o autor fizer alterações ou atualizações',
      'I want to receive emails about BetaReader news and updates.':
        'Eu quero receber e-mails de notícias e atualizações sobre BetaReader.',
      'I want to receive emails about new books that match my reading preferences.':
        'Eu quero receber e-mails sobre novos livros que correspondem as minhas preferências de leitura.',
      'this book': 'este livro',
      'Email us on': 'Nos envie um e-mail',
      "and we'll see what we can do :)": 'e vamos ver o que podemos fazer :)',
      'Want an author account?': 'Quer uma conta de autor?',
      Preferences: 'preferências',
      Language: 'Idioma',
      Browse: 'Navegar',
      'to beginning': 'para o início',
      'to chapter...': 'o capítulo ...',
      Location: 'Localização',
      Previous: 'Anterior',
      Finish: 'Terminar',
      Description: 'Descrição',
      FeedbackGuidelines: 'Orientações para o feedback',
      'Start reading': 'Comece a ler',
      StartReading: 'Comece a ler',
      LeaveComment: 'Comente',
      replies: 'respostas',
      Show: 'Mostrar',
      Hide: 'ocultar',
      'Add Reply': 'Adicionar resposta',
      PendingInvitation: 'Convite pendente',
      Decline: 'Recusar',
      DeclinedInvitationMessage:
        'Você recusou este convite para ler, mas você ainda pode aceitá-lo se você tiver alterado sua mente.',
      Welcome: 'Bem-vindo(a)',
      by: 'por',
      to: 'para',
      AcceptInvitation: 'Aceitar convite',
      InvitationAccepted: 'Convite aceito',
      WaitForBook: 'Aguarde enquanto preparamos seu livro',
      PasswordLength: 'Sua senha precisa ter pelo menos 6 caracteres',
      AgreeToPolicyAndTerms:
        'Você deve concordar com a Política de Privacidade e os Termos de Serviço do BetaReader para se inscrever',
      'Sending...': 'Enviando ...',
      VerificationSent: 'E-mail de verificação enviado.',
      VerificationEmailFailed: 'Falha ao enviar e-mail de verificação.',
      IAcceptToC:
        '<0>Eu aceito a <1>Política de Privacidade</1> & <3> Termos de Serviço </ 3></0> do BetaReader',
      AccountSettings: 'Configurações da conta',
      ContactSettings: 'Configurações de contato',
      Chapters: 'capítulos',
      Options: 'opções',
      Option: 'Opção',
      BetaLanguageMessage:
        '<0>Estamos trabalhando para melhorar as configurações de idioma e seremos gratos por qualquer ajuda. Se você encontrar algum erro no site, por favor não hesite em nos contactar em: <1>hello@betareader.io</1></0>',
      Genres: 'gêneros',
      PressEnterToSave: 'Pressione Enter para salvar',
      female: 'mulher',
      male: 'homem',
      other: 'Outro',
      DontWantToShare: 'privado',
      SignInWithPassword: 'Entrar com senha',
      GoToBook: 'Ir para o livro',
      Requested: 'Sua solicitação está pendente',
      AnsweredOrRequestedNotice:
        'Você pediu para se juntar a este beta. O autor vai responder assim que puder.',
      RequestedWithDifferentEmail:
        'Você pediu para se juntar a este beta com um e-mail diferente. O autor vai voltar para você assim que puder.',
      LoadingLibrary: 'À procura de livros ...',
      PreparingBook: 'preparando livro',
      InLineCommentPlaceholder:
        'Deixe o seu comentário e pressione Enter para salvar',
      ExitSurvey: 'Sair das Perguntas',
      WhyExitQuestion: 'Por que você quer sair desse beta?',
      LostInterest: 'Eu perdi o interesse',
      NotEnoughTime: 'Eu não tenho tempo suficiente',
      Other: 'Outro',
      reason_lost_interest: 'Eu perdi o interesse',
      reason_no_time: 'Eu não tenho tempo suficiente',
      reason_other: 'Outra razão',
      reason_: 'Não especificado',
      ExitFeedbackQuestion: 'Você quer deixar algum feedback para o autor?',
      ExitFeedbackPlaceholder: 'Todo o feedback é útil',
      ThankYouForYourFeebdack: 'Obrigado pelo seu feedback!',
      AppVersion: 'Versão',
      AppBuildNumber: '#',
      Like: 'Gostar',
      Love: 'Ame',
      Laugh: 'Haha',
      Surprise: 'Surpresa',
      Sad: 'Triste',
      Angry: 'Bravo',
      NoBooksInList: 'Nenhum livro aqui.',
      Update: 'Atualizar',
      Overview: 'Visão Geral',
      Content: 'Conteúdo',
      Readers: 'Leitores',
      NotificationSettings: 'Configurações de notificação',
      SendEmailOnComments:
        'Enviar e-mail quando os leitores deixarem comentários',
      SendEmailOnSurveyComplete:
        'Enviar um e-mail quandos os leitores terminarem as Perguntas',
      CoverUploadMsg:
        'Clique para carregar a sua imagem de capa (png ou jpg, 980x1568px), ou solte nesta área.',
      DefaultNamePlaceholder: 'Padrão: {{displayName}}',
      SelectUpTo5Genres: 'Selecione até 5 gêneros',
      AdvancedSettings: 'Configurações avançadas',
      EnableComments: 'Ativar comentários',
      EnableBookReviews: 'Ativar resenhas de livros',
      DeleteBook: 'Excluir livro',
      DeleteCover: 'Excluir capa',
      DeleteCoverConfirmation:
        'Tem certeza de que deseja excluir a capa do livro?',
      YesBinIt: 'Sim, guarde!',
      AreYouSureYouWantToDeleteBookName:
        '<0>Tem certeza de que deseja excluir <1>{{bookTitle}}</1>?</0>',
      DeletedBookX: '{{x}} foi excluído.',
      No: 'Não',
      Yes: 'Sim',
      UserCommentedOn: 'comentou sobre',
      ShowXReplies: 'Mostrar {{count}} resposta',
      ShowXReplies_plural: 'Mostrar {{count}} respostas',
      HideXReplies: 'Esconder {{count}} resposta',
      HideXReplies_plural: 'Esconder {{count}} respostas',
      NoInLineComments: 'Sem comentários',
      Chapter: 'Capítulo',
      NoCommentsToShow: 'Sem comentários para mostrar.',
      Save: 'Salve',
      TooLongTitle: 'Seu título é muito longo (máximo de 60 caracteres)',
      ClickToUploadDoc:
        'Clique aqui para adicionar ou soltar o arquivo docx para importar',
      LongChapterWarning: 'capítulo longo, isso é correto?',
      Upload: 'Enviar',
      UploadChapters: 'Enviar capítulos',
      ImportBooksHelpMsg:
        'Ninguém será capaz de ver o seu manuscrito sem a sua aprovação. Leia sobre <1>livros importados</1>.',
      Add: 'Adicionar',
      NewChapter: 'Novo capítulo',
      NewSurvey: 'Nova Pergunta',
      SelectedPart: 'parte selecionada',
      SelectPart: 'Selecionar parte',
      NotifyMe: 'Me avise',
      WhenReadersStart: 'no início',
      WhenReadersComplete: 'No fim',
      ManageChapter: 'Gerenciar capítulo',
      ManageSurvey: 'Gerenciar Perguntas',
      ManageReaders: 'Gerenciar leitores',
      MoveUp: 'Subir',
      MoveDown: 'Descer',
      SaveSurvey: 'Salvar Perguntas',
      Saved: 'Salvo',
      EnterSurveyName: 'Dê um título à sua Pergunta',
      EnterSurveyDescription: 'Descreva a sua Pergunta',
      SelectAtLeast: 'Selecione pelo menos',
      SelectAtMost: 'Selecione no máximo',
      LabelOptional: 'Etiqueta (opcional)',
      MyBooks: 'Meus livros',
      ChangeRoleToWriterToCreateBooks:
        'Por favor, selecione a opção de escritor para criar livros.',
      UsingXOutOfYBooks: 'Você está usando {{x}} de {{y}} livros.',
      UsingXOutOfYReaders: 'Você está usando {{x}} de {{y}} leitores.',
      NothingHere: 'Nada aqui no momento.',
      OpenReaderWeb: 'Página de leitor',
      PrivacyLevel: 'Status de compartilhamento',
      PublishingStatus: 'Status de compartilhamento',
      PrivacyLevel_infotext_unpublished:
        '<0> Seu livro está desligado. Mova para <1> privado </1>, <3> fechado </3> ou <5> listado </5> para que os leitores possam encontrá-lo. </0>',
      PrivacyLevel_infotext_private:
        '<0>O seu livro não está listado em lugar algum. Somente você pode convidar os leitores.</0>',
      PrivacyLevel_infotext_closed:
        '<0>A apresentação do seu livro pode ser visto por qualquer pessoa com <1>o link</1>. Os leitores podem solicitar um convite, mas você precisa aprovar cada pedido.</0>',
      PrivacyLevel_infotext_listed:
        '<0>O seu livro está listado <1>aqui</1>. Os leitores podem solicitar um convite, mas você precisa aprovar cada pedido.</0>',
      PrivacyLevel_state_unpublished: 'Desligado',
      PrivacyLevel_state_private: 'Privado',
      PrivacyLevel_state_closed: 'Fechado',
      PrivacyLevel_state_listed: 'Listado',
      SendReminder: 'Mande um lembrete',
      YouCanSendAReminderEvery3Days:
        'Você pode enviar um lembrete a cada três dias',
      LastSeenAt: 'Visto por último em',
      ReminderSentAt: 'lembrete enviado',
      XReadersInactiveForYDays:
        '{{count}} leitor não terminou o livro e não tem sido ativo durante os últimos {{dias}} dias.',
      XReadersInactiveForYDays_plural:
        '{{count}} leitores não terminaram o livro e não têm sido ativos nos últimos {{dias}} dias.',
      InactiveReaders: 'leitores inativos',
      XReadersNotStartedForYDays:
        '{{count}} leitor não aceita seu convite há mais de {{dias}} dia ',
      XReadersNotStartedForYDays_plural:
        '{{count}} leitor não aceita seu convite há mais de {{dias}} dias ',
      ReadersWhoHaveNotAcceptedTheInvitation:
        'Leitores que não aceitaram o convite',
      YouHaveXJoinRequests: 'Você tem {{count}} pedido para ler o seu livro',
      YouHaveXJoinRequests_plural:
        'Você tem {{count}} pedidos para ler o seu livro',
      JoinRequests: 'Pedidos para ler',
      Approve: 'Aprovar',
      RequestedAt: 'Pedidos',
      NoAnswer: 'Sem resposta',
      SendSurveyReminder: 'Enviar uma lembrança de Perguntas',
      Remove: 'Remover',
      Created: 'Criado',
      RequestedToRead: 'Solicitado para leitura',
      InvitationSent: 'convite enviado',
      Reminded: 'lembrado',
      Question: 'Questão',
      YouCanAskUpToXQuestions:
        'Você pode adicionar até {{maxQuestions}} perguntas que os leitores são obrigados a responder quando se inscrever.',
      NewQuestion: 'nova pergunta',
      ReaderSignUpForm: 'Formulário de Inscrição',
      NoReadersFound: 'Nenhum leitor encontrado',
      ReachedPartTitle: 'Atingido {{partTitle}}',
      ReasonToQuit: 'Razão',
      YourBookDoesNotHaveAnyChaptersYet:
        'Seu livro não tem nenhum capítulo ainda',
      Invited: 'Convidado',
      Started: 'Começado',
      Finished: 'Acabado',
      Abandoned: 'Abandonado',
      AvgOverallRating: 'Geral',
      AvgPlotRating: 'Enredo',
      AvgGrammarRating: 'Gramática',
      YouDoNotHaveReviewsYet: 'Você não tem resenhas até agora',
      PersonalLink: 'Link pessoal',
      HelpUsReachMoreWriters:
        'Você gosta de usar BetaReader.io? Use o seu link pessoal para nos ajudar a ajudar mais autores!',
      Reports: 'Relatórios',
      WeNeedYourEmail: 'É necessario o e-mail',
      MissingEmailMsg:
        'Precisamos ser capazes de entrar em contato com você a respeito de sua conta. Nós não vamos compartilhar isso com ninguém, é para entrar em contato com você, se estritamente necessário.',
      EmailNotVerifiedPrompt:
        'E-mail não verificado. <1>Clique aqui para reenviar</1> o e-mail de verificação. (Verifique sua pasta de spam se você não recebê-lo)',
      Skip: 'Pular',
      RoleWriter: 'Escritor',
      RoleReader: 'Leitor',
      RoleBoth: 'Ambos',
      AddReader: 'Adicionar novo leitor',
      DownloadReaderList: 'Baixar lista leitor',
      'Show only': 'Apenas mostrar',
      StatusNEW: 'Novo',
      StatusTODO: 'Para fazer',
      StatusDOING: 'Fazendo',
      StatusDONE: 'Feito',
      StatusIGNORE: 'Ignorar',
      RemoveFilters: 'Remover filtros',
      ReadingDataReport: 'Lendo dados',
      SurveyAnswersReport: 'Responder as Perguntas',
      Reviews: 'Resenha',
      AbandonedReadersReport: 'Leitores perdidos',
      SignUpMsg:
        '<0>Obrigado por se inscrever!</0> <1>Um e-mail de verificação foi enviado para <1>{{email}}</1></1>',
      CopiedXToClipboard: 'Copiado {{x}} para a área de transferência.',
      DoYouWantToBeMyBetaReader: 'Você quer ser meu beta reader?',
      LookingForBetaReadersForBookTitle:
        'Estou à procura de beta readers para {{bookTitle}}!',
      LookingForBetaReadersForBookTitleWithURL:
        'Estou à procura de beta readers para {{bookTitle}}. Clique neste link para ler mais e se inscrever: {{url}}',
      SocialSharingIsNotPossibleForPrivateBooks:
        'Compartilhamento social não é possível para livros privados.',
      ShareTheLinkToYourBook: 'Compartilhe o link para seu livro!',
      Messages: 'Mensagens ',
      FailedToLoadMessage: 'Nós não conseguimos carregar essa mensagem',
      FailedToSendMessage: 'Nós não conseguimos enviar essa mensagem',
      FailedToStartConversation: 'Nós não conseguimos iniciar a conversa',
      NewMessage: 'Nova mensagem',
      MessageSubject: 'Assunto',
      MessageBody: 'Escreva a sua mensagem ...',
      MessageFrom: 'De',
      MessageTo: 'Para',
      SendMessage: 'Mandar',
      Message: 'mensagem',
      ReaderMarketplaceBetaInfoText:
        'Estamos experimentando com deixar nossos autores pagar a  leitores comuns e bookworms uma quantia simbólica pelo o serviço de leitura de um manuscrito. Oferecemos mecanismos de controle para validar o feedback e ter certeza de que os leitores leram o manuscrito completo antes que seja pago. Se você quiser tentar este recurso, por favor indique quantos leitores que você precisa.',
      ReaderMarketplaceBetaFullText:
        'Este programa está completo por enquanto. Gostaria de ser notificado quando ele for aberto?',
      Sum: 'Soma',
      XUSDPerReader: '${{x}} / leitor',
      AmountOfReaders: 'Quantidade de leitores',
      PayForTestReaders: 'Pagar para os leitores de teste',
      GoToCheckout: 'Ir para checagem',
      YesPlease: 'Sim, por favor!',
      BETA: 'BETA',
      NoMessages: 'Você não tem mensagens',
      OnBoarding_import_book:
        '<0>Bem-vindo ao BetaReader.io!</0> A fim de compartilhar seu livro, você primeiro precisa importá-lo, quer por <2> upload</2>, ou criando-lo diretamente na plataforma. Vá em frente e experimentá-lo! Tudo sobre BetaReader.io é privado por padrão, para que ninguém vai vê-lo a menos que você convidá-los.',
      Onboarding_book_overview:
        'A visão geral do livro é onde você pode configurar detalhes sobre o seu livro como título, gênero e orientações de feedback, mas também se deseja ou não que os leitores possam comentar e deixar resenhas.',
      Onboarding_book_content:
        'A guia Conteúdo é onde você controla suas coisas. Adicione seus capítulos e perguntas aqui.',
      Onboarding_book_readers:
        'A guia Leitores é onde você controla quem começa a ler o seu livro. Envie convites privados, compartilhe seu link de inscrição via Twitter ou adicione seu livro a nossa lista Descoberta e alcançe mais leitores.',
      Onboarding_book_feedback:
        'A guia Comentários lhe dá uma visão geral de todos os comentários que os leitores deixaram. Responda aos comentários e filtre coisas sem importância.',
      Onboarding_book_reports:
        'A guia Relatório te dá uma visão geral de como seu livro está indo. Quantos leitores foram convidados, quantos já começaram a ler, deixaram resenhas e assim por diante.',
      Close: 'Fechar',
      Last: 'Último',
      DuplicatedBook: '<0><0></0> livro copiado {{bookTitle}}</0>',
      PenName: 'pseudônimo de escritor',
      MissingNameMsg:
        'Você precisa colocar um nome para ser capaz de se comunicar com seus leitores.',
      NameConversation: 'Dê um nome para essa conversa',
      With: 'com...',
      SelectPeople: 'Selecione pessoas',
      UploadAsNewVersion: '... como nova versão',
      UploadAsExtraChapters: '... para esta versão',
      Cancel: 'Cancelar',
      Unnamed: 'sem nome',
      NewVersion: 'Nova versão',
      NoContacts: 'nenhum contato',
      GetContacts:
        'Autores dos livros que você lê e leitores dos livros que você escreve tornar-se seus contatos.',
      NoConversations: 'Nenhuma conversa...',
      AllVersionsNeedContent:
        'Sua versão atual precisa de conteúdo antes de criar um novo.',
      InviteToVersion: 'Convidar para a versão',
      latestVersion: 'Mais recentes',
      VersionName: 'Versão {{x}}',
      Reader: 'Leitor',
      StartedReading: 'A leitura começou',
      CurrentPosition: 'Posição atual',
      PickVersion: 'Escolher versão',
      Filters: 'filtros',
      Versions: 'versões',
      ChapterInitial: 'CH',
      AvgTimeToComplete: 'Tempo médio para o capítulo leitura',
      ReaderProgress: 'Posições',
      SelectFilter: 'Escolha um filtro',
      CreatePost: 'Compartilhe uma atualização',
      NewBookPostPlaceholder: 'O que você quer dizer?',
      BookUpdateForX: 'Atualização para o livro {{x}}',
      OnSaveChapterMessage: 'Deixe seus leitores saber o que você mudou',
      SampleChapterUpdatePost: 'Eu mudei isso ...',
      SaveWithoutPosting: 'Salvar sem postar',
      SaveAndPost: 'Salvar e postar',
      DiscoverSearch: 'Procurar um título ou gênero ...',
      LoadMore: 'Carregar mais ',
      PendingReadInvitations: 'Convites pendentes',
      PendingReadRequests: 'Solicitações Pendentes',
      UserReactedOn: 'reagiram em',
      ChapterComment: 'Comentário do capítulo',
      InlineCommentNotFound:
        'Este comentário não foi encontrado no texto. Será que ele foi removido?',
      Or: 'Ou',
      Version: 'Versão',
      Updates: 'Atualizações',
      CurrentBetaIsFullMessage:
        'Este beta está cheio, entre em contato com ele para que ele abra mais vagas.',
      Edit: 'Editar',
      Delete: 'Excluir',
      DropFilesHere: 'Soltar os arquivos aqui',
      ShowOnly: 'Apenas mostrar',
      FeedbackSearch: 'Pesquisa de texto',
      'BuySubscriptionToS&PPMessage':
        '<0>Ao comprar uma assinatura de BetaReader.io, você indica que leu e aceitou o nossas <1> condições de serviço</1> & <5> política de privacidade </ 5></0>',
      Inbox: 'Caixa de entrada',
      CofirmMovePart:
        'Você realmente deseja mover esta parte? Vai afetar os seguintes leitores:',
      'AreYouSure?': 'Você tem certeza?',
      LetYourReadersKnowWhatYouChanged:
        'Deixe seus leitores saberem o que você mudou',
      ThisChangeAffectsTheFollowingReaders:
        'Essa mudança afeta os seguintes leitores:',
      MovedXFromYtoZ: 'Mudou {{x}} de {{y}} a {{z}}',
      Subscription: 'Inscrição',
      Subscriptions: 'Inscrições',
      FreeSubscriptionTitle: 'Livre',
      FreeSubscriptionDescription: 'Plano básico, livre para sempre.',
      StandardSubscriptionTitle: 'Padrão',
      StandardSubscriptionDescription: 'Bom para perfeccionistas de hobby',
      IndieSubscriptionTitle: 'Indie',
      IndieSubscriptionDescription: 'Ótimo para escritores indie profissionais',
      ProSubscriptionTitle: 'Profissional',
      ProSubscriptionDescription: 'Perfeito para os editores',
      CancelSubscription: 'Cancelar assinatura',
      YourSubscriptionHasBeenCancelled: 'Cancelar inscrição',
      CancelSubscriptionConfirmation:
        'Tem a certeza que pretende cancelar a sua inscrição?',
      ValidUntil: 'Válido até',
      UpgradeSubscriptionTitle: 'Atualizar',
      UpgradeSubscriptionPromoText: 'Atualize sua conta',
      Abort: 'Abortar',
      BuyNow: 'compre',
      Current: 'Plano atual',
      Monthly: 'Por mês',
      Yearly: 'Anual',
      monthly: 'Por mês',
      yearly: 'Anual',
      month: 'mês',
      XCheaper: '{{x}} mais barato!',
      Downgrade: 'Abaixar',
      Forever: 'para sempre',
      ChangePlan: 'Mude o plano',
      TrackReaderProgress: 'Acompanhe os seus leitores em tempo real',
      InlineComments: 'Comentários in-line e reações',
      ReaderSurveys: 'Perguntas dos leitores',
      NativeApps: 'Android e iOS',
      MultipleVersions: 'versionamento manuscrito',
      '1ActiveManuscript': 'Versão manuscrito',
      '5ActiveManuscripts': 'Até 5 manuscritos',
      '20ActiveManuscripts': 'Até 20 manuscritos',
      UnlimitedActiveManuscripts: 'Manuscritos ilimitadas',
      '3ActiveReadersPerManuscript': 'Até 3 leitores',
      '20ActiveReadersPerManuscript': 'Até 20 leitores',
      '50ActiveReadersPerManuscript': 'Até 50 leitores',
      UnlimitedActiveReaders: 'Leitores ilimitadas',
      Zero: 'Zero',
      NoCreditCardNeeded: 'Nenhum cartão de crédito necessário',
      Recommended: 'Recomendado',
      SomethingWentWrong: 'Algo deu errado',
      SubscriptionChangeError: 'Falha ao alterar inscrição',
      SubscriptionChangeSuccess: 'Atualizamos a sua assinatura',
      ActionCancelled: 'A ação foi cancelada',
      Cancelled: 'Cancelado',
      NextPayment: 'Próximo pagamento',
      PaymentPastDueMessage:
        'Seu pagamento está vencido. Tentativa seguinte: {{nextAttempt}}',
      ChangePaymentInformation: 'Alterar detalhes de faturamento',
      DashboardWelcomeMessage:
        '<0>Bem-vindo ao BetaReader.io!</0> <1>Comece <2> fazendo o upload</2> ou <6> adicionando </ 6> seu manuscrito</1> <2> Descoberta </ ​​2> < 3> Não se esqueça de colocar o seu manuscrito na lista <2> descoberta </ ​​2> para conseguir mais beta readers. </ 3> <4> <0>Aqui</0> está um rápido tutorial de como fazer isso. </ 4> <5> Suporte </ 5> <6> Por favor, consulte o nosso portal <2>  ajuda </2> e fale conosco diretamente clicando em (?) na parte superior direita da página. < / 6> <7> Você sempre pode enviar para nós um e-mail amigável <2> hello@betareader.io</2> </ 7>',
      Manuscripts: 'manuscritos',
      CurrentlyReading: 'Lendo atualmente',
      WeeklyReaders: 'Leituras Semanais ',
      TotalReaders: 'Total de leitores',
      FailedToDownloadReport: 'Falha ao baixar relatório',
      WaitingForConfirmation: 'À espera de confirmação',
      ChangePublishingStatusToAddReaders:
        'Alterar o status da publicação para adicionar leitores',
      SuggestASwap: 'Sugerir uma troca',
      WithdrawRequest: 'retirar pedido',
      Confirm: 'Confirmar',
      ThisIsASwapRequestForX:
        'Este é um pedido de troca para <2>{{x}}</2>. Se você aprovar este pedido, você será adicionado como um leitor para {{x}}.',
      ApprovedSwapRequestForX: 'Este é um pedido de troca para <2>{{x}}</2>.',
      ThisIsASwapRequestFor:
        'Este é um pedido de troca. Se você aprovar este pedido, você será adicionado como um leitor para:',
      SwapRequest: 'pedido de swap',
      ApproveAndJoin: 'Aprove e junte-se',
      AuthorXHasProposedToSwapThisForY:
        '{{x}} ofereceu para você a leitura de {{y}} em troca de você que você leia este livro.',
      GoToSwapRequest: 'Trocar pedido',
      SwitchVersion: 'Versão de troca',
      SendMagicLinkToX: 'Enviar um link mágico para {{x}}',
      notification_verb_comment: 'comentou',
      notification_verb_react: 'reagiu',
      notification_verb_reply: 'respondeu um comentário',
      notification_verb_abandon: 'abandonou o seu livro',
      notification_verb_request_to_join: 'solicitado para ler {{y}}',
      notification_verb_message: 'enviou uma mensagem',
      notification_verb_sent_join_approval: 'aprovou sua solicitação para ler',
      notification_verb_invitation_to_join: 'convidou você para ler {{y}}',
      notification_verb_manuscript_submitted: 'enviou um manuscrito para {{y}}',
      'SaveAnd...': 'Salvar e ...',
      Send: 'Enviar',
      OnlySave: 'Apenas salvar',
      YourNotifications: 'Suas notificações',
      Notifications: 'notificações',
      Images: 'imagens',
      '365DaysReadingData': '?',
      '90DaysReadingData': '?',
      '30DaysReadingData': '?',
      alerts_PaymentPastDue:
        'Seu pagamento está vencido. Atualize os detalhes da sua conta',
      alerts_MissingName: 'Atualize aqui seu nome ou pseudônimo:',
      alerts_TimeLeftOnLaunchOffer:
        'Você deixou de pedir o seu desconto. Digite "INICIAR" no campo de cupom quando você atualizar:',
      RequestedToSwapForX: 'Você pediu para trocar um presente para {{x}}',
      PaymentSuccessMessage:
        '<0><0>Obrigada pela compra!</0> <1><0></0></1></0>',
      Dashboard: 'Painel de Controle',
      year: 'ano',
      HowLikelyAreYouToRecommendBetaReaderToAFriend:
        'Qual a probabilidade de você recomendar BetaReader.io a um amigo?',
      NotAtAllLikely: 'Não é provável',
      ExtremelyLikely: 'Extremamente provável',
      ThanksDoYouWantToTellUsWhy: 'Obrigado! Você quer nos dizer por quê?',
      Create: 'Criar',
      Books: 'livros',
      Feed: 'Feed',
      ANewStory: 'uma nova história',
      FailedToGetInvitations: 'Falha ao receber convites',
      unauthorizedPrivateBook:
        'Este livro é privado e não está disponível para esta conta.',
      invalidToken: 'O convite não é válido para esta conta ou ele expirou.',
      RaiseYourReaderLimit:
        'Aumentar o seu limite de leitores (atualmente é: {{x}})',
      InviteYourFriends: 'Convide seus amigos',
      referralText:
        '<0> Obtenha até 10 slots de leitor extras, convidando seus amigos para BetaReader.io! </0> <1> Para cada amigo que criar uma conta de autor validada e listar um manuscrito graças a você, nós daremos a você e ao seu amigo 1 slot de leitor cada, até um máximo de 10 slots. </1> <2> Você também pode aumentar seu limite de leitor lendo e revisando 100k palavras por meio de nosso <1> <0> aplicativo de leitor </0> </1>, ou <3><0> atualizando</0> </3> sua conta! </2>',
      INVITED: 'Convidado',
      CREATED_ACCOUNT: 'Conta criada',
      CONFIRMED: 'Confirmado',
      RegistrationDate: 'Data de registro',
      Profile: 'Perfil',
      EditProfile: 'Editar Perfil',
      GetMoreReaders: 'Obter mais leitores',
      Status: 'Status',
      maxReadersLimitMessage:
        '<0>Você atingiu seu limite de leitores. <1>Atualize</1> para enviar mais convites em nosso índice do leitor ou aprovar solicitações de leitura. Em nosso plano gratuito, você pode sugerir <3>trocar</3> com outros escritores ou convidar quantos leitores desejar diretamente por meio de seus endereços de e-mail.</0>',
      SwapsDontAffectReaderLimit:
        'A troca de manuscritos não conta para o seu limite de leitores.',
      maxBooksLimitMessage:
        '<0>Você atingiu seu limite de manuscrito. <1>Aumentar o limite</1> para ser capaz de testar vários manuscritos ao mesmo tempo.</0>',
      Type: 'Tipo',
      'referral-type_REFERRAL': 'REFERÊNCIA',
      'referral-type_SIGNUP_BONUS': 'bônus de inscrição',
      'referral-type_READ_AND_REVIEW': 'LEIA & REVISE',
      unlockBonusText:
        '<0>Desbloquei o seu bônus de inscrição!</0> <1>Parece que você foi indicado por um amigo! Siga os passos abaixo para desbloquear o bônus </1> <2> <0><0 ></0> <1><0>Valide sua conta </ ​​0> <1>Você precisa de um nome e endereço de email válido</1></1></0> <1><0></0> <1><0>Liste o  manuscrito</0> <1>Liste o manuscrito que você deseja testar</1></1></1> <2> <0></0> <1><0>Espere</0> <1>Demora cerca de três dias para a gente validar</1></1></2></2>',
      titleDiscoveryRecommended: 'Recomendado para você',
      subheaderDiscoveryRecommended:
        'Algo novo com base em seus gêneros selecionados',
      titleDiscoveryRecentlyAdded: 'Adicionado recentemente',
      subheaderDiscoveryRecentlyAdded: 'títulos adicionados recentemente',
      discoveryRecentlyAddedTitlesInGenre:
        'Títulos recentemente adicionados ao gênero {{x}}',
      titleDiscoveryPremium: 'Os títulos em destaque',
      subheaderDiscoveryPremium: 'Títulos de autores prémio',
      referralMessage:
        'Convido você ao BetaReader.io! Se você se inscrever através deste link, nós dois vamos receber um bônus!',
      referralBody: 'Registe-se aqui: {{x}}',
      ThisCannotBeUndone: 'Isto não pode ser desfeito',
      DeletedVersion: 'A versão foi excluída',
      InvalidEmail: 'E-mail inválido',
      EmailNotVerified: 'Não verificado, por favor confira o seu e-mail',
      SelectFavoriteGenres: 'Selecione seus gêneros favoritos',
      Loading: 'Carregando',
      ChapterIdentification: 'Identificação de Capítulo',
      ChapterAnalysis: 'Análise do capítulo',
      CompleteImport: 'Concluir a importação',
      Import: 'Importar',
      Processing: 'Carregando arquivo...',
      ThisMightTakeAWhile: 'Isso pode demorar um pouco',
      LikeThisCover: 'Gostou da capa deste livro?',
      DownloadFailedMsg:
        'O download falhou. Será que ele foi interrompido por um bloqueador de pop-up?',
      ShareAuthorAppEmailSuccessMessage:
        'Convite enviado. Verifique o status <1>aqui</1>.',
      failedToUpdateReader: 'Falha ao atualizar o leitor',
      activeReaders: 'Leitores ativos',
      ReadingList: 'Lista de leitura',
      Users: 'Usuários',
      InviteUser: 'Convidar Usuário',
      Role: 'Opção',
      member_role_admin: 'Administrador',
      member_role_member: 'Membro',
      couponsOrDiscountsWillNotBeKept:
        '<0>Os descontos existentes não vão para o novo plano.</0>',
      FailedToFetchX: 'Falha ao buscar {{x}}',
      YouHaveBeenInvitedToJoinAccountX:
        'Você foi convidado para se juntar {{x}} ao BetaReader.io.',
      AcceptAccountInvitationMsg:
        'Se você aceitar o convite, você poderá ver e editar todos os manuscritos desta conta.',
      InvitedMembersMsg:
        'Membros da conta serão capazes de ver e editar todos os manuscritos em sua conta, mas eles não vão ver todos os detalhes de faturamento. Consulte betareader.io/pricing para informações sobre o custo por membro da conta.',
      InviteCollaborator: 'Convidar colaborador',
      InviteCollaboratorMsg:
        'Colaboradores podem ver o manuscrito e detalhes de leitura, mas também pode responder comentários.',
      YouHaveBeenInvitedToCollaborateOnX:
        'Você foi convidado para colaborar em {{book}} por {{autor}}',
      AcceptCollaborationInvitationMsg:
        'Se você aceitar esse convite, você poderá  ver o manuscrito e detalhes de leitura, mas também pode responder comentários.',
      Collaborators: 'colaboradores',
      ThisIsABetaFeature:
        'Este é um recurso beta. Deixe-nos saber se se você tiver problemas com ele, ou se você tiver qualquer feedback para compartilhar.',
      UserNotFoundMsg:
        'Parece que você não tem uma conta. Criar uma <1>aqui</1>!',
      AuthorName: 'Nome do autor',
      Details: 'detalhes',
      Settings: 'Configurações',
      ReviewAtLeastXwords: 'Resenha de pelo menos {{x}} palavras.',
      CheckBonus: 'Verifique seu bônus',
      FailedToAddCommentOrReaction: 'Falha ao adicionar comentário ou reação',
      ThisIsAWIP: 'Em Andamento',
      WIPExplanation:
        'Categorize sua história EA (Em Andamento) se a sua hstória ainda não estiver terminada. Categorize como e.g. se você não adicionou todos os capítulos.',
      MatureContent: 'conteúdo adulto',
      PublicationDate: 'Data de publicação',
      TimePlan: 'Plano de Tempo',
      NotSpecified: 'Não especificado',
      WhatIsThisStoryAbout: 'Sobre o que é essa história?',
      WhatDoYouNeedHelpWith: 'Com o que você precisa de ajuda?',
      StartWriting: 'Comece a escrever',
      CreateManuscript: 'Criar manuscrito',
      ImportManuscript: 'Importar manuscrito',
      ImportManuscriptStepDescription: 'Carregar ou criar o seu manuscrito',
      InviteReaders: 'Convide seus leitores',
      InviteReadersStepDescription: 'Você decide quem pode ler',
      CollectFeedback: 'coletar feedback',
      CollectFeedbackStepDescription:
        'Analisar comentários & padrões de leitura',
      YouDontHaveAnyManuscripts: 'Você ainda não tem nenhum manuscrito',
      AuthorsAndPublishers: 'Autores e editores',
      FindOutWhatYourReadersThink:
        'Descubra o que seus leitores realmente acham de seu livro',
      ReadAndInfluenceTheNextBestseller: 'Compartilhamento privado',
      PrivateSharing: 'Compartilhamento privado',
      PrivateSharingDescription:
        'Compartilhe seu manuscrito em particular com os leitores',
      CollectUnbiasedFeedback: 'Coletar feedback imparcial',
      CollectUnbiasedFeedbackDescription:
        'Deixe seus leitores comentarem sem ver os feedbacks uns dos outros',
      AnalyzeReadingPatterns: 'Analisar os padrões de leitura',
      AnalyzeReadingPatternsDescription:
        'Descubra onde seus leitores perdem o interesse ou onde se interessam pela história',
      ReadItFirst: 'Li pela primeira vez',
      ReadItFirstDescription:
        'Leia próximos romances, enquanto eles ainda estão em beta',
      InfluenceTheStory: 'Influenciar a história',
      InfluenceTheStoryDescription:
        'Os seus comentários vão influenciar o resultado final',
      ConnectWithAuthors: 'Conecte-se com os autores',
      ConnectWithAuthorsDescription:
        'Ajude seus autores favoritos, tornando-se parte de sua equipe beta',
      YourBooks: 'Seus livros',
      New: 'Novo',
      Members: 'Membros',
      Contacts: 'Contatos',
      AddMembersFromContacts: 'Adicionar membros da sua lista de contatos',
      ConversationSettings: 'configurações de conversa',
      PremiumAuthorInfo:
        'Este é um manuscrito de um autor premium. Isso normalmente significa que o autor está investindo mais no projeto e você pode esperar que eles façam o que for necessário para publicar este livro.',
      StillWorkingOnThis:
        'Nós ainda estamos construindo BetaReader.io. Deixe-nos saber se há alguma coisa específica que você quer ver aqui.',
      Engagement: 'Noivado',
      AssemblingLetters: 'Montagem letras ...',
      ConstructingAlphabet: 'Construindo alfabeto ...',
      BuildingSentences: 'Construindo frases ...',
      BecomingSentient: 'Tornando-se senciente ...',
      BooksRead: 'Livros lidos: {{x}}',
      WordsReviewed: 'Palavras revisado: {{x}}',
      AvgTurnAroundTimePer1kWords:
        'Média de tempo de rotação por 1000 palavras: {{count}} dia',
      AvgTurnAroundTimePer1kWords_plural:
        'tempo de resposta médio por 1000 palavras: {{count}} dias',
      ViewAsReader: 'Ver como leitor',
      EditMode: 'Modo de edição',
      BookCover: 'Capa de livro',
      WelcomeToBRReadathon: 'Bem-vindo ao BetaReader.io Readathon!',
      ReadathonHasEnded: 'O Readathon acabou de terminar.',
      ReadathonInfo:
        'O BetaReader.io Readathon dura entre 26 de setembro e 31 de outubro de 2019. Qualquer um que lê e comentários pelo menos um livro via BetaReader.io durante este tempo participa, e você pode aumentar sua chance de ganhar pela leitura e rever mais livros. O vencedor será escolhido no dia 1º de novembro, e mais palavras que você leu e revisado por então, os maiores são suas chances de ganhar. Por exemplo, alguém que tenha lido e revisto 50.000 palavras terão 5x tem alta chance de ganhar como alguém que tenha lido e revisto 10.000 palavras.',
      ReadathonPrize1: '1º prémio: $ 50 Gift Card Amazon.',
      ReadathonPrize23: '2o-3o prêmio: $ 20 Gift Card Amazon.',
      SignInToJoin: 'Faça login para participar',
      WordsReviewedByYou: 'Palavras revisados ​​por você',
      WordsReviewedByEveryone: 'Total de palavras revisada',
      ChanceOfWinning: 'Oportunidade atual de ganhar',
      Completed: 'concluído',
      CompletedAt: 'concluída no',
      Pending: 'Pendente',
      Position: 'Posição',
      FindSomethingToRead: 'Encontrar algo para ler',
      DontKnow: 'Não sei',
      IsThisReadyToPublish:
        'Você acha que este livro está pronto para ser publicado?',
      UpdateProfileHeader:
        'Precisamos de mais algumas informações para o seu perfil',
      WhatsYourName: 'Qual é o seu nome ou pseudônimo?',
      WhereAreYouFrom: 'De onde você é?',
      WhenWereYouBorn: 'Que ano você nasceu?',
      WhichGenresDoYouPrefer: 'Qual gêneros você prefere?',
      WhichLanguagesDoYouRead: 'Que línguas você lê?',
      BioPlaceholder:
        'Diga-nos quem você é, e se você tiver quaisquer sites para consulta. A bio mais forte irá ajudá-lo a obter aprovação para mais manuscritos.',
      Thanks: 'Obrigado!',
      WhenDoYouWantToHearFromUs: 'Quando você quer ouvir de nós?',
      ReaderListConsentHeader:
        'Você quer ser incluído no nosso banco de dados leitor?',
      ReaderListConsentInfo:
        'Autores com obras correspondentes suas preferências será capaz de enviar-lhe o link para seus livros, mas você não é obrigado a ler se você não quiser ou não tiver o tempo.',
      RoleHeader: 'Você é um escritor ou um leitor?',
      RoleInfo: 'Usamos essa informação para otimizar a sua experiência.',
      OnYourProfile: 'Isso será exibido em seu perfil de usuário.',
      ProfileBuilderThankYouMessage:
        '<0>Obrigado!</ 0><1>Você sempre pode atualizar seu perfil <1>aqui</1>.</1>',
      DoThisLater: 'Fazer isso mais tarde',
      '2MonthsFree': '2 meses livre',
      Joined: 'Juntou',
      UserInfo: 'Informação de usuário',
      Age: 'Era',
      Bio: 'Bio',
      ReaderList: 'Encontre leitores',
      Popular: 'Popular',
      BookHas0Words: '0 palavras',
      BookHas0WordsDescription:
        '<0>Este libro tiene por lo Menos una versión con 0 palabras. Puede agregar o cargar Capítulos </0> <1>aquí</1>.',
      LeaveBeta: 'Deixe beta',
      DiscoverNewBooks: 'Descubra novos livros',
      FailedToFindReader: 'Falha ao localizar leitor',
      ApprovedReadRequest: 'solicitação de leitura é aprovado',
      Approved: 'aprovado',
      UserAlreadyInvited: '{{user}} já está convidado',
      SelectBook: 'Escolha um livro',
      InvitationMessagePlaceholder:
        'Incluir uma mensagem pessoal em seu convite para aumentar suas chances de ser aceita.',
      YouHaveAPendingInvitation: 'Tem um convite pendente',
      ReaderIndexInfo:
        'O banco de dados do leitor contém usuários do BetaReader.io que indicaram que estão abertos para serem abordados pelos autores. Envie a eles um convite com seu melhor argumento, mas lembre-se de que eles podem recusar seu convite por qualquer motivo.',
      ReaderLimitReached: 'Atingiu o limite de leitor',
      Invite: 'Convite',
      Languages: 'Línguas',
      LinkWasSentToX: 'Enviamos um link de login para {{x}}',
      Verifying: 'Verificando ...',
      FoundNoAvailableManuscripts: 'Nós não encontrou manuscritos disponíveis',
      FindASpecificQuestion: 'As perguntas filtro',
      ReadAndReviewBonusInfo:
        'Seu bônus de READ & REVIEW é atualizado após o término e a revisão de um livro.',
      ManuscriptStatus: 'Estatuto manuscrito',
      MSStatusDescription_alpha:
        'Esta é uma fase inicial ou manuscrito parcial.',
      MSStatusDescription_beta:
        'Este é um manuscrito de estágio final de um livro completo.',
      SelectStatus: 'Escolha um estatuto',
      MaxAllowedGenres: 'Você só pode selecionar até 5 gêneros',
      TooLongDescription:
        'Sua descrição é muito longa (máximo de 1.000 caracteres)',
      Duplicate: 'Duplicado',
      Download: 'Baixar',
      DuplicateVersionInfo:
        'Quando se duplica uma versão, todos os capítulos e os inquéritos serão copiados para a nova versão, mas os leitores vão permanecer na versão antiga.',
      NoBookMsg:
        '<0> Não há nada aqui! </ 0> <1> <0> Deseja </ 0> <1> adicionar seu próprio manuscrito? </ 1> </ 1>',
      CommentsCopiedFromOriginal:
        'Os comentários foram copiados do documento original',
      CopyComments: 'Copie todos os comentários',
      MigrateReaders: 'Migrar leitores',
      Posts: 'Postagens',
      Sent: 'Enviei',
      Username: 'Nome do usuário',
      UsernameAlreadyTaken: 'Esse nome de usuário já está em uso',
      Followers: 'seguidores',
      ReadersAndFollowers: 'Leitores e seguidores',
      WhoCanSeeThis: 'Quem pode ver isso?',
      FollowCount: '{{count}} seguidor',
      FollowCount_plural: '{{count}} seguidores',
      Follow: 'Segue',
      Following: 'Segue',
      MarkAllAsRead: 'marcar tudo como lido',
      Working: 'Trabalhando...',
      Audience: 'Público',
      ShowAllComments: 'Mostrar todos os comentários ...',
      Publish: 'Publicar',
      InviteByEmail: 'Convidar por email',
      InviteByLink: 'Convida por ligação',
      Chat: 'Bate-papo',
      WelcomeToSupportMessage:
        'Olá x}}! Bem-vindo ao suporte Betareader 👋, tentamos responder o mais rapidamente possível. Em que podemos ajudá-lo?',
      TalkToSupport: 'Fale com nossa equipe de suporte',
      StartSupportChat: 'Iniciar bate-papo apoio',
      StartSupportChatPMessage:
        '<0> Você pode sempre contactar-nos através do nosso chat e nós responderemos o mais rapidamente possível. </ 0>',
      FindHelpArticlesMessage:
        '<0> Precisa de ajuda? consulte a nossa <1> help </ 1> seção, cheio de artigos de ajuda para guiá-lo. </ 0>',
      HelpArticles: 'artigos de Ajuda',
      CloseConversationAlertHeader: 'Conversa fechada',
      CloseConversationAlertMessage: 'Você quer fechar esta conversa?',
      EmailSupportMessage:
        '<0> Envie um e-mail para <1> {{mail}} </ 1>, e nós responderemos o mais rapidamente possível. </ 0>',
      Includes: 'inclui',
      DoesNotInclude: 'Não inclui',
      IncludesAll: 'inclui todos',
      RemoveFilter: 'remove filter',
      AddFilter: 'Adicionar Filtro',
      SelectGenres: 'Seleccione gêneros',
      And: 'E',
      ContinueWithGoogle: 'Continue com Google',
      ContinueWithFacebook: 'Continue com Facebook',
      ContinueWithApple: 'Continue com a Apple',
      NewOnBetareader: 'Novo para BetarReader?',
      AlreadyHaveAccount: 'já tem uma conta?',
      SignUpWithFacebook: 'Cadastre-se com Facebook',
      SignUpWithGoogle: 'Inscrever com Google',
      SignUpWithApple: 'Inscreva-se com a Apple',
      OpportunityWithoutDescriptionMessage:
        'O autor não providenciou uma descrição.',
      SignInToViewContent: 'Entre para visualizar este conteúdo',
      ContentBelongsToAnotherAccount:
        'Este conteúdo pertence a uma conta diferente do que seu ativo.',
      SwitchAccount: 'Mudar de conta',
      OwnBooks: 'Livros próprios',
      Collaborations: 'Colaborações',
      UploadImage: 'Enviar Imagem',
      SplitChapter: 'Dividir o capítulo',
      UpgradeToUnlock: 'Atualize para desbloquear',
      '3ReaderSlots': '3 slots de leitor',
      UnlimitedReaderSlots: 'Ranhuras do leitor ilimitadas',
      ImportWarning: 'aviso de importação',
      ImportExeedLenghMessage:
        '<0> <0> Um ou mais dos seus capítulos parecem ser muito tempo para importação, certifique-se de que você seguiu os nossos <1> diretrizes de importação. </ 1> </ 0> <1> Dica: você pode Hower sobre o texto do capítulo e usar a ferramenta sizzor dividir capítulos maiores </ 1> </ 0>',
      OneTeamMember: '1 membro da equipe',
      AdditionalTeamMembers: 'Adicionar membros da equipe',
      PerMonth: 'por mês',
      PremiumSubscriptionTitle: 'Premium',
      GoProWithPremium: 'Go Pro with a Premium account',
      SubmitManuscript: 'Envie seu manuscrito',
      SubmitTo: 'Enviar para',
      YourSubmittedManuscripts: 'Seus manuscritos enviados',
      SelectManuscriptToSubmit: 'Selecione o manuscrito a ser enviado',
      AvailableToRead: 'Disponível para leitura',
      ManuscriptSubmissionSuccess: 'Manuscrito submetido',
      ManuscriptSubmittedTo:
        'Seu manuscrito, {{_manuscriptName}}, foi enviado para {{_inboxName}}',
      SubmittedBy: 'Enviado por {{_userName}}',
      ReceivedManuscripts: 'Manuscritos recebidas',
      InviteToRead: 'Convide para ler',
      YourReview: 'Sua avaliação',
      UserReviewPlaceholder:
        'As avaliações dos usuários são visíveis para qualquer pessoa.',
      LeaveReviewError:
        'Você só pode revisar usuários que terminaram de ler um de seus manuscritos.',
      NotEnoughData: 'Dados insuficientes',
      EditSignUpSurveyQuestions: 'Editar perguntas da pesquisa de inscrição',
      AddSignUpSurveyQuestions: 'Adicionar perguntas da pesquisa de inscrição',
      To: 'para',
      FinishedReadingAt: 'Leitura terminou em {{x}}',
      FailedToSendInvitation:
        'Falha ao enviar o convite, por favor, verifique o endereço de email',
      EditInvitation: 'Editar convite',
      ChapterSeparator: 'Kaflaskilja',
      CancelReason:
        'Antes de continuar, você se importaria de nos dizer por quê?',
      cancel_reason_technical_issues: 'Problemas técnicos',
      cancel_reason_too_expensive: 'O produto é muito caro',
      cancel_reason_finished_my_beta: 'Eu terminei o meu projeto',
      cancel_reason_switching_to_another_product:
        'Eu estou mudando para outro produto',
      cancel_reason_missing_features:
        'Eu estou sentindo falta de características importantes',
      cancel_reason_did_not_meet_expectations:
        'A plataforma não satisfazer as minhas expectativas',
      cancel_reason_other: 'Outras razões',
      TellUsMore: 'conte-nos mais',
      DeleteAccount: 'Excluir conta',
      YourAccountHasBeenDeleted: 'Sua conta foi excluída',
      DeleteAccountConfirmation:
        'Tem certeza de que deseja excluir sua conta e todos os seus dados? Essa ação não pode ser desfeita.',
      GoodbyeMessage:
        'Lamentamos que você nos deixe e esperamos que volte no futuro.',
      EditContent: 'Editar conteúdo',
      AffectedReadersMessage:
        '{{count}} leitor já leu este capítulo. Clique aqui para postar uma atualização sobre suas mudanças.',
      AffectedReadersMessage_plural:
        '{{count}} leitores já leram este capítulo. Clique aqui para postar uma atualização sobre suas mudanças.',
      ImportingChapterXofY: 'Importando capítulo {{_x}} / {{_y}}',
      ImportStarted: 'Importação iniciada',
      XVersions: '{{count}} versão',
      XVersions_plural: '{{count}} versões',
      UnsubscribeSuccessful:
        'Sua inscrição foi cancelada. Você sempre pode atualizar suas preferências através do seu perfil.',
      UnsubscribeUnsuccessful:
        'Falha ao cancelar inscrição. Envie uma mensagem para support@betareader.io se o problema persistir.',
      GoodbyeHeader: 'Adeus!',
      BulkAddReaders: 'Adicionar leitores de CSV',
      Home: 'Casa',
      Actions: 'Ações',
      Invitations: 'Convites',
      SelectCSVWithReaders: 'Selecione CSV com leitores',
      Synopsis: 'Sinopse',
      SynopsisDescription:
        'Deixe nossa IA, BetaBot, gerar uma sinopse baseada no seu texto. Não será visível para seus leitores.',
      LastUpdated: 'Última atualização',
      CreateSynopsis: 'Criar sinopse',
      RefreshSynopsis: 'Atualizar sinopse',
      ElevatorPitch: 'Passo de elevador',
      ThisIsAPremiumFeature: 'Recurso premium',
      GoBack: 'Volte',
      Retry: 'Tentar novamente'
    }
  }
};
