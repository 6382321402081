import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

export const DELETE_USER = 'user/DELETE_USER';
export const LOGGED_IN = 'user/USER_LOGGED_IN';
export const LOGGED_OUT = 'user/LOGGED_OUT';
export const UPDATE_USER = 'user/UPDATE_USER';
export const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
export const UPDATING_USER_PROFILE = 'user/UPDATING_USER_PROFILE';
export const UPDATE_ID_TOKEN = 'user/UPDATE_ID_TOKEN';
export const UPDATE_COUNTRY_OPTIONS = 'user/UPDATE_COUNTRY_OPTIONS';
export const colorModes = ['bright', 'dark'];
export const initialState = {
  user: undefined,
  idToken: undefined,
  userProfile: undefined,
  updatingUserProfile: [],
  socialProfiles: undefined,
  currentLanguage: 'en',
  currentFontSize: 'normal',
  currentColorMode: 'bright',
  countryOptions: undefined,
};

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        user: action.user,
        idToken: action.idToken,
        socialProfiles: action.socialProfiles,
      };
    case UPDATE_ID_TOKEN:
      return {
        ...state,
        idToken: action.idToken,
      };
    case LOGGED_OUT:
      return {
        ...state,
        user: undefined,
        idToken: undefined,
        userProfile: undefined,
        socialProfiles: undefined,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
        userUpdated: Date.now(),
      };
    case UPDATING_USER_PROFILE:
      return {
        ...state,
        updatingUserProfile: action.updating,
      };
    case UPDATE_PROFILE:
      if (action.profile && action.profile !== state.userProfile) {
        const gaDimensions = {
          dimension2: action.profile.type, // user type
          dimension3: action.profile.role, // user role
        };

        if (action.profile.account) {
          const { subscription, product } = action.profile.account;
          // get the current subscription product (if any)
          const productId = product && product.internalId;
          // get the current subscription billing interval (if any)
          const billingInterval =
            subscription &&
            subscription.pricingPlan &&
            subscription.pricingPlan.billingInterval;
          // add subscription product to GA dimensions
          gaDimensions.dimension4 = productId;
          // add subscription billing interval to GA dimensions
          gaDimensions.dimension5 = billingInterval;
        }
        ReactGA.set(gaDimensions);
      }
      return {
        ...state,
        userProfile: action.profile,
        userProfileLoaded: true,
      };
    case UPDATE_COUNTRY_OPTIONS:
      return {
        ...state,
        countryOptions: action.countryOptions,
      };
    default:
      return state;
  }
};

// actions
export const loggedIn = (user, idToken) => {
  ReactGA.set({ dimension1: true });
  const socialProfiles = {};
  user.providerData.forEach(provider => {
    if (provider.providerId === 'google.com') {
      socialProfiles.google = provider;
    }
    if (provider.providerId === 'facebook.com') {
      socialProfiles.facebook = provider;
    }
    if (provider.providerId === 'twitter.com') {
      socialProfiles.twitter = provider;
    }
  });
  return dispatch => {
    dispatch({
      type: LOGGED_IN,
      user,
      idToken,
      socialProfiles,
    });
  };
};

export const loggedOut = () => {
  ReactGA.set({ isLoggedIn: false });
  return dispatch => {
    dispatch({
      type: LOGGED_OUT,
      user: undefined,
      idToken: undefined,
      userProfile: undefined,
    });
  };
};

export const updateUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER,
      user,
    });
  };
};

export const getUserProfile = idToken => {
  const refererToken = localStorage.getItem('br-signup-referer-token');
  const referredEmail = localStorage.getItem('br-signup-referred-email');
  const displayName = localStorage.getItem('br-signup-display-name');
  const targetUrl = localStorage.getItem('br-signup-target-url');
  const signupLng = localStorage.getItem('br-signup-lng');

  const headers = {
    'Content-Type': 'application/json',
    'br-token': idToken,
  };

  if (refererToken) {
    headers['br-signup-referer-token'] = refererToken;
    localStorage.removeItem('br-signup-referer-token');
  }
  if (referredEmail) {
    headers['br-signup-referred-email'] = referredEmail;
    localStorage.removeItem('br-signup-referred-email');
  }
  if (displayName) {
    headers['br-signup-display-name'] = displayName;
    localStorage.removeItem('br-signup-display-name');
  }
  if (targetUrl) {
    headers['br-signup-target-url'] = targetUrl;
    localStorage.removeItem('br-signup-target-url');
  }
  if (signupLng) {
    headers['br-signup-lng'] = targetUrl;
    localStorage.removeItem('br-signup-lng');
  }

  return dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/profile`,
      {
        method: 'GET',
        headers,
      }
    )
      .then(res => {
        if (!res.ok) {
          console.log('profile error', res.error);

          throw new Error(res.error);
        }
        return res.json();
      })
      .then(profile => {
        // if the user had a profile
        if (profile && profile._id !== undefined) {
          if (
            profile.displayName === undefined ||
            profile.role === undefined ||
            profile.preferredGenres === undefined
          ) {
            window.localStorage.removeItem('filled-profile');
          } else {
            window.localStorage.setItem('filled-profile', 'true');
          }
          return dispatch({
            type: UPDATE_PROFILE,
            profile,
            profileFetched: true,
          });
        }

        window.localStorage.removeItem('beta-access');
        window.localStorage.removeItem('filled-profile');
        return dispatch({
          type: UPDATE_PROFILE,
          profile: {},
          profileFetched: true,
        });
      })
      .catch(err => {
        console.log('no user profile');
      });
  };
};

export const updateUserProfile = (idToken, profileData, callback) => {
  return dispatch => {
    dispatch({
      type: UPDATING_USER_PROFILE,
      updating: Object.keys(profileData),
    });
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/profile`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify(profileData),
      }
    )
      .then(res => res.json())
      .then(profile => {
        if (profile.err) {
          throw new Error(profile.msg);
        }
        // if the user had a profile
        if (callback) {
          callback(true);
        }
        return dispatch({
          type: UPDATE_PROFILE,
          profile,
        });
      })
      .catch(err => {
        toast.error(err.toString());
        if (callback) {
          callback(false);
        }
      })
      .finally(() => {
        dispatch({
          type: UPDATING_USER_PROFILE,
          updating: [],
        });
      });
  };
};

export const refreshUserProfile = profileData => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROFILE,
      profile: profileData,
    });
  };
};

export const updateIdToken = newIdToken => {
  return dispatch => {
    dispatch({
      type: UPDATE_ID_TOKEN,
      idToken: newIdToken,
    });
  };
};

export const fetchCountryOptions = (idToken, lng) => dispatch => {
  fetch(
    `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/global/countries?lng=${lng}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'br-token': idToken,
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        throw new Error(res.error);
      }
      return res.json();
    })
    .then(countryOptions => {
      if (countryOptions) {
        dispatch({
          type: UPDATE_COUNTRY_OPTIONS,
          countryOptions,
        });
      }
    })
    .catch(err => {
      console.error(err);
    });
};

export const deleteUser =
  ({ idToken, reason, feedback }, callback) =>
  dispatch => {
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/profile`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken,
        },
        body: JSON.stringify({
          reason,
          feedback,
        }),
      }
    )
      .then(res => {
        dispatch({
          type: DELETE_USER,
        });
        callback && callback(!!res.ok);
      })
      .catch(err => {
        console.error(err);
      });
  };
