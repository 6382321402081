import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { MediaContextProvider, createMediaStyle } from 'src/utils/Media';
import './App.css';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/sv';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/fi';
import 'moment/locale/is';
import 'moment/locale/de';

import { useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import useAuthentication from './hooks/useAuthentication';
import usePaddle from './hooks/usePaddle';

import ContextMenu from './containers/menu/contextMenu';

import i18n from './utils/i18n/i18n';
import 'react-toastify/dist/ReactToastify.min.css';
import ApplicationContent from './AppContent';

import { getTheme } from './constants/theme';
import { AutoSaveProvider } from './hooks/useAutoSaveContext';
import usePreventBrowserSave from './hooks/usePreventBrowserSave';
import {
  HostAppProxyProvider,
  useHostAppProxy,
} from './contexts/HostAppProxyContext';
import { Loader } from 'semantic-ui-react';
import { useAuth } from './contexts/AuthContext';

const mediaStyles = createMediaStyle();

const AppWrapper = styled.div`
  height: 100vh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';

  > header {
    grid-area: header;
  }
`;

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// Create a client
const queryClient = new QueryClient();

const App = () => {
  usePreventBrowserSave();
  const {
    settings: { customAuthToken: hostAppCustomAuthToken },
  } = useHostAppProxy();

  const userProfile = useSelector(state => state.user.userProfile);
  const loadingAppAuth =
    hostAppCustomAuthToken !== undefined && userProfile === undefined;

  // Use the custom hooks
  usePaddle();
  const { renders } = useAuth();

  useEffect(() => {
    window.name = process.env.REACT_APP_WINDOW_NAME;
    // ReactGA.initialize('UA-101984305-1', {
    //   testMode: process.env.NODE_ENV !== 'production',
    // });
  }, []);

  const swapTheme = (colorMode: string) => {
    const colorSheet = document.querySelector('#color-theme');
    if (!colorSheet) {
      return;
    }
    colorSheet.setAttribute('href', `/styles/color-${colorMode}.css`);
  };

  useEffect(() => {
    if (userProfile) {
      i18n.changeLanguage(userProfile.language);
      moment.locale(userProfile.language);
      swapTheme(userProfile.readerSettings.colorMode);
    }
  }, [
    userProfile,
    userProfile?.language,
    userProfile?.readerSettings?.colorMode,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <MediaContextProvider>
        <Helmet>
          <style>{mediaStyles}</style>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-779841759"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'AW-779841759', {
              'linker': {
                'accept_incoming': true
              }
            });
            `}
          </script>
          <script>
            {`
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              // Insert Twitter Pixel ID and Standard Event data below
              twq('init','o0kcm');
              twq('track','PageView');
          `}
          </script>
          <script src="//platform.twitter.com/oct.js" type="text/javascript" />
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '171805850082105');
                fbq('track', 'PageView');
            `}
          </script>
          <noscript>
            {`
              <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=171805850082105&ev=PageView&noscript=1"
              />
            `}
          </noscript>
        </Helmet>
        <ThemeProvider theme={getTheme(userProfile?.readerSettings?.colorMode)}>
          <I18nextProvider i18n={i18n}>
            <AutoSaveProvider>
              <AppWrapper>
                {loadingAppAuth ? (
                  <Loader active />
                ) : (
                  <>
                    {!inIframe() && <ContextMenu />}
                    <ApplicationContent />
                  </>
                )}
              </AppWrapper>
              <ToastContainer
                autoClose={2500}
                hideProgressBar
                position="bottom-right"
                draggable
              />
            </AutoSaveProvider>
          </I18nextProvider>
        </ThemeProvider>
      </MediaContextProvider>
    </QueryClientProvider>
  );
};

export default App;
