import React, { useEffect } from 'react';

import { useTranslation, withTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import {
  updateSurveyAnswer,
  fetchSurveyAnswers,
  submitSurveyAnswers,
  updateQuestionDomReference,
  clearSurvey,
} from '../../../../modules/survey';

import Question from './question';

import { getFreshIdToken } from '../../../../utils/authUtil';

export const Survey = ({ percentagePassedUpdated }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBook = useSelector(state => state.book.currentBook);
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const answers = useSelector(state => state.survey.answers);
  const fetchedAnswers = useSelector(state => state.survey.fetchedAnswers);
  const defaultFontSize = useSelector(state => state.app.defaultFontSize);
  const userProfile = useSelector(state => state.user.userProfile);
  const currentFontSize =
    userProfile !== undefined
      ? userProfile.readerSettings.fontSize
      : defaultFontSize;

  const currentItem = currentBook.parts[currentSpineIndex];

  useEffect(() => {
    const doFetchSurveyAnswers = async data => {
      const idToken = await getFreshIdToken();
      const filter = 'own';
      dispatch(fetchSurveyAnswers(idToken, data, filter));
    };

    doFetchSurveyAnswers({ surveyId: currentItem._id }); // fetch survey answers on first load

    return () => {
      dispatch(clearSurvey()); // clear survey on exit
    };
  }, [currentSpineIndex]);

  // custom methods
  const answerChanged = async (questionIndex, questionId, newAnswer) => {
    const idToken = await getFreshIdToken();
    const survey = currentBook.parts[currentSpineIndex];
    dispatch(
      updateSurveyAnswer(
        idToken,
        currentBook._id,
        survey._id,
        questionId,
        newAnswer
      )
    );
    percentagePassedUpdated({
      top: 0,
      bottom: (questionIndex + 1) / survey.questions.length,
    });
  };

  return (
    <div
      style={{ maxWidth: 768 }}
      className={`br-text font-size-${currentFontSize}`}
    >
      <span>{currentItem.description}</span>
      {currentItem.questions &&
        currentItem.questions.map((question, index) => {
          let answer = {};
          if (answers) {
            answer =
              answers.find(answer => answer.surveyQuestion === question._id) ||
              {};
          }
          return (
            <Question
              tabIndex={index * 10}
              key={question._id}
              question={question}
              questionIndex={index}
              answer={answer}
              fetchedAnswers={fetchedAnswers}
              handleReference={ref => {
                dispatch(updateQuestionDomReference(question._id, ref));
              }}
              onAnswerChanged={(newAnswer, isDone) => {
                answerChanged(index, question._id, newAnswer, isDone);
              }}
              action={t('AnswerSurvey')}
            />
          );
        })}
    </div>
  );
};

export default Survey;
